import instance from "../../index";


export default GetOperatorById

async function GetOperatorById(id: string): Promise<GetOperatorResponse> {
    const response = await instance.get(`/v1/backoffice/operadores/${id}`).catch();
    return response?.data?.data;
}

export interface GetOperatorRequest {
    id: string;
}

export interface GetOperatorResponseData {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    createdAt: Date;
    updatedAt: Date;
    isActive: boolean;
    clients: string[];
    clientName: string;
}

export interface GetOperatorResponse {
    success: boolean;
    data: GetOperatorResponseData;
}