import React, {ChangeEvent, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useBlockUI} from "../../../shared/services/block-ui-service";
import {CreatePesquisaSaudeParticipantes} from "../../../services/sistema-aulas/pesquisa-saude/create-pesquisa";
import {toast} from "react-toastify";
import {PesquisaSaudeParticipantes} from "../../../models/pesquisaSaudeParticipantes";
import {Button, Form, Grid, Header, Segment} from "semantic-ui-react";
import {
    EPerguntaSobreAmbienteDeTrabalhoPesquisaSaude
} from "../../../enums/EPerguntaSobreAmbienteDeTrabalhoPesquisaSaude";
import {
    EPerguntaDesconfortoUltimosDiasPesquisaSaude
} from "../../../enums/EPerguntaDesconfortoUltimosDiasPesquisaSaude";
import {
    EPerguntaOqueSeAplicouParaVoceNaUltimaSemana
} from "../../../enums/EPerguntaOqueSeAplicouParaVoceNaUltimaSemana";
import {GetAllClientsToDropdownAnon} from "../../../services/backoffice/clients/get-clients";

const CreatePesquisaSaudePage = () => {
    const [pesquisa, setPesquisa] = useState<Partial<PesquisaSaudeParticipantes>>({});
    const navigate = useNavigate();
    const [clientes, setClients] = useState<any[]>([])

    const [loading, setLoading] = useState<boolean>(false);
    const blockUI = useBlockUI();
    const [error, setError] = useState<string>('');
    useEffect(() => {
        blockUI?.setActive(loading)
    }, [loading]);


    const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setPesquisa(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleDropdownChange = (name: string, data: any) => {
        setPesquisa((prevPesquisa) => ({
            ...prevPesquisa,
            [name]: data.value,
        }));
    };

    const handleCheckboxChange = (name: string, value: any) => {
        setPesquisa((prevPesquisa) => ({
            ...prevPesquisa,
            [name]: value,
        }));
    };

    useEffect(() => {
        function ListClientes() {
            GetAllClientsToDropdownAnon().then((response) => {
                setClients(response);
            })
                .catch(error => {
                    console.warn(error);
                })
        }

        ListClientes()
    }, [])



    const handleSubmit = async () => {
        try {
            setLoading(true);
            await CreatePesquisaSaudeParticipantes(pesquisa);
            navigate('/pesquisa-saude/sucesso');
            toast.success('Pesquisa respondida com sucesso!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setError('');
        } catch (error:any) {
            toast.warn('Falha ao responder pesquisa', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <Grid textAlign='center' style={{height: '200'}} verticalAlign='middle' centered >
                <Grid.Column style={{maxWidth: 800}} color={'orange'} >
            <Segment>

                <img src={'/logotipo_retangulo.png'} style={{
                    maxHeight: '10rem',
                    objectFit: 'contain',
                    marginTop: 0,
                    marginBottom: -30,
                    paddingBottom: 5,
                    alignSelf: 'center',
                    width: '100%',
                }} alt={'superativa'}/>

            <Header textAlign={"center"} as={"h2"}>Pesquisa de Nível de Saúde - Ergonomia organizacional,
                cognitiva e comportamental.</Header>

            <Form stackable>
                <Header as="h4" textAlign="center" color={'black'}>
                    Essa pesquisa tem como objetivo levantamento de dados de saúde, para conhecermos o seu Super Time
                    de forma individual para projeção de programas de saúde.
                </Header>
                <br/>
                <Form.Field>
                    <Form.Select
                        label={'Empresa onde trabalha'}
                        options={clientes}
                        placeholder='Selecione uma opção'
                        value={pesquisa?.clientId || ''}
                        required
                        search
                        clearable
                        onChange={(e: any, data: any) => {
                            const selectedValue = data.value;
                            setPesquisa(prevPesquisa => ({ ...prevPesquisa, clientId: selectedValue }));
                        }}
                    />
                </Form.Field>
                <Form.Group widths="equal" stackable>
                    <Form.Field>
                        <Form.Input
                            label={'Escreva aqui seu nome completo'}
                            name="nomeParticipante"
                            type="text"
                            placeholder="Nome"
                            value={pesquisa.nomeParticipante}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Field>

                    <Form.Field>
                        <Form.Input
                            label={'Escreva aqui seu email (Opcional)'}
                            name="emailParticipante"
                            type="text"
                            placeholder="Email"
                            value={pesquisa?.emailParticipante}
                            onChange={handleInputChange}
                        />
                    </Form.Field>
                </Form.Group>

                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Input
                            label={'Qual sua função no time?'}
                            name="funcaoNoTime"
                            type="text"
                            placeholder="Função"
                            value={pesquisa.funcaoNoTime}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Field>

                    <Form.Field>
                        <Form.Input
                            label={'Qual o seu setor de trabalho?'}
                            name="setorTrabalhado"
                            type="text"
                            placeholder="Setor"
                            value={pesquisa.setorTrabalhado}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Input
                            label={'Escreva aqui sua idade:'}
                            name="idade"
                            type="number"
                            placeholder="Ex: 30"
                            value={pesquisa.idade}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Field>

                    <Form.Field>
                        <Form.Input
                            label={'Qual sua altura em cm? (Exemplo: 165)'}
                            name="altura"
                            type="number"
                            placeholder="Altura"
                            value={pesquisa.altura}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Field>

                    <Form.Field>
                        <Form.Input
                            label={'Qual seu peso em Kg? (Exemplo: 80)'}
                            name="peso"
                            type="number"
                            placeholder="Peso"
                            value={pesquisa.peso}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Field>
                </Form.Group>


                <br/>
                <Form.Field>
                <Form.Input
                    label={'Quais as suas TAREFAS/ATIVIDADES PRINCIPAIS de trabalho?'}
                    name="tarefasPrincipaisTrabalho"
                    type="text"
                    placeholder="Sua resposta"
                    value={pesquisa.tarefasPrincipaisTrabalho}
                    onChange={handleInputChange}
                    required
                />
                </Form.Field>
                <br/>
                <Form.Field>
                    <Header as="h5" textAlign="left" color={'black'}>
                        Você pratica exercício físico?
                    </Header>

                    <Form.Checkbox
                        type="checkbox"
                        label={"Pratico exercícios diariamente\n"}
                        id={"praticoExerciciosDiariamentePerguntaExercicioFisico"}
                        onChange={(e, { checked }) => {
                            handleCheckboxChange('praticoExerciciosDiariamentePerguntaExercicioFisico', checked);
                            // Desmarca os outros checkboxes
                            setPesquisa(prevState => ({
                                ...prevState,
                                praticoExercicios3VezesNaSemanaPerguntaExercicioFisico: false,
                                praticoExercicios2VezesNaSemanaPerguntaExercicioFisico: false,
                                naoPraticoExerciciosFísicosPerguntaExercicioFisico: false,
                            }));
                        }}
                        checked={!!pesquisa.praticoExerciciosDiariamentePerguntaExercicioFisico}
                    />

                    <Form.Checkbox
                        type="checkbox"
                        label={"Pratico exercícios 3x na semana\n"}
                        id={"praticoExercicios3VezesNaSemanaPerguntaExercicioFisico"}
                        onChange={(e, { checked }) => {
                            handleCheckboxChange('praticoExercicios3VezesNaSemanaPerguntaExercicioFisico', checked);
                            // Desmarca os outros checkboxes
                            setPesquisa(prevState => ({
                                ...prevState,
                                praticoExerciciosDiariamentePerguntaExercicioFisico: false,
                                praticoExercicios2VezesNaSemanaPerguntaExercicioFisico: false,
                                naoPraticoExerciciosFísicosPerguntaExercicioFisico: false,
                            }));
                        }}
                        checked={!!pesquisa.praticoExercicios3VezesNaSemanaPerguntaExercicioFisico}
                    />

                    <Form.Checkbox
                        type="checkbox"
                        label={"Pratico exercícios 2x na semana\n"}
                        id={"praticoExercicios2VezesNaSemanaPerguntaExercicioFisico"}
                        onChange={(e, { checked }) => {
                            handleCheckboxChange('praticoExercicios2VezesNaSemanaPerguntaExercicioFisico', checked);
                            // Desmarca os outros checkboxes
                            setPesquisa(prevState => ({
                                ...prevState,
                                praticoExerciciosDiariamentePerguntaExercicioFisico: false,
                                praticoExercicios3VezesNaSemanaPerguntaExercicioFisico: false,
                                naoPraticoExerciciosFísicosPerguntaExercicioFisico: false,
                            }));
                        }}
                        checked={!!pesquisa.praticoExercicios2VezesNaSemanaPerguntaExercicioFisico}
                    />

                    <Form.Checkbox
                        type="checkbox"
                        label={"Não pratico exercícios\n"}
                        id={"naoPraticoExerciciosFísicosPerguntaExercicioFisico"}
                        onChange={(e, { checked }) => {
                            handleCheckboxChange('naoPraticoExerciciosFísicosPerguntaExercicioFisico', checked);
                            // Desmarca os outros checkboxes
                            setPesquisa(prevState => ({
                                ...prevState,
                                praticoExerciciosDiariamentePerguntaExercicioFisico: false,
                                praticoExercicios3VezesNaSemanaPerguntaExercicioFisico: false,
                                praticoExercicios2VezesNaSemanaPerguntaExercicioFisico: false,
                            }));
                        }}
                        checked={!!pesquisa.naoPraticoExerciciosFísicosPerguntaExercicioFisico}
                    />


                </Form.Field>
                <br/>
                <Form.Field>
                    <Header as="h5" textAlign="left" color={'black'}>
                        Você tem algum hábito que não te gera saúde?
                    </Header>

                    <Form.Checkbox type="checkbox"
                                   label={"Fumante"}
                                   id={"fumantePerguntaHabitoQueNaoGeraSaude"}
                                   onChange={(e, b) => handleCheckboxChange('fumantePerguntaHabitoQueNaoGeraSaude', b.checked)}
                                   checked={pesquisa.fumantePerguntaHabitoQueNaoGeraSaude}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Não ter momento de relaxamento\n"}
                                   id={"naoTerMomentoRelaxamentoPerguntaHabitoQueNaoGeraSaude"}
                                   onChange={(e, b) => handleCheckboxChange('naoTerMomentoRelaxamentoPerguntaHabitoQueNaoGeraSaude', b.checked)}
                                   checked={pesquisa.naoTerMomentoRelaxamentoPerguntaHabitoQueNaoGeraSaude}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Uso de bebidas alcólicas\n"}
                                   id={"usoBebidasAlcoolicasPerguntaHabitoQueNaoGeraSaude"}
                                   onChange={(e, b) => handleCheckboxChange('usoBebidasAlcoolicasPerguntaHabitoQueNaoGeraSaude', b.checked)}
                                   checked={pesquisa.usoBebidasAlcoolicasPerguntaHabitoQueNaoGeraSaude}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Rede social em excesso\n"}
                                   id={"redesSociaisEmExcessoPerguntaHabitoQueNaoGeraSaude"}
                                   onChange={(e, b) => handleCheckboxChange('redesSociaisEmExcessoPerguntaHabitoQueNaoGeraSaude', b.checked)}
                                   checked={pesquisa.redesSociaisEmExcessoPerguntaHabitoQueNaoGeraSaude}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Negligenciar a alimentação\n"}
                                   id={"negligenciarAlimentacaoPerguntaHabitoQueNaoGeraSaude"}
                                   onChange={(e, b) => handleCheckboxChange('negligenciarAlimentacaoPerguntaHabitoQueNaoGeraSaude', b.checked)}
                                   checked={pesquisa.negligenciarAlimentacaoPerguntaHabitoQueNaoGeraSaude}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Não"}
                                   id={"naoTenhoHabitosPrejudiciaisPerguntaHabitoQueNaoGeraSaude"}
                                   onChange={(e, b) => handleCheckboxChange('naoTenhoHabitosPrejudiciaisPerguntaHabitoQueNaoGeraSaude', b.checked)}
                                   checked={pesquisa.naoTenhoHabitosPrejudiciaisPerguntaHabitoQueNaoGeraSaude}/>

                    <Form.Input
                        label={'Outro'}
                        name="outroPerguntaHabitoQueNaoGeraSaude"
                        type="text"
                        placeholder="Outro"
                        value={pesquisa.outroPerguntaHabitoQueNaoGeraSaude}
                        onChange={handleInputChange}
                        />
                </Form.Field>
<br/>

                <Form.Field>
                    <Header as="h5" textAlign="left" color={'black'}>
                        Com relação ao ambiente de trabalho, você se sente incomodado com o:
                    </Header>
                    <Form.Group widths="equal">
                        <Form.Field>
                            <label>Ruído</label>
                            <Form.Select
                                options={EPerguntaSobreAmbienteDeTrabalhoPesquisaSaude}
                                placeholder='Selecione uma opção'
                                value={pesquisa.ruidoPerguntaIncomodoAmbienteTrabalho}
                                onChange={(e: any, data: any) => handleDropdownChange('ruidoPerguntaIncomodoAmbienteTrabalho', data)}
                                required
                            />
                        </Form.Field>

                        <Form.Field>
                            <label>Temperatura</label>
                            <Form.Select
                                options={EPerguntaSobreAmbienteDeTrabalhoPesquisaSaude}
                                placeholder='Selecione uma opção'
                                value={pesquisa.temperaturaPerguntaIncomodoAmbienteTrabalho}
                                onChange={(e: any, data: any) => handleDropdownChange('temperaturaPerguntaIncomodoAmbienteTrabalho', data)}
                                required
                            />
                        </Form.Field>

                        {/* Adicione mais Form.Fields conforme necessário */}
                    </Form.Group>

                    <Form.Group widths="equal">
                        <Form.Field>
                            <label>Iluminação</label>
                            <Form.Select
                                options={EPerguntaSobreAmbienteDeTrabalhoPesquisaSaude}
                                placeholder='Selecione uma opção'
                                value={pesquisa.iluminacaoPerguntaIncomodoAmbienteTrabalho}
                                onChange={(e: any, data: any) => handleDropdownChange('iluminacaoPerguntaIncomodoAmbienteTrabalho', data)}
                                required
                            />
                        </Form.Field>

                        <Form.Field>
                            <label>Umidade</label>
                            <Form.Select
                                options={EPerguntaSobreAmbienteDeTrabalhoPesquisaSaude}
                                placeholder='Selecione uma opção'
                                value={pesquisa.umidadePerguntaIncomodoAmbienteTrabalho}
                                onChange={(e: any, data: any) => handleDropdownChange('umidadePerguntaIncomodoAmbienteTrabalho', data)}
                                required
                            />
                        </Form.Field>

                        {/* Adicione mais Form.Fields conforme necessário */}
                    </Form.Group>

                    <Form.Group widths="equal">
                        <Form.Field>
                            <label>Cadeira</label>
                            <Form.Select
                                options={EPerguntaSobreAmbienteDeTrabalhoPesquisaSaude}
                                placeholder='Selecione uma opção'
                                value={pesquisa.cadeiraPerguntaIncomodoAmbienteTrabalho}
                                onChange={(e: any, data: any) => handleDropdownChange('cadeiraPerguntaIncomodoAmbienteTrabalho', data)}
                                required
                            />
                        </Form.Field>

                        <Form.Field>
                            <label>Mesa</label>
                            <Form.Select
                                options={EPerguntaSobreAmbienteDeTrabalhoPesquisaSaude}
                                placeholder='Selecione uma opção'
                                value={pesquisa.mesaPerguntaIncomodoAmbienteTrabalho}
                                onChange={(e: any, data: any) => handleDropdownChange('mesaPerguntaIncomodoAmbienteTrabalho', data)}
                                required
                            />
                        </Form.Field>

                        {/* Adicione mais Form.Fields conforme necessário */}
                    </Form.Group>


                    <Form.Select
                        label={'Equipamentos de trabalho (computador, notebook)\t\n'}
                        options={EPerguntaSobreAmbienteDeTrabalhoPesquisaSaude}
                        placeholder='Selecione uma opção'
                        value={pesquisa.equipamentosDeTrabalhoPerguntaIncomodoAmbienteTrabalho}
                        onChange={(e: any, data: any) => handleDropdownChange('equipamentosDeTrabalhoPerguntaIncomodoAmbienteTrabalho', data)}
                        required
                    />
                </Form.Field>
<br/>
                <Form.Field>
                    <Header as="h5" textAlign="left" color={'black'}>
                        Nos últimos 7 dias você sentiu alguma dor/desconforto? Se sim,
                        avalie a intensidade dessa dor em cada parte do corpo a partir da
                        escala a seguir: sendo 0 nenhuma dor e 10 a pior dor que já sentiu na sua vida:
                    </Header>

                    <img src={'/charts/escala_dor.png'} style={{
                        objectFit: 'contain',
                        marginTop: 10,
                        marginBottom: 10,
                        padding: 20,
                        alignSelf: 'center',
                        width: '100%',
                    }} alt={'matriz de risco'}/>

                    <Form.Group widths="equal">
                        <Form.Field>
                            <label>Desconforto ou dores na cabeça</label>
                            <Form.Select
                                options={EPerguntaDesconfortoUltimosDiasPesquisaSaude}
                                placeholder='Selecione uma opção'
                                value={pesquisa.cabecaPerguntaDesconfortoUltimosDias}
                                onChange={(e: any, data: any) => handleDropdownChange('cabecaPerguntaDesconfortoUltimosDias', data)}
                                required
                            />
                        </Form.Field>

                        <Form.Field>
                            <label>Desconforto ou dores no pescoço</label>
                            <Form.Select
                                options={EPerguntaDesconfortoUltimosDiasPesquisaSaude}
                                placeholder='Selecione uma opção'
                                value={pesquisa.pescocoPerguntaDesconfortoUltimosDias}
                                onChange={(e: any, data: any) => handleDropdownChange('pescocoPerguntaDesconfortoUltimosDias', data)}
                                required
                            />
                        </Form.Field>
                    </Form.Group>

                    <Form.Group widths="equal">
                        <Form.Field>
                            <label>Desconforto ou dores nos ombros</label>
                            <Form.Select
                                options={EPerguntaDesconfortoUltimosDiasPesquisaSaude}
                                placeholder='Selecione uma opção'
                                value={pesquisa.ombroPerguntaDesconfortoUltimosDias}
                                onChange={(e: any, data: any) => handleDropdownChange('ombroPerguntaDesconfortoUltimosDias', data)}
                                required
                            />
                        </Form.Field>

                        <Form.Field>
                            <label>Desconforto ou dores nos braços</label>
                            <Form.Select
                                options={EPerguntaDesconfortoUltimosDiasPesquisaSaude}
                                placeholder='Selecione uma opção'
                                value={pesquisa.bracosPerguntaDesconfortoUltimosDias}
                                onChange={(e: any, data: any) => handleDropdownChange('bracosPerguntaDesconfortoUltimosDias', data)}
                                required
                            />
                        </Form.Field>

                    </Form.Group>

                    <Form.Group widths="equal">
                        <Form.Field>
                            <label>Desconforto ou dores nos punhos</label>
                            <Form.Select
                                options={EPerguntaDesconfortoUltimosDiasPesquisaSaude}
                                placeholder='Selecione uma opção'
                                value={pesquisa.punhosPerguntaDesconfortoUltimosDias}
                                onChange={(e: any, data: any) => handleDropdownChange('punhosPerguntaDesconfortoUltimosDias', data)}
                                required
                            />
                        </Form.Field>

                        <Form.Field>
                            <label>Desconforto ou dores nas mãos</label>
                            <Form.Select
                                options={EPerguntaDesconfortoUltimosDiasPesquisaSaude}
                                placeholder='Selecione uma opção'
                                value={pesquisa.maosPerguntaDesconfortoUltimosDias}
                                onChange={(e: any, data: any) => handleDropdownChange('maosPerguntaDesconfortoUltimosDias', data)}
                                required
                            />
                        </Form.Field>

                    </Form.Group>


                    <Form.Group widths="equal">
                        <Form.Field>
                            <label>Desconforto ou dores na coluna</label>
                            <Form.Select
                                options={EPerguntaDesconfortoUltimosDiasPesquisaSaude}
                                placeholder='Selecione uma opção'
                                value={pesquisa.colunaPerguntaDesconfortoUltimosDias}
                                onChange={(e: any, data: any) => handleDropdownChange('colunaPerguntaDesconfortoUltimosDias', data)}
                                required
                            />
                        </Form.Field>

                        <Form.Field>
                            <label>Desconforto ou dores no quadril</label>
                            <Form.Select
                                options={EPerguntaDesconfortoUltimosDiasPesquisaSaude}
                                placeholder='Selecione uma opção'
                                value={pesquisa.quadrilPerguntaDesconfortoUltimosDias}
                                onChange={(e: any, data: any) => handleDropdownChange('quadrilPerguntaDesconfortoUltimosDias', data)}
                                required
                            />
                        </Form.Field>

                    </Form.Group>

                    <Form.Group widths="equal">
                        <Form.Field>
                            <label>Desconforto ou dores nas coxas</label>
                            <Form.Select
                                options={EPerguntaDesconfortoUltimosDiasPesquisaSaude}
                                placeholder='Selecione uma opção'
                                value={pesquisa.coxasPerguntaDesconfortoUltimosDias}
                                onChange={(e: any, data: any) => handleDropdownChange('coxasPerguntaDesconfortoUltimosDias', data)}
                                required
                            />
                        </Form.Field>

                        <Form.Field>
                            <label>Desconforto ou dores nos joelhos</label>
                            <Form.Select
                                options={EPerguntaDesconfortoUltimosDiasPesquisaSaude}
                                placeholder='Selecione uma opção'
                                value={pesquisa.joelhosPerguntaDesconfortoUltimosDias}
                                onChange={(e: any, data: any) => handleDropdownChange('joelhosPerguntaDesconfortoUltimosDias', data)}
                                required
                            />
                        </Form.Field>

                    </Form.Group>

                    <Form.Group widths="equal">
                        <Form.Field>
                            <label>Desconforto ou dores nas pernas</label>
                            <Form.Select
                                options={EPerguntaDesconfortoUltimosDiasPesquisaSaude}
                                placeholder='Selecione uma opção'
                                value={pesquisa.pernasPerguntaDesconfortoUltimosDias}
                                onChange={(e: any, data: any) => handleDropdownChange('pernasPerguntaDesconfortoUltimosDias', data)}
                                required
                            />
                        </Form.Field>

                        <Form.Field>
                            <label>Desconforto ou dores nos tornozelos</label>
                            <Form.Select
                                options={EPerguntaDesconfortoUltimosDiasPesquisaSaude}
                                placeholder='Selecione uma opção'
                                value={pesquisa.tornozelosPerguntaDesconfortoUltimosDias}
                                onChange={(e: any, data: any) => handleDropdownChange('tornozelosPerguntaDesconfortoUltimosDias', data)}
                                required
                            />
                        </Form.Field>

                    </Form.Group>

                    <Form.Group widths="equal">
                        <Form.Field>
                            <label>Desconforto ou dores nos pés</label>
                            <Form.Select
                                options={EPerguntaDesconfortoUltimosDiasPesquisaSaude}
                                placeholder='Selecione uma opção'
                                value={pesquisa.pesPerguntaDesconfortoUltimosDias}
                                onChange={(e: any, data: any) => handleDropdownChange('pesPerguntaDesconfortoUltimosDias', data)}
                                required
                            />
                        </Form.Field>

                        <Form.Field>
                        </Form.Field>

                    </Form.Group>

                </Form.Field>
                <br/>
                <br/>
                <Form.Field>
                    <Header as="h5" textAlign="left" color={'black'}>
                       Leia cuidadosamente e marque o que se aplicou para você durante a sua última semana:
                    </Header>

                    <Form.Select
                        label={'Achei difícil me acalmar'}
                        options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                        placeholder='Selecione uma opção'
                        value={pesquisa.acheiDificilMeAcalmarPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                        onChange={(e: any, data: any) => handleDropdownChange('acheiDificilMeAcalmarPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana', data)}
                        required
                    />
                    <Form.Select
                        label={'Senti minha boca seca'}
                        options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                        placeholder='Selecione uma opção'
                        value={pesquisa.sentiMinhaBocaSecaPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                        onChange={(e: any, data: any) => handleDropdownChange('sentiMinhaBocaSecaPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana', data)}
                        required
                    />
                    <Form.Select
                        label={'Não consegui vivenciar nenhum sentimento positivo'}
                        options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                        placeholder='Selecione uma opção'
                        value={pesquisa.naoConseguiVivenciarNenhumSentimentoPositivoPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                        onChange={(e: any, data: any) => handleDropdownChange('naoConseguiVivenciarNenhumSentimentoPositivoPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana', data)}
                        required
                    />
                    <Form.Select
                        label={'Tive dificuldade em respirar em alguns momentos (ex. respiração ofegante)'}
                        options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                        placeholder='Selecione uma opção'
                        value={pesquisa.tiveDificuldadeEmRespirarEmAlgunsMomentosPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                        onChange={(e: any, data: any) => handleDropdownChange('tiveDificuldadeEmRespirarEmAlgunsMomentosPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana', data)}
                        required
                    />
                    <Form.Select
                        label={'ofegante, falta de ar, sem ter feito nenhum esforço físico)'}
                        options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                        placeholder='Selecione uma opção'
                        value={pesquisa.ofeganteFaltadeArSemEsforcoFisicoPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                        onChange={(e: any, data: any) => handleDropdownChange('ofeganteFaltadeArSemEsforcoFisicoPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana', data)}
                        required
                    />

                    <Form.Select
                        label={'Achei difícil ter iniciativa para fazer as coisas'}
                        options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                        placeholder='Selecione uma opção'
                        value={pesquisa.acheiDificilTerIniciativaParaFazerAsCoisasPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                        onChange={(e: any, data: any) => handleDropdownChange('acheiDificilTerIniciativaParaFazerAsCoisasPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana', data)}
                        required
                    />
                    <Form.Select
                        label={'Tive a tendência de reagir de forma exagerada às situações'}
                        options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                        placeholder='Selecione uma opção'
                        value={pesquisa.tendenciaAReagirDeFormaExageradaAsSituacoesPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                        onChange={(e: any, data: any) => handleDropdownChange('tendenciaAReagirDeFormaExageradaAsSituacoesPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana', data)}
                        required
                    />
                    <Form.Select
                        label={'Senti tremores (ex. nas mãos)'}
                        options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                        placeholder='Selecione uma opção'
                        value={pesquisa.sentiTremoresNoCorpoPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                        onChange={(e: any, data: any) => handleDropdownChange('sentiTremoresNoCorpoPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana', data)}
                        required
                    />
                    <Form.Select
                        label={'Senti que estava sempre nervoso'}
                        options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                        placeholder='Selecione uma opção'
                        value={pesquisa.sentiQueEstavaSempreNervosoPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                        onChange={(e: any, data: any) => handleDropdownChange('sentiQueEstavaSempreNervosoPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana', data)}
                        required
                    />
                    <Form.Select
                        label={'Preocupei-me com situações em que eu pudesse entrar em pânico'}
                        options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                        placeholder='Selecione uma opção'
                        value={pesquisa.preocupeiComSituacoesQuePudesseEntrarEmPanicoPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                        onChange={(e: any, data: any) => handleDropdownChange('preocupeiComSituacoesQuePudesseEntrarEmPanicoPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana', data)}
                        required
                    />
                    <Form.Select
                        label={'Senti que não tinha nada a desejar'}
                        options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                        placeholder='Selecione uma opção'
                        value={pesquisa.sentiQueNaoTinhaNadaADesejarPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                        onChange={(e: any, data: any) => handleDropdownChange('sentiQueNaoTinhaNadaADesejarPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana', data)}
                        required
                    />
                    <Form.Select
                        label={'Senti-me agitado'}
                        options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                        placeholder='Selecione uma opção'
                        value={pesquisa.sentimeAgitadoPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                        onChange={(e: any, data: any) => handleDropdownChange('sentimeAgitadoPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana', data)}
                        required
                    />


                    <Form.Select
                        label={'Achei difícil relaxar'}
                        options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                        placeholder='Selecione uma opção'
                        value={pesquisa.acheiDificilRelaxarPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                        onChange={(e: any, data: any) => handleDropdownChange('acheiDificilRelaxarPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana', data)}
                        required
                    />

                    <Form.Select
                        label={'Senti-me depressivo (a) e sem ânimo'}
                        options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                        placeholder='Selecione uma opção'
                        value={pesquisa.sentimeDepressivoESemAnimoPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                        onChange={(e: any, data: any) => handleDropdownChange('sentimeDepressivoESemAnimoPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana', data)}
                        required
                    />

                    <Form.Select
                        label={'Fui intolerante com as coisas que impediam minhas atividades'}
                        options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                        placeholder='Selecione uma opção'
                        value={pesquisa.fuiIntoleranteComCoisasQueImpediamMinhasAtividadesPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                        onChange={(e: any, data: any) => handleDropdownChange('fuiIntoleranteComCoisasQueImpediamMinhasAtividadesPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana', data)}
                        required
                    />

                    <Form.Select
                        label={'Senti que ia entrar em pânico'}
                        options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                        placeholder='Selecione uma opção'
                        value={pesquisa.sentiQueIaEntrarEmPanicoPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                        onChange={(e: any, data: any) => handleDropdownChange('sentiQueIaEntrarEmPanicoPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana', data)}
                        required
                    />

                    <Form.Select
                        label={'Não consegui me entusiasmar com nada'}
                        options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                        placeholder='Selecione uma opção'
                        value={pesquisa.naoConseguiMeEntusiasmarComNadaPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                        onChange={(e: any, data: any) => handleDropdownChange('naoConseguiMeEntusiasmarComNadaPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana', data)}
                        required
                    />

                    <Form.Select
                        label={'Senti que não tinha valor como pessoa'}
                        options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                        placeholder='Selecione uma opção'
                        value={pesquisa.sentiQueNaoTinhaValorComoPessoaPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                        onChange={(e: any, data: any) => handleDropdownChange('sentiQueNaoTinhaValorComoPessoaPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana', data)}
                        required
                    />

                    <Form.Select
                        label={'Senti que estava um pouco emotivo/sensível demais'}
                        options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                        placeholder='Selecione uma opção'
                        value={pesquisa.sentiQueEstavaUmPoucoEmotivoSensivelDemaisPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                        onChange={(e: any, data: any) => handleDropdownChange('sentiQueEstavaUmPoucoEmotivoSensivelDemaisPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana', data)}
                        required
                    />

                    <Form.Select
                        label={'Sabia que meu coração estava alterado mesmo não tendo feito nenhum esforço'}
                        options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                        placeholder='Selecione uma opção'
                        value={pesquisa.sabiaQueMeuCoracaoEstavaAlteradoMesmoSemEsforcoPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                        onChange={(e: any, data: any) => handleDropdownChange('sabiaQueMeuCoracaoEstavaAlteradoMesmoSemEsforcoPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana', data)}
                        required
                    />

                    <Form.Select
                        label={'Senti medo sem motivo'}
                        options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                        placeholder='Selecione uma opção'
                        value={pesquisa.sentiMedoSemMotivoPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                        onChange={(e: any, data: any) => handleDropdownChange('sentiMedoSemMotivoPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana', data)}
                        required
                    />

                    <Form.Select
                        label={'Senti que a vida não tinha sentido'}
                        options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                        placeholder='Selecione uma opção'
                        value={pesquisa.sentiQueAVidaNaoTinhaSentidoPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                        onChange={(e: any, data: any) => handleDropdownChange('sentiQueAVidaNaoTinhaSentidoPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana', data)}
                        required
                    />
                </Form.Field>
                <br/>
                <Form.Field>
                    <label>
                        Se você pudesse mudar algum aspecto da sua vida, do seu trabalho, de maneira geral, o que mudaria?
                    </label>
                    <Form.TextArea
                        name="seVocePudesseMudaAlgumAspectoDaSuaVidaTrabalhoOuManeiraGeralOqueSeria"
                        placeholder="Sua resposta"
                        value={pesquisa.seVocePudesseMudaAlgumAspectoDaSuaVidaTrabalhoOuManeiraGeralOqueSeria}
                        onChange={handleInputChange}
                        rows={6}
                    />
                </Form.Field>


                <Button type="submit" color="blue" fluid size="large"
                        icon={'submit'} content={'Responder pesquisa'}
                        onClick={() => {
                            handleSubmit()
                        }}>
                </Button>

            </Form>

            </Segment>
                </Grid.Column>
            </Grid>
        </div>
    )
}

export default CreatePesquisaSaudePage