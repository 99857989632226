import instance from "services";

export default EditColetaErgo

async function EditColetaErgo(request: Partial<EditColetaErgoRequest>, id: string | string[] | undefined): Promise<EditColetaErgoResponse> {
    const response = await instance.put(`/v1/backoffice/coletas-ergo/${id}`, request);
    return response.data;
}
export interface EditColetaErgoRequest {
    id: string;
    userId: string
    userName: string
    clientId: string
    clientName: string
    nome: string
    setor: string
    funcaoClt: string
    nomeSupervisor: string
    horarioTrabalho: number
    idade: number
    atividadesPrincipais: string
    tempoTrabalhado: number
    praticaExercicioFisico: number
    fumantePerguntaHabitoQueNaoGeraSaude: boolean
    naoDescansaPerguntaHabitoQueNaoGeraSaude: boolean
    bebePerguntaHabitoQueNaoGeraSaude: boolean
    redesSociaisPerguntaHabitoQueNaoGeraSaude: boolean
    alimentacaoRuimPerguntaHabitoQueNaoGeraSaude: boolean
    naoPerguntaHabitoQueNaoGeraSaude: boolean
    outrosPerguntaHabitoQueNaoGeraSaude: string
    altura: number
    peso: number
    ruidoPerguntaSobreAmbienteDeTrabalho: number
    temperaturaPerguntaSobreAmbienteDeTrabalho: number
    iluminacaoPerguntaSobreAmbienteDeTrabalho: number
    baixaUmidadePerguntaSobreAmbienteDeTrabalho: number
    cabecaPerguntaDesconfortoUltimosDias: number
    pescocoPerguntaDesconfortoUltimosDias: number
    ombroPerguntaDesconfortoUltimosDias: number
    bracosPerguntaDesconfortoUltimosDias: number
    punhosPerguntaDesconfortoUltimosDias: number
    colunaPerguntaDesconfortoUltimosDias: number
    coxasPerguntaDesconfortoUltimosDias: number
    pernasPerguntaDesconfortoUltimosDias: number
    quadrilPerguntaDesconfortoUltimosDias: number
    joelhosPerguntaDesconfortoUltimosDias: number
    tornozelosPerguntaDesconfortoUltimosDias: number
    pesPerguntaDesconfortoUltimosDias: number
    perguntaEsforcoMental: number
    perguntaEsforcoFisico: number
    respostaPerguntaMudarAlgoNoTrabalho: string
    flexaoAcima20GrausPerguntaPosturaInadequadaPescoco: boolean
    extensaoSemSupAcima10GrausPerguntaPosturaInadequadaPescoco: boolean
    inclinacaoAcima20GrausPerguntaPosturaInadequadaPescoco: boolean
    rotacaoAcima30GrausPerguntaPosturaInadequadaPescoco: boolean
    naoExisteRiscoPescocoPerguntaPosturaInadequadaPescoco: boolean
    outrosPerguntaPosturaInadequadaPescoco: string
    flexaoAcima45A90GrausPerguntaPosturaInadequadaOmbros: boolean
    extensaoAcima20GrausPerguntaPosturaInadequadaOmbros: boolean
    aducaoAcima45GrausPerguntaPosturaInadequadaOmbros: boolean
    rotacaoAcima45GrausPerguntaPosturaInadequadaOmbros: boolean
    abducaoAcima45GrausPerguntaPosturaInadequadaOmbros: boolean
    ombrosElevadosPerguntaPosturaInadequadaOmbros: boolean
    naoExisteRiscoOmbroPerguntaPosturaInadequadaOmbros: boolean
    outrosPerguntaPosturaInadequadaOmbros: string
    flexaoAcima100GrausPerguntaPosturaInadequadaCotovelos: boolean
    extensaoAcima60GrausPerguntaPosturaInadequadaCotovelos: boolean
    totalmenteExtendPerguntaPosturaInadequadaCotovelos: boolean
    totalmenteFlexPerguntaPosturaInadequadaCotovelos: boolean
    naoExisteRiscoCotovelosPerguntaPosturaInadequadaCotovelos: boolean
    outrosPerguntaPosturaInadequadaCotovelos: string
    flexaoAcima45GrausPerguntaPosturaInadequadaPunhos: boolean
    desviosLateraisAcima10GrausPerguntaPosturaInadequadaPunhos: boolean
    extensaoAcima15GrausPerguntaPosturaInadequadaPunhos: boolean
    totalmenteFlexionadoEstendidoPerguntaPosturaInadequadaPunhos: boolean
    naoExisteRiscoPunhosPerguntaPosturaInadequadaPunhos: boolean
    outrosPerguntaPosturaInadequadaPunhos: string
    flexaoMaiorOu20GrausPerguntaPosturaInadequadaTronco: boolean
    extensaoSemSuporteAcima10GrausPerguntaPosturaInadequadaTronco: boolean
    inclinacaoAcimaDe30GrausPerguntaPosturaInadequadaTronco: boolean
    rotacaoAcimaDe30GrausPerguntaPosturaInadequadaTronco: boolean
    naoExisteRiscoTroncoPerguntaPosturaInadequadaTronco: boolean
    outrosPerguntaPosturaInadequadaTronco: string
    flexaoAcima90GrausPerguntaPosturaInadequadaPernas: boolean
    naoTotalmenteApoiadaOuEquilibradaPerguntaPosturaInadequadaPernas: boolean
    naoHaEspacoParaAsPernasMaisDe2HorasPerguntaPosturaInadequadaPernas: boolean
    agachadoOuAjoelhadoPerguntaPosturaInadequadaPernas: boolean
    naoExisteRiscoPernasPerguntaPosturaInadequadaPernas: boolean
    outrosPerguntaPosturaInadequadaPernas: string
    posturaSentadoLongoPeriodoPerguntaPerigosPosturaisGerais: boolean
    posturaEmPeLongoPeriodoPerguntaPerigosPosturaisGerais: boolean
    frequentementeDeslocAPeDuranteTrabalhoPerguntaPerigosPosturaisGerais: boolean
    levantamentoTransporteManualDeCargasPerguntaPerigosPosturaisGerais: boolean
    movimentosRepetitivosFrequentesPerguntaPerigosPosturaisGerais: boolean
    manuseioFerramentasObjetosPesadosLongosPeriodosPerguntaPerigosPosturaisGerais: boolean
    compressaoPartesCorpoPorSuperficiesPerguntaPerigosPosturaisGerais: boolean
    usoFrequentePedaisPerguntaPerigosPosturaisGerais: boolean
    usoFrequenteAlavancasPerguntaPerigosPosturaisGerais: boolean
    exposicaoAVibracaoDeCorpoInteiroPerguntaPerigosPosturaisGerais: boolean
    exposicaoAVibracaoLocalizadaPerguntaPerigosPosturaisGerais: boolean
    usoFrequenteEscadasPerguntaPerigosPosturaisGerais: boolean
    trabalhoIntensivoTecladoEntradaDeDadosPerguntaPerigosPosturaisGerais: boolean
    naoExisteRiscosNessaAtivPerguntaPerigosPosturaisGerais: boolean
    outrosPerguntaPerigosPosturaisGerais: string
    entre2A10KGPerguntaForcasExistentesNaAtividadeAvaliada: boolean
    entre2A10KGEstaticoPerguntaForcasExistentesNaAtividadeAvaliada: boolean
    maior10KgRepetitivoOuImpactoPerguntaForcasExistentesNaAtividadeAvaliada: boolean
    posturaEstaticaSegurarPor1MOuMaisPerguntaForcasExistentesNaAtividadeAvaliada: boolean
    maosEmPincaPressaoMaiorQue09KGPerguntaForcasExistentesNaAtividadeAvaliada: boolean
    maosEmAgarreMaiorQue45KgPerguntaForcasExistentesNaAtividadeAvaliada: boolean
    cotovelosMaiorQue45KgPerguntaForcasExistentesNaAtividadeAvaliada: boolean
    ombrosMaiorQue45KgPerguntaForcasExistentesNaAtividadeAvaliada: boolean
    cervicalPesoAdicionalPerguntaForcasExistentesNaAtividadeAvaliada: boolean
    colunaMaiorQue113KgPerguntaForcasExistentesNaAtividadeAvaliada: boolean
    pedalMaiorQue45KgPerguntaForcasExistentesNaAtividadeAvaliada: boolean
    transporteManual15kgMinutoPerguntaForcasExistentesNaAtividadeAvaliada: boolean
    peso5A10Kg1XPorMinutoPerguntaForcasExistentesNaAtividadeAvaliada: boolean
    pegarPesoAcimaDe175CmPerguntaForcasExistentesNaAtividadeAvaliada: boolean
    puxarEmpurrarEmDesnivelPerguntaForcasExistentesNaAtividadeAvaliada: boolean
    distanciaHorizontalMaior63CmPegarCargaPerguntaForcasExistentesNaAtividadeAvaliada: boolean
    naoHaRiscosDeForcaNaAtividadePerguntaForcasExistentesNaAtividadeAvaliada: boolean
    outrosPerguntaForcasExistentesNaAtividadeAvaliada: string
    movimentoConstanteSemPausasPerguntaFatoresRepetitividadeNaAtividade: boolean
    movimentoFrequenteComPausaPerguntaFatoresRepetitividadeNaAtividade: boolean
    repeticaoDeAcoesTecnicasMaisDaMetadeDoCicloPerguntaFatoresRepetitividadeNaAtividade: boolean
    naoHaRepetitividadeNaAtividadePerguntaFatoresRepetitividadeNaAtividade: boolean
    outrosPerguntaFatoresRepetitividadeNaAtividade: string
    trabalhoSemPausasPreDefinidasPerguntaOrganizacaoDoTrabalho: boolean
    pausaRealizadaForaDoPostoPerguntaOrganizacaoDoTrabalho: boolean
    necessidadadeDeManterRitmosIntensosPerguntaOrganizacaoDoTrabalho: boolean
    trabalhoComNecessidadeDeVariacaoDeTurnosPerguntaOrganizacaoDoTrabalho: boolean
    monotoniaPerguntaOrganizacaoDoTrabalho: boolean
    trabalhoNoturnoPerguntaOrganizacaoDoTrabalho: boolean
    insuficienciaDeCapacitacaoParaExecucaoPerguntaOrganizacaoDoTrabalho: boolean
    trabalhoUtilizacaoRigorosaDeMetasDeProdPerguntaOrganizacaoDoTrabalho: boolean
    trabalhoRemuneradoPorProducaoPerguntaOrganizacaoDoTrabalho: boolean
    cadenciaDoTrabalhoImpostaPorEquipamentoPerguntaOrganizacaoDoTrabalho: boolean
    desequilibrioEntreTempoTrabalhadoETempoRepousoPerguntaOrganizacaoDoTrabalho: boolean
    jornadaLongaDeTrabalhoMaisDe8HPorDiaPerguntaOrganizacaoDoTrabalho: boolean
    concentracoesDesequilibradasDeTrabalhoPerguntaOrganizacaoDoTrabalho: boolean
    nenhumFatorDeRisgoNaAtivPerguntaOrganizacaoDoTrabalho: boolean
    outrosPerguntaOrganizacaoDoTrabalho: string
    trabalhoCondicoesDeDificilComunicacaoPerguntaFatoresPsicossociais: boolean
    excessoDeConflitosHierarquicosPerguntaFatoresPsicossociais: boolean
    insatisfacaoNoTrabalhoPerguntaFatoresPsicossociais: boolean
    faltaDeAutonomiaNoTrabalhoPerguntaFatoresPsicossociais: boolean
    extresseNoTrabalhoPerguntaFatoresPsicossociais: boolean
    naoHaFatorDeRiscoPsicossocialPerguntaFatoresPsicossociais: boolean
    outrosPerguntaFatoresPsicossociais: string
    perguntaFatorDeRiscoGravidade: number
    perguntaFatorDeProbabilidade: number
    sugestaoDoColetador: string

    trabalhoExigeConcentracaoPerguntaFatoresCognitivos: boolean,
    trabalhoExigeAtencaoPerguntaFatoresCognitivos: boolean,
    trabalhoExigeMemorizeSenhaNumerosPerguntaFatoresCognitivos: boolean,
    usabilidadeNoTrabalhoFacilDeTrabalharPerguntaFatoresCognitivos: boolean,
    usabilidadeNoTrabalhoEngajamentoPerguntaFatoresCognitivos: boolean,
    usabilidadeNoTrabalhoAplicavelPerguntaFatoresCognitivos: boolean,
    oTrabalhoTemSistemaDeRetornoDeInfoPerguntaFatoresCognitivos: boolean,
    affordanceSistemasMaquinasEquipamentosERotasPerguntaFatoresCognitivos: boolean,
    sistemaDeCicloAbertoSistemasMaquinasEquipamentosPerguntaFatoresCognitivos: boolean,
    sistemaDeCicloFechadoSistemasMaquinasEquipamentosPerguntaFatoresCognitivos: boolean,
    operarMaisDeUmEquipamentoAoMesmoTempoPerguntaFatoresCognitivos: boolean,
    naoHaNenhumFatorDeRiscoPerguntaFatoresCognitivos: boolean,
    outrosPerguntaFatoresCognitivos: string,

    mesaeCadeiraPerguntaMobiliarioPerguntaMobiliario: boolean
    mesaCadeiraComputadorDeMesaMouseTecladoApoioPunhoMousePerguntaMobiliario: boolean
    mesaCadeiraComputadorDeMesaMouseTecladoPerguntaMobiliario: boolean
    mesaCadeiraComputadorDeMesaMouseTecladoApoioPunhoTecladoPerguntaMobiliario: boolean
    mesaCadeiraComputadorDeMesaMouseTecladoApoioPunhoAmbosPerguntaMobiliario: boolean
    mesaCadeiraNotebookMousePerguntaMobiliario: boolean
    mesaCadeiraNotebookTecladoEMouseExternoPerguntaMobiliario: boolean
    mesaCadeiraNotebookTecladoMouseExternoSupAlturaTelaPerguntaMobiliario: boolean
    mesaCadeiraNotebookTecladoMouseExternoSupAlturaApoioPunhoPerguntaMobiliario: boolean
    bancadaPerguntaMobiliario: boolean
    bancadaCadeiraBanquetaPerguntaMobiliario: boolean
    trabalhoEmCampoArLivrePerguntaMobiliario: boolean
    galpaoOficinaOutrosPerguntaMobiliario: boolean
    outroPerguntaMobiliario: string

    perguntaPredominanciaDaPostura: number

}
export interface EditColetaErgoResponseData {
    userId: string
    userName: string
    clientId: string
    clientName: string
    nome: string
    setor: string
    funcaoClt: string
    nomeSupervisor: string
    eHorarioTrabalho: number
    idade: number
    atividadesPrincipais: string
    tempoTrabalhado: number
    praticaExercicioFisico: number
    fumantePerguntaHabitoQueNaoGeraSaude: boolean
    naoDescansaPerguntaHabitoQueNaoGeraSaude: boolean
    bebePerguntaHabitoQueNaoGeraSaude: boolean
    redesSociaisPerguntaHabitoQueNaoGeraSaude: boolean
    alimentacaoRuimPerguntaHabitoQueNaoGeraSaude: boolean
    naoPerguntaHabitoQueNaoGeraSaude: boolean
    outrosPerguntaHabitoQueNaoGeraSaude: string
    altura: number
    peso: number
    ruidoPerguntaSobreAmbienteDeTrabalho: number
    temperaturaPerguntaSobreAmbienteDeTrabalho: number
    iluminacaoPerguntaSobreAmbienteDeTrabalho: number
    baixaUmidadePerguntaSobreAmbienteDeTrabalho: number
    cabecaPerguntaDesconfortoUltimosDias: number
    pescocoPerguntaDesconfortoUltimosDias: number
    ombroPerguntaDesconfortoUltimosDias: number
    bracosPerguntaDesconfortoUltimosDias: number
    punhosPerguntaDesconfortoUltimosDias: number
    colunaPerguntaDesconfortoUltimosDias: number
    quadrilPerguntaDesconfortoUltimosDias: number
    joelhosPerguntaDesconfortoUltimosDias: number
    tornozelosPerguntaDesconfortoUltimosDias: number
    pesPerguntaDesconfortoUltimosDias: number
    perguntaEsforcoMental: number
    perguntaEsforcoFisico: number
    respostaPerguntaMudarAlgoNoTrabalho: string
    flexaoAcima20GrausPerguntaPosturaInadequadaPescoco: boolean
    extensaoSemSupAcima10GrausPerguntaPosturaInadequadaPescoco: boolean
    inclinacaoAcima20GrausPerguntaPosturaInadequadaPescoco: boolean
    rotacaoAcima30GrausPerguntaPosturaInadequadaPescoco: boolean
    naoExisteRiscoPescocoPerguntaPosturaInadequadaPescoco: boolean
    outrosPerguntaPosturaInadequadaPescoco: string
    flexaoAcima45A90GrausPerguntaPosturaInadequadaOmbros: boolean
    extensaoAcima20GrausPerguntaPosturaInadequadaOmbros: boolean
    aducaoAcima45GrausPerguntaPosturaInadequadaOmbros: boolean
    rotacaoAcima45GrausPerguntaPosturaInadequadaOmbros: boolean
    abducaoAcima45GrausPerguntaPosturaInadequadaOmbros: boolean
    ombrosElevadosPerguntaPosturaInadequadaOmbros: boolean
    naoExisteRiscoOmbroPerguntaPosturaInadequadaOmbros: boolean
    outrosPerguntaPosturaInadequadaOmbros: string
    flexaoAcima100GrausPerguntaPosturaInadequadaCotovelos: boolean
    extensaoAcima60GrausPerguntaPosturaInadequadaCotovelos: boolean
    totalmenteExtendPerguntaPosturaInadequadaCotovelos: boolean
    totalmenteFlexPerguntaPosturaInadequadaCotovelos: boolean
    naoExisteRiscoCotovelosPerguntaPosturaInadequadaCotovelos: boolean
    outrosPerguntaPosturaInadequadaCotovelos: string
    flexaoAcima45GrausPerguntaPosturaInadequadaPunhos: boolean
    desviosLateraisAcima10GrausPerguntaPosturaInadequadaPunhos: boolean
    extensaoAcima15GrausPerguntaPosturaInadequadaPunhos: boolean
    totalmenteFlexionadoEstendidoPerguntaPosturaInadequadaPunhos: boolean
    naoExisteRiscoPunhosPerguntaPosturaInadequadaPunhos: boolean
    outrosPerguntaPosturaInadequadaPunhos: string
    flexaoMaiorOu20GrausPerguntaPosturaInadequadaTronco: boolean
    extensaoSemSuporteAcima10GrausPerguntaPosturaInadequadaTronco: boolean
    inclinacaoAcimaDe30GrausPerguntaPosturaInadequadaTronco: boolean
    rotacaoAcimaDe30GrausPerguntaPosturaInadequadaTronco: boolean
    naoExisteRiscoTroncoPerguntaPosturaInadequadaTronco: boolean
    outrosPerguntaPosturaInadequadaTronco: string
    flexaoAcima90GrausPerguntaPosturaInadequadaPernas: boolean
    naoTotalmenteApoiadaOuEquilibradaPerguntaPosturaInadequadaPernas: boolean
    naoHaEspacoParaAsPernasMaisDe2HorasPerguntaPosturaInadequadaPernas: boolean
    agachadoOuAjoelhadoPerguntaPosturaInadequadaPernas: boolean
    naoExisteRiscoPernasPerguntaPosturaInadequadaPernas: boolean
    outrosPerguntaPosturaInadequadaPernas: string
    posturaSentadoLongoPeriodoPerguntaPerigosPosturaisGerais: boolean
    posturaEmPeLongoPeriodoPerguntaPerigosPosturaisGerais: boolean
    frequentementeDeslocAPeDuranteTrabalhoPerguntaPerigosPosturaisGerais: boolean
    levantamentoTransporteManualDeCargasPerguntaPerigosPosturaisGerais: boolean
    movimentosRepetitivosFrequentesPerguntaPerigosPosturaisGerais: boolean
    manuseioFerramentasObjetosPesadosLongosPeriodosPerguntaPerigosPosturaisGerais: boolean
    compressaoPartesCorpoPorSuperficiesPerguntaPerigosPosturaisGerais: boolean
    usoFrequentePedaisPerguntaPerigosPosturaisGerais: boolean
    usoFrequenteAlavancasPerguntaPerigosPosturaisGerais: boolean
    exposicaoAVibracaoDeCorpoInteiroPerguntaPerigosPosturaisGerais: boolean
    exposicaoAVibracaoLocalizadaPerguntaPerigosPosturaisGerais: boolean
    usoFrequenteEscadasPerguntaPerigosPosturaisGerais: boolean
    trabalhoIntensivoTecladoEntradaDeDadosPerguntaPerigosPosturaisGerais: boolean
    naoExisteRiscosNessaAtivPerguntaPerigosPosturaisGerais: boolean
    outrosPerguntaPerigosPosturaisGerais: string
    entre2A10KGPerguntaForcasExistentesNaAtividadeAvaliada: boolean
    entre2A10KGEstaticoPerguntaForcasExistentesNaAtividadeAvaliada: boolean
    maior10KgRepetitivoOuImpactoPerguntaForcasExistentesNaAtividadeAvaliada: boolean
    posturaEstaticaSegurarPor1MOuMaisPerguntaForcasExistentesNaAtividadeAvaliada: boolean
    maosEmPincaPressaoMaiorQue09KGPerguntaForcasExistentesNaAtividadeAvaliada: boolean
    maosEmAgarreMaiorQue45KgPerguntaForcasExistentesNaAtividadeAvaliada: boolean
    cotovelosMaiorQue45KgPerguntaForcasExistentesNaAtividadeAvaliada: boolean
    ombrosMaiorQue45KgPerguntaForcasExistentesNaAtividadeAvaliada: boolean
    cervicalPesoAdicionalPerguntaForcasExistentesNaAtividadeAvaliada: boolean
    colunaMaiorQue113KgPerguntaForcasExistentesNaAtividadeAvaliada: boolean
    pedalMaiorQue45KgPerguntaForcasExistentesNaAtividadeAvaliada: boolean
    transporteManual15kgMinutoPerguntaForcasExistentesNaAtividadeAvaliada: boolean
    peso5A10Kg1XPorMinutoPerguntaForcasExistentesNaAtividadeAvaliada: boolean
    pegarPesoAcimaDe175CmPerguntaForcasExistentesNaAtividadeAvaliada: boolean
    puxarEmpurrarEmDesnivelPerguntaForcasExistentesNaAtividadeAvaliada: boolean
    distanciaHorizontalMaior63CmPegarCargaPerguntaForcasExistentesNaAtividadeAvaliada: boolean
    naoHaRiscosDeForcaNaAtividadePerguntaForcasExistentesNaAtividadeAvaliada: boolean
    outrosPerguntaForcasExistentesNaAtividadeAvaliada: string
    movimentoConstanteSemPausasPerguntaFatoresRepetitividadeNaAtividade: boolean
    movimentoFrequenteComPausaPerguntaFatoresRepetitividadeNaAtividade: boolean
    repeticaoDeAcoesTecnicasMaisDaMetadeDoCicloPerguntaFatoresRepetitividadeNaAtividade: boolean
    naoHaRepetitividadeNaAtividadePerguntaFatoresRepetitividadeNaAtividade: boolean
    outrosPerguntaFatoresRepetitividadeNaAtividade: string
    trabalhoSemPausasPreDefinidasPerguntaOrganizacaoDoTrabalho: boolean
    pausaRealizadaForaDoPostoPerguntaOrganizacaoDoTrabalho: boolean
    necessidadadeDeManterRitmosIntensosPerguntaOrganizacaoDoTrabalho: boolean
    trabalhoComNecessidadeDeVariacaoDeTurnosPerguntaOrganizacaoDoTrabalho: boolean
    monotoniaPerguntaOrganizacaoDoTrabalho: boolean
    trabalhoNoturnoPerguntaOrganizacaoDoTrabalho: boolean
    insuficienciaDeCapacitacaoParaExecucaoPerguntaOrganizacaoDoTrabalho: boolean
    trabalhoUtilizacaoRigorosaDeMetasDeProdPerguntaOrganizacaoDoTrabalho: boolean
    trabalhoRemuneradoPorProducaoPerguntaOrganizacaoDoTrabalho: boolean
    cadenciaDoTrabalhoImpostaPorEquipamentoPerguntaOrganizacaoDoTrabalho: boolean
    desequilibrioEntreTempoTrabalhadoETempoRepousoPerguntaOrganizacaoDoTrabalho: boolean
    jornadaLongaDeTrabalhoMaisDe8HPorDiaPerguntaOrganizacaoDoTrabalho: boolean
    concentracoesDesequilibradasDeTrabalhoPerguntaOrganizacaoDoTrabalho: boolean
    nenhumFatorDeRisgoNaAtivPerguntaOrganizacaoDoTrabalho: boolean
    outrosPerguntaOrganizacaoDoTrabalho: string
    trabalhoCondicoesDeDificilComunicacaoPerguntaFatoresPsicossociais: boolean
    excessoDeConflitosHierarquicosPerguntaFatoresPsicossociais: boolean
    insatisfacaoNoTrabalhoPerguntaFatoresPsicossociais: boolean
    faltaDeAutonomiaNoTrabalhoPerguntaFatoresPsicossociais: boolean
    extresseNoTrabalhoPerguntaFatoresPsicossociais: boolean
    naoHaFatorDeRiscoPsicossocialPerguntaFatoresPsicossociais: boolean
    outrosPerguntaFatoresPsicossociais: string
    perguntaFatorDeRiscoGravidade: number
    perguntaFatorDeProbabilidade: number
    sugestaoDoColetador: string

    trabalhoExigeConcentracaoPerguntaFatoresCognitivos: boolean,
    trabalhoExigeAtencaoPerguntaFatoresCognitivos: boolean,
    trabalhoExigeMemorizeSenhaNumerosPerguntaFatoresCognitivos: boolean,
    usabilidadeNoTrabalhoFacilDeTrabalharPerguntaFatoresCognitivos: boolean,
    usabilidadeNoTrabalhoEngajamentoPerguntaFatoresCognitivos: boolean,
    usabilidadeNoTrabalhoAplicavelPerguntaFatoresCognitivos: boolean,
    oTrabalhoTemSistemaDeRetornoDeInfoPerguntaFatoresCognitivos: boolean,
    affordanceSistemasMaquinasEquipamentosERotasPerguntaFatoresCognitivos: boolean,
    sistemaDeCicloAbertoSistemasMaquinasEquipamentosPerguntaFatoresCognitivos: boolean,
    sistemaDeCicloFechadoSistemasMaquinasEquipamentosPerguntaFatoresCognitivos: boolean,
    operarMaisDeUmEquipamentoAoMesmoTempoPerguntaFatoresCognitivos: boolean,
    naoHaNenhumFatorDeRiscoPerguntaFatoresCognitivos: boolean,
    outrosPerguntaFatoresCognitivos: string,
}

export interface EditColetaErgoResponse {
    success: boolean;
    data: EditColetaErgoResponseData;
}
