import instance from "../../index";

export default UpdateSolucaoErgo



async function UpdateSolucaoErgo(request: Partial<UpdateSolucaoErgoRequest>, id: string | string[] | undefined) : Promise<UpdateSolucaoErgoResponse> {
    const response = await instance.put(`/v1/backoffice/solucoes-ergo/${id}`, request)
    return response.data;
}

interface UpdateSolucaoErgoRequest {
    id: string;
    tipoSolucaoErgonomica: number,
    tipoDeAcaoSolucaoErgo: number,
    descricao: string,
    normasRegulamentativas: string[]
}

interface UpdateSolucaoErgoResponseData {
    id: string
    tipoSolucaoErgonomica: number,
    tipoDeAcaoSolucaoErgo: number,
    descricao: string,
    normasRegulamentativas: string[]
    createdAt: Date,
}

export interface UpdateSolucaoErgoResponse {
    success: boolean;
    data: UpdateSolucaoErgoResponseData
}

