import React, {useState} from 'react';
import {Button, Form, Grid, Header, Icon, Segment, Table} from 'semantic-ui-react';
import CreateClient, {CreateClientRequest} from "services/backoffice/clients/create-client";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

const CreateClientPage = () => {
    const [client, setClient] = useState<Partial<CreateClientRequest>>({});
    const navigate = useNavigate();

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setClient((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));
    };

    const handleSubmit = async () => {
        try {
            await CreateClient({...client, zipCode: Number(client.zipCode?.toString().replace('-', ''))})
            navigate('/backoffice/clients/')
            toast.success('Cliente criado com sucesso', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (error) {
            // handle error
            console.warn(error);
        }
    };

    return (
        <div>
            <Grid.Column mobile={16} tablet={8} computer={6}>
                <Header as="h2" textAlign="center" color={'black'}>
                    Clientes / Adicionar
                </Header>
                <Segment>
                    <Form onSubmit={handleSubmit}>
                        <Form.Input
                            label="Nome Fantasia"
                            name="fantasyName"
                            type="text"
                            placeholder="Nome Fantasia"
                            value={client.fantasyName}
                            onChange={handleInputChange}
                            required
                        />
                        <Form.Input
                            label="Razão Social"
                            name="businessName"
                            type="text"
                            placeholder="Razão Social"
                            value={client.businessName}
                            onChange={handleInputChange}
                            required
                        />
                        <Form.Input
                            label="CNPJ"
                            name="cnpj"
                            type="number"
                            placeholder="CNPJ"
                            value={client.cnpj}
                            onChange={handleInputChange}
                            required>
                            {/*<InputMask*/}
                            {/*    mask="99.999.999/9999-99"*/}
                            {/*/>*/}
                        </Form.Input>
                        <Form.Input
                            label="CNAE"
                            name="cnae"
                            type="text"
                            placeholder="CNAE"
                            value={client.cnae}
                            onChange={handleInputChange}
                            required
                        />
                        <Form.Input
                            label="Endereço"
                            name="address"
                            type="text"
                            placeholder="Endereço"
                            value={client.address}
                            onChange={handleInputChange}
                            required
                        />
                        <Form.Input
                            label="Número do Endereço"
                            name="addressNumber"
                            type="number"
                            placeholder="Número do Endereço"
                            value={client.addressNumber}
                            onChange={handleInputChange}
                            required
                        />
                        <Form.Input
                            label="Complemento do Endereço"
                            name="addressComplement"
                            type="text"
                            placeholder="Complemento do Endereço"
                            value={client.addressComplement}
                            onChange={handleInputChange}
                        />
                        <Form.Input
                            label="Bairro"
                            name="addressNeighborhood"
                            type="text"
                            placeholder="Bairro"
                            value={client.addressNeighborhood}
                            onChange={handleInputChange}
                            required
                        />
                        <Form.Input
                            label="CEP"
                            name="zipCode"
                            type="text"
                            placeholder="CEP"
                            value={client.zipCode}
                            onChange={handleInputChange}
                            maxLength={9}
                            mask={"[0-9]{5}-[0-9]{3}"}
                            required>
                        </Form.Input>
                        <Form.Input
                            label="Cidade"
                            name="city"
                            type="text"
                            placeholder="Cidade"
                            value={client.city}
                            onChange={handleInputChange}
                            required
                        />
                        <Form.Input
                            label="Estado"
                            name="state"
                            type="text"
                            placeholder="Estado"
                            value={client.state}
                            onChange={handleInputChange}
                            required
                            maxLength={2}
                        />
                        <Table.Row>
                            <Table.HeaderCell>
                                <Form.Button content="Voltar"
                                             icon='arrow left'
                                             onClick={() => navigate('/backoffice/clients/')} grey/>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <Form.Button content="Adicionar"
                                             type={"submit"}
                                             icon='save'
                                             primary/>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Form>
                </Segment>
            </Grid.Column>
        </div>
    );
};

export default CreateClientPage;
