import instance from "services";

export default EditCollector

async function EditCollector(request: Partial<EditCollectorRequest>, id: string | string[] | undefined): Promise<EditCollectorResponse> {
    const response = await instance.put(`/v1/backoffice/coletores/${id}`, request);
    return response.data;
}
export interface EditCollectorRequest {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    clients: string[];
}
export interface EditCollectorResponseData {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    createdAt: Date;
    // updatedAt: Date;
    // isActive: boolean;
}

export interface EditCollectorResponse {
    success: boolean;
    data: EditCollectorResponseData;
}
