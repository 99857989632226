import GetSolucaoErgoById from "../../../../services/backoffice/solucoes-ergo/get-solucao-ergo";
import React, {useEffect, useRef, useState} from 'react';
import {Form, Grid, Header, Segment, Table} from 'semantic-ui-react';
import {toast} from "react-toastify";
import {useLoaderData, useNavigate} from "react-router-dom";
import {ETiposSolucoesErgonomicas} from "../../../../enums/ePerguntaSolucoesCognitivas";
import UpdateSolucaoErgo from "../../../../services/backoffice/solucoes-ergo/update-solucao-ergo";
import GetNormasRegulamentativasToDropdown
    from "../../../../services/backoffice/normas-regulamentativas/get-normas-short-list";
import {ETipoDeAcaoSolucaoErgo} from "../../../../enums/eTipoDeAcaoSolucaoErgo";

export async function viewSolucaoErgoLoader({params}: any) {
    window.scrollTo(0, 0);
    return await GetSolucaoErgoById(params.id);
}



const ViewSolucaoErgoPage = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const navigate = useNavigate();
    const loader: any = useLoaderData();
    const [solucao, setSolucao] = useState<any>();
    const [normasRegulamentativas, setNormasRegulamentativas] = useState<any[]>([]);
    useEffect(()=> setSolucao(loader), [])



    useEffect(() => {
        function ListNormasRegulamentativas() {
            GetNormasRegulamentativasToDropdown().then((response) => {
                setNormasRegulamentativas(response);
            })
                .catch(error => {
                    console.warn(error);
                })
        }
        ListNormasRegulamentativas()
    }, [])



    return (
        <div>
            <Grid.Column mobile={16} tablet={8} computer={6}>
                <Header as="h2" textAlign="center" color={'black'}>
                    Solução Ergonômica / Visualizar
                </Header>
                <Segment>
                    <Form>
                        <Form.Field>
                            <Form.Select
                                label={'Tipo de Ação'}
                                options={ETipoDeAcaoSolucaoErgo}
                                value={solucao?.tipoDeAcaoSolucaoErgo}
                                readOnly
                                required
                            />
                        </Form.Field>

                        <Form.Field>
                            <Form.Select
                                label={'Tipo de Solução Ergonômica'}
                                options={ETiposSolucoesErgonomicas}
                                value={solucao?.tipoSolucaoErgonomica}
                                readOnly
                                required
                            />
                        </Form.Field>

                        <Form.Input
                            label="Descrição"
                            name="solucaoParaErgonomia"
                            type="text"
                            placeholder="Solução encontrada"
                            value={solucao?.descricao}
                            readOnly
                        />

                        <Form.Select
                            label={'Normas Regulamentativas'}
                            options={normasRegulamentativas}
                            placeholder='Selecione uma opção'
                            value={solucao?.normasRegulamentativas}
                            multiple
                            readOnly
                        />
                        <Table.Row>
                            <Table.HeaderCell>
                                <Form.Button content="Voltar"
                                             icon='arrow left'
                                             onClick={() => navigate('/backoffice/solucoes-ergo/')} grey/>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Form>
                </Segment>
            </Grid.Column>
        </div>
    );
};

export default ViewSolucaoErgoPage;
