import instance from "../../index";
import {TurmaRelatorio} from "../../../models/turmaRelatorio";
import {TurmaRelatorioFilter} from "../../../models/turmaRelatorioFilter";
import {RelatorioTurmaPresenca} from "../../../models/relatorioTurmaPresenca";
import {TurmaRelatorioPresencasFilter} from "../../../models/turmaRelatorioPresencasFilter";
import {ApiConfig} from "config/ApiConfig";
import StringUtils from "../../../shared/utils/string-utils";

export async function GetTurmaRelatorio(idTurma: string, filter?: TurmaRelatorioFilter): Promise<TurmaRelatorio> {
    const response = await instance.get(`/v1/backoffice/relatorios/turma/${idTurma}`, {
        params: filter
    })
    return response.data.data
}

export async function GetTurmaRelatorioPdf(idTurma: string, filter?: TurmaRelatorioFilter) {
    window.open(ApiConfig.API_URL + `/v1/backoffice/relatorios/turma/${idTurma}/pdf` + StringUtils.jsonToQueryString(filter), '_blank')
}

export async function GetTurmaRelatorioPresenca(idTurma: string, filter?: TurmaRelatorioPresencasFilter): Promise<RelatorioTurmaPresenca> {

    const response = await instance.get(`/v1/backoffice/relatorios/turma/${idTurma}/presenca`, {
        params: filter
    })
    return response?.data?.data
}

export async function GetTurmaRelatorioPresencaPdf(idTurma: string, filter?: TurmaRelatorioPresencasFilter) {
    // const response = await instance.get(`/v1/backoffice/relatorios/turma/${idTurma}/presenca/pdf`, {
    //     params: filter
    // })
    // console.log('file', {response});
    // const linkSource = `data:application/pdf;base64,${response}`;
    // const downloadLink = document.createElement("a");
    // const fileName = "file.pdf";
    // downloadLink.href = linkSource;
    // downloadLink.download = fileName;
    // downloadLink.click();
    window.open(ApiConfig.API_URL + `/v1/backoffice/relatorios/turma/${idTurma}/presenca/pdf` + StringUtils.jsonToQueryString(filter), '_blank')
}


