import React, {useEffect, useState} from "react";
import {SessionUser} from "../../../models/session-user";
import {useNavigate} from "react-router-dom";
import GetTreinamentos from "../../../services/sistema-aulas/treinamentos/get-treinamentos";
import {TreinamentoFilter} from "../../../filters/treinamento-filter";
import SessionStorage from "../../../store/session";
import {toast} from "react-toastify";
import DeleteTreinamento from "../../../services/sistema-aulas/treinamentos/delete-treinamento";
import {Button, Dropdown, Header, Icon, Input, Pagination, Segment, Table} from "semantic-ui-react";
import {useBlockUI} from "../../../shared/services/block-ui-service";

const TreinamentosList = () => {
    const [treinamentos, setTreinamentos] = useState<any[]>([])
    const [error, setError] = useState<string>()
    const [loading, setLoading] = useState<boolean>(false)
    const [filter, setFilter] = useState<TreinamentoFilter>()
    const [totalPages, setTotalPages] = useState()
    const [showFilters, setShowFilters] = useState(false)
    const [sessionUser, setSessionUser] = useState<SessionUser>();
    const navigate = useNavigate();
    const blockUI = useBlockUI();
    useEffect(() => {
        blockUI?.setActive(loading)
    }, [loading]);

    useEffect(() => {
        async function updateSessionUser() {
            const user = await SessionStorage.getSession();
            setSessionUser(user)
        }

        updateSessionUser()
    }, [])

    async function listTreinamentos() {
        try {
            setLoading(true);
            const response = (await GetTreinamentos(filter))?.data?.data;
            setTotalPages(response.totalPages);
            setTreinamentos(response.items);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    }


    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };
    useEffect(() => {
        listTreinamentos();
    }, [filter]);

    async function deleteTreinamento(treinamento: any) {
        try {
            const confirm = window.confirm(`Tem certeza que deseja apagar o treinamento ${treinamento.name}?`);
            if (confirm) {
                setLoading(true);
                if (treinamento) {
                    await DeleteTreinamento(treinamento.id);
                    toast.success(`Treinamento ${treinamento?.name} deletado com sucesso`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    await listTreinamentos();
                }
            }
        } catch (error: any) {
            toast.warn(`Falha ao deletar treinamento`, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } finally {
            setLoading(false);
        }
    }



    return (
        <div>
            <Header as="h2" textAlign="center" color={'black'}>
                Treinamentos
            </Header>
            <Table.Row>
                <Table.HeaderCell>
                    {sessionUser?.userData?.profile === 'Admin' &&
                        <Button color="blue"
                                onClick={() => navigate('/treinamentos/treinamentos/create/')}>

                            <Icon name='plus'/> Novo treinamento
                        </Button>}
                </Table.HeaderCell>
                <Table.HeaderCell colSpan="4">
                    <Button onClick={toggleFilters} primary>
                        <Icon name={showFilters ? 'hide' : 'unhide'}/>
                        {showFilters ? 'Ocultar filtros' : 'Exibir filtros'}
                    </Button>
                </Table.HeaderCell>
            </Table.Row>{showFilters && (
            <Segment>
                <div style={{padding: 10}}>
                    <div>
                        <Input
                            fluid
                            icon="search"
                            type="text"
                            placeholder="Nome do treinamento"
                            value={filter?.Name ?? ''}
                            onChange={event =>
                                setFilter({...filter, Name: event.target?.value})
                            }
                        />
                    </div>
                    <br/>
                    <div>
                        <Input
                            fluid
                            icon="search"
                            type="text"
                            placeholder="Descrição do treinamento"
                            value={filter?.Description ?? ''}
                            onChange={event =>
                                setFilter({...filter, Description: event.target?.value})
                            }
                        />
                    </div>
                </div>
            </Segment>
        )}

            <Table color={"orange"}>
                <Table.Header>
                    <Table.Row>
                        {sessionUser?.userData?.profile === 'Admin' &&
                        <Table.HeaderCell>#</Table.HeaderCell>}
                        <Table.HeaderCell>Nome</Table.HeaderCell>
                        <Table.HeaderCell>Descrição</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {treinamentos?.map((treinamento) => {
                        return (
                            <Table.Row key={treinamento?.id}>
                                {sessionUser?.userData?.profile === 'Admin' &&
                                    <Table.Cell>
                                        <Dropdown item icon={'bars'}>
                                            <Dropdown.Menu>
                                                <Dropdown.Item
                                                    color="blue"
                                                    text={'Editar'}
                                                    onClick={() => navigate('/treinamentos/treinamentos/edit/' + treinamento?.id)}
                                                >
                                                    <Icon name='edit' color='blue' />
                                                    Editar
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    text={'Deletar'}
                                                    onClick={() => deleteTreinamento(treinamento)}
                                                >
                                                    <Icon name='trash' color={'red'} />
                                                    Deletar
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Table.Cell>
                                }
                                <Table.Cell>{treinamento?.name}</Table.Cell>
                                <Table.Cell>{treinamento?.description}</Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
            <Pagination totalPages={totalPages ?? 0}
                        defaultActivePage={filter?.Page ?? 1}
                        onPageChange={(e, {activePage}) => {
                            setFilter({...filter, Page: activePage as number})
                        }
                        }>
            </Pagination>
        </div>
    )
}

export default TreinamentosList;
