import instance from "../../index";

export default CreateParticipanteLote

async function CreateParticipanteLote(buffer: any, name: any): Promise<any> {
    return await instance.post('/v1/backoffice/participantes/import',{
        file:{
            buffer, name
        }
    })
}
