import instance from "services";

export default EditClient

async function EditClient(request: Partial<EditClientRequest>, id: string | string[] | undefined): Promise<EditClientResponse> {
    const response = await instance.put(`/v1/backoffice/clientes/${id}`, request);
    return response.data;
}
export interface EditClientRequest {
    id: string;
    fantasyName: string;
    businessName: string;
    cnpj: string;

    cnae: string;

    address: string;

    addressNumber: number;

    addressComplement: string;

    addressNeighborhood: string;

    zipCode: number;

    city: string;

    state: string;
}
export interface EditClientResponseData {
    id: string;
    fantasyName: string;
    businessName: string;
    cnpj: string;

    cnae: string;

    address: string;

    addressNumber: number;

    addressComplement: string;

    addressNeighborhood: string;

    zipCode: number;

    city: string;

    state: string;
}

export interface EditClientResponse {
    success: boolean;
    data: EditClientResponseData;
}
