export const EStatusPresenca = [
    { key: '0', text: 'Atestado Médico', value: 'AtestadoMedico' },
    { key: '1', text: 'Licença Maternidade', value: 'LicancaMaternidade' },
    { key: '2', text: 'Desligado', value: 'Desligado' },
    { key: '3', text: 'Férias', value: 'Ferias' },
    { key: '4', text: 'Folga', value: 'Folga' },
    { key: '5', text: 'Serviço Externo', value: 'ServicoExterno' },
    { key: '7', text: 'Serviço Interno', value: 'ServicoInterno' },
    { key: '6', text: 'Home Office', value: 'HomeOffice' },
    { key: '8', text: 'Horário', value: 'Horario' },
    { key: '10', text: 'Ausente', value: 'Ausente' },
]