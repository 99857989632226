import GetParticipanteById from "../../../../services/sistema-aulas/participante/get-participante";
import React, {useEffect, useState} from "react";
import EditParticipante, {
    UpdateParticipanteRequest
} from "../../../../services/sistema-aulas/participante/edit-participante";
import {GetClientsResponse} from "../../../../services/backoffice/clients/get-clients";
import {useLoaderData, useNavigate} from "react-router-dom";
import GetAllClients from "../../../../services/backoffice/clients/get-all-clients";
import {toast} from "react-toastify";
import {Form, Grid, Header, Segment, Table} from "semantic-ui-react";
import {useBlockUI} from "../../../../shared/services/block-ui-service";

export async function editParticipanteLoader({params}: any) {
    return await GetParticipanteById(params.id);
}

const EditParticipantePage = () => {
    const [participante, setParticipante] = useState<Partial<UpdateParticipanteRequest>>();
    const [clientes, setClientes] = useState<GetClientsResponse[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const loader: any = useLoaderData();
    const navigate = useNavigate();
    const blockUI = useBlockUI();
    useEffect(()=> setParticipante(loader), [])
    useEffect(() => {
        blockUI?.setActive(loading)
    }, [loading]);


    useEffect(() => {
        function ListClientes() {
            GetAllClients().then((response) => {
                setClientes(response);
            })
                .catch(error => {
                    console.warn(error);
                })
        }
        ListClientes()
    }, [])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setParticipante((prevParticipante) => ({
            ...prevParticipante,
            [name]: value,
        }));
    };

    const handleDropdownChange = (name: string, data: any) => {
        setParticipante((prevParticipante: any) => ({
            ...prevParticipante,
            [name]: data.value,
        }));
    };

    const updateParticipante = async () => {
        try {
            setLoading(true);
            if (participante) {
                await EditParticipante(participante, participante.id);
                toast.success('Participante atualizado com sucesso', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate('/treinamentos/participantes/');
            }
        }
        catch (e: string | any){
            setError(e)
        }
        finally {
            setLoading(false);
        }
    };



    return (
        <div>
            <Grid.Column mobile={16} tablet={8} computer={6}>
                <Header as="h2" textAlign="center" color={'black'}>
                    Atualizar Participante
                </Header>
                <Segment>
                    <Form onSubmit={updateParticipante}>
                        <Form.Input
                            label="Nome"
                            name="name"
                            placeholder="Nome"
                            value={participante?.nome}
                            onChange={(event) => setParticipante({...participante, nome: event.target.value})}

                        />
                        <Form.Select
                            label={'Cliente'}
                            options={clientes}
                            placeholder='Selecione uma opção'
                            value={participante?.clientId}
                            onChange={(evento: any, data: any) => {
                                setParticipante({
                                    ...participante,
                                    clientId: data.value,
                                });
                            }}
                            required
                        />
                        <Form.Input
                            label={'Setor do participante'}
                            type='text'
                            placeholder='Setor em que o participante trabalha'
                            value={participante?.setor}
                            onChange={(event) => setParticipante({...participante, setor: event.target.value})}
                            required
                        />
                        <Table.Row>
                            <Table.HeaderCell>
                                <Form.Button content="Voltar"
                                             icon='arrow left'
                                             onClick={() => navigate('/treinamentos/participantes/')} grey/>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                            <Form.Button content="Atualizar"
                                         icon='save'
                                         primary/>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Form>
                </Segment>
            </Grid.Column>
        </div>
    );
}

export default EditParticipantePage;
