import instance from "services";
import {CollectorFilter} from "../../../filters/collector-filter";

export default async function GetCollectors(filter?: CollectorFilter) : Promise<any> {
    return await instance.get('/v1/backoffice/coletores', {
        params: filter
    })
}
export interface GetCollectorResponseData {
    id: string
    firstName: string
    lastName: string
    email: string
    createdAt: string
    updatedAt: string
    isActive: boolean
}

export interface GetCollectorResponse {
    success: boolean;
    data: GetCollectorResponseData;
}
