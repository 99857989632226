import instance from "services";

export default CreateCollector

async function CreateCollector(request: Partial<CreateCollectorRequest>): Promise<CreateCollectorResponse> {
    const response = await instance.post(`/v1/backoffice/coletores/`, request);
    return response?.data?.data;
}
export interface CreateCollectorRequest {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    passwordConfirmation: string;
    clients: string[];
    isActive: boolean;
}
export interface CreateCollectorResponseData {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    clients: string[];
    createdAt: Date;
    updatedAt: Date;
    isActive: boolean;
}

export interface CreateCollectorResponse {
    success: boolean;
    data: CreateCollectorResponseData;
}
