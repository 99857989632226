import instance from "../../../index";

export default CreateAvaliacaoErgoSolucaoErgo

async function CreateAvaliacaoErgoSolucaoErgo(request: Partial<CreateAvaliacaoErgoSolucaoErgoRequest>): Promise<CreateAvaliacaoErgoSolucaoErgoResponse> {
    const response = await instance.post(`/v1/backoffice/avaliacoes-ergo-preliminares/solucao-ergo`, request);
    return response?.data?.data
}

export interface CreateAvaliacaoErgoSolucaoErgoRequest {
    avaliacaoErgoId: string
    solucaoErgoId: string
}

export interface CreateAvaliacaoErgoSolucaoErgoResponse {
    success: boolean;
    data: CreateAvaliacaoErgoSolucaoErgoResponseData;
}

export interface CreateAvaliacaoErgoSolucaoErgoResponseData {
    avaliacaoErgoId: string
    solucaoErgoId: string
}