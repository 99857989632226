export const EPerguntaEsforcoMental = [
    { key: '0', text: '1 - Muito, muito baixo', value: 'MuitoMuitoBaixo' },
    { key: '1', text: '2 - Muito baixo', value: 'MuitoBaixo' },
    { key: '2', text: '3 - Baixo', value: 'Baixo' },
    { key: '3', text: '4 - Quase baixo', value: 'QuaseBaixo' },
    { key: '4', text: '5 - Nem baixo nem alto', value: 'NemBaixoNemAlto' },
    { key: '5', text: '6 - Quase alto', value: 'QuaseAlto' },
    { key: '6', text: '7 - Alto', value: 'Alto' },
    { key: '7', text: '8 - Muito alto', value: 'MuitoAlto' },
    { key: '8', text: '9 - Muito, muito alto', value: 'MuitoMuitoAlto' },
]