import React, {useEffect, useState} from 'react';
import {Button, Form, Header, Icon, Message, Segment, Table} from 'semantic-ui-react';
import PutClient, {EditClientRequest} from "services/backoffice/clients/put-client";
import GetClient from "services/backoffice/clients/get-client";
import {useLoaderData, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {useBlockUI} from "../../../../shared/services/block-ui-service";

export async function editClientLoader({params}: any) {
    return await GetClient(params.id);
}

const EditClientPage = () => {
    const [client, setClient] = useState<Partial<EditClientRequest>>();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const navigate = useNavigate();
    const loader: any = useLoaderData();
    useEffect(()=> setClient(loader), [])
    const blockUI = useBlockUI();
    useEffect(() => {
        blockUI?.setActive(loading)
    }, [loading]);
    const updateClient = async () => {
        try {
            setLoading(true);

            if (client) {
                const result = await PutClient(client, client.id);
                if (result.success) {
                    toast.success('Cliente atualizado com sucesso', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });

                    await navigate('/backoffice/clients/');
                }
            }
        } catch (error) {
            console.warn(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <Header as="h2" textAlign="center" color={'black'}>
                Clientes / Editar
            </Header>
            <Form size="large" onSubmit={updateClient}>
                <Segment stacked>
                    <Form.Input
                        fluid
                        label="Nome Fantasia"
                        placeholder="Nome Fantasia"
                        name="fantasyName"
                        value={client?.fantasyName}
                        onChange={(event) => setClient({...client, fantasyName: event.target.value})}
                        required
                    />
                    <Form.Input
                        fluid
                        placeholder="Razão Social"
                        label="Razão Social"
                        name="businessName"
                        value={client?.businessName}
                        onChange={(event) => setClient({...client, businessName: event.target.value})}
                        required
                    />
                    <Form.Input
                        fluid
                        label="CNPJ"
                        name="cnpj"
                        value={client?.cnpj}
                        onChange={(event) => setClient({...client, cnpj: event.target.value})}
                        required>
                        {/*<InputMask*/}
                        {/*    mask="99.999.999/9999-99"*/}
                        {/*/>*/}
                    </Form.Input>
                    <Form.Input
                        fluid
                        label="CNAE"
                        name="cnae"
                        value={client?.cnae}
                        onChange={(event) => setClient({...client, cnae: event.target.value})}
                        required>
                        {/*<InputMask*/}
                        {/*    mask="99.999.999/9999-99"*/}
                        {/*/>*/}
                    </Form.Input>
                    <Form.Input
                        fluid
                        placeholder="Endereço"
                        label="Endereço"
                        name="address"
                        value={client?.address}
                        onChange={(event) => setClient({...client, address: event.target.value})}
                        required
                    />
                    <Form.Input
                        fluid
                        placeholder="Número do endereço"
                        label="Número do endereço"
                        name="addressNumber"
                        value={client?.addressNumber}
                        onChange={(event) => setClient({...client, addressNumber: Number.parseInt(event.target.value)})}
                        required
                    />
                    <Form.Input
                        fluid
                        placeholder="Complemento"
                        label="Complemento"
                        name="addressComplement"
                        value={client?.addressComplement}
                        onChange={(event) => setClient({...client, addressComplement: event.target.value})}
                    />
                    <Form.Input
                        fluid
                        placeholder="Bairro"
                        label="Bairro"
                        name="addressNeighborhood"
                        value={client?.addressNeighborhood}
                        onChange={(event) => setClient({...client, addressNeighborhood: event.target.value})}
                        required
                    />
                    <Form.Input
                        fluid
                        placeholder="CEP do endereço"
                        label="CEP do endereço"
                        name="zipCode"
                        maxLength={9}
                        mask={"[0-9]{5}-[0-9]{3}"}
                        value={client?.zipCode}
                        onChange={(event) => setClient({...client, zipCode: Number.parseInt(event.target.value)})}
                        required
                    />
                    <Form.Input
                        fluid
                        placeholder="Cidade"
                        label="Cidade"
                        name="city"
                        value={client?.city}
                        onChange={(event) => setClient({...client, city: event.target.value})}
                        required
                    />
                    <Form.Input
                        fluid
                        placeholder="Estado"
                        label="Estado"
                        name="state"
                        value={client?.state}
                        onChange={(event) => setClient({...client, state: event.target.value})}
                        required
                    />

                    <Table.Row>
                        <Table.HeaderCell>
                            <Form.Button content="Voltar"
                                         icon='arrow left'
                                         onClick={() => navigate('/backoffice/clients/')} grey/>
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            <Form.Button content="Atualizar"
                                         type={"submit"}
                                         icon='save'
                                         primary/>
                        </Table.HeaderCell>
                    </Table.Row>
                    {error && <Message negative>{error}</Message>}
                </Segment>
            </Form>
        </div>
    );
};

export default EditClientPage;
