import instance from "services";

import {RelatorioTurmasClienteFilter} from "../../../../../filters/relatorio-turmas-cliente-filter";

export async function GetRelatorioTreinamentosAnual(filter?: RelatorioTurmasClienteFilter): Promise<RelatorioAnualTurmasPorClienteResponse> {
    const response = await instance.get<RelatorioAnualTurmasPorClienteResponse>(`/v1/backoffice/relatorios/treinamentos/anual`, { params: filter });
    return response.data;
}
interface MesRelatorioAnualTurmasPorClienteDTO {
    mes: number;
    ano: number;
    participacaoPorcentagem: number;
    periodoDescricao: string;
}

export interface RelatorioAnualTurmasPorClienteResponse {
    clienteNome: string;
    ano: number;
    participacaoPorcentagem: number;
    meses: MesRelatorioAnualTurmasPorClienteDTO[];
    periodoDescricao: string;
    aulasTotal: number;
}
