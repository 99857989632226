import React, {useEffect, useState} from 'react';
import {Button, Form, Header, Icon, Message, Segment, Table} from 'semantic-ui-react';
import PutCollectors, {EditCollectorRequest} from "services/backoffice/collectors/put-collectors";
import GetCollector from "services/backoffice/collectors/get-collector";
import {useLoaderData, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import GetAllClients from "../../../../services/backoffice/clients/get-all-clients";
import {useBlockUI} from "../../../../shared/services/block-ui-service";

export async function editCollectorLoader({params}: any) {
    return await GetCollector(params.id);
}

const EditCollectorUserPage = () => {
    const [collector, setCollector] = useState<Partial<EditCollectorRequest>>();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const navigate = useNavigate();
    const loader: any = useLoaderData();
    const [clients, setClients] = useState<any[]>([]);
    const blockUI = useBlockUI();
    useEffect(() => {
        blockUI?.setActive(loading)
    }, [loading]);

    useEffect(() => {
        function ListClients() {
            GetAllClients().then((response) => {
                setClients(response);
            })
                .catch(error => {
                    console.warn(error);
                })
        }

        ListClients()
    }, [])

    useEffect(() => {
        setCollector(loader)
    }, [])

    const updateCollector = async () => {
        try {
            setLoading(true);

            if (collector) {
                const result = await PutCollectors(collector, collector.id);

                if (result.success) {
                    navigate('/backoffice/collectors/');
                    toast.success('Coletor atualizado com sucesso', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });

                    setError('');
                }
            }
        } catch (error:any) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };


    return (
        <div>
            <Header as="h2" textAlign="center" color={'black'}>
                Coletores / Editar
            </Header>
            <Form size="large" onSubmit={updateCollector}>
                <Segment stacked>
                    <Form.Input
                        fluid
                        label="Nome"
                        placeholder="Nome"
                        name="firstName"
                        value={collector?.firstName}
                        onChange={(event) => setCollector({...collector, firstName: event.target.value})}
                        required
                    />
                    <Form.Input
                        fluid
                        label="Sobrenome"
                        placeholder="Sobrenome"
                        name="lastName"
                        value={collector?.lastName}
                        onChange={(event) => setCollector({...collector, lastName: event.target.value})}
                        required
                    />
                    <Form.Input
                        fluid
                        label="E-mail"
                        placeholder="E-mail"
                        name="email"
                        value={collector?.email}
                        onChange={(event) => setCollector({...collector, email: event.target.value})}
                        required
                        type="email"
                    />
                    <Form.Field>
                        <Form.Select
                            label={'Clientes vinculados'}
                            options={clients}
                            placeholder='Selecione uma opção'
                            value={collector?.clients}
                            onChange={(evento: any, data: any) => {
                                setCollector({
                                    ...collector,
                                    clients: data.value,
                                });
                            }}
                            multiple
                            search
                            required
                        />
                    </Form.Field>
                    <Table.Row>
                        <Table.HeaderCell>
                            <Form.Button content="Voltar"
                                         icon='arrow left'
                                         onClick={() => navigate('/backoffice/collectors/')} grey/>
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            <Form.Button content="Atualizar"
                                         type={"submit"}
                                         icon='save'
                                         primary/>
                        </Table.HeaderCell>
                    </Table.Row>
                    {error && <Message negative>{error}</Message>}
                </Segment>
            </Form>
        </div>
    );
};

export default EditCollectorUserPage;
