export const EPerguntaEsforcoFisico = [
    { key: '0', text: '0 - Repouso', value: 'Repouso' },
    { key: '1', text: '1 - Demasiado Leve', value: 'DemasiadoLeve' },
    { key: '2', text: '2 - Muito leve', value: 'MuitoLeve' },
    { key: '3', text: '3 - Muito leve leve', value: 'MuitoLeveLeve' },
    { key: '4', text: '4 - Leve', value: 'Leve' },
    { key: '5', text: '5 - Leve moderado', value: 'LeveModerado' },
    { key: '6', text: '6 - Moderado', value: 'Moderado' },
    { key: '7', text: '7 - Moderado intenso', value: 'ModeradoIntenso' },
    { key: '8', text: '8 - Intenso', value: 'Intenso' },
    { key: '9', text: '9 - Muito intenso', value: 'MuitoIntenso' },
    { key: '10', text: '10 - Exaustivo', value: 'Exaustivo' },
]