import React, {useEffect, useState} from 'react';
import './App.css';
import {RouterProvider} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import {adminRoutes} from "./navigation/admin";
import SessionStorage from "./store/session";
import {collectorRoutes} from "./navigation/collector";
import {operatorRoutes} from "./navigation/operator";
import {authRoutes} from "./navigation/auth-route";
import {professorRoutes} from "./navigation/professor";
import { BlockUIProvider } from 'shared/services/block-ui-service';

function App() {
    const [profile, setProfile] = useState<string>();
    const [username, setUsername] = useState<string>('');
    useEffect(() => {
        async function updateSessionUser() {
            try {
                SessionStorage.getSession().then(user => {
                    if (!user) return
                    const isLogged = user?.isLogged;
                    if (!isLogged) return
                    setProfile(user?.userData?.profile)
                    setUsername(user?.userData?.firstName)

                })
            } catch (e) {
            }
        }

        updateSessionUser()
    }, [SessionStorage])
    return (
        <BlockUIProvider>
            <RouterProvider router={!profile ? authRoutes
                : profile === 'Admin' ? adminRoutes(profile, !!profile, username)
                : profile === 'Professor' ? professorRoutes(profile, !!profile, username)
                : profile === 'Collector' ? collectorRoutes(profile, !!profile, username)
                    : profile === 'Operator' ? operatorRoutes(profile, !!profile, username)
                        : authRoutes}></RouterProvider>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"/>
        </BlockUIProvider>
    );
}

export default App;
