import BlockUI from 'components/block-ui';
import React, { createContext, useContext, useRef } from 'react';

interface BlockUIContextType {
    setActive: (active: boolean) => void;
}

const BlockUIContext = createContext<BlockUIContextType | undefined>(undefined);

export function useBlockUI() {
    const context = useContext(BlockUIContext);
    if (!context) {
        console.error('useBlockUI must be used within a BlockUIProvider');
    }
    return context;
}

export function BlockUIProvider({ children }: { children: React.ReactNode }) {
    // @ts-ignore
    const blockUiRef = useRef<BlockUI>(null);

    const setActive = (active: boolean) => {
        if (blockUiRef.current) {
            blockUiRef.current.setActive(active);
        }
    };

    return (
        <BlockUIContext.Provider value={{ setActive }}>
            {children}
            <BlockUI ref={blockUiRef} active={false} />
        </BlockUIContext.Provider>
    );
}
