import instance from "services";

export default DeleteClient

async function DeleteClient( id:string ) {
    const response = await instance.delete(`/v1/backoffice/clientes/${id}`);
    return response?.data;
}
export interface DeleteClientRequest {
    id: string;
}
export interface DeleteClientResponseData {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    createdAt: Date;
    updatedAt: Date;
    isActive: boolean;
}

export interface DeleteClientResponse {
    success: boolean;
    data: DeleteClientResponseData;
}
