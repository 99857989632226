import instance from "services";

export default GetClientById

async function GetClientById(id: string): Promise<GetClientResponse> {
    const response = await instance.get(`/v1/backoffice/clientes/${id}`).catch();
    return response?.data?.data;
}
export interface GetClientRequest {
    id: string;
}
export interface GetClientResponseData {
    id: string
    createdAt: string
    updatedAt: string
    fantasyName: string
    businessName: string
    cnpj: string
    cnae: string
    address: string
    addressNumber: number
    addressComplement: string
    addressNeighborhood: string
    zipCode: number
    city: string
    state: string
    isActive: boolean
}

export interface GetClientResponse {
    success: boolean;
    data: GetClientResponseData;
}
