import instance from "services";

export default DeleteCollector

async function DeleteCollector( id:string ) {
    const response = await instance.delete(`/v1/backoffice/coletores/${id}`);
    return response?.data;
}
export interface DeleteCollectorRequest {
    id: string;
}
export interface DeleteCollectorResponseData {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    createdAt: Date;
    updatedAt: Date;
    isActive: boolean;
}

export interface DeleteCollectorResponse {
    success: boolean;
    data: DeleteCollectorResponseData;
}
