import instance from "services";

export default CreateClient

async function CreateClient(request: Partial<CreateClientRequest>): Promise<CreateClientResponse> {
    const response = await instance.post(`/v1/backoffice/clientes/`, request);
    return response.data.data;
}
export interface CreateClientRequest {
    fantasyName: string
    businessName: string
    cnpj: string
    cnae: string
    address: string
    addressNumber: number
    addressComplement: string
    addressNeighborhood: string
    zipCode: number
    city: string
    state: string
    isActive: boolean
}
export interface CreateClientResponseData {
    fantasyName: string
    businessName: string
    cnpj: string
    cnae: string
    address: string
    addressNumber: number
    addressComplement: string
    addressNeighborhood: string
    zipCode: number
    city: string
    state: string
    isActive: boolean
}

export interface CreateClientResponse {
    success: boolean;
    data: CreateClientResponseData;
}

//TODO: Configurar corretamente o retorno da requisição create;
