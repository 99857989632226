import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {Button, Form, Grid, Header, Segment, Table} from "semantic-ui-react";
import {useLoaderData, useNavigate} from "react-router-dom";
import PostTurmaParticipante from "../../../../../services/sistema-aulas/turmas/create-turma-participante";
import {GetAllTurmas} from "../../../../../services/sistema-aulas/turmas/get-turmas";
import GetTurmaById from "../../../../../services/sistema-aulas/turmas/get-turma";
import {Turma} from "../../../../../models/turma";
import {Participante} from "../../../../../models/participante";
import {GetParticipantesSimplified} from "../../../../../services/sistema-aulas/participante/get-participantes";
import {SelectListDefault} from "../../../../../models/selectListDefault";
import {useBlockUI} from "../../../../../shared/services/block-ui-service";

export async function createTurmaParticipanteLoader({params}: any) {
    return await GetTurmaById(params.id);
}

const CreateTurmaParticipantePage = () => {
    const [turmaParticipantes, setTurmaParticipantes] = useState<[]>([])
    const [turmas, setTurmas] = useState<SelectListDefault[]>([])
    const [participantes, setParticipantes] = useState<Participante[]>([])
    const [turma, setTurma] = useState<Turma>()
    const loader: any = useLoaderData();
    const navigate = useNavigate();
    const blockUI = useBlockUI();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => setTurma(loader), [])

    useEffect(() => {
        blockUI?.setActive(loading)
    }, [loading]);

    useEffect(() => {
        function ListTurmas() {
            GetAllTurmas()
                .then((response) => {
                    const turmasData = response?.map((item: Turma) => ({
                        key: item.id,
                        text: item.nome,
                        value: item.id,
                    }));
                    setTurmas(turmasData);
                })
                .catch((error) => {
                    console.warn(error);
                });
        }

        ListTurmas();
    }, []);

    useEffect(() => {
        function ListParticipantes() {
            if (turma)
                GetParticipantesSimplified(turma?.id)
                    .then((response) => {

                        setParticipantes(response);
                    })
                    .catch(error => {
                        console.warn(error);
                    })
        }

        ListParticipantes()
    }, [turma])


    const handleSubmit = async () => {
        try {
            setLoading(true);

            await PostTurmaParticipante(turma?.id, turmaParticipantes)
                .then(() => {
                    toast.success('Participante adicionado a turma com sucesso', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });

                    navigate(`/treinamentos/turmas/${turma?.id}/lista-participantes/`);
                })
        } catch (error) {
            // handle error
            console.warn(error);
        } finally {
            setLoading(false);
        }
    };


    return (
        <div>
            <Grid.Column mobile={16} tablet={8} computer={6}>
                <Header as="h3" textAlign="center" color={'black'}>
                    Adicionar novos participantes
                </Header>
                <Header as="h4" textAlign="center" color={'black'}>
                    Turma {turma?.nome} /
                    Treinamento {turma?.treinamentoNome}
                </Header>
                <Segment>
                    <Form>
                        <Form.Select
                            label={'Turma'}
                            options={turmas}
                            placeholder='Selecione uma opção'
                            value={turma?.id}
                            disabled
                            required
                            onChange={(e: any, data: any) => {
                                const selectedValue = data.value;
                                setTurmaParticipantes(prevState => ({...prevState, turmaId: selectedValue}));
                            }}
                        />
                        <Form.Select
                            label={'Selecione o colaborador para adicioná-lo'}
                            options={participantes}
                            noResultsMessage={"Nenhum resultado encontrado"}
                            placeholder='Selecione uma opção'
                            value={turmaParticipantes}
                            required
                            multiple
                            search
                            onChange={(e: any, data: any) => {
                                const selectedValue = data.value;
                                setTurmaParticipantes(selectedValue);
                            }}
                        />
                        <Table.Row>
                            <Table.HeaderCell>
                                <Button content="Voltar"
                                        icon='arrow left'
                                        onClick={() => navigate(`/treinamentos/turmas/${turma?.id}/lista-participantes`)}
                                        grey/>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <Form.Button content="Adicionar"
                                             onClick={() => handleSubmit()}
                                             icon='save'
                                             primary/>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Form>
                </Segment>
            </Grid.Column>
        </div>
    )
}

export default CreateTurmaParticipantePage;
