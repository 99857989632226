import React, {useEffect, useState} from "react";
import {SessionUser} from "../../../../models/session-user";
import {useNavigate} from "react-router-dom";
import SessionStorage from "../../../../store/session";
import GetRelatoriosTurmas from "../../../../services/sistema-aulas/relatorio-turmas/get-relatorios-turmas";
import DeleteRelatorioTurma from "../../../../services/sistema-aulas/relatorio-turmas/delete-relatorio-turma";
import {toast} from "react-toastify";
import {Button, Header, Icon, Input, Message, Pagination, Table} from "semantic-ui-react";
import {TreinamentoTurmasFilters} from "../../../../filters/treinamento-turmas-filters";
import {useBlockUI} from "../../../../shared/services/block-ui-service";


const TurmasRelatoriosList = () => {
    const [relatoriosTurmas, setRelatoriosTurmas] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [filter, setFilter] = useState<TreinamentoTurmasFilters>()
    const [totalPages, setTotalPages] = useState()
    const [showFilters, setShowFilters] = useState(false)
    const [sessionUser, setSessionUser] = useState<SessionUser>();
    const navigate = useNavigate();
    const blockUI = useBlockUI();
    useEffect(() => {
        blockUI?.setActive(loading)
    }, [loading]);

    useEffect(() => {
        async function updateSessionUser() {
            const user = await SessionStorage.getSession();
            setSessionUser(user)
        }

        updateSessionUser()
    }, [])

    useEffect(() => {
        listTurmas();
    }, [filter]);

    async function listTurmas() {
        try {
            setLoading(true);
            const response = await GetRelatoriosTurmas(filter);
            const data = response?.data?.data;
            setTotalPages(data?.totalPages);
            setRelatoriosTurmas(data?.items);
        } catch (error) {
            console.error('Erro ao obter relatórios de turmas:', error);
        } finally {
            setLoading(false);
        }
    }

    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };




    async function deleteRelatorioTurma(turma: any) {
        try {
            const confirm = window.confirm(`Tem certeza que deseja apagar a este relatorio ?`);

            if (confirm) {
                if (turma) {
                    await DeleteRelatorioTurma(turma.id);
                    toast.success(`Relatório de turma deletado com sucesso`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    listTurmas()
                }
                setLoading(false);
            }
        } catch (error: any) {
            toast.warn(`Falha ao deletar relatório`, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setLoading(false);
            setError(error.message);
        }
    }

    return (
        <div>
            <Header as="h3" textAlign="center" color={'black'}>
                Treinamento + Turmas / Linkar Treinamento a Turma
            </Header>
            {error && <Message
                floating
                style={{
                    position: 'fixed',
                    top: 0,
                    left: '50%',
                    transform: 'translateX(10%)',
                    zIndex: 9999,
                }}
            >
                <p>{error}</p>
            </Message>}


            <div>
                <Table.Row>
                    {sessionUser?.userData?.profile === 'Admin' && 'Professor' &&
                        <Table.HeaderCell>
                            <Button color="blue"
                                    onClick={() => navigate('/treinamentos/turmas/create/')}>

                                <Icon name='plus'/> Novo
                            </Button>
                        </Table.HeaderCell> }

                    <Table.HeaderCell colSpan="4">
                        <Button onClick={toggleFilters} primary>
                            <Icon name={showFilters ? 'hide' : 'unhide'} />
                            {showFilters ? 'Ocultar filtros' : 'Exibir filtros'}
                        </Button>
                    </Table.HeaderCell>
                </Table.Row>
                <Table color={"orange"}>
                    {showFilters && (
                        <Table.Row>
                            <Table.HeaderCell>
                                <Input
                                    type="text"
                                    placeholder="Nome da turma"
                                    value={filter?.turma ?? ''}
                                    onChange={event =>
                                        setFilter({ ...filter, turma: event.target?.value })
                                    }
                                />
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <Input
                                    type="text"
                                    placeholder="Nome do treinamento"
                                    value={filter?.treinamento ?? ''}
                                    onChange={event =>
                                        setFilter({ ...filter, treinamento: event.target?.value })
                                    }
                                />
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <Button color="blue" onClick={() => listTurmas()} fluid>
                                    <Icon name="search" /> Pesquisar
                                </Button>
                            </Table.HeaderCell>
                        </Table.Row>
                    )}
                </Table>
                <Table color={"orange"}>
                    <Table.Header>
                        <Table.Row>
                            {sessionUser?.userData?.profile === 'Admin' &&
                                <Table.HeaderCell>#</Table.HeaderCell>}
                            <Table.HeaderCell>Nome da Turma</Table.HeaderCell>
                            <Table.HeaderCell>Nome do Treinamento</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {relatoriosTurmas?.map((relatorioTurma) => (
                            <Table.Row key={relatorioTurma.id}>
                                {sessionUser?.userData?.profile === 'Admin' &&
                                    <Table.Cell>
                                        <Button.Group>
                                            <Button color="blue"
                                                    onClick={() => navigate('/treinamentos/relatorios/turmas/edit/' + relatorioTurma.id)}
                                                    icon={'edit'}/>

                                            <Button color="yellow"
                                                    onClick={() => navigate('/treinamentos/relatorios/turmas/edit')}>
                                                <Icon name="user plus" />
                                                Adicionar Participantes
                                            </Button>
                                            <Button color="red" onClick={() => deleteRelatorioTurma(relatorioTurma)} icon={'trash'}></Button>
                                        </Button.Group>
                                    </Table.Cell> }

                                <Table.Cell>{relatorioTurma?.turma}</Table.Cell>
                                <Table.Cell>{relatorioTurma?.treinamento}</Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </div>
            <Pagination totalPages={totalPages ?? 0}
                        defaultActivePage={filter?.Page ?? 1}
                        onPageChange={(e, {activePage}) => {
                            setFilter({...filter, Page: activePage as number})
                        }
                        }></Pagination>
        </div>
    )
}

export default TurmasRelatoriosList

