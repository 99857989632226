import React, {useEffect, useState} from 'react';
import {Button, Form, Header, Icon, Menu, Progress, Segment} from 'semantic-ui-react';
import {useLoaderData, useNavigate} from "react-router-dom";
import {EPerguntaHorariotrabalho} from "../../../../enums/ePerguntaHorariotrabalho";
import {EPerguntaTempoTrabalhado} from "../../../../enums/ePerguntaTempoTrabalhado";
import {EPerguntaPraticaExercicioFisico} from "../../../../enums/ePerguntaPraticaExercicioFisico";
import {EPerguntaSobreAmbienteDeTrabalho} from "../../../../enums/ePerguntaSobreAmbienteDeTrabalho";
import {EPerguntaDesconfortoUltimosDias} from "../../../../enums/ePerguntaDesconfortoUltimosDias";
import {EPerguntaEsforcoMental} from "../../../../enums/ePerguntaEsforcoMental";
import {EPerguntaEsforcoFisico} from "../../../../enums/ePerguntaEsforcoFisico";
import {EPerguntaFatorDeRiscoGravidade} from "../../../../enums/ePerguntaFatorDeRiscoGravidade";
import {EPerguntaFatorDeProbabilidade} from "../../../../enums/ePerguntaFatorDeProbabilidade";
import GetColetaById from "../../../../services/backoffice/coleta-ergo/get-coleta-ergo";
import GetClients from "../../../../services/backoffice/clients/get-clients";
import {EPerguntaPredominanciaDaPostura} from "../../../../enums/EPerguntaPredominanciaDaPostura";

export async function viewColetaErgoLoader({params}: any) {
    return await GetColetaById(params.id);
}

const DetailsColetaErgo = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const navigate = useNavigate();
    const loader: any = useLoaderData();
    const [coleta, setColeta] = useState<any>();
    const [step, setStep] = useState(1);
    const [clients, setClients] = useState<any[]>([]);
    useEffect(() => setColeta(loader), [])
    useEffect(() => {
        function ListClients() {
            GetClients().then((response) => {
                setClients(response.items);
            })
                .catch(error => {
                    console.warn(error);
                })
        }

        ListClients()
    }, [])


    const nextStep = () => {
        setStep(step + 1);
    };

    const prevStep = () => {
        setStep(step - 1);
    };

    const ProgressBar = ({ totalSteps, currentStep }: any) => {
        const percent = (currentStep / totalSteps) * 100;
        return (
            <div>
                <Progress percent={percent} className="orange" />
                <div className="progress-titles">
                    {Array.from({ length: totalSteps }, (_, index) => (
                        <div
                            key={index}


                            className={`progress-title ${index === currentStep - 1 ? 'active' : ''}`}
                        >
                            {/*RenderStep {index + 1}*/}
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    function renderButtons(top= false) {
        return (
            <>
                { top && <Menu borderless secondary>
                    <Menu.Item>
                        <Button content="Voltar para lista de coletas"
                                icon='arrow left'
                                onClick={() => navigate('/backoffice/coleta-ergo/')} grey/>
                    </Menu.Item>
                </Menu>}
                { !top && <Menu borderless secondary>
                    {step > 1 &&
                        <Menu.Item>
                            <Button onClick={prevStep} primary icon={'arrow left'} floated={'left'}
                                    labelPosition={'left'} content={'Anterior'}></Button>
                        </Menu.Item>}
                    {step != 9 &&
                        <Menu.Item position={"right"}>
                            <Button onClick={nextStep} primary icon={'arrow right'} labelPosition={'right'}
                                    content={'Próximo'}></Button>
                        </Menu.Item>}
                </Menu>}
            </>
        );
    }

    const renderStep1 = () => {
        const currentStep: number = 1;

        return (
            <Form>
                <Header textAlign={"center"} as={"h2"}>Pesquisa Ergonômica - NR-17</Header>
                <Header as="h3" textAlign="center" color={'black'}>
                    Informações básicas da empresa e do colaborador
                </Header>
                <ProgressBar totalSteps={9} currentStep={currentStep} />
                <Header textAlign={"center"} as={"h4"} content={"justify-center"}>Essa pesquisa tem como
                    objetivo levantamento de dados de saúde, produtividade e é uma forma
                    de ouvir os colaboradores de forma individual sobre melhorias para os postos de
                    trabalho.
                </Header>

                    <Form.Field>
                        <Form.Input
                            label={'Qual a Empresa onde o entrevistado trabalha?'}
                            name={"clientName"}
                            placeholder='Selecione uma opção'
                            value={coleta?.clientName}
                            required
                            readOnly
                        />
                    </Form.Field>

                    <Form.Input
                        label={'Nome completo do trabalhador entrevistado (pode ser somente iniciais do nome)'}
                        name="nome"
                        type="text"
                        placeholder="Nome"
                        value={coleta?.nome}
                        readOnly
                        required
                    />

                    <Form.Input
                        label={'Qual o setor que trabalha?'}
                        name="setor"
                        type="text"
                        placeholder="Setor"
                        value={coleta?.setor}
                        readOnly
                        required
                    />

                    <Form.Input
                        label={'Qual sua função (como está registrado CLT)?'}
                        name="funcaoClt"
                        type="text"
                        placeholder="Função Clt"
                        value={coleta?.funcaoClt}
                        readOnly
                        required>
                    </Form.Input>

                    <Form.Input
                        label={'Nome completo do responsável pelo setor:'}
                        name="nomeSupervisor"
                        type="text"
                        placeholder="Nome do Supervisor"
                        value={coleta?.nomeSupervisor}
                        readOnly
                        required
                    />

                    <Form.Field>
                        <Form.Select
                            label={'Qual seu horário de trabalho?'}
                            options={EPerguntaHorariotrabalho}
                            placeholder='Selecione uma opção'
                            value={coleta?.eHorarioTrabalho}
                            readOnly
                            required
                        />
                    </Form.Field>

                    <Form.Input
                        label={'Qual a sua idade?'}
                        name="idade"
                        type="number"
                        placeholder="Idade do colaborador"
                        value={coleta?.idade}
                        readOnly
                        required
                    />

                    <Form.Input
                        label={'Atividades principais do colaborador'}
                        name="atividadesPrincipais"
                        type="text"
                        placeholder="Atividades principais do colaborador"
                        value={coleta?.atividadesPrincipais}
                        readOnly
                        required
                    />

                    <Form.Field>
                        <Form.Select
                            label={'Predominancia da postura no trabalho?'}
                            options={EPerguntaPredominanciaDaPostura}
                            placeholder='Selecione uma opção'
                            value={coleta?.tempoTrabalhado}
                            readOnly
                            required
                        />
                    </Form.Field>

                    <Form.Field>
                        <h4>Mobiliario utilizado pelo entrevistado no trabalho</h4>
                        <Form.Checkbox type="checkbox"
                                       label={"Mesa + cadeira"}
                                       id={"mesaeCadeiraPerguntaMobiliarioPerguntaMobiliario"}
                                       readOnly
                                       checked={coleta?.mesaeCadeiraPerguntaMobiliarioPerguntaMobiliario}/>

                        <Form.Checkbox type="checkbox"
                                       label={"Mesa + cadeira - Computador de mesa + mouse e teclado"}
                                       id={"mesaCadeiraComputadorDeMesaMouseTecladoApoioPunhoMousePerguntaMobiliario"}
                                       readOnly
                                       checked={coleta?.mesaCadeiraComputadorDeMesaMouseTecladoApoioPunhoMousePerguntaMobiliario}/>

                        <Form.Checkbox type="checkbox"
                                       label={"Mesa + cadeira - Computador de mesa + mouse e teclado +apoio de punho no mouse"}
                                       id={"mesaCadeiraComputadorDeMesaMouseTecladoPerguntaMobiliario"}
                                       readOnly
                                       checked={coleta?.mesaCadeiraComputadorDeMesaMouseTecladoPerguntaMobiliario}/>

                        <Form.Checkbox type="checkbox"
                                       label={"Mesa + cadeira - Computador de mesa + mouse e teclado +apoio de punho no teclado"}
                                       id={"mesaCadeiraComputadorDeMesaMouseTecladoApoioPunhoTecladoPerguntaMobiliario"}
                                       readOnly
                                       checked={coleta?.mesaCadeiraComputadorDeMesaMouseTecladoApoioPunhoTecladoPerguntaMobiliario}/>

                        <Form.Checkbox type="checkbox"
                                       label={"Mesa + cadeira - Computador de mesa + mouse e teclado +apoio de punho no mouse e teclado"}
                                       id={"mesaCadeiraComputadorDeMesaMouseTecladoApoioPunhoAmbosPerguntaMobiliario"}
                                       readOnly
                                       checked={coleta?.mesaCadeiraComputadorDeMesaMouseTecladoApoioPunhoAmbosPerguntaMobiliario}/>

                        <Form.Checkbox type="checkbox"
                                       label={"Mesa + cadeira - Notebook + mouse"}
                                       id={"mesaCadeiraNotebookMousePerguntaMobiliario"}
                                       readOnly
                                       checked={coleta?.mesaCadeiraNotebookMousePerguntaMobiliario}/>

                        <Form.Checkbox type="checkbox"
                                       label={"Mesa + cadeira - Notebook + mouse"}
                                       id={"mesaCadeiraNotebookMousePerguntaMobiliario"}
                                       readOnly
                                       checked={coleta?.mesaCadeiraNotebookMousePerguntaMobiliario}/>

                        <Form.Checkbox type="checkbox"
                                       label={"Mesa + cadeira - Computador de mesa + mouse e teclado +apoio de punho no mouse e teclado"}
                                       id={"mesaCadeiraComputadorDeMesaMouseTecladoApoioPunhoAmbosPerguntaMobiliario"}
                                       readOnly
                                       checked={coleta?.mesaCadeiraComputadorDeMesaMouseTecladoApoioPunhoAmbosPerguntaMobiliario}/>

                        <Form.Checkbox type="checkbox"
                                       label={"Mesa + cadeira - Notebook + mouse"}
                                       id={"mesaCadeiraNotebookMousePerguntaMobiliario"}
                                       readOnly
                                       checked={coleta?.mesaCadeiraNotebookMousePerguntaMobiliario}/>

                        <Form.Checkbox type="checkbox"
                                       label={"Mesa + cadeira - Notebook + teclado e mouse externo"}
                                       id={"mesaCadeiraNotebookTecladoEMouseExternoPerguntaMobiliario"}
                                       readOnly
                                       checked={coleta?.mesaCadeiraNotebookTecladoEMouseExternoPerguntaMobiliario}/>
                        <Form.Checkbox type="checkbox"
                                       label={"Mesa + cadeira - Notebook + teclado e mouse externo + suporte de altura de tela"}
                                       id={"mesaCadeiraNotebookTecladoMouseExternoSupAlturaTelaPerguntaMobiliario"}
                                       readOnly
                                       checked={coleta?.mesaCadeiraNotebookTecladoMouseExternoSupAlturaTelaPerguntaMobiliario}/>

                        <Form.Checkbox type="checkbox"
                                       label={"Mesa + cadeira - Notebook + teclado e mouse externo + suporte de altura de tela + apoios de punho"}
                                       id={"mesaCadeiraNotebookTecladoMouseExternoSupAlturaApoioPunhoPerguntaMobiliario"}
                                       readOnly
                                       checked={coleta?.mesaCadeiraNotebookTecladoMouseExternoSupAlturaApoioPunhoPerguntaMobiliario}/>

                        <Form.Checkbox type="checkbox"
                                       label={"Bancada"}
                                       id={"bancadaPerguntaMobiliario"}
                                       readOnly
                                       checked={coleta?.bancadaPerguntaMobiliario}/>

                        <Form.Checkbox type="checkbox"
                                       label={"Bancada com cadeira/banqueta"}
                                       id={"bancadaCadeiraBanquetaPerguntaMobiliario"}
                                       readOnly
                                       checked={coleta?.bancadaCadeiraBanquetaPerguntaMobiliario}/>

                        <Form.Checkbox type="checkbox"
                                       label={"Trabalho em campo (ao ar livre)"}
                                       id={"trabalhoEmCampoArLivrePerguntaMobiliario"}
                                       readOnly
                                       checked={coleta?.trabalhoEmCampoArLivrePerguntaMobiliario}/>

                        <Form.Checkbox type="checkbox"
                                       label={"Galpão/oficina e outros"}
                                       id={"galpaoOficinaOutrosPerguntaMobiliario"}
                                       readOnly
                                       checked={coleta?.galpaoOficinaOutrosPerguntaMobiliario}/>
                    </Form.Field>
                    <Form.Field>
                        <Form.Input
                            label="Outros"
                            name="outroPerguntaMobiliario"
                            type="text"
                            placeholder="Outros"
                            value={coleta?.outroPerguntaMobiliario}
                            readOnly
                        />
                    </Form.Field>

                    <Form.Field>
                        <Form.Select
                            label={'Quanto tempo você trabalha na empresa?'}
                            options={EPerguntaTempoTrabalhado}
                            placeholder='Selecione uma opção'
                            value={coleta?.tempoTrabalhado}
                            readOnly
                            required
                        />
                    </Form.Field>

                    <Form.Field>
                        <Form.Select
                            label={'Você pratica exercício físico?'}
                            options={EPerguntaPraticaExercicioFisico}
                            placeholder='Selecione uma opção'
                            value={coleta?.praticaExercicioFisico}
                            readOnly
                            required
                        />
                    </Form.Field>

                    <h4>Possui hábitos não saudáveis?</h4>
                    <Form.Field>

                        <Form.Checkbox type="checkbox"
                                       label={"Fumante"}
                                       id={"fumantePerguntaHabitoQueNaoGeraSaude"}
                                       readOnly
                                       checked={coleta?.fumantePerguntaHabitoQueNaoGeraSaude}/>

                        <Form.Checkbox type="checkbox"
                                       label={"Não ter momento de relaxamento"}
                                       id={"naoDescansaPerguntaHabitoQueNaoGeraSaude"}
                                       readOnly
                                       checked={coleta?.naoDescansaPerguntaHabitoQueNaoGeraSaude}/>

                        <Form.Checkbox type="checkbox"
                                       label={"Uso de bebidas alcólicas"}
                                       id={"bebePerguntaHabitoQueNaoGeraSaude"}
                                       readOnly
                                       checked={coleta?.bebePerguntaHabitoQueNaoGeraSaude}/>

                        <Form.Checkbox type="checkbox"
                                       label={"Redes sociais em excesso"}
                                       id={"redesSociaisPerguntaHabitoQueNaoGeraSaude"}
                                       readOnly
                                       checked={coleta?.redesSociaisPerguntaHabitoQueNaoGeraSaude}/>

                        <Form.Checkbox type="checkbox"
                                       label={"Negligenciar a alimentação"}
                                       id={"alimentacaoRuimPerguntaHabitoQueNaoGeraSaude"}
                                       readOnly
                                       checked={coleta?.alimentacaoRuimPerguntaHabitoQueNaoGeraSaude}/>

                        <Form.Checkbox type="checkbox"
                                       label={"Não"}
                                       id={"naoPerguntaHabitoQueNaoGeraSaude"}
                                       readOnly
                                       checked={coleta?.naoPerguntaHabitoQueNaoGeraSaude}/>
                    </Form.Field>


                    <Form.Field>
                        <Form.Input
                            label="Outros"
                            name="outrosPerguntaHabitoQueNaoGeraSaude"
                            type="text"
                            placeholder="Outros hábitos que não geram saúde"
                            value={coleta?.outrosPerguntaHabitoQueNaoGeraSaude}
                            readOnly/>
                    </Form.Field>

                    <Form.Input
                        label={'Qual sua altura? (Exemplo: 165)'}
                        name="altura"
                        type="number"
                        placeholder="Altura do colaborador"
                        value={coleta?.altura}
                        readOnly
                        required
                    />

                    <Form.Input
                        label={'Qual seu peso (kg)? (Exemplo: 72)'}
                        name="peso"
                        type="number"
                        placeholder="Peso do colaborador"
                        value={coleta?.peso}
                        readOnly
                        required
                    />
            </Form>
        )
    };
    {/*#region identificação de riscos para a saúde e opinião do trabalhador*/}

    const renderStep2 = () => {
        const currentStep: number = 2;
        return (
                <Form>
                    <Header textAlign={"center"} as={"h2"}>Pesquisa Ergonômica - NR-17</Header>
                    <Header as="h3" textAlign="center" color={'black'}>
                        Identificação de riscos para a saúde e opinião do trabalhador
                    </Header>
                    <ProgressBar totalSteps={9} currentStep={currentStep} />
                    <h4>Com relação ao ambiente de trabalho:</h4>
                    <Form.Field>
                        <h3>Com relação ao ambiente de trabalho:</h3>
                        <Form.Select
                            label={'Ruído me afeta?'}
                            options={EPerguntaSobreAmbienteDeTrabalho}
                            placeholder='Selecione uma opção'
                            value={coleta?.ruidoPerguntaSobreAmbienteDeTrabalho}
                            readOnly
                            required
                        />
                        <Form.Select
                            label={'Temperatura me afeta?'}
                            options={EPerguntaSobreAmbienteDeTrabalho}
                            placeholder='Selecione uma opção'
                            value={coleta?.temperaturaPerguntaSobreAmbienteDeTrabalho}
                            readOnly
                            required
                        />
                        <Form.Select
                            label={'Iluminação me afeta?'}
                            options={EPerguntaSobreAmbienteDeTrabalho}
                            placeholder='Selecione uma opção'
                            value={coleta?.iluminacaoPerguntaSobreAmbienteDeTrabalho}
                            readOnly
                            required
                        />
                        <Form.Select
                            label={'Baixa umidade me afeta?'}
                            options={EPerguntaSobreAmbienteDeTrabalho}
                            placeholder='Selecione uma opção'
                            value={coleta?.baixaUmidadePerguntaSobreAmbienteDeTrabalho}
                            readOnly
                            required
                        />
                    </Form.Field>
                    <h4>Nos últimos 7 dias você sentiu alguma dor/desconforto? Se sim,
                        avalie a intensidade dessa dor em cada parte do corpo a partir da
                        escala a seguir: sendo 0 nenhuma dor e 10 a pior dor que você já sentiu na sua vida:
                    </h4>
                    <img src={'/charts/escala_dor.png'} style={{
                        objectFit: 'contain',
                        marginTop: 10,
                        marginBottom: 10,
                        padding: 20,
                        alignSelf: 'center',
                        width: '100%',
                    }} alt={'matriz de risco'}/>
                    <Form.Field>
                        <br/>
                        <Form.Select
                            label={'Cabeça'}
                            options={EPerguntaDesconfortoUltimosDias}
                            placeholder='Selecione uma opção'
                            value={coleta?.cabecaPerguntaDesconfortoUltimosDias}
                            readOnly
                            required
                        />
                        <Form.Select
                            label={'Pescoço'}
                            options={EPerguntaDesconfortoUltimosDias}
                            placeholder='Selecione uma opção'
                            value={coleta?.pescocoPerguntaDesconfortoUltimosDias}
                            readOnly
                            required
                        />
                        <Form.Select
                            label={'Ombros'}
                            options={EPerguntaDesconfortoUltimosDias}
                            placeholder='Selecione uma opção'
                            value={coleta?.ombroPerguntaDesconfortoUltimosDias}
                            readOnly
                            required
                        />
                        <Form.Select
                            label={'Braços'}
                            options={EPerguntaDesconfortoUltimosDias}
                            placeholder='Selecione uma opção'
                            value={coleta?.bracosPerguntaDesconfortoUltimosDias}
                            readOnly
                            required
                        />
                        <Form.Select
                            label={'Punhos'}
                            options={EPerguntaDesconfortoUltimosDias}
                            placeholder='Selecione uma opção'
                            value={coleta?.punhosPerguntaDesconfortoUltimosDias}
                            readOnly
                            required
                        />
                        <Form.Select
                            label={'Coluna'}
                            options={EPerguntaDesconfortoUltimosDias}
                            placeholder='Selecione uma opção'
                            value={coleta?.colunaPerguntaDesconfortoUltimosDias}
                            readOnly
                            required
                        />
                        <Form.Select
                            label={'Coxas'}
                            options={EPerguntaDesconfortoUltimosDias}
                            placeholder='Selecione uma opção'
                            value={coleta?.coxasPerguntaDesconfortoUltimosDias}
                            readOnly
                            required
                        />
                        <Form.Select
                            label={'Pernas'}
                            options={EPerguntaDesconfortoUltimosDias}
                            placeholder='Selecione uma opção'
                            value={coleta?.pernasPerguntaDesconfortoUltimosDias}
                            readOnly
                            required
                        />
                        <Form.Select
                            label={'Quadril'}
                            options={EPerguntaDesconfortoUltimosDias}
                            placeholder='Selecione uma opção'
                            value={coleta?.quadrilPerguntaDesconfortoUltimosDias}
                            readOnly
                            required
                        />
                        <Form.Select
                            label={'Joelhos'}
                            options={EPerguntaDesconfortoUltimosDias}
                            placeholder='Selecione uma opção'
                            value={coleta?.joelhosPerguntaDesconfortoUltimosDias}
                            readOnly
                            required
                        />
                        <Form.Select
                            label={'Tornozelos'}
                            options={EPerguntaDesconfortoUltimosDias}
                            placeholder='Selecione uma opção'
                            value={coleta?.tornozelosPerguntaDesconfortoUltimosDias}
                            readOnly
                            required
                        />
                        <Form.Select
                            label={'Pés'}
                            options={EPerguntaDesconfortoUltimosDias}
                            placeholder='Selecione uma opção'
                            value={coleta?.pesPerguntaDesconfortoUltimosDias}
                            readOnly
                            required
                        />
                    </Form.Field>


                    <h4>Marque de acordo com a escala: Pensando naquela atividade de trabalho
                        mais desafiadora, em relação ao esforço MENTAL quando eu termino essa tarefa,
                        eu investi um esforço MENTAL de:</h4>
                    <img src={'/charts/escala_esforco_mental.png'} style={{
                        objectFit: 'contain',
                        marginTop: 10,
                        marginBottom: 10,
                        padding: 20,
                        alignSelf: 'center',
                        width: '100%',
                    }} alt={'matriz de risco'}/>

                    <Form.Field>
                        <Form.Select
                            label={'Esforço Mental'}
                            options={EPerguntaEsforcoMental}
                            placeholder='Selecione uma opção'
                            value={coleta?.perguntaEsforcoMental}
                            readOnly
                            required
                        />
                    </Form.Field>

                    <h4>Marque de acordo com a escala: Pensando naquela atividade de trabalho
                        mais desafiadora, em relação ao esforço FÍSICO quando eu termino essa tarefa,
                        eu investi um esforço FÍSICO de:
                    </h4>

                    <img src={'/charts/escala_esforco_fisico.png'} style={{
                        objectFit: 'contain',
                        marginTop: 10,
                        marginBottom: 10,
                        padding: 20,
                        alignSelf: 'center',
                        width: '100%',
                    }} alt={'matriz de risco'}/>
                    <Form.Field>

                        <Form.Select
                            label={'Esforço Físico'}
                            options={EPerguntaEsforcoFisico}
                            placeholder='Selecione uma opção'
                            value={coleta?.perguntaEsforcoFisico}
                            readOnly
                            required
                        />
                    </Form.Field>


                    <Form.Field>
                        <Form.Input
                            label={'Se você pudesse mudar algo no seu trabalho agora, o que seria?'}
                            name="respostaPerguntaMudarAlgoNoTrabalho"
                            type="text"
                            placeholder="O que você mudaria?"
                            value={coleta?.respostaPerguntaMudarAlgoNoTrabalho}
                            readOnly                        required
                        />
                    </Form.Field>
                </Form>
        )
    };

    const renderStep3 = () => {
        const currentStep: number = 3;
        return (
            <Form>
                <Header textAlign={"center"} as={"h2"}>Pesquisa Ergonômica - NR-17</Header>
                <Header as="h3" textAlign="center" color={'black'}>
                    Identificação de riscos de postura inadequada do colaborador
                </Header>
                <ProgressBar totalSteps={9} currentStep={currentStep} />
                <span>Identificação de riscos e perigos ergonômicos relacionados a postura.</span>
                <h4>Marque a postura inadequada do PESCOÇO:</h4>
                <Form.Field>

                    <Form.Checkbox type="checkbox"
                                   label={"Flexão acima de 20 graus"}
                                   id={"flexaoAcima20GrausPerguntaPosturaInadequadaPescoco"}
                                   readOnly
                                   checked={coleta?.flexaoAcima20GrausPerguntaPosturaInadequadaPescoco}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Extensão sem suporte acima de 10 graus"}
                                   id={"extensaoSemSupAcima10GrausPerguntaPosturaInadequadaPescoco"}
                                   readOnly
                                   checked={coleta?.extensaoSemSupAcima10GrausPerguntaPosturaInadequadaPescoco}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Inclinação acima de 20 Graus"}
                                   id={"inclinacaoAcima20GrausPerguntaPosturaInadequadaPescoco"}
                                   readOnly
                                   checked={coleta?.inclinacaoAcima20GrausPerguntaPosturaInadequadaPescoco}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Rotação acima de 30 graus"}
                                   id={"rotacaoAcima30GrausPerguntaPosturaInadequadaPescoco"}
                                   readOnly
                                   checked={coleta?.rotacaoAcima30GrausPerguntaPosturaInadequadaPescoco}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Não existe risco para o pescoço nessa atividade"}
                                   id={"naoExisteRiscoPescocoPerguntaPosturaInadequadaPescoco"}
                                   readOnly
                                   checked={coleta?.naoExisteRiscoPescocoPerguntaPosturaInadequadaPescoco}/>

                    <Form.Input
                        label="Outros"
                        name="outrosPerguntaPosturaInadequadaPescoco"
                        type="text"
                        placeholder="Outros fatores de postura inadequada do pescoço"
                        value={coleta?.outrosPerguntaPosturaInadequadaPescoco}
                        readOnly
                    />
                </Form.Field>

                <h4>Marque a postura inadequada dos Ombros:</h4>
                <Form.Field>

                    <Form.Checkbox type="checkbox"
                                   label={"Flexão acima de 45 a 90 graus"}
                                   id={"flexaoAcima45A90GrausPerguntaPosturaInadequadaOmbros"}
                                   readOnly
                                   checked={coleta?.flexaoAcima45A90GrausPerguntaPosturaInadequadaOmbros}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Extensão acima de 20 graus"}
                                   id={"extensaoAcima20GrausPerguntaPosturaInadequadaOmbros"}
                                   readOnly
                                   checked={coleta?.extensaoAcima20GrausPerguntaPosturaInadequadaOmbros}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Adução acima de 45 graus"}
                                   id={"aducaoAcima45GrausPerguntaPosturaInadequadaOmbros"}
                                   readOnly
                                   checked={coleta?.aducaoAcima45GrausPerguntaPosturaInadequadaOmbros}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Rotação acima de 45 graus"}
                                   id={"rotacaoAcima45GrausPerguntaPosturaInadequadaOmbros"}
                                   readOnly
                                   checked={coleta?.rotacaoAcima45GrausPerguntaPosturaInadequadaOmbros}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Abdução acima de 45 graus"}
                                   id={"abducaoAcima45GrausPerguntaPosturaInadequadaOmbros"}
                                   readOnly
                                   checked={coleta?.abducaoAcima45GrausPerguntaPosturaInadequadaOmbros}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Ombros elevados"}
                                   id={"ombrosElevadosPerguntaPosturaInadequadaOmbros"}
                                   readOnly
                                   checked={coleta?.ombrosElevadosPerguntaPosturaInadequadaOmbros}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Não existem riscos para os ombros nessa atividade"}
                                   id={"naoExisteRiscoOmbroPerguntaPosturaInadequadaOmbros"}
                                   readOnly
                                   checked={coleta?.naoExisteRiscoOmbroPerguntaPosturaInadequadaOmbros}/>

                    <Form.Input
                        label="Outros"
                        name="outrosPerguntaPosturaInadequadaOmbros"
                        type="text"
                        placeholder="Outros fatores de postura inadequada dos ombros"
                        value={coleta?.outrosPerguntaPosturaInadequadaOmbros}
                        readOnly             />
                </Form.Field>


                <h4>Marque a postura inadequada dos Cotovelos:</h4>
                <Form.Field>

                    <Form.Checkbox type="checkbox"
                                   label={"Flexão acima de 100 graus"}
                                   id={"flexaoAcima100GrausPerguntaPosturaInadequadaCotovelos"}
                                   readOnly
                                   checked={coleta?.flexaoAcima100GrausPerguntaPosturaInadequadaCotovelos}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Extensão acima de 60 graus"}
                                   id={"extensaoAcima60GrausPerguntaPosturaInadequadaCotovelos"}
                                   readOnly
                                   checked={coleta?.extensaoAcima60GrausPerguntaPosturaInadequadaCotovelos}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Totalmente extendido"}
                                   id={"totalmenteExtendPerguntaPosturaInadequadaCotovelos"}
                                   readOnly
                                   checked={coleta?.totalmenteExtendPerguntaPosturaInadequadaCotovelos}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Totalmente flexionado"}
                                   id={"totalmenteFlexPerguntaPosturaInadequadaCotovelos"}
                                   readOnly
                                   checked={coleta?.totalmenteFlexPerguntaPosturaInadequadaCotovelos}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Não existe riscos para os cotovelos nessa atividade"}
                                   id={"naoExisteRiscoCotovelosPerguntaPosturaInadequadaCotovelos"}
                                   readOnly
                                   checked={coleta?.naoExisteRiscoCotovelosPerguntaPosturaInadequadaCotovelos}/>

                    <Form.Input
                        label="Outros"
                        name="outrosPerguntaPosturaInadequadaCotovelos"
                        type="text"
                        placeholder="Outros fatores de postura inadequada dos cotovelos"
                        value={coleta?.outrosPerguntaPosturaInadequadaCotovelos}
                        readOnly/>
                </Form.Field>


                <h4>Marque a postura inadequada dos Punhos:</h4>
                <Form.Field>

                    <Form.Checkbox type="checkbox"
                                   label={"Flexão acima de 45 graus"}
                                   id={"flexaoAcima45GrausPerguntaPosturaInadequadaPunhos"}
                                   readOnly
                                   checked={coleta?.flexaoAcima45GrausPerguntaPosturaInadequadaPunhos}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Desvios laterais acima de 10 graus"}
                                   id={"desviosLateraisAcima10GrausPerguntaPosturaInadequadaPunhos"}
                                   readOnly
                                   checked={coleta?.desviosLateraisAcima10GrausPerguntaPosturaInadequadaPunhos}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Extensão acima de 15 graus"}
                                   id={"extensaoAcima15GrausPerguntaPosturaInadequadaPunhos"}
                                   readOnly
                                   checked={coleta?.extensaoAcima15GrausPerguntaPosturaInadequadaPunhos}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Totalmente flexionado/estendido"}
                                   id={"totalmenteFlexionadoEstendidoPerguntaPosturaInadequadaPunhos"}
                                   readOnly
                                   checked={coleta?.totalmenteFlexionadoEstendidoPerguntaPosturaInadequadaPunhos}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Não existe riscos para os punhos nessa atividade"}
                                   id={"naoExisteRiscoPunhosPerguntaPosturaInadequadaPunhos"}
                                   readOnly
                                   checked={coleta?.naoExisteRiscoPunhosPerguntaPosturaInadequadaPunhos}/>

                    <Form.Input
                        label="Outros"
                        name="outrosPerguntaPosturaInadequadaPunhos"
                        type="text"
                        placeholder="Outros fatores de postura inadequada dos punhos"
                        value={coleta?.outrosPerguntaPosturaInadequadaPunhos}
                        readOnly/>
                </Form.Field>


                <h4>Marque a postura inadequada do Tronco:</h4>
                <Form.Field>

                    <Form.Checkbox type="checkbox"
                                   label={"Flexão maior ou igual a 20 graus"}
                                   id={"flexaoMaiorOu20GrausPerguntaPosturaInadequadaTronco"}
                                   readOnly
                                   checked={coleta?.flexaoMaiorOu20GrausPerguntaPosturaInadequadaTronco}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Extensão sem suporte acima de 10 graus."}
                                   id={"extensaoSemSuporteAcima10GrausPerguntaPosturaInadequadaTronco"}
                                   readOnly
                                   checked={coleta?.extensaoSemSuporteAcima10GrausPerguntaPosturaInadequadaTronco}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Inclinação acima de 30 graus\n"}
                                   id={"inclinacaoAcimaDe30GrausPerguntaPosturaInadequadaTronco"}
                                   readOnly
                                   checked={coleta?.inclinacaoAcimaDe30GrausPerguntaPosturaInadequadaTronco}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Rotação Acima de 30 graus\n"}
                                   id={"rotacaoAcimaDe30GrausPerguntaPosturaInadequadaTronco"}
                                   readOnly
                                   checked={coleta?.rotacaoAcimaDe30GrausPerguntaPosturaInadequadaTronco}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Não existe riscos para o tronco nessa atividade"}
                                   id={"naoExisteRiscoTroncoPerguntaPosturaInadequadaTronco"}
                                   readOnly
                                   checked={coleta?.naoExisteRiscoTroncoPerguntaPosturaInadequadaTronco}/>

                    <Form.Input
                        label="Outros"
                        name="outrosPerguntaPosturaInadequadaTronco"
                        type="text"
                        placeholder="Outros fatores de postura inadequada do tronco"
                        value={coleta?.outrosPerguntaPosturaInadequadaTronco}
                        readOnly/>
                </Form.Field>


                <h4>Marque a postura inadequada das Pernas:</h4>
                <Form.Field>

                    <Form.Checkbox type="checkbox"
                                   label={"Flexão acima de 90 graus"}
                                   id={"flexaoAcima90GrausPerguntaPosturaInadequadaPernas"}
                                   readOnly
                                   checked={coleta?.flexaoAcima90GrausPerguntaPosturaInadequadaPernas}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Não está totalmente apoiada e equilibrada"}
                                   id={"naoTotalmenteApoiadaOuEquilibradaPerguntaPosturaInadequadaPernas"}
                                   readOnly
                                   checked={coleta?.naoTotalmenteApoiadaOuEquilibradaPerguntaPosturaInadequadaPernas}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Não há espaço suficiente para as pernas por mais de 2 horas"}
                                   id={"naoHaEspacoParaAsPernasMaisDe2HorasPerguntaPosturaInadequadaPernas"}
                                   readOnly
                                   checked={coleta?.naoHaEspacoParaAsPernasMaisDe2HorasPerguntaPosturaInadequadaPernas}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Agachado ou ajoelhado"}
                                   id={"agachadoOuAjoelhadoPerguntaPosturaInadequadaPernas"}
                                   readOnly
                                   checked={coleta?.agachadoOuAjoelhadoPerguntaPosturaInadequadaPernas}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Não existe riscos para pernas nessa atividade"}
                                   id={"naoExisteRiscoPernasPerguntaPosturaInadequadaPernas"}
                                   readOnly
                                   checked={coleta?.naoExisteRiscoPernasPerguntaPosturaInadequadaPernas}/>

                    <Form.Input
                        label="Outros"
                        name="outrosPerguntaPosturaInadequadaPernas"
                        type="text"
                        placeholder="Outros fatores de postura inadequada das pernas"
                        value={coleta?.outrosPerguntaPosturaInadequadaPernas}
                        readOnly/>
                </Form.Field>


                <h4>Outros perigos posturais Gerais:</h4>
                <Form.Field>

                    <Form.Checkbox type="checkbox"
                                   label={"Postura sentado por longo período\n"}
                                   id={"posturaSentadoLongoPeriodoPerguntaPerigosPosturaisGerais"}
                                   readOnly
                                   checked={coleta?.posturaSentadoLongoPeriodoPerguntaPerigosPosturaisGerais}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Postura em pé por longo período\n"}
                                   id={"posturaEmPeLongoPeriodoPerguntaPerigosPosturaisGerais"}
                                   readOnly
                                   checked={coleta?.posturaEmPeLongoPeriodoPerguntaPerigosPosturaisGerais}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Frequente deslocamento a pé durante a jornada de trabalho\n"}
                                   id={"frequentementeDeslocAPeDuranteTrabalhoPerguntaPerigosPosturaisGerais"}
                                   readOnly
                                   checked={coleta?.frequentementeDeslocAPeDuranteTrabalhoPerguntaPerigosPosturaisGerais}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Levantamento e transporte manual de cargas ou volumes\n"}
                                   id={"levantamentoTransporteManualDeCargasPerguntaPerigosPosturaisGerais"}
                                   readOnly
                                   checked={coleta?.levantamentoTransporteManualDeCargasPerguntaPerigosPosturaisGerais}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Frequente execução de movimentos repetitivos\n"}
                                   id={"movimentosRepetitivosFrequentesPerguntaPerigosPosturaisGerais"}
                                   readOnly
                                   checked={coleta?.movimentosRepetitivosFrequentesPerguntaPerigosPosturaisGerais}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Manuseio de ferramentas e/ou objetos pesados por períodos prolongados\n"}
                                   id={"manuseioFerramentasObjetosPesadosLongosPeriodosPerguntaPerigosPosturaisGerais"}
                                   readOnly
                                   checked={coleta?.manuseioFerramentasObjetosPesadosLongosPeriodosPerguntaPerigosPosturaisGerais}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Compressão de partes do corpo por superfícies rígidas ou com quinas\n"}
                                   id={"compressaoPartesCorpoPorSuperficiesPerguntaPerigosPosturaisGerais"}
                                   readOnly
                                   checked={coleta?.compressaoPartesCorpoPorSuperficiesPerguntaPerigosPosturaisGerais}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Uso frequente de pedais\n"}
                                   id={"usoFrequentePedaisPerguntaPerigosPosturaisGerais"}
                                   readOnly
                                   checked={coleta?.usoFrequentePedaisPerguntaPerigosPosturaisGerais}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Uso frequente de alavancas\n"}
                                   id={"usoFrequenteAlavancasPerguntaPerigosPosturaisGerais"}
                                   readOnly
                                   checked={coleta?.usoFrequenteAlavancasPerguntaPerigosPosturaisGerais}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Exposição à vibração de corpo inteiro\n"}
                                   id={"exposicaoAVibracaoDeCorpoInteiroPerguntaPerigosPosturaisGerais"}
                                   readOnly
                                   checked={coleta?.exposicaoAVibracaoDeCorpoInteiroPerguntaPerigosPosturaisGerais}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Exposição à vibração localizada\n"}
                                   id={"exposicaoAVibracaoLocalizadaPerguntaPerigosPosturaisGerais"}
                                   readOnly
                                   checked={coleta?.exposicaoAVibracaoLocalizadaPerguntaPerigosPosturaisGerais}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Uso frequente de escadas\n"}
                                   id={"usoFrequenteEscadasPerguntaPerigosPosturaisGerais"}
                                   readOnly
                                   checked={coleta?.usoFrequenteEscadasPerguntaPerigosPosturaisGerais}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Trabalho intensivo com teclado ou outros dispositivos de entrada de dados\n"}
                                   id={"trabalhoIntensivoTecladoEntradaDeDadosPerguntaPerigosPosturaisGerais"}
                                   readOnly
                                   checked={coleta?.trabalhoIntensivoTecladoEntradaDeDadosPerguntaPerigosPosturaisGerais}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Não existe outros riscos físicos nessa atividade\n"}
                                   id={"naoExisteRiscosNessaAtivPerguntaPerigosPosturaisGerais"}
                                   readOnly
                                   checked={coleta?.naoExisteRiscosNessaAtivPerguntaPerigosPosturaisGerais}/>

                    <Form.Input
                        label="Outros"
                        name="outrosPerguntaPerigosPosturaisGerais"
                        type="text"
                        placeholder="Outros fatores de postura inadequada geral"
                        value={coleta?.outrosPerguntaPerigosPosturaisGerais}
                        readOnly/>
                </Form.Field>
            </Form>
                )
    };

    const renderStep4 = () => {
        const currentStep: number = 4;
        return (
            <Form>
                <Header textAlign={"center"} as={"h2"}>Pesquisa Ergonômica - NR-17</Header>
                <Header as="h3" textAlign="center" color={'black'}>
                    Forças existentes na atividade
                </Header>
                <ProgressBar totalSteps={9} currentStep={currentStep} />
                <h4>Marque as FORÇAS existentes na atividade avaliada:</h4>
                <Form.Field>

                    <Form.Checkbox type="checkbox"
                                   label={"Entre 2 a 10kg (intermitente)"}
                                   id={"entre2A10KGPerguntaForcasExistentesNaAtividadeAvaliada"}
                                   readOnly
                                   checked={coleta.entre2A10KGPerguntaForcasExistentesNaAtividadeAvaliada}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Entre 2 a 10kg (estático ou mais de 4x por min.)"}
                                   id={"entre2A10KGEstaticoPerguntaForcasExistentesNaAtividadeAvaliada"}
                                   readOnly
                                   checked={coleta.entre2A10KGEstaticoPerguntaForcasExistentesNaAtividadeAvaliada}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Maior que 10kg (repetitivo ou impacto)"}
                                   id={"maior10KgRepetitivoOuImpactoPerguntaForcasExistentesNaAtividadeAvaliada"}
                                   readOnly
                                   checked={coleta.maior10KgRepetitivoOuImpactoPerguntaForcasExistentesNaAtividadeAvaliada}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Postura estática (segurar por mais de 1 min. ou ação repetitiva 4x ou mais por minuto)"}
                                   id={"posturaEstaticaSegurarPor1MOuMaisPerguntaForcasExistentesNaAtividadeAvaliada"}
                                   readOnly
                                   checked={coleta.posturaEstaticaSegurarPor1MOuMaisPerguntaForcasExistentesNaAtividadeAvaliada}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Mãos em pinça/pressão maior que 0,9 kg"}
                                   id={"maosEmPincaPressaoMaiorQue09KGPerguntaForcasExistentesNaAtividadeAvaliada"}
                                   readOnly
                                   checked={coleta.maosEmPincaPressaoMaiorQue09KGPerguntaForcasExistentesNaAtividadeAvaliada}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Mãos em agarre maior de 4,5kg"}
                                   id={"maosEmAgarreMaiorQue45KgPerguntaForcasExistentesNaAtividadeAvaliada"}
                                   readOnly
                                   checked={coleta.maosEmAgarreMaiorQue45KgPerguntaForcasExistentesNaAtividadeAvaliada}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Cotovelos maior que 4,5kg"}
                                   id={"cotovelosMaiorQue45KgPerguntaForcasExistentesNaAtividadeAvaliada"}
                                   readOnly
                                   checked={coleta.cotovelosMaiorQue45KgPerguntaForcasExistentesNaAtividadeAvaliada}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Ombros maior que 4,5kg"}
                                   id={"ombrosMaiorQue45KgPerguntaForcasExistentesNaAtividadeAvaliada"}
                                   readOnly
                                   checked={coleta.ombrosMaiorQue45KgPerguntaForcasExistentesNaAtividadeAvaliada}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Cervical peso adicional"}
                                   id={"cervicalPesoAdicionalPerguntaForcasExistentesNaAtividadeAvaliada"}
                                   readOnly
                                   checked={coleta.cervicalPesoAdicionalPerguntaForcasExistentesNaAtividadeAvaliada}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Coluna maior que 11,3 kg"}
                                   id={"colunaMaiorQue113KgPerguntaForcasExistentesNaAtividadeAvaliada"}
                                   readOnly
                                   checked={coleta.colunaMaiorQue113KgPerguntaForcasExistentesNaAtividadeAvaliada}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Pedal maior que 4,5 kg"}
                                   id={"pedalMaiorQue45KgPerguntaForcasExistentesNaAtividadeAvaliada"}
                                   readOnly
                                   checked={coleta.pedalMaiorQue45KgPerguntaForcasExistentesNaAtividadeAvaliada}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Transporte manual maior que 15kg/min"}
                                   id={"transporteManual15kgMinutoPerguntaForcasExistentesNaAtividadeAvaliada"}
                                   readOnly
                                   checked={coleta.transporteManual15kgMinutoPerguntaForcasExistentesNaAtividadeAvaliada}/>

                    <Form.Checkbox type="checkbox"
                                   label={"5.1 a 10kg mais que 1x/min"}
                                   id={"peso5A10Kg1XPorMinutoPerguntaForcasExistentesNaAtividadeAvaliada"}
                                   readOnly
                                   checked={coleta.peso5A10Kg1XPorMinutoPerguntaForcasExistentesNaAtividadeAvaliada}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Pegar ou manusear peso acima de 175cm"}
                                   id={"pegarPesoAcimaDe175CmPerguntaForcasExistentesNaAtividadeAvaliada"}
                                   readOnly
                                   checked={coleta.pegarPesoAcimaDe175CmPerguntaForcasExistentesNaAtividadeAvaliada}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Puxar/empurrar em desnível"}
                                   id={"puxarEmpurrarEmDesnivelPerguntaForcasExistentesNaAtividadeAvaliada"}
                                   readOnly
                                   checked={coleta.puxarEmpurrarEmDesnivelPerguntaForcasExistentesNaAtividadeAvaliada}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Distância horizontal maior que 63 cm (pegar a carga)"}
                                   id={"distanciaHorizontalMaior63CmPegarCargaPerguntaForcasExistentesNaAtividadeAvaliada"}
                                   readOnly
                                   checked={coleta.distanciaHorizontalMaior63CmPegarCargaPerguntaForcasExistentesNaAtividadeAvaliada}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Não há riscos/perigos de força nessa atividade"}
                                   id={"naoHaRiscosDeForcaNaAtividadePerguntaForcasExistentesNaAtividadeAvaliada"}
                                   readOnly
                                   checked={coleta.naoHaRiscosDeForcaNaAtividadePerguntaForcasExistentesNaAtividadeAvaliada}/>

                    <Form.Input
                        label="Outros"
                        name="outrosPerguntaForcasExistentesNaAtividadeAvaliada"
                        type="text"
                        placeholder="Outros fatores de força existentes na atividade avaliada"
                        value={coleta.outrosPerguntaForcasExistentesNaAtividadeAvaliada}
                        readOnly
                    />
                </Form.Field>
            </Form>
        );
    };


    const renderStep5 = () => {
        const currentStep: number = 5;
        return (
            <Form>
                <Header textAlign={"center"} as={"h2"}>Pesquisa Ergonômica - NR-17</Header>
                <Header  textAlign={"center"} as={"h2"}>Fatores de repetitividade existentes na atividade</Header>
                <ProgressBar totalSteps={9} currentStep={currentStep} />
                <h4>Marque os fatores REPETITIVIDADE existentes na atividade avaliada:
                </h4>
                <Form.Field>
                    <Form.Checkbox
                        type="checkbox"
                        label="Movimento constantes sem pausas"
                        readOnly
                        id={"movimentoConstanteSemPausasPerguntaFatoresRepetitividadeNaAtividade"}
                        checked={coleta?.movimentoConstanteSemPausasPerguntaFatoresRepetitividadeNaAtividade}></Form.Checkbox>

                    <Form.Checkbox type="checkbox"
                                   label={"Movimento frequente com pausas"}
                                   readOnly
                                   id={"movimentoFrequenteComPausaPerguntaFatoresRepetitividadeNaAtividade"}
                                   checked={coleta?.movimentoFrequenteComPausaPerguntaFatoresRepetitividadeNaAtividade}
                    />

                    <Form.Checkbox type="checkbox"
                                   label={"Repetição das mesmas ações técnicas por mais de 50% do ciclo da atividade"}
                                   readOnly
                                   id={"repeticaoDeAcoesTecnicasMaisDaMetadeDoCicloPerguntaFatoresRepetitividadeNaAtividade"}
                                   checked={coleta?.repeticaoDeAcoesTecnicasMaisDaMetadeDoCicloPerguntaFatoresRepetitividadeNaAtividade}
                    />

                    <Form.Checkbox type="checkbox"
                                   label={"Não há repetitividade nessa atividade"}
                                   readOnly
                                   id={"naoHaRepetitividadeNaAtividadePerguntaFatoresRepetitividadeNaAtividade"}
                                   checked={coleta?.naoHaRepetitividadeNaAtividadePerguntaFatoresRepetitividadeNaAtividade}/>

                    <Form.Input
                        label="Outros"
                        name="outrosPerguntaFatoresRepetitividadeNaAtividade"
                        type="text"
                        placeholder="Outros fatores de repetitividade na atividade"
                        value={coleta?.outrosPerguntaFatoresRepetitividadeNaAtividade}
                        readOnly
                    />
                </Form.Field>
            </Form>
        )
    };

    const renderStep6 = () => {
        const currentStep: number = 6;
        return (
            <Form>
                <Header textAlign={"center"} as={"h2"}>Pesquisa Ergonômica - NR-17</Header>
                <Header  textAlign={"center"} as={"h2"}>Organização do trabalho</Header>
                <ProgressBar totalSteps={9} currentStep={currentStep} />
                <span>Identificação de riscos ergonômicos relacionados a organização do trabalho</span>
                <h4>Marque os fatores ORGANIZAÇÃO DO TRABALHO existentes na atividade avaliada:</h4>

                <Form.Field>
                    <Form.Checkbox type="checkbox"
                                   label={"Trabalho realizado sem pausas pré-definidas para descanso"}
                                   id={"trabalhoSemPausasPreDefinidasPerguntaOrganizacaoDoTrabalho"}
                                   readOnly
                                   checked={coleta?.trabalhoSemPausasPreDefinidasPerguntaOrganizacaoDoTrabalho}>
                    </Form.Checkbox>

                    <Form.Checkbox type="checkbox"
                                   label={"A pausa é realizada fora do posto de trabalho"}
                                   id={"pausaRealizadaForaDoPostoPerguntaOrganizacaoDoTrabalho"}
                                   readOnly
                                   checked={coleta?.pausaRealizadaForaDoPostoPerguntaOrganizacaoDoTrabalho}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Necessidade de manter ritmos intensos de trabalho"}
                                   id={"necessidadadeDeManterRitmosIntensosPerguntaOrganizacaoDoTrabalho"}
                                   readOnly
                                   checked={coleta?.necessidadadeDeManterRitmosIntensosPerguntaOrganizacaoDoTrabalho}
                    />

                    <Form.Checkbox type="checkbox"
                                   label={"Trabalho com necessidade de variação de turnos"}
                                   id={"trabalhoComNecessidadeDeVariacaoDeTurnosPerguntaOrganizacaoDoTrabalho"}
                                   readOnly
                                   checked={coleta?.trabalhoComNecessidadeDeVariacaoDeTurnosPerguntaOrganizacaoDoTrabalho}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Monotonia"}
                                   id={"monotoniaPerguntaOrganizacaoDoTrabalho"}
                                   readOnly
                                   checked={coleta?.monotoniaPerguntaOrganizacaoDoTrabalho}/>

                    <Form.Checkbox
                        type="checkbox"
                        label={"Trabalho noturno"}
                        id={"trabalhoNoturnoPerguntaOrganizacaoDoTrabalho"}
                        readOnly
                        checked={coleta?.trabalhoNoturnoPerguntaOrganizacaoDoTrabalho}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Insuficiência de capacitação para execução da tarefa"}
                                   id={"insuficienciaDeCapacitacaoParaExecucaoPerguntaOrganizacaoDoTrabalho"}
                                   readOnly
                                   checked={coleta?.insuficienciaDeCapacitacaoParaExecucaoPerguntaOrganizacaoDoTrabalho}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Trabalho com utilização rigorosa de metas de produção"}
                                   id={"trabalhoUtilizacaoRigorosaDeMetasDeProdPerguntaOrganizacaoDoTrabalho"}
                                   readOnly
                                   checked={coleta?.trabalhoUtilizacaoRigorosaDeMetasDeProdPerguntaOrganizacaoDoTrabalho}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Trabalho remunerado por produção"}
                                   id={"trabalhoRemuneradoPorProducaoPerguntaOrganizacaoDoTrabalho"}
                                   readOnly
                                   checked={coleta?.trabalhoRemuneradoPorProducaoPerguntaOrganizacaoDoTrabalho}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Cadência do trabalho imposta por um equipamento"}
                                   id={"cadenciaDoTrabalhoImpostaPorEquipamentoPerguntaOrganizacaoDoTrabalho"}
                                   readOnly
                                   checked={coleta?.cadenciaDoTrabalhoImpostaPorEquipamentoPerguntaOrganizacaoDoTrabalho}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Desequilíbrio entre tempo de trabalho e tempo de repouso (menos de 11h)"}
                                   id={"desequilibrioEntreTempoTrabalhadoETempoRepousoPerguntaOrganizacaoDoTrabalho"}
                                   readOnly
                                   checked={coleta?.desequilibrioEntreTempoTrabalhadoETempoRepousoPerguntaOrganizacaoDoTrabalho}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Jornada longa de trabalho de mais de 8 h por dia"}
                                   id={"jornadaLongaDeTrabalhoMaisDe8HPorDiaPerguntaOrganizacaoDoTrabalho"}
                                   readOnly
                                   checked={coleta?.jornadaLongaDeTrabalhoMaisDe8HPorDiaPerguntaOrganizacaoDoTrabalho}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Concentrações desequilibradas de trabalho em um dia, semana, mês ou ano"}
                                   id={"concentracoesDesequilibradasDeTrabalhoPerguntaOrganizacaoDoTrabalho"}
                                   readOnly
                                   checked={coleta?.concentracoesDesequilibradasDeTrabalhoPerguntaOrganizacaoDoTrabalho}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Nenhum fator de risco/perigo organizacional nessa atividade."}
                                   id={"nenhumFatorDeRisgoNaAtivPerguntaOrganizacaoDoTrabalho"}
                                   readOnly checked={coleta?.nenhumFatorDeRisgoNaAtivPerguntaOrganizacaoDoTrabalho}/>

                    <Form.Input
                        label="Outros"
                        name="outrosPerguntaOrganizacaoDoTrabalho"
                        type="text"
                        placeholder="Outros fatores de risco em relação a organização do trabalho"
                        value={coleta?.outrosPerguntaOrganizacaoDoTrabalho}
                        readOnly
                    />
                </Form.Field>
            </Form>
        )
    };

    const renderStep7 = () => {
        const currentStep: number = 7;
        return (
            <Form>
                <Header textAlign={"center"} as={"h2"}>Pesquisa Ergonômica - NR-17</Header>
                <Header  textAlign={"center"} as={"h2"}>Fatores psicossociais</Header>
                <ProgressBar totalSteps={9} currentStep={currentStep} />
                <span>Identificação de riscos e perigos ergonômicos relacionados a fatores psicossociais</span>
                <h4>Marque os fatores PSICOSSOCIAIS existentes na atividade avaliada:</h4>
                <Form.Field>

                    <Form.Checkbox type="checkbox"
                                   label={"Trabalho em condições de difícil comunicação"}
                                   id={"trabalhoCondicoesDeDificilComunicacaoPerguntaFatoresPsicossociais"}
                                   readOnly
                                   checked={coleta?.trabalhoCondicoesDeDificilComunicacaoPerguntaFatoresPsicossociais}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Excesso de conflitos hierárquicos no trabalho"}
                                   id={"excessoDeConflitosHierarquicosPerguntaFatoresPsicossociais"}
                                   readOnly
                                   checked={coleta?.excessoDeConflitosHierarquicosPerguntaFatoresPsicossociais}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Insatisfação no trabalho"}
                                   id={"insatisfacaoNoTrabalhoPerguntaFatoresPsicossociais"}
                                   readOnly
                                   checked={coleta?.insatisfacaoNoTrabalhoPerguntaFatoresPsicossociais}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Falta de autonomia no trabalho"}
                                   id={"faltaDeAutonomiaNoTrabalhoPerguntaFatoresPsicossociais"}
                                   readOnly
                                   checked={coleta?.faltaDeAutonomiaNoTrabalhoPerguntaFatoresPsicossociais}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Extresse no trabalho"}
                                   id={"extresseNoTrabalhoPerguntaFatoresPsicossociais"}
                                   readOnly
                                   checked={coleta?.extresseNoTrabalhoPerguntaFatoresPsicossociais}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Não há nenhum fator de risco/perigo psicossocial na atividade."}
                                   id={"naoHaFatorDeRiscoPsicossocialPerguntaFatoresPsicossociais"}
                                   readOnly
                                   checked={coleta?.naoHaFatorDeRiscoPsicossocialPerguntaFatoresPsicossociais}/>

                    <Form.Input
                        label="Outros"
                        name="outrosPerguntaFatoresPsicossociais"
                        type="text"
                        placeholder="Outros fatores de risco em relação a fatores psicossociais do trabalho"
                        value={coleta?.outrosPerguntaFatoresPsicossociais}
                        readOnly
                    />
                </Form.Field>
            </Form>
        )
    };

    const renderStep8 = () => {
        const currentStep: number = 8;
        return (
            <Form>
                <Header textAlign={"center"} as={"h2"}>Pesquisa Ergonômica - NR-17</Header>
                <Header  textAlign={"center"} as={"h2"}>Matriz de riscos</Header>
                <ProgressBar totalSteps={9} currentStep={currentStep} />
                <span>Dados para classificação do nível de risco.</span>
                <img src={'/charts/matriz_risco.png'} style={{
                    objectFit: 'contain',
                    marginTop: 10,
                    marginBottom: 10,
                    padding: 20,
                    alignSelf: 'center',
                    width: '100%',
                }} alt={'matriz de risco'}/>

                <Form.Field>
                    <Form.Select
                        label={'Marque o fator GRAVIDADE existentes na atividade avaliada:'}
                        options={EPerguntaFatorDeRiscoGravidade}
                        placeholder='Selecione uma opção'
                        value={coleta?.perguntaFatorDeRiscoGravidade}
                        readOnly
                        required
                    />
                </Form.Field>

                <Form.Field>
                    <Form.Select
                        label={'Marque o fator PROBABILIDADE existentes na atividade avaliada:'}
                        options={EPerguntaFatorDeProbabilidade}
                        placeholder='Selecione uma opção'
                        value={coleta?.perguntaFatorDeProbabilidade}
                        readOnly
                        required
                    />
                </Form.Field>

                <Form.Field>
                    <Form.Input
                        label={'Sugestão do coletador sobre o posto de trabalho e outras informações importantes:'}
                        name="sugestaoDoColetador"
                        type="text"
                        placeholder="Sugestão do coletador..."
                        value={coleta?.sugestaoDoColetador}
                        readOnly
                        required
                    />
                </Form.Field>
            </Form>
        )
    };

    const renderStep9 = () => {
        const currentStep: number = 9;
        return (
            <Form>
                <Header textAlign={"center"} as={"h2"}>Pesquisa Ergonômica - NR-17</Header>
                <Header  textAlign={"center"} as={"h2"} >Fatores cognitivos</Header>
                <ProgressBar totalSteps={9} currentStep={currentStep} />
                <h4>Identificação de riscos e perigos ergonômicos relacionados a fatores cognitivos</h4>
                <Form.Field>
                    <Form.Checkbox type="checkbox"
                                   label={"Trabalho exige concentração"}
                                   id={"trabalhoExigeConcentracaoPerguntaFatoresCognitivos"}
                                   required
                                   checked={coleta.trabalhoExigeConcentracaoPerguntaFatoresCognitivos}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Trabalho exige atenção"}
                                   id={"trabalhoExigeAtencaoPerguntaFatoresCognitivos"}
                                   required
                                   checked={coleta.trabalhoExigeAtencaoPerguntaFatoresCognitivos}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Trabalho exige que memorize senha, números ou outros\n"}
                                   id={"trabalhoExigeMemorizeSenhaNumerosPerguntaFatoresCognitivos"}
                                   required
                                   checked={coleta.trabalhoExigeMemorizeSenhaNumerosPerguntaFatoresCognitivos}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Usabilidade no trabalho - Fácil de trabalhar (Sistemas, máquinas e equipamentos)"}
                                   id={"usabilidadeNoTrabalhoFacilDeTrabalharPerguntaFatoresCognitivos"}
                                   required
                                   checked={coleta.usabilidadeNoTrabalhoFacilDeTrabalharPerguntaFatoresCognitivos}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Usabilidade no trabalho - Engajamento (Sistemas, máquinas e equipamentos)\n"}
                                   id={"usabilidadeNoTrabalhoEngajamentoPerguntaFatoresCognitivos"}
                                   required
                                   checked={coleta.usabilidadeNoTrabalhoEngajamentoPerguntaFatoresCognitivos}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Usabilidade no trabalho - Aplicável (Sistemas, máquinas e equipamentos)"}
                                   id={"usabilidadeNoTrabalhoAplicavelPerguntaFatoresCognitivos"}
                                   required
                                   checked={coleta.usabilidadeNoTrabalhoAplicavelPerguntaFatoresCognitivos}/>

                    <Form.Checkbox type="checkbox"
                                   label={"O trabalho tem sistema de retorno de informação (Sistemas, máquinas e equipamentos)"}
                                   id={"oTrabalhoTemSistemaDeRetornoDeInfoPerguntaFatoresCognitivos"}
                                   required
                                   checked={coleta.oTrabalhoTemSistemaDeRetornoDeInfoPerguntaFatoresCognitivos}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Affordance (Sistemas, máquinas, equipamentos e rotas)"}
                                   id={"affordanceSistemasMaquinasEquipamentosERotasPerguntaFatoresCognitivos"}
                                   required
                                   checked={coleta.affordanceSistemasMaquinasEquipamentosERotasPerguntaFatoresCognitivos}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Sistema de Ciclo aberto (Sistemas, máquinas e equipamentos)"}
                                   id={"sistemaDeCicloAbertoSistemasMaquinasEquipamentosPerguntaFatoresCognitivos"}
                                   required
                                   checked={coleta.sistemaDeCicloAbertoSistemasMaquinasEquipamentosPerguntaFatoresCognitivos}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Sistema de Ciclo fechado (Sistemas, máquinas e equipamentos)"}
                                   id={"sistemaDeCicloFechadoSistemasMaquinasEquipamentosPerguntaFatoresCognitivos"}
                                   required
                                   checked={coleta.sistemaDeCicloFechadoSistemasMaquinasEquipamentosPerguntaFatoresCognitivos}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Operar mais de um equipamento ao mesmo tempo (Sistemas, máquinas e equipamentos)"}
                                   id={"operarMaisDeUmEquipamentoAoMesmoTempoPerguntaFatoresCognitivos"}
                                   required
                                   checked={coleta.operarMaisDeUmEquipamentoAoMesmoTempoPerguntaFatoresCognitivos}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Não há nenhum fator de risco/perigo cognitivo na atividade"}
                                   id={"naoHaNenhumFatorDeRiscoPerguntaFatoresCognitivos"}
                                   required
                                   checked={coleta.naoHaNenhumFatorDeRiscoPerguntaFatoresCognitivos}/>

                </Form.Field>
                <Form.Field>
                    <Form.Input
                        label="Outros"
                        name="outrosPerguntaFatoresCognitivos"
                        type="text"
                        placeholder="Outros"
                        value={coleta.outrosPerguntaFatoresCognitivos}
                        required
                    />
                </Form.Field>
            </Form>
        );
    };

    return (
        <div>
            <Header as="h2" textAlign="center" color={'black'}>
                Coletas Ergonômicas / Visualização
            </Header>
            {renderButtons(true)}
            <Segment>
                {step === 1 && renderStep1()}
                {step === 2 && renderStep2()}
                {step === 3 && renderStep3()}
                {step === 4 && renderStep4()}
                {step === 5 && renderStep5()}
                {step === 6 && renderStep6()}
                {step === 7 && renderStep7()}
                {step === 8 && renderStep8()}
                {step === 9 && renderStep9()}
                {renderButtons()}
            </Segment>
        </div>

    )
};


export default DetailsColetaErgo;
