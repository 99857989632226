import {ParticipanteFilter} from "../../../filters/participante-filters";
import instance from "../../index";
import {Participante} from "../../../models/participante";

export default GetParticipantes

async function GetParticipantes(filter?: ParticipanteFilter): Promise<any> {
    const response = await instance.get('/v1/backoffice/participantes', {
        params: filter
    })
    return response?.data?.data;
}
export async function GetAulaParticipantes(id: string, filter?: ParticipanteFilter): Promise<Participante[]> {
    const response = await instance.get(`/v1/backoffice/aulas/${id}/participantes`, {
        params: {...filter, perPage: 1000, MaxPageSize: 1000}
    })
    return response?.data?.data?.items;
}
export async function GetParticipantesSimplified(id?: string): Promise<any> {
    const response = await instance.get(`/v1/backoffice/participantes/simplified?TurmaId=${id}`)
    return response?.data?.data;
}

export interface GetParticipantesResponseData {
    id: string
    nome: string
    setor: string
    clientId: string
    clientName: string
    createdAt: Date
    formattedCreatedAt: string
}

export interface GetParticipantesResponse {
    data: GetParticipantesResponseData
    success: boolean
}

