export const EMes =
    [
        {"key": 1, "text": "Janeiro", "value": 1},
        {"key": 2, "text": "Fevereiro", "value": 2},
        {"key": 3, "text": "Março", "value": 3},
        {"key": 4, "text": "Abril", "value": 4},
        {"key": 5, "text": "Maio", "value": 5},
        {"key": 6, "text": "Junho", "value": 6},
        {"key": 7, "text": "Julho", "value": 7},
        {"key": 8, "text": "Agosto", "value": 8},
        {"key": 9, "text": "Setembro", "value": 9},
        {"key": 10, "text": "Outubro", "value": 10},
        {"key": 11, "text": "Novembro", "value": 11},
        {"key": 12, "text": "Dezembro", "value": 12}
    ]