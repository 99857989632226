import instance from "services";

export default ActiveUser

async function ActiveUser(id:string ) {
    const response = await instance.put(`/v1/backoffice/backoffice-users/active-user/${id}`);
    return response.data;
}
// export interface ActiveUserRequest {
//     id: string;
// }
// export interface ActiveUserResponseData {
//     id: string;
//     firstName: string;
//     lastName: string;
//     email: string;
//     createdAt: Date;
//     updatedAt: Date;
//     isActive: boolean;
// }
//
// export interface ActiveUserResponse {
//     success: boolean;
//     data: ActiveUserResponseData;
// }
