import instance from "../../index";
import {AvaliacoesErgoFilters} from "../../../filters/avaliacoes-ergo-filters";

export default async function GetAllPreliminarAvaliations(filter?: AvaliacoesErgoFilters): Promise<any> {
    const response = await instance.get('/v1/backoffice/avaliacoes-ergo-preliminares/all', {
        params:filter
    })
    return response.data
}

export async function GetAllPreliminarAvaliationsPaged(filter?: AvaliacoesErgoFilters): Promise<any> {
    const response = await instance.get('/v1/backoffice/avaliacoes-ergo-preliminares/lista-paginada', {
        params:filter
    })
    return response?.data?.data;
}

export interface GetAllPreliminarAvaliationsResponseData {
    id: string,
    createdAt: Date,
    coletaErgoId: string,
    nomeDoEntrevistado: string,
    dataDaEntrevista: Date,
    clientName: string,

    solucaoErgonomicaId: string,
    identificacaoVisual: string,
    fontesGeradoras: string,
    parteDoCorpoAtingida: string,
    gravidade: number,
    probabilidade: number,
    classificação: number,
    eliminarSolucaoErgonomica: string,
    prevencaoProtecaoColetiva: string,
    tipoDeAcao: number,
    outroTipoDeAcao: string,
    exigeInvestimento: number,
    responsavel: string,
    prazoSugerido: number,
    observacoes: string,
    responsavelPelaColetaDeDados: string,
}

export interface GetAllPreliminarAvaliationsResponse {
    success: boolean;
    data: GetAllPreliminarAvaliationsResponseData;
}