import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {Form, Grid, Header, Segment, Table} from "semantic-ui-react";
import GetAllClients from "../../../../services/backoffice/clients/get-all-clients";
import CreateParticipante, {
    CreateParticipanteRequest
} from "../../../../services/sistema-aulas/participante/create-participacao";
import {GetClientsResponse} from "../../../../services/backoffice/clients/get-clients";
import {useBlockUI} from "../../../../shared/services/block-ui-service";

const CreateParticipantePage = () => {
    const [participante, setParticipante] = useState<Partial<CreateParticipanteRequest>>({})
    const [clientes, setClientes] = useState<GetClientsResponse[]>([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const blockUI = useBlockUI();
    useEffect(() => {
        blockUI?.setActive(loading)
    }, [loading]);

    useEffect(() => {
        function ListClientes() {
            GetAllClients().then((response) => {
                setClientes(response);
            })
                .catch(error => {
                    console.warn(error);
                })
        }
        ListClientes()
    }, [])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setParticipante((prevParticipante) => ({
            ...prevParticipante,
            [name]: value,
        }));
    };

    const handleDropdownChange = (name: string, data: any) => {
        setParticipante((prevParticipante: any) => ({
            ...prevParticipante,
            [name]: data.value,
        }));
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            await CreateParticipante(participante);
            navigate('/treinamentos/participantes/');
            toast.success('Participante adicionado com sucesso', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (error) {
            console.warn(error);
        } finally {
            setLoading(false);
        }
    };


    return (
        <div>
            <Grid.Column mobile={16} tablet={8} computer={6}>
                <Header as="h2" textAlign="center" color={'black'}>
                   Novo participante / Adicionar
                </Header>
                <Segment>
                    <Form onSubmit={handleSubmit}>
                        <Form.Input
                            label="Nome"
                            type='text'
                            name="nome"
                            placeholder="Nome"
                            value={participante?.nome}
                            onChange={handleInputChange}
                        />
                        <Form.Select
                            label={'Cliente'}
                            options={clientes}
                            placeholder='Selecione uma opção'
                            value={participante?.clientId}
                            onChange={(evento: any, data: any) => {
                                setParticipante({
                                    ...participante,
                                    clientId: data.value,
                                });
                            }}
                            required
                            search
                        />
                        <Form.Input
                            label={'Setor do participante'}
                            type='text'
                            placeholder='Setor em que o participante trabalha'
                            name='setor'
                            value={participante?.setor}
                            onChange={handleInputChange}
                            required
                        />

                        <Table.Row>
                            <Table.HeaderCell>
                                <Form.Button content="Voltar"
                                             icon='arrow left'
                                             onClick={() => navigate('/treinamentos/participantes/')} grey/>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <Form.Button content="Adicionar"
                                             icon='save'
                                             primary/>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Form>
                </Segment>
            </Grid.Column>
        </div>
    )
}

export default CreateParticipantePage
