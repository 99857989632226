import instance from "../../index";

export default DeleteTreinamento

async function DeleteTreinamento(id: string) {
    const response = await instance.delete(`/v1/backoffice/treinamentos/${id}`);
    return response.data;
}

export interface DeleteTreinamentoRequest {
    id: string;
}
