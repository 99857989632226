export const EPerguntaFatorDeRiscoGravidade = [
    { key: '0', text: 'Baixa - Sobrecarga física dentro dos limites de tolerância tecidual. Não é suficiente para o ' +
            'desenvolvimento de lesões osteomusculares. As ações são voltadas ao conforto e ao processo de melhoria ' +
            'contínua produtiva ', value: 'Baixa',weight: 1 },

    { key: '1', text: 'MODERADO - Sobrecarga física suficiente para ultrapassar os limites de adaptação e tolerância' +
            ' tecidual. Pode levar a lesões osteomusculares de menor gravidade, ou mesmo agravar lesões pré-existentes'
        , value: 'Moderado', weight: 2 },

    { key: '2', text: 'ALTO - Sobrecarga física suficiente para ultrapassar os limites de adaptação e tolerância ' +
            'tecidual, levando a lesão e incapacidade funcional.'
        , value: 'Alto' , weight: 3},

    { key: '3', text: 'MUITO ALTO - Pode levar ao desenvolvimento de Distúrbios Osteomusculares Permanentes,' +
            ' bem como incapacidade funcional mais graves.'
        , value: 'MuitoAlto',weight: 4  },

    { key: '4', text: 'ALTÍSSIMO - Situação não permitida para o trabalho manual. Valores de limites de tolerância ' +
            'não conhecidos e/ou não permitidos. Lesões graves e permanentes.'
        , value: 'Altissimo',weight: 5 },

]