import instance from "../../index";
import {TurmaParticipantesFilter} from "../../../filters/turma-participante-filters";
import {ParticipanteFilter} from "../../../filters/participante-filters";

export default async function GetTurmaParticipantes(idTurma: string, filter?: ParticipanteFilter): Promise<any> {

    return await instance.get(`/v1/backoffice/turmas/${idTurma}/participantes`, {
        params: filter
    })
}

export interface GetTurmaParticipantesResponseData {
    id: string
    participanteId: string
    participante: string
    turmaId: string
    turma:string
}

export interface GetTurmaParticipantesResponse {
    success: boolean;

    data: GetTurmaParticipantesResponseData;
}



