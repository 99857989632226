import instance from "services";

export default CreateProfessor

async function CreateProfessor(request: Partial<CreateProfessorRequest>): Promise<CreateProfessorResponse> {
    const response = await instance.post(`/v1/backoffice/professores/`, request);
    return response.data.data;
}
export interface CreateProfessorRequest {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    passwordConfirmation: string;
}
export interface CreateProfessorResponseData {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    createdAt: Date;
    updatedAt: Date;
    isActive: boolean;
}

export interface CreateProfessorResponse {
    success: boolean;
    data: CreateProfessorResponseData;
}
