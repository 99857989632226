import React, {useCallback, useEffect, useState} from 'react';
import {Button, Dropdown, Header, Icon, Input, Label, Message, Pagination, Segment, Table} from 'semantic-ui-react';
import GetUsers from 'services/backoffice/users/get-users';
import ActiveUser from "services/backoffice/users/active-user";
import DeactiveUser from "services/backoffice/users/deactive-user";
import DeleteUser from "services/backoffice/users/delete-users";
import {useNavigate} from "react-router-dom";
import moment from "moment/moment";
import {UserFilter} from "../../../filters/user-filter";
import {toast} from "react-toastify";
import {SessionUser} from "../../../models/session-user";
import SessionStorage from "../../../store/session";
import {useBlockUI} from "../../../shared/services/block-ui-service";

const BackofficeUsersList = () => {
    const [users, setUsers] = useState<any[]>([]);
    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const [filter, setFilter] = useState<UserFilter>()
    const [totalPages, setTotalPages] = useState()
    const [sessionUser, setSessionUser] = useState<SessionUser>();
    const blockUI = useBlockUI();

    useEffect(() => {
        blockUI?.setActive(loading)
    }, [blockUI, loading]);

    useEffect(() => {
        async function updateSessionUser() {
            const user = await SessionStorage.getSession();
            setSessionUser(user)
        }
        updateSessionUser()
    }, [])
    const [showFilters, setShowFilters] = useState(false);

    function setFilterAndResetPage(newFilter: any){
        setFilter({...newFilter, Page: 1} )
    }
    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };

    const listUsers = useCallback(async () => {
        try {
            setLoading(true);

            const response = await GetUsers(filter);
            const data = response?.data?.data;
            setTotalPages(data?.totalPages);
            setUsers(data?.items);
        } catch (error) {
            console.error('Erro ao obter lista de usuários:', error);
        } finally {
            setLoading(false);
        }
    }, [filter]);
    useEffect(() => {
        listUsers();
    }, [filter, listUsers]);

    const toggleActivationUser = async (user: any, activate?: boolean) => {
        try {
            const result = window.confirm(`Tem certeza que deseja ${user.isActive ? 'desativar' : 'ativar'} o usuário ${user.firstName} ${user.lastName}?`);
            if (!result) return;

            setLoading(true);

            if (user) {
                const activationResult = !user.isActive ? await ActiveUser(user.id) : await DeactiveUser(user.id);

                if (activationResult.success) {
                    toast.success(`O usuário foi ${user.isActive ? 'desativado' : 'ativado'} com sucesso`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });

                    setTimeout(() => {
                        setError('');
                    }, 3000);

                    await listUsers(); // Usar 'await' para esperar a conclusão da função
                }
            }

            setLoading(false);
        } catch (error:any) {
            toast.warn(`Falha ao ${user.isActive ? 'desativar' : 'ativar'} usuário`, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setError(error.message);
            setLoading(false);
        }
    };


    const deleteUser = async (user: any) => {
        try {
            const confirm = window.confirm(`Tem certeza que deseja apagar o usuário ${user.firstName} ${user.lastName}?`);

            if (confirm) {
                setLoading(true);

                if (user) {
                    await DeleteUser(user.id);
                    toast.success('Usuário deletado com sucesso', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });

                    await listUsers(); // Usar 'await' para esperar a conclusão da função
                }
            }
        } catch (error:any) {
            toast.warn('Falha ao deletar usuário', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            setError(error.message);
        } finally {
            setLoading(false);
        }
    };


    return (
        <div>
            <Header as="h2" textAlign="center" color={'black'}>
                Usuários do backoffice
            </Header>
            {error && <Message
                floating
                style={{
                    position: 'fixed',
                    top: 0,
                    left: '50%',
                    transform: 'translateX(10%)',
                    zIndex: 9999,
                }}
            >
                <p>{error}</p>
            </Message>}
            <Table.Row>
                <Table.HeaderCell>
                    {sessionUser?.userData?.profile === 'Admin' &&
                        <Button color="blue"
                                onClick={() => navigate('/backoffice/users/create/')}>

                            <Icon name='plus'/> Novo
                        </Button>}
                </Table.HeaderCell>
                <Table.HeaderCell colSpan="4">
                    <Button onClick={toggleFilters} primary>
                        <Icon name={showFilters ? 'hide' : 'unhide'}/>
                        {showFilters ? 'Ocultar filtros' : 'Exibir filtros'}
                    </Button>
                </Table.HeaderCell>
            </Table.Row>{showFilters && (
            <Segment>
                <div style={{padding: 10}}>
                    <div>
                        <Input
                            fluid
                            icon="search"
                            type="text"
                            placeholder="Nome do usuário"
                            value={filter?.Name ?? ''}
                            onChange={event =>
                                setFilterAndResetPage({...filter, Name: event.target?.value})
                            }
                        />
                    </div>
                    <br/>
                    <div>
                        <Input
                            fluid
                            icon="search"
                            type="text"
                            placeholder="E-mail"
                            value={filter?.Email ?? ''}
                            onChange={event =>
                                setFilterAndResetPage({...filter, Email: event.target?.value})
                            }
                        />
                    </div>
                </div>
            </Segment>
        )}

            <Table color={"orange"}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>#</Table.HeaderCell>
                        <Table.HeaderCell>Nome</Table.HeaderCell>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                        <Table.HeaderCell>Criado em</Table.HeaderCell>
                        <Table.HeaderCell>Ativo</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                {sessionUser?.userData?.profile === 'Admin' &&
                    <Table.Body>
                        {users?.map((user) => (
                            <Table.Row key={user.id}>
                                <Table.Cell>
                                    <Dropdown item icon={'bars'}>
                                        <Dropdown.Menu>
                                            <Dropdown.Item
                                                color="blue"
                                                text={'Editar'}
                                                onClick={() => navigate('/backoffice/users/edit/' + user.id)}
                                            >
                                                <Icon name='edit' color='blue' />
                                                Editar
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                text={'Deletar'}
                                                onClick={() => deleteUser(user)}
                                            >
                                                <Icon name='trash' color={'red'} />
                                                Deletar
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                color={user.isActive ? 'red' : 'green'}
                                                text={user.isActive ? 'Desativar' : 'Ativar'}
                                                onClick={() => toggleActivationUser(user)}
                                            >
                                                <Icon name={user.isActive ? 'power off' : 'power'} color={user.isActive ? 'red' : 'green'} />
                                                {user.isActive ? 'Desativar' : 'Ativar'}
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Table.Cell>

                                <Table.Cell>{`${user.firstName} ${user?.lastName ?? ''}`}</Table.Cell>
                                <Table.Cell>{user.email}</Table.Cell>
                                <Table.Cell>{moment(user.createdAt).format('DD/MM/YY HH:mm')}</Table.Cell>
                                <Table.Cell>
                                    <Label key={user.id}
                                           color={!user.isActive ? 'red' : 'green'}>{user.isActive ? 'Sim' : 'Não'}</Label>
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>}
            </Table>
            <Pagination totalPages={totalPages ?? 0}
                        defaultActivePage={filter?.Page ?? 1}
                        onPageChange={(e, {activePage}) => {
                            setFilter({...filter, Page: activePage as number})
                        }
                        }>
            </Pagination>
        </div>
    );
};

export default BackofficeUsersList;
