import instance from "../../index";


export default EditOperator

async function EditOperator(request: Partial<EditOperatorRequest>, id: string | string[] | undefined): Promise<EditOperatorResponse> {
    const response = await instance.put(`/v1/backoffice/operadores/${id}`, request);
    return response.data;
}

export interface EditOperatorRequest {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    clients: string[];
}

export interface EditOperatorResponseData {
    id: string;
    firstName: string;
    lastName: string;
    clients: string[];

}

export interface EditOperatorResponse {
    success: boolean;
    data: EditOperatorResponseData;

}