import instance from "../../index";

export default UpdateParticipante

async function UpdateParticipante(request: Partial<UpdateParticipanteRequest>, id: string | string[] | undefined): Promise<UpdateParticipanteResponse> {
    const response = await instance.put(`/v1/backoffice/participantes/${id}`, request)
    return response?.data?.data;
}

export interface UpdateParticipanteRequest {
    id: string
    nome:string
    setor:string
    clientId:string
}

export interface UpdateParticipanteResponseData {
    id: string
    nome:string
    setor:string
    clientId:string
}

export interface UpdateParticipanteResponse {
    data: UpdateParticipanteResponseData
    success: boolean
}