import instance from "../../index";

export default CreateAvaliacaoErgoPreliminar

async function CreateAvaliacaoErgoPreliminar(request: Partial<CreateAvaliacaoErgoPreliminarRequest>): Promise<CreateAvaliacaoErgoPreliminarResponse> {
    const response = await instance.post(`/v1/backoffice/avaliacoes-ergo-preliminares/`, request);
    return response.data.data;
}

export interface CreateAvaliacaoErgoPreliminarRequest {
    coletaErgoId: string
    solucoesErgonomicasIds: string[]
    identificacaoVisual: string
    fontesGeradoras: string
    parteDoCorpoAtingida: string
    gravidade: number
    probabilidade: number
    classificação: number | string | undefined
    eliminarSolucaoErgonomica: string
    prevencaoProtecaoColetiva: string
    // tipoDeAcao: number | string | undefined
    outroTipoDeAcao: string
    exigeInvestimento: number | string | undefined
    responsavel: string
    prazoSugerido: number | string | undefined
    observacoes: string
    responsavelPelaColetaDeDados: string
    posturaInadequadaOmbrosPresente: boolean
    posturaInadequadaOmbrosAusente: boolean
    posturaInadequadaCotovelosPresente: boolean
    posturaInadequadaCotovelosAusente: boolean
    posturaInadequadaPunhosPresente: boolean
    posturaInadequadaPunhosAusente: boolean
    posturaInadequadaPescoçoPresente: boolean
    posturaInadequadaPescoçoAusente: boolean
    posturaInadequadaTroncoPresente: boolean
    posturaInadequadaTroncoAusente: boolean
    posturaInadequadaPernasPresente: boolean
    posturaInadequadaPernasAusente: boolean
    posturaInadequadaOutrosPresente: boolean
    posturaInadequadaOutrosAusente: boolean
    outrosPerigosPosturaisPresente: boolean
    outrosPerigosPosturaisAusente: boolean
    perigosForcasPresente: boolean
    perigosForcasAusente: boolean
    perigoFatorRepetitividadePresente: boolean
    perigoFatorRepetitividadeAusente: boolean
    perigoFatorOrganizacaoDoTrabalhoPresente: boolean
    perigoFatorOrganizacaoDoTrabalhoAusente: boolean
    perigoFatorPsicossocialPresente: boolean
    perigoFatorPsicossocialAusente: boolean
    perigoFatorCognitivoPresente: boolean
    perigoFatorCognitivoAusente: boolean
}

export interface CreateAvaliacaoErgoPreliminarResponseData {
    coletaErgoId: string
    identificacaoVisual: string
    fontesGeradoras: string
    parteDoCorpoAtingida: string
    solucoesErgos: string[]
    gravidade: number
    probabilidade: number
    classificação: number
    eliminarSolucaoErgonomica: string
    prevencaoProtecaoColetiva: string
    // tipoDeAcao: number
    outroTipoDeAcao: string
    exigeInvestimento: number
    responsavel: string
    prazoSugerido: number
    observacoes: string
    responsavelPelaColetaDeDados: string
    posturaInadequadaOmbrosPresente: boolean
    posturaInadequadaOmbrosAusente: boolean
    posturaInadequadaCotovelosPresente: boolean
    posturaInadequadaCotovelosAusente: boolean
    posturaInadequadaPunhosPresente: boolean
    posturaInadequadaPunhosAusente: boolean
    posturaInadequadaPescoçoPresente: boolean
    posturaInadequadaPescoçoAusente: boolean
    posturaInadequadaTroncoPresente: boolean
    posturaInadequadaTroncoAusente: boolean
    posturaInadequadaPernasPresente: boolean
    posturaInadequadaPernasAusente: boolean
    posturaInadequadaOutrosPresente: boolean
    posturaInadequadaOutrosAusente: boolean
    outrosPerigosPosturaisPresente: boolean
    outrosPerigosPosturaisAusente: boolean
    perigosForcasPresente: boolean
    perigosForcasAusente: boolean
    perigoFatorRepetitividadePresente: boolean
    perigoFatorRepetitividadeAusente: boolean
    perigoFatorOrganizacaoDoTrabalhoPresente: boolean
    perigoFatorOrganizacaoDoTrabalhoAusente: boolean
    perigoFatorPsicossocialPresente: boolean
    perigoFatorPsicossocialAusente: boolean
    perigoFatorCognitivoPresente: boolean
    perigoFatorCognitivoAusente: boolean
}

export interface CreateAvaliacaoErgoPreliminarResponse {
    success: boolean;
    data: CreateAvaliacaoErgoPreliminarResponseData;
}