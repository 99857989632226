import React, {useEffect, useState} from "react";
import {useLoaderData, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {Button, Checkbox, Header, Message, Select, Table,} from "semantic-ui-react";
import GetAulaById from "../../../../services/sistema-aulas/aulas/get-aula";
import CreatePresenca from "../../../../services/sistema-aulas/presenca/create-presenca";
import {PresencaFilter} from "../../../../filters/presenca-filter";
import DeleteTreinamento from "../../../../services/sistema-aulas/treinamentos/delete-treinamento";
import {Turma} from "../../../../models/turma";
import GetTurmaById from "../../../../services/sistema-aulas/turmas/get-turma";
import {Aula} from "../../../../models/aula";
import moment from "moment/moment";
import {GetAulaParticipantes} from "../../../../services/sistema-aulas/participante/get-participantes";
import {Participante} from "../../../../models/participante";
import {EStatusPresenca} from "../../../../enums/eStatusPresenca";
import DeletePresenca from "../../../../services/sistema-aulas/presenca/delete-presenca";
import SessionStorage from "../../../../store/session";
import StringUtils from "../../../../shared/utils/string-utils";
import {useBlockUI} from "../../../../shared/services/block-ui-service";

export async function aulaChamadaLoader({params}: any) {
    return await GetAulaById(params.id);
}

const AulaChamadaPage = () => {
    const [aula, setAula] = useState<Partial<Aula>>();
    const [participantes, setParticipantes] = useState<Participante[]>([]);
    const [turma, setTurma] = useState<Turma>();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [profile, setProfile] = useState<string>();
    const [filter, setFilter] = useState<PresencaFilter>();
    const loader: any = useLoaderData();
    const turmaIdLoader = loader?.turmaId;


    const blockUI = useBlockUI();
    const navigate = useNavigate();

    useEffect(() => {
        blockUI?.setActive(loading);
    }, [loading]);

    useEffect(() => {
        async function updateSessionUser() {
            try {
                SessionStorage.getSession().then(user => {
                    if (!user) return
                    const isLogged =  user?.isLogged;
                    if(!isLogged) return
                    setProfile(user?.userData?.profile)
                })
            } catch (e) {
            }
        }

        updateSessionUser()
    }, [SessionStorage])

    useEffect(() => {
        setAula(loader);
    }, []);

    useEffect(() => {
        listParticipantes();
    }, [filter, aula?.id]);

    useEffect(() => {
        getTurma();
    }, [aula]);

    async function listParticipantes() {
        if (aula?.id) {
            setLoading(true);
            const response = await GetAulaParticipantes(aula?.id, filter)
                .finally(() => setLoading(false));
            ;
            setParticipantes(response);
        }
    }

    async function getTurma() {
        if (aula?.turmaId) {
            setLoading(true);
            const response = await GetTurmaById(aula.turmaId)
                    .finally(() => setLoading(false))
                ;
            setTurma(response);
        }
    }


    return (
        <div>
            <Header as="h2" textAlign="center" color="black">
                Turma: {aula?.turmaNome} - {turma?.clientName}
            </Header>
            <Header as="h3" textAlign="center" color="black">
                Aula: {aula?.nome}
                <br/>
                {StringUtils.formatToBrazilianShortDateTime(aula?.data?.toString())}
            </Header>
            <Header as="h4" textAlign="center" color="black">
            </Header>

            <Table.Row>
                <Table.HeaderCell>
                    <Button content="Voltar para lista de aulas"
                            icon='arrow left'
                            onClick={() => navigate(`/treinamentos/aulas/?turmaId=${turmaIdLoader}`)}/>
                </Table.HeaderCell>
            </Table.Row>

            <Table color={"orange"} celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>#</Table.HeaderCell>
                        <Table.HeaderCell>Participante</Table.HeaderCell>
                        <Table.HeaderCell>Cliente</Table.HeaderCell>
                        <Table.HeaderCell>Presente</Table.HeaderCell>
                        <Table.HeaderCell>Ausente</Table.HeaderCell>
                        <Table.HeaderCell>Motivo da ausência</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {participantes.map((participante) => (
                        <Table.Row key={participante.id}>
                            <Table.Cell>
                                <Button.Group>
                                </Button.Group>
                            </Table.Cell>
                            <Table.Cell> {participante?.nome}</Table.Cell>
                            <Table.Cell>{participante?.clientName}</Table.Cell>
                            <Table.Cell>
                                <Checkbox
                                    toggle
                                    checked={participante?.presente && (participante?.statusPresenca === 'Presente')}
                                    onChange={(e, props) => {
                                        if (profile !== 'Operator') {
                                            if (aula?.id && props.checked) {
                                                setLoading(true);
                                                CreatePresenca({
                                                    aulaId: aula?.id,
                                                    participanteId: participante?.id,
                                                    statusPresenca: 9 // 9 = Presente
                                                }).then(r => {
                                                    listParticipantes();
                                                })
                                                    .finally(() => setLoading(false))
                                                ;
                                            } else if (aula?.id && participante?.presencaId) {
                                                setLoading(true);
                                                DeletePresenca(participante.presencaId).then(r => {
                                                    listParticipantes();
                                                })
                                                    .finally(() => setLoading(false))
                                                ;
                                            }
                                        }
                                    }}
                                    disabled={profile === 'Operator'}
                                />
                            </Table.Cell>

                            <Table.Cell>
                                <Checkbox
                                    toggle
                                    checked={participante?.presente && (participante?.statusPresenca !== 'Presente')}
                                    onChange={(e, props) => {
                                        if (profile !== 'Operator') {
                                            if (aula?.id) {
                                                if (props.checked) {
                                                    setLoading(true);
                                                    CreatePresenca({
                                                        aulaId: aula?.id,
                                                        participanteId: participante?.id,
                                                        statusPresenca: 10 // 10 = Ausente
                                                    }).then(() => {
                                                        listParticipantes();
                                                    })
                                                        .finally(() => setLoading(false))
                                                    ;
                                                } else {
                                                    if (participante?.presencaId) {
                                                        setLoading(true);
                                                        DeletePresenca(participante.presencaId).then(() => {
                                                            listParticipantes();
                                                        })
                                                            .finally(() => setLoading(false))
                                                        ;
                                                    }
                                                }
                                            }
                                        }
                                    }}
                                    disabled={profile === 'Operator'}
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <Select
                                    options={EStatusPresenca}
                                    value={participante?.statusPresenca || '10'}
                                    onChange={(e, props) => {
                                            if (aula?.id && props.value) {
                                                setLoading(true);
                                                CreatePresenca({
                                                    aulaId: aula?.id,
                                                    participanteId: participante?.id,
                                                    statusPresenca: props.value?.toString()
                                                }).then(() => {
                                                    toast.info(`Motivo registrado para ${participante.nome}`);
                                                    listParticipantes();
                                                })
                                                    .finally(() => setLoading(false))
                                                ;
                                            }
                                    }}
                                    disabled={profile === 'Operator' || !participante?.statusPresenca || participante?.statusPresenca === 'Presente'}
                                    text={participante?.statusPresenca === 'Presente' ? 'Presente' : EStatusPresenca.find(item => item.value === participante?.statusPresenca)?.text || 'Falta'}
                                />
                            </Table.Cell>


                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
};

export default AulaChamadaPage;
