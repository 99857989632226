import React, {useEffect, useState} from 'react';
import {Button, Container, Dropdown, Form, Grid, Icon, Menu, Modal, Segment, Sidebar} from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import {useNavigate} from "react-router-dom";
import SessionStorage from "../../store/session";
import ChangePassword, {ChangePasswordRequest} from "../../services/password/change-password";
import {toast} from "react-toastify";
import {useBlockUI} from "../services/block-ui-service";

interface Props {
    children: React.ReactNode;
    profile: string;
    isLogged: boolean;
    username: string;
}

const MasterContainer = ({children, profile, isLogged = false, username = ""}: Props) => {
    const [visible, setVisible] = useState(false);
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [showSubMenu, setShowSubMenu] = useState(false);
    const [modalPassword, setModalPassword] = useState<Partial<ChangePasswordRequest>>({});
    const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);
    const blockUI = useBlockUI();

    useEffect(() => {
        blockUI?.setActive(loading);
    }, [loading]);

    const toggleSidebar = () => {
        setVisible(!visible);
    };
    const incompleteItemOfMenu = true;
    useEffect(() => {
        if (!profile)
            window.location.reload()
    }, [])

    const handleSubmit = async () => {
        try {
            setLoading(true);
            const result = await ChangePassword(modalPassword);
            if (result.success)
                toast.success('Senha atualizada com sucesso', {
                    position: 'top-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
            setIsChangePasswordModalOpen(false);
            setModalPassword({});
        } finally {
            setLoading(false);
        }
    };


    const openChangePasswordModal = () => {
        setIsChangePasswordModalOpen(true);
        setModalPassword({});
    };

    if (isLogged)
        return (
            <Sidebar.Pushable>
                <Sidebar
                    as={Menu}
                    animation='slide along'
                    icon='labeled'
                    onHide={() => setVisible(false)}
                    vertical
                    visible={visible}
                    width='wide'
                >
                    <Menu.Item
                        name='sair'
                        onClick={() => {
                        SessionStorage.deleteSession()
                            navigate('/')
                            window.location.reload()
                        }}
                    >
                        <Icon name='sign-out' color={'red'}/>
                        Sair
                    </Menu.Item>
                </Sidebar>
                <Sidebar.Pusher dimmed={visible}>
                    <div suppressContentEditableWarning
                         style={{
                             backgroundImage: 'url(/page_bg_top.png), url(/page_bg_bottom.png)',
                             backgroundSize: 'cover',
                             backgroundRepeat: 'no-repeat, no-repeat',
                             backgroundPosition: 'center top, center bottom'
                         }}>
                        <Menu stackable>
                            <Menu.Item>
                                <img alt="logo" src='/logotipo_redondo.png' onClick={()=>navigate('/')}/>
                            </Menu.Item>
                            {profile === "Admin" &&
                            <Dropdown
                                text={`Cadastros`}
                                item
                            >
                                <Dropdown.Menu>
                                    {profile === 'Admin' &&
                                        <Dropdown.Item
                                            name='backoffice-users'
                                            onClick={() => {
                                                navigate('/backoffice/users/')
                                                setVisible(false)
                                            }}>
                                            <Icon name="user" color={'blue'}/>
                                            Administradores
                                        </Dropdown.Item>
                                    }
                                    {(profile === 'Admin' || profile === 'Collector') &&
                                        <Dropdown.Item
                                            name='clientes'
                                            onClick={() => {
                                                navigate('/backoffice/clients/')
                                                setVisible(false)
                                            }}
                                        >
                                            <Icon name='handshake' color={'blue'}/>
                                            Clientes
                                        </Dropdown.Item>}
                                    {(profile === 'Admin') && incompleteItemOfMenu &&
                                        <Dropdown.Item
                                            name='coletores'
                                            onClick={() => {
                                                navigate('/backoffice/collectors/')
                                                setVisible(false)
                                            }}
                                        >
                                            <Icon name='file text outline' color={'blue'}/>
                                            Coletores
                                        </Dropdown.Item>}
                                    {(profile === 'Admin') && incompleteItemOfMenu &&
                                        <Dropdown.Item
                                            name='operadores'
                                            onClick={() => {
                                                navigate('/backoffice/operators/')
                                                setVisible(false)
                                            }}
                                        >
                                            <Icon name='user' color={'blue'}/>
                                            Operadores
                                        </Dropdown.Item>}
                                    {profile === "Admin" && <Dropdown.Item
                                        name='professores'
                                        onClick={() => {
                                            navigate('/treinamentos/professor')
                                            setVisible(false)
                                        }}
                                    >
                                        <Icon name='write square' color={'blue'}/>
                                        Professores
                                    </Dropdown.Item>}
                                </Dropdown.Menu>
                            </Dropdown> }

                            <Dropdown
                            text={'Relatórios'}
                            item>

                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        name='relatorios'
                                        onClick={() => {
                                            navigate('/treinamentos/relatorios/clients')
                                            setVisible(false)
                                        }}
                                    >
                                        <Icon name='student' color={'blue'}/>
                                        Relatório de Treinamentos (Por cliente)
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        name='relatorio-professor'
                                        onClick={() => {
                                            navigate('/treinamentos/relatorios/professores')
                                            setVisible(false)
                                        }}
                                    >
                                        <Icon name='student' color={'blue'}/>
                                        Relatório de Aulas (Professor/Cliente)
                                    </Dropdown.Item>
                                </Dropdown.Menu>

                            </Dropdown>

                            {(profile === 'Admin' || profile === 'Professor' || profile == 'Operator') &&
                                <Dropdown
                                text={`Treinamentos`}
                                item
                            >
                                <Dropdown.Menu>
                                    {
                                        (profile === 'Admin' || profile == 'Operator') &&
                                        <Dropdown.Item
                                            name='participantes'
                                            onClick={() => {
                                                navigate('/treinamentos/participantes')
                                                setVisible(false)
                                            }}
                                        >
                                            <Icon name='id badge' color={'blue'}/>
                                            Colaboradores/Participantes
                                        </Dropdown.Item>}

                                        <Dropdown.Item
                                            name='dashboard'
                                            onClick={() => {
                                                navigate('/treinamentos/dashboard/turmas')
                                                setVisible(false)
                                            }}
                                        >
                                            <Icon name='file text' color={'blue'}/>
                                            Dashboards de Treinamentos
                                        </Dropdown.Item>

                                    <Dropdown.Item
                                        name='treinamentos'
                                        onClick={() => {
                                            navigate('/treinamentos/treinamentos')
                                            setVisible(false)
                                        }}
                                    >
                                        <Icon name='certificate' color={'blue'}/>
                                        Treinamentos
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        name='turmas'
                                        onClick={() => {
                                            navigate('/treinamentos/turmas')
                                            setVisible(false)
                                        }}
                                    >
                                        <Icon name='users' color={'blue'}/>
                                        Turmas
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown> }

                            {(profile === 'Admin' || profile === 'Collector' || profile == 'Operator') &&
                            <Dropdown
                                text={`Ergonomia`}
                                item
                            >
                                <Dropdown.Menu>
                                    {
                                        (profile === 'Admin' || profile == 'Collector' || profile == 'Operator') && incompleteItemOfMenu &&
                                        <Dropdown.Item
                                            name='avaliacões ergonomicas'
                                            onClick={() => {
                                                navigate('/backoffice/avaliacoes-ergo')
                                                setVisible(false)
                                            }}
                                        >
                                            <Icon name='file text' color={'blue'}/>
                                            Avaliações Ergonômicas
                                        </Dropdown.Item>
                                    }
                                    {(profile === 'Admin' || profile === 'Collector'
                                            || profile === 'Operator') && incompleteItemOfMenu &&
                                        <Dropdown.Item
                                            name='coletas'
                                            onClick={() => {
                                                navigate('/backoffice/coleta-ergo/')
                                                setVisible(false)
                                            }}
                                        >
                                            <Icon name='file text' color={'blue'}/>
                                            Coletas
                                        </Dropdown.Item>}

                                    {(profile === 'Admin' &&
                                    <Dropdown.Item
                                        name='dashboard'
                                        onClick={() => {
                                            navigate('/treinamentos/dashboard/saude')
                                            setVisible(false)
                                        }}
                                    >
                                        <Icon name='file text' color={'blue'}/>
                                        Dashboards de Pesquisa de Saúde
                                    </Dropdown.Item>
                                        )}

                                    {
                                        (profile === 'Admin' || profile === 'Collector') && incompleteItemOfMenu &&
                                        <Dropdown.Item
                                            name='normas-regulamentadoras'
                                            onClick={() => {
                                                navigate('/backoffice/normas-regulamentadoras')
                                                setVisible(false)
                                            }}
                                        >
                                            <Icon name='file text' color={'blue'}/>
                                            Normas Regulamentadoras
                                        </Dropdown.Item>}

                                    {(profile === 'Admin' || profile === 'Collector'
                                            || profile === 'Operator') && incompleteItemOfMenu &&
                                        <Dropdown.Item
                                            name='coletas'
                                            onClick={() => {
                                                navigate('/backoffice/pesquisa-saude/')
                                                setVisible(false)
                                            }}
                                        >
                                            <Icon name='file text' color={'blue'}/>
                                            Pesquisas de saúde
                                        </Dropdown.Item>}

                                    {
                                        (profile === 'Admin' || profile === 'Collector') && incompleteItemOfMenu &&
                                        <Dropdown.Item
                                            name='solucoes-ergonomicas'
                                            onClick={() => {
                                                navigate('/backoffice/solucoes-ergo')
                                                setVisible(false)
                                            }}
                                        >
                                            <Icon name='file text' color={'blue'}/>
                                            Soluções Ergonômicas
                                        </Dropdown.Item>}
                                </Dropdown.Menu>
                            </Dropdown> }
                            <Dropdown
                                className={'floated right'}
                                text={`Bem-vindo(a), ${username}`}
                                item
                            >
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        text="Alterar Senha"
                                        icon="lock"
                                        onClick={openChangePasswordModal}
                                    />
                                    <Dropdown.Item
                                        onClick={() => {
                                            SessionStorage.deleteSession();
                                            window.location.reload();
                                        }}>
                                        <Icon name='log out' color={'red'}/>
                                        Sair
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                            {/*<Menu.Item as='a' onClick={toggleSidebar} className={'no-print'} color={'blue'}>*/}
                            {/*    <Icon name={'bars'} size={'large'} color={'blue'}></Icon>*/}
                            {/*    <span>Menu</span>*/}
                            {/*</Menu.Item>*/}
                            {/*<Menu.Item as='a'*/}
                            {/*           position={'left'}*/}
                            {/*           onClick={toggleSidebar}*/}
                            {/*           className={'no-print'}*/}
                            {/*           name={'MENU'}*/}
                            {/*           color={'blue'}*/}
                            {/*           icon={'bars'}>*/}
                            {/*    <img src={'/logotipo.png'} style={{*/}
                            {/*        maxHeight: '3rem',*/}
                            {/*        objectFit: 'contain',*/}
                            {/*        marginTop: 10,*/}
                            {/*        marginBottom: 10,*/}
                            {/*        paddingBottom: 5,*/}
                            {/*        alignSelf: 'center',*/}
                            {/*        width: '100%',*/}
                            {/*    }} alt={'superativa'}/>*/}
                            {/*</Menu.Item>*/}
                        </Menu>
                        <Container fluid>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column style={{width: '100%'}}>
                                        <Grid.Row style={{width: '100%', padding: '5%'}}>
                                            {children}
                                        </Grid.Row>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Container>
                    </div>
                </Sidebar.Pusher>
                {/* Modal de alteração de senha */}
                <Modal open={isChangePasswordModalOpen} onClose={() => setIsChangePasswordModalOpen(false)}
                       onOpen={openChangePasswordModal}>
                    <Modal.Header>Alterar Senha</Modal.Header>
                    <Modal.Content>
                        <Form size="large">
                            <Segment stacked>
                                <Form.Input
                                    label="Senha Atual"
                                    name="currentPassword"
                                    type="password"
                                    placeholder="Senha Atual"
                                    value={modalPassword.currentPassword || ''}
                                    onChange={(event) => setModalPassword({
                                        ...modalPassword,
                                        currentPassword: event.target.value
                                    })}
                                />
                                <Form.Input
                                    label="Nova Senha"
                                    name="newPassword"
                                    type="password"
                                    placeholder="Nova Senha"
                                    value={modalPassword.password || ''}
                                    onChange={(event) => setModalPassword({
                                        ...modalPassword,
                                        password: event.target.value
                                    })}
                                />
                                <Form.Input
                                    label="Confirmar Nova Senha"
                                    name="confirmNewPassword"
                                    type="password"
                                    placeholder="Confirmar Nova Senha"
                                    value={modalPassword.passwordConfirmation || ''}
                                    onChange={(event) => setModalPassword({
                                        ...modalPassword,
                                        passwordConfirmation: event.target.value
                                    })}
                                />
                            </Segment>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color="blue" onClick={handleSubmit}>
                            Alterar Senha
                        </Button>
                        <Button color="red" onClick={() => setIsChangePasswordModalOpen(false)}>
                            Fechar
                        </Button>
                    </Modal.Actions>
                </Modal>
            </Sidebar.Pushable>
        );
    else
        return <div>{children}</div>
};
export default MasterContainer;
