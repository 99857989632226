import instance from "../../index";
import {OperatorFilter} from "../../../filters/operator-filter";


export default async function GetOperators(filter? : OperatorFilter) : Promise<any> {
    return await instance.get('/v1/backoffice/operadores', {
        params: filter
    })
}

export interface GetOperatorsResponseData {
    id: string
    firstName: string
    lastName: string
    email: string
    createdAt: string
    updatedAt: string
    isActive: boolean
    clients: string[];
    clientName: string;

}

export interface GetOperatorsResponse {
    success: boolean;
    data: GetOperatorsResponseData;

}
