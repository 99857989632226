import React, {useEffect, useState} from "react";
import {useLoaderData, useLocation, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {Button, Header, Icon, Pagination, Table} from "semantic-ui-react";
import GetTurmaParticipantes from "../../../../services/sistema-aulas/turmas/get-turma-participantes";
import {SessionUser} from "../../../../models/session-user";
import SessionStorage from "../../../../store/session";
import {TurmasParticipantesFilter} from "../../../../filters/turmas-participantes.filters";
import GetTurmaById from "../../../../services/sistema-aulas/turmas/get-turma";
import {Turma} from "../../../../models/turma";
import {Participante} from "../../../../models/participante";
import DeleteParticipanteDaTurma from "../../../../services/sistema-aulas/turmas/delete-participante";
import {useBlockUI} from "../../../../shared/services/block-ui-service";

export async function viewTurmaParticipantesLoader({params}: any) {
    return await GetTurmaById(params.id);
}

const TurmaParticipantesList = () => {
    const [participantes, setParticipantes] = useState<Participante[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [totalPages, setTotalPages] = useState();
    const [sessionUser, setSessionUser] = useState<SessionUser>();
    const [turma, setTurma] = useState<Turma>();
    const loader: any = useLoaderData();
    const blockUI = useBlockUI();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [filter, setFilter]
        = useState<TurmasParticipantesFilter>();
    // const turmasFilter: TurmasFilter = {
    //     nome: queryParams.get("nome"),
    //     descricao: queryParams.get("descricao"),
    //     clientId: queryParams.get("clientId"),
    //     treinamentoId: queryParams.get("treinamentoId"),
    //     userProfessorId: queryParams.get("userProfessorId"),
    //
    // };

    const filters = queryParams.values();
    useEffect(() => {
        blockUI?.setActive(loading)
    }, [loading]);
    useEffect(() => setTurma(loader), [])

    const navigate = useNavigate();

    useEffect(() => {
        async function updateSessionUser() {
            const user = await SessionStorage.getSession();
            setSessionUser(user);
        }

        updateSessionUser();
    }, []);

    useEffect(() => {
        listTurmaParticipantes();
    }, [filter, turma]);

    async function listTurmaParticipantes() {
        if (turma?.id) {
            const response = (await GetTurmaParticipantes(turma?.id, filter))?.data?.data;
            setTotalPages(response?.totalPages);
            setParticipantes(response?.items);
        }
    }

    async function deleteParticipante(participanteId: string) {
        try {
            const confirm = window.confirm(`Tem certeza que deseja apagar o participante ?`);

            if (confirm) {
                setLoading(true);

                if (participanteId && turma) {
                    await DeleteParticipanteDaTurma(turma?.id, participanteId);
                    toast.success(`Participante deletado com sucesso`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    await listTurmaParticipantes();
                }
            }
        } catch (error: any) {
            toast.warn(`Falha ao deletar participante`, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } finally {
            setLoading(false);
        }
    }


    return (
        <div>
            <Header as="h2" textAlign="center" color={'black'}>
                Turma {turma?.nome}
            </Header>
            <Header as="h3" textAlign="center" color={'black'}>
                Treinamento {turma?.treinamentoNome}
            </Header>

            <Table.Row>
                <Table.HeaderCell>
                    <Button content="Voltar para lista de turmas"
                            icon='arrow left'
                            onClick={() =>
                                navigate(`/treinamentos/turmas`)} grey/>
                </Table.HeaderCell>
                {sessionUser?.userData?.profile === 'Admin' && 'Professor' && (
                    <Table.HeaderCell>
                        <Button color="blue"
                                onClick={() => navigate(`/treinamentos/turmas/${turma?.id}/lista-participantes/create`)}>
                            <Icon name='plus'/> Participante
                        </Button>
                    </Table.HeaderCell>
                )}
            </Table.Row>
            <Header as="h4" textAlign="center">
                Lista de colaboradores/participantes
            </Header>
            {!!participantes && <Table color={"orange"}>
                <Table.Header>
                    <Table.Row>
                        {sessionUser?.userData?.profile === 'Admin' &&
                            <Table.HeaderCell>#</Table.HeaderCell>}
                        <Table.HeaderCell>#</Table.HeaderCell>
                        <Table.HeaderCell>Nome do participante</Table.HeaderCell>
                        <Table.HeaderCell>Setor</Table.HeaderCell>
                        <Table.HeaderCell>Cliente/Empresa</Table.HeaderCell>
                        <Table.HeaderCell>Data de criação</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {participantes?.map((participante, index) => (
                        <Table.Row key={participante.id}>
                            {sessionUser?.userData?.profile === 'Admin' && (
                                <Table.Cell>
                                    <Button.Group>
                                        <Button color="red" onClick={() => deleteParticipante(participante.id)}
                                                icon={'trash'}></Button>
                                    </Button.Group>
                                </Table.Cell>
                            )}
                            <Table.Cell>{`#${(filter?.Page ? filter.Page -1 : 0) * (filter?.PerPage ?? 20) + index + 1}`}</Table.Cell>
                            <Table.Cell>{`${participante?.nome}`}</Table.Cell>
                            <Table.Cell>{`${participante?.setor}`}</Table.Cell>
                            <Table.Cell>{participante?.clientName}</Table.Cell>
                            <Table.Cell>{participante?.formattedCreatedAt}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>}
            {!participantes &&
                <Header as={"h4"} textAlign={'center'} color={'grey'}>Nenhum colaborador adicionado</Header>}

            {!!participantes && <Pagination
                totalPages={totalPages ?? 0}
                defaultActivePage={filter?.Page ?? 1}
                onPageChange={(e, {activePage}) => {
                    setFilter({...filter, Page: activePage as number});
                }}
            ></Pagination>}
        </div>
    );
};

export default TurmaParticipantesList;
