import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {DashboardFilter} from "../../../../filters/dashboard-filter";
import {DashboardInfoCliente, DashboardTurma} from "../../../../models/dashboardInfoCliente";
import {Client} from "../../../../models/client";
import {Container, Form, Grid, Header, Icon, Message, Segment} from "semantic-ui-react";
import {GetAllClientsToDropdown} from "../../../../services/backoffice/clients/get-clients";
import {useBlockUI} from "../../../../shared/services/block-ui-service";
import GetDashboardByClient from "../../../../services/backoffice/dashboard-cliente/get-client-dashboard";
import GraficoParticipacaoGeral from '../components/porcentagem-participacao-geral';
import StringUtils from "../../../../shared/utils/string-utils";
import GraficoNaoParticipacaoGeral from "../components/porcentagem-nao-participacao-geral";

interface DashboardChartProps {
    data: {
        turmas: DashboardTurma[];
    };
}

const DashboardChart = () => {

    const [dashboardInfo, setDashboardInfo] = useState<DashboardInfoCliente>();
    const [loading, setLoading] = React.useState(false);
    const [client, setClient] = useState<Client>();
    const [clientId, setClientId] = useState<string>();
    const [clients, setClients] = useState<any[]>([]);

    const [error, setError] = React.useState(false);
    const mesParam = new Date().getMonth() + 1;
    const anoParam = new Date().getFullYear();
    const [filter, setFilter] = useState<DashboardFilter>();
    const blockUI = useBlockUI();

    useEffect(() => {
        blockUI?.setActive(loading)
    }, [blockUI, loading]);

    useEffect(() => {
        function ListClients() {
            GetAllClientsToDropdown().then((response) => {
                setClients(response);
            })
                .catch(error => {
                    console.warn(error);
                })
        }

        ListClients()
    }, [])

    const handleDropdownChange = useCallback((name: string, data: any) => {
        setDashboardInfo((prevColeta: any) => ({
            ...prevColeta,
            [name]: data.value,
        }));
    }, []);

    const getDashboardData = useCallback(async () => {
        try {
            setLoading(true);
            if (clientId && filter?.PeriodoInicio && filter?.PeriodoFim) {
                const response = await GetDashboardByClient(clientId, {
                    PeriodoInicio: filter.PeriodoInicio,
                    PeriodoFim: filter.PeriodoFim,
                });
                setDashboardInfo(response);
            }
        } catch (error: any) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }, [clientId, filter]);

    const handleClientChange = useCallback(
        async (e: any, data: any) => {
            const selectedClient = clients.find((c) => c.id === data.value);
            setClient(selectedClient);
            setClientId(data.value);


            if (selectedClient) {
                await getDashboardData();
            }
        },
        [clients, handleDropdownChange, getDashboardData]
    );

    useEffect(() => {
        if (filter?.PeriodoFim) {
            getDashboardData();
        }
    }, [filter?.PeriodoFim, getDashboardData]);

    const formatDate = (date:any) => {
        if (!date) return '';

        const d = new Date(date);
        let month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    };

    const temTurmasNaoParticipantes = useMemo(() => {
        return dashboardInfo?.turmas?.some(turma => turma.porcentagemNaoParticipantes > 40);
    }, [dashboardInfo]);



    return (
        <Container fluid>
            <Header as="h2" textAlign="center" color={'black'}>
                Dashboard Métricas de Treinamentos
            </Header>
            <Segment>
                <div style={{padding: 10}}>
                    <Form>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <Form.Select
                                    label={'Qual o Cliente?'}
                                    options={clients}
                                    placeholder='Selecione uma opção'
                                    value={clientId || ''}
                                    search
                                    selectOnBlur={false}
                                    onChange={(e, data) => handleClientChange(e, data)}
                                    required
                                />

                            </Form.Field>
                            <Form.Input
                                width={'4'}
                                type="datetime-local"
                                fluid
                                label="Período Inicial de aulas"
                                placeholder="Selecione a data"
                                value={filter?.PeriodoInicio}
                                onChange={(e: React.SyntheticEvent<HTMLElement>, data: any) =>
                                    setFilter({
                                        ...filter,
                                        PeriodoInicio: data.value,
                                    })
                                }
                            />

                            <Form.Input
                                width={'4'}
                                type="datetime-local"
                                fluid
                                label="Período Final de aulas"
                                placeholder="Selecione a data"
                                value={filter?.PeriodoFim}
                                onChange={(e: React.SyntheticEvent<HTMLElement>, data: any) =>
                                    setFilter({
                                        ...filter,
                                        PeriodoFim: data.value,
                                    })
                                }
                            />
                        </Form.Group>
                    </Form>
                    {(!filter?.ClientId || !filter?.PeriodoInicio || !filter?.PeriodoFim) && (
                        <Message
                            icon={'info'}
                            size={'small'}
                            info
                            content={"Preencha os filtros para continuar."}
                        />
                    )}
                </div>
            </Segment>
            {!!dashboardInfo?.clienteNome && (
                <Segment>
                    <div>
                        <Header as="h2" textAlign="left" color={'black'}> KPI'S DOS TREINAMENTOS </Header>
                        <Header as="h3" textAlign="left" color={'black'}>
                            Cliente: {dashboardInfo?.clienteNome}
                        </Header>
                        <Header as="h4" textAlign="left" color={'black'}>
                            Período Inicial filtrado: {StringUtils.formatToBrazilianDate(dashboardInfo?.inicioPeriodo?.toString())}
                        </Header>
                        <Header as="h4" textAlign="left" color={'black'}>
                            Período Final: {StringUtils.formatToBrazilianDate(dashboardInfo?.fimPeriodo?.toString())}
                        </Header>
                    </div>
                </Segment>
            )}

            {/*{!!dashboardInfo?.clienteNome && (*/}
            {/*    <Segment style={{ maxWidth: '400px', margin: 'auto', padding: '5px' }}>*/}
            {/*    <Grid>*/}
            {/*        <Grid.Row columns={3} style={{ height: '200px' }}> /!* Removido width: '380px' *!/*/}
            {/*            <Grid.Column width={4} style={{ backgroundColor: '#FFCC80', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>*/}
            {/*                /!* Exibição da porcentagem de participação geral *!/*/}
            {/*                <div>*/}
            {/*                    <h2>{dashboardInfo?.porcentagemParticipacaoGeral || 0}%</h2>*/}
            {/*                </div>*/}
            {/*            </Grid.Column>*/}
            {/*            <Grid.Column width={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>*/}
            {/*                /!* Ícone centralizado *!/*/}
            {/*                <Icon name="radio" size="large" />*/}
            {/*            </Grid.Column>*/}
            {/*            <Grid.Column width={8} style={{ backgroundColor: '#D3D3D3', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>*/}
            {/*                <p>Participação Geral</p>*/}
            {/*            </Grid.Column>*/}
            {/*        </Grid.Row>*/}
            {/*    </Grid>*/}
            {/*</Segment>*/}
            {/*    )}*/}

            {!!dashboardInfo?.clienteNome && (
            <Segment style={{ backgroundColor: '#f5f5f5', padding: '20px' }}>
                <Grid>
            {/* Linha para o Título */}
                <Grid.Row style={{ backgroundColor: '#13496a', padding: '10px' }}>
            <Grid.Column width={16}
                         style={{
                             display: 'flex',
                             flexDirection: 'column',
                             justifyContent: 'center',
                             alignItems: 'center',
                         }}>
                <p style={{
                    color: 'white',
                    fontSize: '1.5em',
                    fontWeight: 'bold',
                }}>
                    % PARTICIPAÇÃO POR TURMA
                </p>
            </Grid.Column>
            </Grid.Row>

            <Grid.Row>
            <Grid.Column width={16}
                     style={{
                         display: 'flex',
                         alignItems: 'center',
                         minHeight: '700px',  // Aumente esta altura conforme necessário

                     }}>
                <div style={{ width: '100%', overflowX: 'auto' }}>
                    <div style={{ minWidth: '800px' }}> {/* Ajuste a largura mínima conforme necessário */}
                        <GraficoParticipacaoGeral dashboardInfo={dashboardInfo}
                                              PeriodoInicio={formatDate(filter?.PeriodoInicio)}
                                              PeriodoFim={formatDate(filter?.PeriodoFim)}
                        ></GraficoParticipacaoGeral>
                </div>
             </div>
            </Grid.Column>
            </Grid.Row>
            </Grid>
            </Segment>
                )}

            {!!dashboardInfo?.clienteNome && (
                <Segment style={{ backgroundColor: '#f5f5f5', padding: '20px' }}>
                    <Grid>
                        {/* Linha para o Título */}
                        <Grid.Row style={{ backgroundColor: '#13496a', padding: '10px' }}>
                            <Grid.Column width={16}
                                         style={{
                                             display: 'flex',
                                             flexDirection: 'column',
                                             justifyContent: 'center',
                                             alignItems: 'center',
                                         }}>
                                <p style={{
                                    color: 'white',
                                    fontSize: '1.5em',
                                    fontWeight: 'bold',
                                }}>
                                    % NÃO PARTICIPAÇÃO POR TURMA (TURMAS COM PARTICIPAÇÃO MENOR QUE 60%)
                                </p>
                            </Grid.Column>
                        </Grid.Row>

                        {/* Linha para o Gráfico */}
                        <Grid.Row>
                            <Grid.Column width={16}
                                         style={{
                                             display: 'flex',
                                             alignItems: 'center',
                                             minHeight: '500px'  // Aumentado para dar mais espaço ao gráfico
                                         }}>
                                {temTurmasNaoParticipantes ? (
                                    <div style={{ width: '100%', overflowX: 'auto' }}>
                                        <div style={{ minWidth: '800px' }}>
                                            <GraficoNaoParticipacaoGeral dashboardInfo={dashboardInfo}
                                                                         PeriodoInicio={formatDate(filter?.PeriodoInicio)}
                                                                         PeriodoFim={formatDate(filter?.PeriodoFim)}
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <p>Não há dados para o período informado.</p>
                                    </div>
                                )}
                                {/*<div style={{ width: '100%', overflowX: 'auto' }}>*/}
                                {/*    <div style={{ minWidth: '800px' }}> /!* Ajuste a largura mínima conforme necessário *!/*/}
                                {/*        <GraficoNaoParticipacaoGeral dashboardInfo={dashboardInfo}*/}
                                {/*                                     PeriodoInicio={formatDate(filter?.PeriodoInicio)}*/}
                                {/*                                     PeriodoFim={formatDate(filter?.PeriodoFim)}*/}
                                {/*        ></GraficoNaoParticipacaoGeral>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            )}
        </Container>
    );
};

export default DashboardChart;
