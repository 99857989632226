import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {Button, Form, Header, Segment} from "semantic-ui-react";
import ChangePassword, {ChangePasswordRequest} from "../../services/password/change-password";

const ChangePasswordPage = () => {
    const [password, setPassword] = useState<Partial<ChangePasswordRequest>>();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const navigate = useNavigate();

    const changePassword = async () => {
        try {
            setLoading(true);
            if (password) {
                const result = await ChangePassword(password);
                if (result.success) {
                    toast.success('Senha atualizada com sucesso', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    await navigate('/backoffice/password/');
                }
            }
        } finally {
            setLoading(false);
        }
    };

    return(
        <div>
            <Header as="h3" textAlign="center" color={'black'}>
                Senha / Atualizar
            </Header>
            <Form size="large" onSubmit={changePassword}>
                <Segment stacked>
                    <Form.Field>
                        <Form.Input
                            fluid
                            label={"Nova senha"}
                            placeholder="Insira a nova senha"
                            name="password"
                            value={password?.password}
                            type='password'
                            onChange={(event) => setPassword({...password, password: event.target.value})}
                            required
                        />
                    </Form.Field>
                    <Form.Field>
                        <Form.Input
                            fluid
                            placeholder="Confirmação da senha"
                            label="Confirme a senha"
                            name="passwordConfirmation"
                            type='password'
                            value={password?.passwordConfirmation}
                            onChange={(event) => setPassword({...password, passwordConfirmation: event.target.value})}
                            required
                        />
                    </Form.Field>
                    <Form.Field>
                        <Form.Input
                            fluid
                            placeholder="Senha atual"
                            label="Senha atual para confirmar a mudança"
                            name="currentPassword"
                            type='password'
                            value={password?.currentPassword}
                            onChange={(event) => setPassword({...password, currentPassword: event.target.value})}
                            required
                        />
                    </Form.Field>
                    <Button fluid size='large' color={'blue'}>
                        Salvar
                    </Button>
                </Segment>
            </Form>
        </div>
    );

}

export default ChangePasswordPage;