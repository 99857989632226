import React, {useEffect, useState} from "react";
import {UserFilter} from "../../../filters/user-filter";
import {ProfessorFilter} from "../../../filters/professor-filter";
import {SessionUser} from "../../../models/session-user";
import SessionStorage from "../../../store/session";
import GetUsers from "../../../services/backoffice/users/get-users";
import GetProfessorUsers from "../../../services/sistema-aulas/professor-user/get-professores";
import ActiveUser from "../../../services/backoffice/users/active-user";
import DeactiveUser from "../../../services/backoffice/users/deactive-user";
import {toast} from "react-toastify";
import DeleteUser from "../../../services/backoffice/users/delete-users";
import DeleteProfessor from "../../../services/sistema-aulas/professor-user/delete-professor";
import {Button, Dropdown, Header, Icon, Input, Label, Message, Pagination, Segment, Table} from "semantic-ui-react";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {useBlockUI} from "../../../shared/services/block-ui-service";


const ProfessorUsersList = () => {
    const [professores, setProfessores] = useState<any[]>([])
    const [error, setError] = useState<string>()
    const [loading, setLoading] = useState<boolean>(false)
    const [filter, setFilter] = useState<ProfessorFilter>()
    const [totalPages, setTotalPages] = useState()
    const [showFilters, setShowFilters] = useState(false)
    const [sessionUser, setSessionUser] = useState<SessionUser>();
    const navigate = useNavigate();
    const blockUI = useBlockUI();
    useEffect(() => {
        blockUI?.setActive(loading)
    }, [loading]);

    useEffect(() => {
        async function updateSessionUser() {
            const user = await SessionStorage.getSession();
            setSessionUser(user)
        }

        updateSessionUser()
    }, [])

    async function listProfessores() {
        try {
            setLoading(true);
            const response = (await GetProfessorUsers(filter))?.data?.data;
            setTotalPages(response.totalPages);
            setProfessores(response.items);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }


    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };
    useEffect(() => {
        listProfessores();
    }, [filter]);

    const toggleActivationProfessor = async (user: any, activate?: boolean) => {
        try {
            const result = window.confirm(`Tem certeza que deseja ${user.isActive ? 'desativar' : 'ativar'} o Professor ${user.firstName} ${user.lastName}?`);
            if (!result) return;

            setLoading(true);

            if (user) {
                const result = !user.isActive ? await ActiveUser(user.id) : await DeactiveUser(user.id);
                if (result.success) {
                    toast.success((`O Professor foi ${user.isActive ? 'desativado' : 'ativado'} com sucesso`), {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setTimeout(() => {
                        setError('');
                    }, 3000);

                    await listProfessores();
                }
            }
            setLoading(false);
        } catch (error: any) {
            toast.warn(`Falha ao ${user.isActive ? 'desativar' : 'ativar'} professor`, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            setLoading(false);
            setError(error.message);
        }
    };


    async function deleteProfessor(user: any) {
        try {
            const confirm = window.confirm(`Tem certeza que deseja apagar o professor ${user.firstName} ${user.lastName}?`);

            if (confirm) {
                setLoading(true);

                if (user) {
                    await DeleteProfessor(user.id);
                    toast.success('professor deletado com sucesso', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    listProfessores();
                }

                setLoading(false);
            }
        } catch (error: any) {
            toast.warn(`Falha ao deletar professor`, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            setLoading(false);
            setError(error.message);
        }
    }



    return (
        <div>
            <Header as="h2" textAlign="center" color={'black'}>
                Usuários de Professores
            </Header>
            {error && <Message
                floating
                style={{
                    position: 'fixed',
                    top: 0,
                    left: '50%',
                    transform: 'translateX(10%)',
                    zIndex: 9999,
                }}
            >
                <p>{error}</p>
            </Message>}
            <Table.Row>
                <Table.HeaderCell>
                    {sessionUser?.userData?.profile === 'Admin' &&
                        <Button color="blue"
                                onClick={() => navigate('/treinamentos/professor/create/')}>

                            <Icon name='plus'/> Novo professor
                        </Button>}
                </Table.HeaderCell>
                <Table.HeaderCell colSpan="4">
                    <Button onClick={toggleFilters} primary>
                        <Icon name={showFilters ? 'hide' : 'unhide'}/>
                        {showFilters ? 'Ocultar filtros' : 'Exibir filtros'}
                    </Button>
                </Table.HeaderCell>
            </Table.Row>{showFilters && (
            <Segment>
                <div style={{padding: 10}}>
                    <div>
                        <Input
                            fluid
                            icon="search"
                            type="text"
                            placeholder="Nome do usuário"
                            value={filter?.Name ?? ''}
                            onChange={event =>
                                setFilter({...filter, Name: event.target?.value})
                            }
                        />
                    </div>
                    <br/>
                    <div>
                        <Input
                            fluid
                            icon="search"
                            type="text"
                            placeholder="E-mail"
                            value={filter?.Email ?? ''}
                            onChange={event =>
                                setFilter({...filter, Email: event.target?.value})
                            }
                        />
                    </div>
                </div>
            </Segment>
        )}

            <Table color={"orange"}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>#</Table.HeaderCell>
                        <Table.HeaderCell>Nome</Table.HeaderCell>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                        <Table.HeaderCell>Criado em</Table.HeaderCell>
                        <Table.HeaderCell>Ativo</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                {sessionUser?.userData?.profile === 'Admin' &&
                    <Table.Body>
                        {professores?.map((professor) => (
                            <Table.Row key={professor.id}>
                                <Table.Cell>
                                    <Dropdown item icon={'bars'}>
                                        <Dropdown.Menu>
                                            <Dropdown.Item
                                                color="blue"
                                                text={'Editar'}
                                                onClick={() => navigate('/treinamentos/professor/edit/' + professor.id)}
                                            >
                                                <Icon name='edit' color='blue' />
                                                Editar
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                text={'Deletar'}
                                                onClick={() => deleteProfessor(professor)}
                                            >
                                                <Icon name='trash' color={'red'} />
                                                Deletar
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                color={professor.isActive ? 'red' : 'green'}
                                                text={professor.isActive ? 'Desativar' : 'Ativar'}
                                                onClick={() => toggleActivationProfessor(professor)}
                                            >
                                                <Icon name={professor.isActive ? 'power off' : 'power'} color={professor.isActive ? 'red' : 'green'} />
                                                {professor.isActive ? 'Desativar' : 'Ativar'}
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Table.Cell>

                                <Table.Cell>{`${professor.firstName} ${professor?.lastName ?? ''}`}</Table.Cell>
                                <Table.Cell>{professor.email}</Table.Cell>
                                <Table.Cell>{moment(professor.createdAt).format('DD/MM/YY HH:mm')}</Table.Cell>
                                <Table.Cell>
                                    <Label key={professor.id}
                                           color={!professor.isActive ? 'red' : 'green'}>{professor.isActive ? 'Sim' : 'Não'}</Label>
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>}
            </Table>
            <Pagination totalPages={totalPages ?? 0}
                        defaultActivePage={filter?.Page ?? 1}
                        onPageChange={(e, {activePage}) => {
                            setFilter({...filter, Page: activePage as number})
                        }
                        }>
            </Pagination>
        </div>
    )

}

export default ProfessorUsersList
