import instance from "services";
import {UserFilter} from "../../../filters/user-filter";

export default async function GetUsers(filter?: UserFilter) : Promise<any> {
    return await instance.get('/v1/backoffice/backoffice-users', {
        params: filter
    })
}

export interface GetUsersResponseData {
    id: string
    firstName: string
    lastName: string
    email: string
    createdAt: string
    updatedAt: string
    isActive: boolean
}

export interface GetUsersResponse {
    success: boolean;
    data: GetUsersResponseData;
}
