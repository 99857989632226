export default class StringUtils {
    static toBrazilianDecimalFormat(value: number) {
        if (!value) return null
        return value.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2});
    }

    static formatarPlaca(str: string) {
        if (!str) return null
        const part1 = str.substr(0, 3);
        const part2 = str.substr(3);
        return `${part1}-${part2}`;
    }

    static formatToBRL(number: number) {
        if (!number) return null
        return number.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
    }

    static formatCPF(cpf: string | undefined) {
        if (!cpf) return '-------'
        const part1 = cpf?.substr(0, 3);
        const part2 = cpf?.substr(3, 3);
        const part3 = cpf?.substr(6, 3);
        const part4 = cpf?.substr(9, 2);
        return `${part1}.${part2}.${part3}-${part4}`;
    }

    static formatToBrazilianShortDateTime(dateString?: string) {
        if(!dateString) return ''
        const date = new Date(dateString?.replace('Z', ''));
        const options = {day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'};
        //@ts-ignore
        return date.toLocaleString('pt-BR', options);
    }

    static formatToBrazilianDate = (dateString?: string) => {
        if (!dateString) return '';

        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    };

    static capitalizeFirstLetter(inputString: string): string {
        return inputString.charAt(0).toUpperCase() + inputString.slice(1);
    }

    static formatPercentNumber(value: number): string {
        return `${value.toFixed(2).toString()} %`;
    }

    static getFirstTwoCharacters(inputString?: string): string {
        return inputString?.slice(0, 2) ?? '';
    }

    static getLastFourCharacters(inputString?: string): string {
        return inputString?.slice(-4) ?? '';
    }

    static formatCnpj(cnpj?: string) {
        try {
            if (!cnpj) return '--.---.---/----';

            const part1 = cnpj.substr(0, 2);
            const part2 = cnpj.substr(2, 3);
            const part3 = cnpj.substr(5, 3);
            const part4 = cnpj.substr(8, 4);
            const part5 = cnpj.substr(12, 2);

            return `${part1}.${part2}.${part3}/${part4}-${part5}`;
        } catch {
            return cnpj
        }
    }
    static jsonToQueryString(json: any) {
        return '?' + Object.keys(json).map(function(key) {
            return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
        }).join('&');
    }
}