import GetTurmaById from "../../../../../services/sistema-aulas/turmas/get-turma";
import React, {useEffect, useState} from "react";
import {Turma} from "../../../../../models/turma";
import {useLoaderData, useLocation, useNavigate, useParams} from "react-router-dom";
import {Button, Form, Grid, Header, Icon, Input, Message, Segment, Select, Table} from "semantic-ui-react";
import {
    GetTurmaRelatorioPresenca,
    GetTurmaRelatorioPresencaPdf
} from "../../../../../services/sistema-aulas/turmas/get-turma-relatorio";
import {RelatorioTurmaPresenca} from "../../../../../models/relatorioTurmaPresenca";
import moment from "moment";
import 'moment/locale/pt-br';
import StringUtils from "shared/utils/string-utils";
import {useBlockUI} from "../../../../../shared/services/block-ui-service";
import {EMes} from "../../../../../enums/eMes";
import {TurmaRelatorioPresencasFilter} from "../../../../../models/turmaRelatorioPresencasFilter";
import SessionStorage from "../../../../../store/session";


const TurmaRelatorioPresencaPage = () => {
    const [relatorio, setRelatorio] = useState<RelatorioTurmaPresenca>();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [turma, setTurma] = useState<Turma>();
    const navigate = useNavigate()
    const blockUI = useBlockUI();
    const [showFilters, setShowFilters] = useState<boolean>(true);
    const [profile, setProfile] = useState<string>();
    const { turmaId } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const mesParam = queryParams.get("mes") ?? (new Date().getMonth() + 1).toString();
    const anoParam = queryParams.get("ano") ?? new Date().getFullYear().toString();
    const [filter, setFilter] = useState<TurmaRelatorioPresencasFilter>({
        mes: parseInt(mesParam),
        ano: parseInt(anoParam)
    });

    useEffect(() => {
        async function updateSessionUser() {
            try {
                const user = await SessionStorage.getSession();
                if (user?.isLogged) {
                    setProfile(user.userData?.profile);
                }
            } catch (e) {
            }
        }

        updateSessionUser();
    }, []);

    useEffect(() => {
        if (turmaId) {
            const loadTurmaDetails = async () => {
                try {
                    setLoading(true);
                    const response = await GetTurmaById(turmaId);
                    setTurma(response);
                } catch (error:any) {
                    setError(error.message);
                } finally {
                    setLoading(false);
                }
            };
            loadTurmaDetails();
        }
    }, [turmaId]);

    useEffect(() => {
        blockUI?.setActive(loading)
    }, [loading]);


    useEffect(() => {
        const fetchRelatorio = async () => {
            setLoading(true);
            setError(undefined); // Resetar o erro antes de cada requisição

            try {
                if (turma?.id) {
                    const response = await GetTurmaRelatorioPresenca(turma.id, filter);
                    setRelatorio(response);
                }
            } catch (error) {
                setRelatorio(undefined);
                setError('Erro ao obter o relatório de presença.');
            } finally {
                setLoading(false);
            }
        };

        if (!!filter?.mes
            && filter.ano
            && filter?.mes >= 1 && filter?.mes <= 12
            && filter?.ano > 2000 && filter?.ano < 3000
        )

            fetchRelatorio();
    }, [filter, turma]);

    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };


    return (
        <div id={"reportId"} className={'printable'} style={{
            padding: 30
        }}>
            {!loading && <Button onClick={() => {
                if (!!turma?.id)
                    GetTurmaRelatorioPresencaPdf(turma?.id, filter)
                // const capture = document.querySelector('#reportId');
                // // @ts-ignore
                // html2canvas(capture).then(canvas => {
                //     const img = canvas.toDataURL('img/png');
                //     const doc = new jsPDF('l', 'pt', 'a4');
                //
                //     const componentWidth = doc.internal.pageSize.getWidth();
                //     const componentHeight = doc.internal.pageSize.getHeight();
                //     doc.addImage(img, 'PNG', 0, 0, componentWidth, componentHeight)
                //     doc.save(`${turma?.nome}-presenca-relatorio.pdf`)
                // })
            }}>
                <Icon name={'print'}></Icon>
                Imprimir</Button>}
            <Header as="h2" textAlign="center" color={'black'}>
                LISTA DE PRESENÇA<p></p>
            </Header>

            <Header as="h3" textAlign="center" color={'black'}>
                Cliente: {turma?.clientName} <p></p>
            </Header>
            <Header as="h3" textAlign="center" color={'black'}>
                Turma: {turma?.nome} <p></p>
            </Header>
            <br/>
            <Header as="a2"></Header>
            {!!relatorio && (
                <div>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={4}>
                                <span><b>TREINAMENTO:</b> {relatorio?.treinamentoNome}</span>
                            </Grid.Column>
                            <Grid.Column width={4}>
                    <span><b>MÊS DE INICIO DA TURMA:</b> {
                        StringUtils.capitalizeFirstLetter(
                            moment(relatorio?.turmaInicio?.toString()
                                , 'DD/MM/YY')
                                .locale('pt-br')
                                .format('MMMM')
                        )} {StringUtils.getLastFourCharacters(relatorio?.turmaInicio)} </span>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column width={4}>
                                <span><b>LOCAL/SETOR:</b> {relatorio?.setorNome}</span>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <span><b>PROFISSIONAL RESPONSÁVEL:</b> {relatorio?.professorNome}</span>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            )}

            <br/>

            <div className={'table-wrapper'}>
                <Table.HeaderCell>
                    <Button content="Voltar para lista de turmas"
                            icon='arrow left'
                            onClick={() =>
                                navigate('/treinamentos/turmas')} grey/>
                </Table.HeaderCell>
                <Table.HeaderCell>
                <Button onClick={toggleFilters} primary>
                    {showFilters ? 'Ocultar filtros' : 'Exibir filtros'}
                </Button>
                </Table.HeaderCell>


                {showFilters && (
                    <Segment>
                        <div style={{padding: 10}}>
                            <Form>
                                <Form.Group widths='equal'>
                                    <Form.Field
                                        width={'4'}
                                        control={Select}
                                        fluid
                                        clearable
                                        label="Mês"
                                        placeholder="Mês"
                                        options={EMes}
                                        value={filter?.mes}
                                        closeOnEscape
                                        search
                                        selectOnBlur={false}
                                        onChange={(e:any, data:any) => {
                                            setFilter({...filter, mes: Number(data.value as string)});
                                        }}


                                    />
                                    <Form.Field
                                        width={'4'}
                                        control={Input}
                                        fluid
                                        label="Ano"
                                        placeholer="2023"
                                        type="number"
                                        value={filter?.ano}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            setFilter({
                                                ...filter,
                                                ano: Number(e.target.value),
                                            })
                                        }
                                    />
                                </Form.Group>
                            </Form>

                            {
                                ((profile !== 'Operator')
                                    || !filter?.mes
                                    || !filter?.ano)
                                && (<Message
                                    icon={'info'}
                                    size={'small'}
                                    info
                                    content={"Preencha os filtros para continuar."}></Message>)
                            }

                        </div>
                    </Segment>
                )}

                {!!relatorio?.participantes &&
                    <Table color={"orange"} celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell colSpan={12}>
                                    <Header textAlign={'center'} as={'h4'}>{relatorio?.periodoDescricao}</Header>
                                </Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell>#</Table.HeaderCell>
                                <Table.HeaderCell>Nome do colaborador</Table.HeaderCell>
                                {relatorio.aulas.map((aula) =>
                                    <Table.HeaderCell>{aula.data?.substring(0,5)}</Table.HeaderCell>)}
                                <Table.HeaderCell>Total</Table.HeaderCell>
                                <Table.HeaderCell>Assinatura do colaborador</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {relatorio.participantes.map((participante, index) =>
                                <Table.Row>
                                    <Table.Cell  width={1} >{index+1}</Table.Cell>
                                    <Table.Cell width={1}>{participante.participanteNome}</Table.Cell>
                                    {participante.presencas.map(presenca =>
                                        <Table.Cell width={1}>
                                            <b>{presenca.presencaStatus ?? "N/A"}</b>
                                        </Table.Cell>
                                    )}
                                    <Table.Cell
                                        width={1}>{participante.presencaTotal} / {relatorio.aulasTotal}</Table.Cell>
                                    <Table.Cell textAlign={'center'}>{'                                '}</Table.Cell>
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>}
                <Segment>
                    <Header as="a4">
                        FISIOTERAPEUTA/EDUCADOR RESPONSÁVEL: <hr/>
                    </Header>
                </Segment>
                <Message
                    icon='info'
                    header='Legendas'
                    content='AT - ATESTADO MÉDICO / LM - LICENÇA MATERNIDADE / D – DESLIGADO / FE – FÉRIAS / FO – FOLGA / SE - SERVIÇO EXTERNO / HO – HOME OFFICE / SI – SERVIÇO INTERNO / HR – HORÁRIO'
                />
            </div>
        </div>
    );
};

export default TurmaRelatorioPresencaPage

