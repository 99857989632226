import instance from "../../index";

export default DeleteTurma

async function DeleteTurma(id: string) {
    const response = await instance.delete(`/v1/backoffice/turmas/${id}`)
    return response?.data;
}
export async function DeleteTurmaConfirm(id: string) {
    const response = await instance.delete(`/v1/backoffice/turmas/${id}/confirm`)
    return response?.data;
}