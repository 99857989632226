import {createBrowserRouter, createRoutesFromElements, Route} from "react-router-dom";
import Home from "../pages/backoffice/home";
import React from "react";
import MasterContainer from "../shared/components/container";
import AulasList from "../pages/ergo-activities/aulas";
import EditAulaPage from "../pages/ergo-activities/aulas/edit";
import AulaChamadaPage, {aulaChamadaLoader} from "../pages/ergo-activities/aulas/chamada";
import CrateAulaPage from "../pages/ergo-activities/aulas/create";
import TreinamentosList from "../pages/ergo-activities/treinamentos";
import EditTreinamentoPage from "../pages/ergo-activities/treinamentos/edit";
import CreateTreinamentoPage from "../pages/ergo-activities/treinamentos/create";
import TurmasList from "../pages/ergo-activities/turmas";
import EditTurmaPage from "../pages/ergo-activities/turmas/edit";
import CreateTurmaPage from "../pages/ergo-activities/turmas/create";
import TurmaParticipantesList, {
    viewTurmaParticipantesLoader
} from "../pages/ergo-activities/turmas/lista-participantes";
import CreateTurmaParticipantePage, {
    createTurmaParticipanteLoader
} from "../pages/ergo-activities/turmas/lista-participantes/create";
import TurmasRelatoriosList from "../pages/ergo-activities/relatorios/turma";
import TurmaRelatorioPage, {viewTurmaRelatorioLoader} from "../pages/ergo-activities/turmas/relatorio";
import TurmaRelatorioPresencaPage from "../pages/ergo-activities/turmas/relatorio/presenca";
import ListaRelatoriosTreinamentos from "../pages/ergo-activities/turmas/relatorio/relatorio-geral-cliente";

export const professorRoutes = (profile: string, isLogged: boolean, username: string) => createBrowserRouter(createRoutesFromElements(<>
    <Route path="home" element={<MasterContainer profile={profile} username={username}
                                                 isLogged={isLogged}><Home/></MasterContainer>}/>

    <Route path="treinamentos/aulas"
           element={<MasterContainer profile={profile} username={username}
                                     isLogged={isLogged}><AulasList/></MasterContainer>}
    />
    <Route
        path="treinamentos/aulas/edit/:aulaId"
        element={<MasterContainer profile={profile} username={username}
                                  isLogged={isLogged}><EditAulaPage/></MasterContainer>}
    />

    <Route
        path="treinamentos/aulas/chamada/:id"
        element={<MasterContainer profile={profile} username={username}
                                  isLogged={isLogged}><AulaChamadaPage/></MasterContainer>}
        loader={aulaChamadaLoader}
    />

    <Route path="treinamentos/aulas/create"
           element={<MasterContainer profile={profile} username={username}
                                     isLogged={isLogged}><CrateAulaPage/></MasterContainer>}/>


    <Route path="treinamentos/treinamentos"
           element={<MasterContainer profile={profile} username={username}
                                     isLogged={isLogged}><TreinamentosList/></MasterContainer>}/>
    <Route
        path="treinamentos/treinamentos/edit/:treinamentoId"
        element={<MasterContainer profile={profile} username={username}
                                  isLogged={isLogged}><EditTreinamentoPage/></MasterContainer>}
    />
    <Route path="treinamentos/treinamentos/create"
           element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><CreateTreinamentoPage/></MasterContainer>}/>

    <Route path="treinamentos/turmas/:id/relatorio"
           element={<MasterContainer profile={profile} username={username}
                                     isLogged={isLogged}><TurmaRelatorioPage/></MasterContainer>}
           loader={viewTurmaRelatorioLoader}/>

    <Route path="treinamentos/turmas/:turmaId/relatorio/presenca"
           element={<MasterContainer profile={profile} username={username}
                                     isLogged={isLogged}><TurmaRelatorioPresencaPage/></MasterContainer>}
    />

    <Route path="treinamentos/turmas"
           element={<MasterContainer profile={profile} username={username}
                                     isLogged={isLogged}><TurmasList/></MasterContainer>}/>
    <Route
        path="treinamentos/turmas/edit/:id"
        element={<MasterContainer profile={profile} username={username}
                                  isLogged={isLogged}><EditTurmaPage/></MasterContainer>}
    />
    <Route path="treinamentos/turmas/create"
           element={<MasterContainer profile={profile} username={username}
                                     isLogged={isLogged}><CreateTurmaPage/></MasterContainer>}
    />
    <Route path="treinamentos/turmas/:id/lista-participantes"
           element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><TurmaParticipantesList/></MasterContainer>}
           loader={viewTurmaParticipantesLoader}
    />
    <Route path="treinamentos/turmas/:id/lista-participantes/create"
           element={<MasterContainer profile={profile} username={username}
                                     isLogged={isLogged}><CreateTurmaParticipantePage/></MasterContainer>}
           loader={createTurmaParticipanteLoader}/>


    <Route path="treinamentos/turmas/lista-participantes"
           element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><TurmaParticipantesList/></MasterContainer>}
           loader={viewTurmaParticipantesLoader}
    />
    <Route path="treinamentos/turmas/lista-participantes/create"
           element={<MasterContainer profile={profile} username={username}
                                     isLogged={isLogged}><CreateTurmaParticipantePage/></MasterContainer>}/>


    <Route path="treinamentos/relatorios/turma"
           element={<MasterContainer profile={profile} username={username}
                                     isLogged={isLogged}><TurmasRelatoriosList/></MasterContainer>}
    />
    <Route path="/" element={<MasterContainer profile={profile} username={username}
                                              isLogged={isLogged}><Home/></MasterContainer>}/>

    <Route
        path="treinamentos/relatorios/clients/"
        element={<MasterContainer profile={profile} username={username}
                                  isLogged={isLogged}><ListaRelatoriosTreinamentos/></MasterContainer>}
    />
    <Route path="*" element={<MasterContainer profile={profile} username={username}
                                              isLogged={isLogged}><Home/></MasterContainer>}/>
</>));
