import instance from "../../index";

export default EditAula

async function EditAula(request: Partial<EditAulaRequest>, id: string | string[] | undefined): Promise<EditAulaResponse> {
    const response = await instance.put(`/v1/backoffice/aulas/${id}`, request);
    return response?.data?.data;
}

export interface EditAulaRequest {
    id: string
    nome: string
    turmaId: string
    estadoAula:number
    data:Date
}

export interface EditAulaResponseData {
    id: string
    nome: string
    turmaId: string
    estadoAula:number
    data:Date
}

export interface EditAulaResponse {
    data: EditAulaResponseData
    success: boolean
}