import React, {useEffect, useState} from 'react';
import {Button, Dropdown, Header, Icon, Input, Message, Pagination, Segment, Select, Table} from 'semantic-ui-react';
import {useNavigate} from "react-router-dom";
import SessionStorage from "../../../store/session";
import GetAllSoluctions from "../../../services/backoffice/solucoes-ergo/get-all-solucoes-ergo";
import {toast} from "react-toastify";
import {SolucoesErgoFilter} from "../../../filters/solucoes-ergo-filters";
import {useBlockUI} from "../../../shared/services/block-ui-service";
import DeleteSolucaoErgo from "../../../services/backoffice/solucoes-ergo/delete-solucao-ergo";
import {ETiposSolucoesErgonomicas} from "../../../enums/ePerguntaSolucoesCognitivas";
import {ETipoDeAcaoSolucaoErgo} from "../../../enums/eTipoDeAcaoSolucaoErgo";
import GetNormasRegulamentativasToDropdown
    from "../../../services/backoffice/normas-regulamentativas/get-normas-short-list";

const SolucoesErgoList = () => {
    const [solucoes, setSolucoes] = useState<any[]>([]);
    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const [filter, setFilter] = useState<SolucoesErgoFilter>()
    const [totalPages, setTotalPages] = useState()
    const [showFilters, setShowFilters] = useState(false);
    const [profile, setProfile] = useState<string>();
    const [normasRegulamentadoras, setNormasRegulamentadoras] = useState<any[]>([]);
    const blockUI = useBlockUI();
    useEffect(() => {
        blockUI?.setActive(loading)
    }, [loading]);

    useEffect(() => {
        async function updateSessionUser() {
            try {
                SessionStorage.getSession().then(user => {
                    if (!user) return
                    const isLogged = user?.isLogged;
                    if (!isLogged) return
                    setProfile(user?.userData?.profile)
                })
            } catch (e) {
            }
        }

        updateSessionUser()
    }, [SessionStorage])

    useEffect(() => {
        function ListNormasRegulamentadoras() {
            GetNormasRegulamentativasToDropdown().then((response) => {
                setNormasRegulamentadoras(response);
            })
                .catch(error => {
                    console.warn(error);
                })
        }

        ListNormasRegulamentadoras()
    }, [])

    function getNormasRegulamentadorasCodigo(normasRegulamentativas: string[]) {
        const codigosNormas = normasRegulamentativas.map((id) => {
            const norma = normasRegulamentadoras.find((item) => item.key === id);
            return norma ? norma.text : "";
        });
        return codigosNormas.join(", ");
    }


    function setFilterAndResetPage(newFilter: any) {
        setFilter({...newFilter, Page: 1})
    }

    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };

    useEffect(() => {
        listSolucoes();
    }, [filter]);

    function getTipoSolucaoText(key: any) {
        const tipoSolucao = ETiposSolucoesErgonomicas.find((item) => item.value === key);
        return tipoSolucao ? tipoSolucao.text : "";
    }

    function getTipoAcaoText(key: any) {
        const tipoAcaoSolucaoErgo = ETipoDeAcaoSolucaoErgo.find((item) => item.value === key);
        return tipoAcaoSolucaoErgo ? tipoAcaoSolucaoErgo.text : "";
    }


    async function listSolucoes() {
        try {
            setLoading(true);
            const response = (await GetAllSoluctions(filter))?.data?.data;
            setTotalPages(response.totalPages);
            setSolucoes(response.items);
        } catch (error) {
            console.error('Erro ao obter soluções:', error);
        } finally {
            setLoading(false);
        }
    }


    async function deleteSolucaoErgo(solucao: any) {
        try {
            const confirm = window.confirm(`Tem certeza que deseja apagar a solução ergonomica ?`);
            if (confirm) {
                setLoading(true);
                if (solucao) {
                    const result = await DeleteSolucaoErgo(solucao.id);
                    if (result?.success) {
                        toast.success('Solução Ergonômica deletada com sucesso', {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });

                        setError("deu bom");
                        setTimeout(() => {
                            setError('');
                        }, 3000);

                        await listSolucoes();
                    }
                }
                setLoading(false);
            }
        } catch (error: any) {
            setLoading(false);
            setError(error.message);
        }
    }


    return (
        <div>
            <Header as="h2" textAlign="center" color={'black'}>
                Soluções Ergonômicas
            </Header>
            {error && <Message
                floating
                style={{
                    position: 'fixed',
                    top: 0,
                    left: '50%',
                    transform: 'translateX(10%)',
                    zIndex: 9999,
                }}
            >
                <p>{error}</p>
            </Message>}

            <Table.Row>
                <Table.HeaderCell>
                    <Button color="blue"
                            onClick={() => navigate('/backoffice/solucoes-ergo/create/')}>

                        <Icon name='plus'/> Novo
                    </Button>
                </Table.HeaderCell>
                <Table.HeaderCell colSpan="4">
                    <Button onClick={toggleFilters} primary>
                        <Icon name={showFilters ? 'hide' : 'unhide'}/>
                        {showFilters ? 'Ocultar filtros' : 'Exibir filtros'}
                    </Button>
                </Table.HeaderCell>
            </Table.Row>

            {showFilters && (
                <Segment>
                    <div style={{padding: 10}}>
                        <div>
                            <Select
                                fluid
                                options={ETiposSolucoesErgonomicas}
                                placeholder="Tipo de Solução"
                                value={filter?.tiposSolucoesErgonomicas ?? ''}
                                onChange={(event, data) =>
                                    //@ts-ignore
                                    setFilterAndResetPage({...filter, tiposSolucoesErgonomicas: data.value})
                                }
                            />
                        </div>
                        <br/>
                        <div>
                            <Select clearable
                                    placeholder="Tipo de ação"
                                    fluid
                                    search
                                    selection
                                    options={ETipoDeAcaoSolucaoErgo}
                                    value={filter?.tipoDeAcaoSolucaoErgo ?? ''}
                                    onChange={(event, data) =>
                                        //@ts-ignore
                                        setFilterAndResetPage({...filter, treinamentoId: data.value})
                                    }
                            />
                        </div>
                        <br/>
                        <div>
                            <Input
                                fluid
                                icon="search"
                                type="text"
                                placeholder="Descrição"
                                value={filter?.descricao ?? ''}
                                onChange={event =>
                                    setFilterAndResetPage({...filter, descricao: event.target?.value})
                                }
                            />
                        </div>
                        <br/>
                        <div>
                            <Input
                                fluid
                                icon="search"
                                type="text"
                                placeholder="Norma Regulamentativa"
                                value={filter?.normasRegulamentativas ?? ''}
                                onChange={event =>
                                    setFilterAndResetPage({...filter, normasRegulamentativas: event.target?.value})
                                }
                            />
                        </div>
                    </div>
                </Segment>
            )}
            <Table color={"orange"}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>#</Table.HeaderCell>
                        <Table.HeaderCell>Tipo de ação</Table.HeaderCell>
                        <Table.HeaderCell>Classificação da Solução</Table.HeaderCell>
                        <Table.HeaderCell>Descrição</Table.HeaderCell>
                        <Table.HeaderCell>NR</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {solucoes?.map((solucao) => (
                        <Table.Row key={solucao.id}>
                            <Table.Cell>
                                <Dropdown item icon={'bars'}>
                                    <Dropdown.Menu>
                                        {profile !== 'Operator' && (
                                            <Dropdown.Item
                                                color="blue"
                                                text={'Editar'}
                                                onClick={() => navigate('/backoffice/solucoes-ergo/edit/' + solucao.id)}
                                            >
                                                <Icon name='edit' color='blue'/>
                                                Editar
                                            </Dropdown.Item>
                                        )}
                                        <Dropdown.Item
                                            text={'Deletar'}
                                            onClick={() => deleteSolucaoErgo(solucao)}
                                        >
                                            <Icon name='trash' color={'red'}/>
                                            Deletar
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            color="green"
                                            text={'Detalhes'}
                                            onClick={() => navigate('/backoffice/solucoes-ergo/details/' + solucao.id)}
                                        >
                                            <Icon name='search' color='green'/>
                                            Detalhes
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Table.Cell>

                            <Table.Cell>{getTipoAcaoText(solucao?.tipoDeAcaoSolucaoErgo)}</Table.Cell>
                            <Table.Cell>{getTipoSolucaoText(solucao.tipoSolucaoErgonomica)}</Table.Cell>
                            <Table.Cell>{solucao.descricao}</Table.Cell>
                            <Table.Cell>{getNormasRegulamentadorasCodigo(solucao?.normasRegulamentativas)}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <Pagination totalPages={totalPages ?? 0}
                        defaultActivePage={filter?.Page ?? 1}
                        onPageChange={(e, {activePage}) => {
                            setFilter({...filter, Page: activePage as number})
                        }
                        }></Pagination>
        </div>
    );
};

export default SolucoesErgoList;
