import React, { useMemo } from "react";
import { Bar } from "react-chartjs-2";
import { CategoryScale, Chart as ChartJS, LinearScale, BarElement, Title, Legend } from "chart.js";
import { TotaisDesconforto } from "../../../../models/dashboardInfoCliente";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Legend);

interface GraficoDesconfortoDoresCorpoProps {
    desconfortosDoresCorpo?: {
        [parteCorpo: string]: TotaisDesconforto | undefined;
    };
}

const GraficoDesconfortoDoresCorpo: React.FC<GraficoDesconfortoDoresCorpoProps> = ({
                                                                                       desconfortosDoresCorpo
                                                                                   }) => {
    const labels = useMemo(() => Object.keys(desconfortosDoresCorpo || {}), [desconfortosDoresCorpo]);

    const datasets = useMemo(() => {
        const categorias = ["leve", "moderado", "intenso", "naoSintoDores"];
        const categoriaLabels: { [key: string]: string } = {
            "naoSintoDores": "Não sinto dores",
            "leve": "Leve",
            "moderado": "Moderado",
            "intenso": "Intenso",
        };

        return categorias.map((categoria, index) => {
            const categoriaData = labels.map((parteCorpo) => {
                const totaisDesconforto = desconfortosDoresCorpo?.[parteCorpo] || { leve: 0, moderado: 0, intenso: 0, naoSintoDores: 0 };
                const valor = totaisDesconforto[categoria as keyof TotaisDesconforto];

                return valor > 0 ? valor : null; // Retorna null para valores zero
            });

            const categoriaColors: { [key: string]: string } = {
                "leve": "rgba(255,255,0,0.58)",          // Amarelo
                "moderado": "rgba(204, 204, 0, 0.8)",      // Amarelo escuro
                "intenso": "rgba(255, 0, 0, 0.8)",         // Vermelho
                "naoSintoDores": "rgba(0, 128, 0, 0.8)",   // Verde
            };

            return {
                label: categoriaLabels[categoria] || categoria,
                data: categoriaData,
                backgroundColor: categoriaColors[categoria] || `rgba(${index * 50}, ${255 - index * 50}, 0, 0.8)`,
                borderColor: "rgba(0,0,0,1)",
                borderWidth: 1,
            };
        });
    }, [desconfortosDoresCorpo, labels]);

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'right',
            },
            title: {
                display: true,
                text: "Desconfortos e Dores no Corpo",
            },
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
    };

    return (
        <div style={{ position: 'relative', width: '100%', height: '60vh' }}>
            {/*@ts-ignore*/}
            <Bar data={{ labels, datasets }} options={chartOptions} />
        </div>
    );
};

export default GraficoDesconfortoDoresCorpo;
