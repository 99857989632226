import instance from "../../index";

export default GetParticipanteById

async function GetParticipanteById(id: string): Promise<GetParticipanteByIdResponseData> {
    const response = await instance.get(`/v1/backoffice/participantes/${id}`).catch();
    return response?.data?.data;
}

export interface GetParticipanteByIdResponseData {
    id: string
    nome:string
    setor:string
    clientId:string
}

export interface GetParticipanteByIdResponse {
    data: GetParticipanteByIdResponseData
    success: boolean
}