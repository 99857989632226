import React, {useEffect, useState} from 'react';
import {Button, Form, Grid, Header, Icon, Segment, Table} from 'semantic-ui-react';
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import CreateOperator, {CreateOperatorRequest} from "../../../../services/backoffice/operators/create-operator";
import {GetAllClientsToDropdown} from "../../../../services/backoffice/clients/get-clients";
import {useBlockUI} from "../../../../shared/services/block-ui-service";

const CreateOperatorUserPage = () => {
    const [operator, setOperator] = useState<Partial<CreateOperatorRequest>>({});
    const navigate = useNavigate();
    const [clients, setClients] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const blockUI = useBlockUI();
    useEffect(() => {
        blockUI?.setActive(loading)
    }, [loading]);

    useEffect(() => {
        function ListClients() {
            GetAllClientsToDropdown().then((response) => {
                setClients(response);
            })
                .catch(error => {
                    console.warn(error);
                })
        }

        ListClients()
    }, [])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setOperator((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));
    };

    const handleDropdownChange = (name: string, data: any) => {
        setOperator((prevColeta: any) => ({
            ...prevColeta,
            [name]: data.value,
        }));
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            setLoading(true);
            await CreateOperator(operator);
            navigate('/backoffice/operators');
            toast.success('Operador criado com sucesso!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (error) {

            toast.warn('Falha ao registrar operador', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            console.warn(error);
        } finally {
            setLoading(false);
        }
    };


    return (
        <div>
            <Grid.Column mobile={16} tablet={8} computer={6}>
                <Header as="h2" textAlign="center" color={'black'}>
                    Operadores / Adicionar
                </Header>
                <Segment>
                    <Form onSubmit={handleSubmit}>
                        <Form.Input
                            label="Email"
                            name="email"
                            type="email"
                            placeholder="Email"
                            value={operator.email}
                            onChange={handleInputChange}
                            required
                        />
                        <Form.Input
                            label="Nome"
                            name="firstName"
                            type="text"
                            placeholder="Nome"
                            value={operator.firstName}
                            onChange={handleInputChange}
                            required
                        />
                        <Form.Input
                            label="Sobrenome"
                            name="lastName"
                            type="text"
                            placeholder="Sobrenome"
                            value={operator.lastName}
                            onChange={handleInputChange}
                            required
                        />
                        <Form.Input
                            label="Senha"
                            name="password"
                            type="password"
                            placeholder="Senha"
                            value={operator.password}
                            onChange={handleInputChange}
                            required
                        />
                        <Form.Input
                            label="Confirmação da senha"
                            name="passwordConfirmation"
                            type="password"
                            placeholder="Confirmação da senha"
                            value={operator.passwordConfirmation}
                            onChange={handleInputChange}
                            required
                        />


                        <Form.Field>
                            <Form.Select
                                label={'Clientes do Operador?'}
                                options={clients}
                                placeholder='Selecione uma ou mais opções'
                                value={operator?.clients}
                                onChange={(evento: any, data: any) => {
                                    setOperator({
                                        ...operator,
                                        clients: data.value,
                                    });
                                }}
                                multiple
                                clearable
                                search
                                required
                            />
                        </Form.Field>


                        <Table.Row>
                            <Table.HeaderCell>
                                <Form.Button content="Voltar"
                                             icon='arrow left'
                                             onClick={() => navigate('/backoffice/operators/')} grey/>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <Form.Button content="Adicionar"
                                             type={"submit"}
                                             icon='save'
                                             primary/>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Form>
                </Segment>
            </Grid.Column>
        </div>
    );
};

export default CreateOperatorUserPage;
