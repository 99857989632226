import instance from "../../index";

export default GetSolucaoErgoById

async function GetSolucaoErgoById(id: string): Promise<GetSolucaoErgoByIdResponse> {
    const response = await instance.get(`/v1/backoffice/solucoes-ergo/${id}`).catch();
    return response?.data?.data
}

export interface GetSolucaoErgoByIdResponseData {
    id: string
    tipoSolucaoErgonomica: number
    tipoDeAcaoSolucaoErgo: number,
    descricao: string
    normasRegulamentativas: string[]
    createdAt: Date
    updatedAt: Date
}

export interface GetSolucaoErgoByIdResponse {
    success: boolean;
    data: GetSolucaoErgoByIdResponseData;
}