import instance from "../../index";
import {Turma} from "../../../models/turma";
import {RelatorioTurmaClientesFilters} from "../../../filters/relatorio-turmas-filters";
import {RelatorioTurma} from "../../../models/clienteTurmasRelatorios";

export default GetTurmaById

export interface GetTurmaByIdRequest {
    id: string
}

async function GetTurmaById(id: string): Promise<Turma> {
    const response = await instance.get(`/v1/backoffice/turmas/${id}`)
    return response?.data?.data;
}

// export async function GetTurmasPorCliente(clientId: string): Promise<Turma[]> {
//     const response = await instance.get(`/v1/backoffice/turmas/cliente/${clientId}/turmas`);
//     return response?.data?.data;
// }



export async function GetRelatorioTurmasPorCliente(filters?: RelatorioTurmaClientesFilters): Promise<RelatorioTurma[]> {
    const response = await instance.get(`/v1/backoffice/relatorios/cliente/relatorio-geral`, { params: filters });
    return response?.data;
}

export interface GetTurmaByIdResponseData {
    id: string
    nome: string
    descricao: string
    dataInicio: Date
    clientId: string
    professorId: string
    estadoTurma: number

}

export interface GetTurmaByIdResponse {
    data: GetTurmaByIdResponseData
    success: boolean
}
