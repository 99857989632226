import React, {useEffect, useState} from 'react';
import {Container, Header} from 'semantic-ui-react';
import SessionStorage from "../../../store/session";

const Home = () => {
    const [userName, setUserName] = useState<string>();
    useEffect(() => {
        async function updateSessionUser() {
            try {
                SessionStorage.getSession().then(user => {
                    if (!user) return
                    const isLogged = user?.isLogged;
                    if (!isLogged) return
                    setUserName(user?.userData?.firstName + ' ' + (!!user?.userData?.lastName ? user?.userData?.lastName : ''))
                })
            } catch (e) {
            }
        }

        updateSessionUser()
    }, [SessionStorage])
    return (
        <div>
            <Container fluid>
                <Header as='h3' textAlign={'center'}>Bem-vindo(a), {userName}.</Header>
            </Container>
            <Container>
                <img alt="logo" src='/logo_slogan.png'/>
            </Container>
        </div>
    );
};

export default Home;
