import instance from "services";

export default CreateUser

async function CreateUser(request: Partial<CreateUserRequest>): Promise<CreateResponse> {
    const response = await instance.post(`/v1/backoffice/backoffice-users/`, request);
    return response.data.data;
}
export interface CreateUserRequest {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    passwordConfirmation: string;
    isActive: boolean;
}
export interface CreateResponseData {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    createdAt: Date;
    updatedAt: Date;
    isActive: boolean;
}

export interface CreateResponse {
    success: boolean;
    data: CreateResponseData;
}
