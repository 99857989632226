import instance from "../../index";

export default CreateNormaRegulamentativa

async function CreateNormaRegulamentativa(request: Partial<CreateNormaRegulamentativaRequest>): Promise<CreateNormaRegulamentativaResponse> {
    const response = await instance.post(`/v1/backoffice/normas-regulamentativas/`, request)
    return response?.data?.data;
}


export interface CreateNormaRegulamentativaRequest {
    codigo: string
    descricao: string
}

export interface CreateNormaRegulamentativaResponseData {
    id: string
    codigo: string
    descricao: string
}

export interface CreateNormaRegulamentativaResponse {
    success: boolean
    data: CreateNormaRegulamentativaResponseData
}