import instance from "../../index";

export default CreatePresenca

async function CreatePresenca(request: Presenca): Promise<CreatePresencaResponse> {
    const response = await instance.post('/v1/backoffice/presencas', request)
    
    return response?.data?.data;
}

export interface Presenca {
    statusPresenca: number | string
    aulaId: string
    participanteId: string
}

export interface CreatePresencaResponseData {
    id: string
    statusPresenca: number
    aulaId: string
    participanteId: string
    createdAt: Date
}

export interface CreatePresencaResponse {
    data: CreatePresencaResponseData
    success: boolean
}
