import instance from "services";

export default DeactiveClient

async function DeactiveClient(id:string ) : Promise<DeactiveClientResponse> {
    const response = await instance.put(`/v1/backoffice/clientes/deactive/${id}`);
    return response.data;
}
export interface DeactiveClientRequest {
    id: string;
}
export interface DeactiveClientResponseData {
    id: string
    createdAt: string
    updatedAt: string
    fantasyName: string
    businessName: string
    cnpj: string
    cnae: string
    address: string
    addressNumber: number
    addressComplement: string
    addressNeighborhood: string
    zipCode: number
    city: string
    state: string
    isActive: boolean

}

export interface DeactiveClientResponse {
    success: boolean;
    data: DeactiveClientResponseData;
}
