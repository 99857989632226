import instance from "../../index";
import {Aula} from "../../../models/aula";

export default GetAulaById

async function GetAulaById(id: string): Promise<Aula> {
    const response = await instance.get(`/v1/backoffice/aulas/${id}`).catch();
    return response?.data?.data;
}

export interface GetAulaByIdResponseData {
    id: string
    nome: string
    turmaId: string
    estadoAula:number
    data:Date
}

export interface GetAulaByIdResponse {
    data: GetAulaByIdResponseData
    success: boolean
}