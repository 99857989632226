import instance from "../../index";

export default async function PostTurmaParticipante(id?: string, participantesIds?: string[]): Promise<CreateTurmaParticipanteResponseData> {
    const response = await instance.post(`/v1/backoffice/turmas/${id}/participantes`,
        {
            participantesIds
        })
    return response?.data?.data;
}

export interface CreateTurmaParticipanteRequest {
    participantesIds: string
}

export interface CreateTurmaParticipanteResponse {
    id: string
    turmaId: string
    participanteId: string
}

export interface CreateTurmaParticipanteResponseData {
    success: boolean;
    data: CreateTurmaParticipanteResponse;
}
