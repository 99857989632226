import instance from "../index";

export default ChangePassword

async function ChangePassword(request: Partial<ChangePasswordRequest>) : Promise<ChangePasswordResponse> {
    const response = await instance.post('v1/identity/account/change-current-password', request);
    return response?.data;
}

export interface ChangePasswordRequest {
    password: string;
    passwordConfirmation: string;
    currentPassword: string;
}



export interface ChangePasswordResponse {
    success: boolean;
}