import instance from "../../index";

export default EditTreinamento

async function EditTreinamento(request: Partial<EditTreinamentoRequest>, id: string | string[] | undefined): Promise<EditTreinamentoResponse> {
    const response = await instance.put(`/v1/backoffice/treinamentos/${id}`, request);
    return response?.data?.data;
}

export interface EditTreinamentoRequest {
    id: string
    name: string
    description: string
}

export interface EditTreinamentoResponseData {
    id: string
    name: string
    description: string
}

export interface EditTreinamentoResponse {
    data: EditTreinamentoResponseData
    success: boolean

}