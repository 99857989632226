import instance from "../../index";

export default DeleteSolucaoErgo

async function DeleteSolucaoErgo(id: string) {
    const response = await instance.delete(`/v1/backoffice/solucoes-ergo/${id}`);
    return response?.data;
}

export interface DeleteSolucaoErgoResponseData {
    id: string;
    tipoSolucaoErgonomica: number,
    descricao: string,
    normasRegulamentativas: string[],
    createdAt: Date,
    updatedAt: Date,
}