import instance from "services";

import {RelatorioTurmasClienteFilter} from "../../../../../filters/relatorio-turmas-cliente-filter";

export async function GetRelatorioTreinamentosMensal(filter?: RelatorioTurmasClienteFilter): Promise<RelatorioTreinamentosMensalResponse> {
    const response = await instance.get<RelatorioTreinamentosMensalResponse>(`/v1/backoffice/relatorios/treinamentos/mensal`, { params: filter });
    return response.data;
}

export interface RelatorioTreinamentosMensalResponse {
    clienteNome: string
    mes: number
    ano: number
    participacaoPorcentagem: number
    turmas: TurmaRelatorioTreinamentosMensal[]
    periodoDescricao: string
    periodoInicio: string
    periodoFim: string
    aulasTotal: number
}

export interface TurmaRelatorioTreinamentosMensal {
    turmaNome: string
    turmaTreinamento: string
    turmaSetores: string
    turmaPorcentagemMedia: number
}
