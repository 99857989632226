import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";
import {Button, Container, Form, Grid, Header, Segment} from "semantic-ui-react";
import ResetPassword, {ResetPasswordRequest} from "../../../services/password/reset-password";

const ResetPasswordPage = () => {
    const [password, setPassword] = useState<Partial<ResetPasswordRequest>>();
    const { token:routeToken } = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const navigate = useNavigate();

    useEffect(() => {
        setPassword({ ...password, token: routeToken });
    }, [routeToken]);

    const resetPassword = async () => {
        try {
            setLoading(true);
            if (password) {
                const result = await ResetPassword(password);
                if (result.success) {
                    toast.success('Senha atualizada com sucesso', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    await navigate('/backoffice/password/');
                }
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div suppressContentEditableWarning
             style={{backgroundImage: 'url(/login_bg.png)', backgroundSize: 'cover'}}>
            <Grid textAlign='center' style={{height: '100vh'}} verticalAlign='middle'>
                <Grid.Column style={{maxWidth: 450}}>
                    <img src={'/logotipo_retangulo.png'} style={{
                        maxHeight: '5rem',
                        objectFit: 'contain',
                        marginTop: 10,
                        marginBottom: -20,
                        paddingBottom: 5,
                        alignSelf: 'center',
                        width: '100%',
                    }} alt={'fleetgame'}/>
                    <Header as="h3" textAlign="center" color={'black'}>
                        Crie uma nova senha
                    </Header>
                    <Form>
                        <Segment>
                            <Form.Field>
                                <Form.Input
                                    fluid
                                    placeholder="Insira seu e-mail cadastrado"
                                    name="email"
                                    value={password?.email}
                                    type='email'
                                    onChange={(event) => setPassword({...password, email: event.target.value})}
                                    required
                                />
                            </Form.Field>
                            <Form.Field>
                                <Form.Input
                                    fluid
                                    placeholder="Insira a nova senha"
                                    name="password"
                                    value={password?.password}
                                    type='password'
                                    onChange={(event) => setPassword({...password, password: event.target.value})}
                                    required
                                />
                            </Form.Field>
                            <Form.Field>
                                <Form.Input
                                    fluid
                                    placeholder="Confirmação da senha"
                                    name="passwordConfirmation"
                                    type='password'
                                    value={password?.passwordConfirmation}
                                    onChange={(event) => setPassword({...password, passwordConfirmation: event.target.value})}
                                    required
                                />
                            </Form.Field>
                            <Button fluid size='large' color={'blue'} onClick={resetPassword}>
                                Salvar
                            </Button>
                        </Segment>
                    </Form>
                </Grid.Column>
            </Grid>
        </div>
    );

}

export default ResetPasswordPage;