import instance from "../../index";

export default CreateRecorrencia

async function CreateRecorrencia(request: Partial<CreateRecorrenciaRequest>): Promise<CreateRecorrenciaResponse> {
    const response = await instance.post(`/v1/backoffice/recorrencias`, request)
    return response?.data?.data;
}

export interface CreateRecorrenciaRequest {
    nome: string
    dataInicio: Date
    dataFim: Date
    diasDaSemana: string[]
    turmaId: string
    hora: number
    minutos: number

}

export interface CreateRecorrenciaResponseData {
    nome: string
    dataInicio: Date
    dataFim: Date
    diasDaSemana: string[]
    turmaId: string
    hora: number
    minutos: number

}

export interface CreateRecorrenciaResponse {
    data: CreateRecorrenciaResponseData
    success: boolean
}