import React, {useEffect, useState} from 'react';
import {Button, Form, Header, Icon, Message, Segment, Table} from 'semantic-ui-react';
import PutUsers, {EditUserRequest} from 'services/backoffice/users/put-users';
import GetUser from 'services/backoffice/users/get-user';
import {useLoaderData, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {useBlockUI} from "../../../../shared/services/block-ui-service";

export async function editUserLoader({params}: any) {
    return await GetUser(params.id);
}

const EditBackofficeUserPage = () => {
    const [user, setUser] = useState<Partial<EditUserRequest>>();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const navigate = useNavigate();
    const loader: any = useLoaderData();
    const blockUI = useBlockUI();
    useEffect(()=> setUser(loader), [])
    useEffect(() => {
        blockUI?.setActive(loading)
    }, [loading]);


    const updateUser = async () => {
        try {
            setLoading(true);

            if (user) {
                const result = await PutUsers(user, user.id);

                if (result.success) {
                    navigate('/backoffice/users/');
                    toast.success('Usuário atualizado com sucesso', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });

                    setError('');
                }
            }
        } catch (error:any) {
            toast.warn('Falha ao atualizar usuário', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <Header as="h2" textAlign="center" color={'black'}>
                Usuários do backoffice / Editar
            </Header>
            <Form size="large" onSubmit={updateUser}>
                <Segment stacked>
                    <Form.Input
                        fluid
                        label="Nome"
                        placeholder="Nome"
                        name="firstName"
                        value={user?.firstName}
                        onChange={(event) => setUser({...user, firstName: event.target.value})}
                        required
                    />
                    <Form.Input
                        fluid
                        label="Sobrenome"
                        placeholder="Sobrenome"
                        name="lastName"
                        value={user?.lastName}
                        onChange={(event) => setUser({...user, lastName: event.target.value})}
                        required
                    />
                    <Form.Input
                        fluid
                        label="E-mail"
                        placeholder="E-mail"
                        name="email"
                        value={user?.email}
                        onChange={(event) => setUser({...user, email: event.target.value})}
                        required
                        type="email"
                    />
                    <Table.Row>
                        <Table.HeaderCell>
                            <Form.Button content="Voltar"
                                         icon='arrow left'
                                         onClick={() => navigate('/backoffice/users/')} grey/>
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            <Form.Button content="Atualizar"
                                         type={"submit"}
                                         icon='save'
                                         primary/>
                        </Table.HeaderCell>
                    </Table.Row>
                    {error && <Message negative>{error}</Message>}
                </Segment>
            </Form>
        </div>
    );
};

export default EditBackofficeUserPage;
