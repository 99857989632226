import GetProfessorById from "../../../../services/sistema-aulas/professor-user/get-professor-by-id";
import React, {useEffect, useState} from "react";
import {useLoaderData, useNavigate} from "react-router-dom";
import EditProfessor, {
    EditProfessorUserRequest
} from "../../../../services/sistema-aulas/professor-user/edit-professor";
import {toast} from "react-toastify";
import {Button, Form, Header, Icon, Message, Segment, Table} from "semantic-ui-react";
import {useBlockUI} from "../../../../shared/services/block-ui-service";

export async function editProfessorUserLoader({params}: any) {
    return await GetProfessorById(params.id);
}

const EditProfessorUserPage = () => {
    const [professor, setProfessor] = useState<Partial<EditProfessorUserRequest>>();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const navigate = useNavigate();
    const loader: any = useLoaderData();
    const blockUI = useBlockUI();
    useEffect(()=> setProfessor(loader), [])
    useEffect(() => {
        blockUI?.setActive(loading)
    }, [loading]);

    const updateProfessor = async () => {
        try {
            setLoading(true);
            if (professor) {
                const result = await EditProfessor(professor, professor.id);
                toast.success('Professor atualizado com sucesso', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate('/treinamentos/professor/');
            }
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };


    return (
        <div>
            <Header as="h2" textAlign="center" color={'black'}>
                Usuário de Professor / Editar
            </Header>
            <Form size="large" onSubmit={updateProfessor}>
                <Segment stacked>
                    <Form.Input
                        fluid
                        label="Nome"
                        placeholder="Nome"
                        name="firstName"
                        value={professor?.firstName}
                        onChange={(event) => setProfessor({...professor, firstName: event.target.value})}
                    />
                    <Form.Input
                        fluid
                        label="Sobrenome"
                        placeholder="Sobrenome"
                        name="lastName"
                        value={professor?.lastName}
                        onChange={(event) => setProfessor({...professor, lastName: event.target.value})}
                    />
                    <Form.Input
                        fluid
                        label="E-mail"
                        placeholder="E-mail"
                        name="email"
                        value={professor?.email}
                        onChange={(event) => setProfessor({...professor, email: event.target.value})}
                        type="email"
                    />
                    <Table.Row>
                        <Table.HeaderCell>
                            <Form.Button content="Voltar"
                                         icon='arrow left'
                                         onClick={() => navigate('/treinamentos/professor/')} grey/>
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            <Form.Button content="Atualizar"
                                         type={"submit"}
                                         icon='save'
                                         primary/>
                        </Table.HeaderCell>
                    </Table.Row>
                    {error && <Message negative>{error}</Message>}
                </Segment>
            </Form>
        </div>
    )
}

export default EditProfessorUserPage;
