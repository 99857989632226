export const EPerguntaPredominanciaDaPostura = [
    { key: '0', text: 'Trabalho na maior parte do tempo na postura sentado com utilização dos membros superiores na digitação e no mouse;', value: 'MaiorParteSentadoUtilizandoMembrosSuperioresDigitacao' },

    { key: '1', text: 'Trabalho na maior parte do tempo na postura sentado, com utilização dos membros superiores na digitação e no mouse e com pausas livres;', value: 'MaiorParteSentadoUtilizandoMembrosSuperioresDigitacaoPausasLivres' },

    { key: '2', text: 'Trabalho na maior parte do tempo na postura sentado, com manuseio de cargas e com pausas livres;', value: 'MaiorParteSentadoManuseioDeCargasPausasLivres' },

    { key: '3', text: 'Trabalho na maior parte do tempo na postura em pé com deslocamento;', value: 'MaiorParteEmPeComDeslocamento' },

    { key: '4', text: 'Trabalho na maior parte do tempo na postura em pé com deslocamento e pausas livres;', value: 'MaiorParteEmPeComDeslocamentoPausasLivres' },

    { key: '5', text: 'Trabalho na maior parte do tempo na postura em pé com manuseio de carga e pausas livres;', value: 'MaiorParteEmPeComManuseioDeCargaPausasLivres' },

    { key: '6', text: 'Trabalho na maior parte do tempo na postura em pé com manuseio de carga;', value: 'MaiorParteEmPeComManuseioDeCarga' },

]