import GetTurmaById from "../../../../services/sistema-aulas/turmas/get-turma";
import React, {useEffect, useState} from "react";
import {useLoaderData, useLocation, useNavigate, useParams} from "react-router-dom";
import EditTurma, {EditTurmaRequest} from "../../../../services/sistema-aulas/turmas/edit-turma";
import {toast} from "react-toastify";
import {Button, Form, Header, Icon, Message, Segment, Table} from "semantic-ui-react";
import {
    GetAllProfessorUsersToDropdown,
    GetProfessoresResponse
} from "../../../../services/sistema-aulas/professor-user/get-professores";
import {GetAllClientsToDropdown, GetClientsResponse} from "../../../../services/backoffice/clients/get-clients";
import {EEstadoAula} from "../../../../enums/eEstadoAula";
import {GetTreinamentosSimplified} from "../../../../services/sistema-aulas/treinamentos/get-treinamentos";
import {useBlockUI} from "../../../../shared/services/block-ui-service";
import {EEstadoTurma} from "../../../../enums/eEstadoTurma";
import GetAulaById from "../../../../services/sistema-aulas/aulas/get-aula";


const EditTurmaPage = () => {
    const [turma, setTurma] = useState<Partial<EditTurmaRequest>>();
    const [professores, setProfessores] = useState<GetProfessoresResponse[]>([]);
    const [clients, setClients] = useState<GetClientsResponse[]>([]);
    const [treinamentos, setTreinamentos] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const navigate = useNavigate();
    const blockUI = useBlockUI();
    const { turmaId } = useParams();


    useEffect(() => {
        if (turmaId) {
            const loadTurmaDetails = async () => {
                try {
                    setLoading(true);
                    const response = await GetTurmaById(turmaId);
                    setTurma(response);
                } catch (error:any) {
                    setError(error.message);
                } finally {
                    setLoading(false);
                }
            };
            loadTurmaDetails();
        }
    }, [turmaId]);

    useEffect(() => {
        blockUI?.setActive(loading)
    }, [loading]);

    const updateTurma = async () => {
        try {
            setLoading(true);

            if (turma) {
                const result = await EditTurma(turma, turma.id);
                if (result.data) {
                    navigate('/treinamentos/turmas/');
                    toast.success('Turma atualizada com sucesso', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            }
        } catch (error: any) {
            console.warn(error);
        } finally {
            setLoading(false);
        }
    };


    const handleDropdownChange = (name: string, data: any) => {
        setTurma((prevTurma) => ({
            ...prevTurma,
            [name]: data.value,
        }));
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setTurma((prevTurma) => ({
            ...prevTurma,
            [name]: value,
        }));
    };

    useEffect(() => {
        function ListProfessores() {
            GetAllProfessorUsersToDropdown()
                .then((response) => {
                    const professoresData = response.items.map((item: any) => ({
                        key: item.id, // substitua "id" pelo nome da propriedade que contém o ID do professor
                        text: item.firstName + ' ' + item.lastName,
                        value: item.id,
                        // substitua "nome" pelo nome da propriedade que contém o nome do professor
                        // adicione outras propriedades, se necessário
                    }));
                    setProfessores(professoresData);
                })
                .catch((error) => {
                    console.warn(error);
                });
        }

        ListProfessores();
    }, []);
    useEffect(() => {
        function ListTreinamentos() {
            GetTreinamentosSimplified()
                .then((response) => {
                    setTreinamentos(response?.data?.data);
                })
                .catch((error) => {
                    console.warn(error);
                });
        }

        ListTreinamentos();
    }, []);


    useEffect(() => {
        function ListClientes() {
            GetAllClientsToDropdown().then((response) => {
                setClients(response);
            })
                .catch(error => {
                    console.warn(error);
                })
        }

        ListClientes()
    }, [])

    const handleClientIdChange = (e: any, data: any) => {
        const selectedValue = data.value;
        setTurma(prevTurma => ({...prevTurma, clientId: selectedValue}));
    };

    const handleProfessorIdChange = (e: any, data: any) => {
        const selectedValue = data.value;
        setTurma(prevTurma => ({...prevTurma, professorId: selectedValue}));
    };


    return (
        <div>
            <Header as="h2" textAlign="center" color={'black'}>
                Turma / Editar
            </Header>
            <Form size="large" onSubmit={updateTurma}>
                <Segment stacked>
                    <Form.Input
                        fluid
                        label="Nome da turma"
                        placeholder="Nome"
                        name="nome"
                        value={turma?.nome}
                        onChange={(event) => setTurma({...turma, nome: event.target.value})}
                        required
                    />
                    <Form.Input
                        fluid
                        label="Descrição"
                        placeholder="Descrição"
                        name="lastName"
                        value={turma?.descricao}
                        onChange={(event) => setTurma({...turma, descricao: event.target.value})}
                        required
                    />
                    <Form.Select
                        label={'Cliente ao qual a turma pertence'}
                        options={clients}
                        placeholder='Selecione uma opção'
                        value={turma?.clientId || ''}
                        required
                        onChange={handleClientIdChange}
                    />
                    <Form.Select
                        label={'Treinamento que será realizado'}
                        options={treinamentos}
                        placeholder='Selecione uma opção'
                        value={turma?.treinamentoId || ''}
                        required
                        onChange={(e: any, data: any) => {
                            const selectedValue = data.value;
                            setTurma(prevTurma =>
                                ({ ...prevTurma, treinamentoId: selectedValue }));
                        }}
                    />
                    <Form.Select
                        label={'Professor'}
                        options={professores}
                        placeholder='Selecione uma opção'
                        value={turma?.professorId || ''}
                        required
                        onChange={handleProfessorIdChange}
                    />

                    <Form.Select
                        label={'Qual o estado da turma?'}
                        options={EEstadoTurma}
                        placeholder='Selecione uma opção'
                        value={turma?.estadoTurma || ''}
                        required
                        onChange={(e: any, data: any) => handleDropdownChange('estadoTurma', data)}

                    />
                    <Form.Input
                        label="Data de Início"
                        name="dataInicio"
                        type="datetime-local"
                        placeholder="Data de Início"
                        value={turma?.dataInicio?.toString()?.replace("Z","")}
                        onChange={(e) => {
                            if(e.target.value.length !== 16) return
                            handleInputChange(e)
                        }}                    />
                    {/*//TODO: Ver o porque não está salvando no formato enviado;*/}

                    <Table.Row>
                        <Table.HeaderCell>
                            <Form.Button content="Voltar"
                                         icon='arrow left'
                                         onClick={() => navigate('/treinamentos/turmas/')} grey/>
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            <Form.Button content="Atualizar"
                                         type={"submit"}
                                         icon='save'
                                         primary/>
                        </Table.HeaderCell>
                    </Table.Row>
                    {error && <Message negative>{error}</Message>}
                </Segment>
            </Form>
        </div>
    );
}

export default EditTurmaPage;
