export const EClassificacaoAvaliacaoErgonomica = [
    { key: '0', text: 'Baixa - Sobrecarga física dentro dos limites de tolerância tecidual. Não é suficiente para o ' +
            'desenvolvimento de lesões osteomusculares. As ações são voltadas ao conforto e ao processo de melhoria contínua produtiva \'', value: 'Baixa' },
    { key: '1', text: 'MODERADO - Sobrecarga física suficiente para ultrapassar os limites de adaptação e tolerância\' +\n' +
            '            \' tecidual. Pode levar a lesões osteomusculares de menor gravidade, ou mesmo agravar lesões pré-existentes\'\n' +
            '        ', value: 'Moderado' },
    { key: '2', text: 'ALTO - Sobrecarga física suficiente para ultrapassar os limites de adaptação e tolerância ' +
            'tecidual, levando a lesão e incapacidade funcional.', value: 'Alto' },
    { key: '3', text: 'MUITO ALTO - Pode levar ao desenvolvimento de Distúrbios Osteomusculares Permanentes,' +
            ' bem como incapacidade funcional mais graves.', value: 'MuitoAlto' },
    { key: '4', text: 'ALTÍSSIMO - Situação não permitida para o trabalho manual. Valores de limites de tolerância ' +
            'não conhecidos e/ou não permitidos. Lesões graves e permanentes.', value: 'Altissimo' },
]
