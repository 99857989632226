import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {Form, Header, Message, Segment, Table} from "semantic-ui-react";
import EditTreinamento, {
    EditTreinamentoRequest
} from "../../../../services/sistema-aulas/treinamentos/edit-treinamento";
import GetTreinamentoById from "../../../../services/sistema-aulas/treinamentos/get-treinamento";
import {useBlockUI} from "../../../../shared/services/block-ui-service";

const EditTreinamentoPage = () => {
    const [treinamento, setTreinamento] = useState<Partial<EditTreinamentoRequest>>();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const navigate = useNavigate();
    const blockUI = useBlockUI();
    const { treinamentoId } = useParams();
    useEffect(() => {
        if (treinamentoId) {
            const loadTreinamentoDetails = async () => {
                try {
                    setLoading(true);
                    const response = await GetTreinamentoById(treinamentoId);
                    setTreinamento(response);
                } catch (error:any) {
                    setError(error.message);
                } finally {
                    setLoading(false);
                }
            };
            loadTreinamentoDetails();
        }
    }, [treinamentoId]);

    useEffect(() => {
        blockUI?.setActive(loading)
    }, [loading]);


    const updateTreinamento = async () => {
        try {
            setLoading(true);
            if (treinamento) {
                const result = await EditTreinamento(treinamento, treinamento.id);

                toast.success('Treinamento atualizado com sucesso', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate('/treinamentos/treinamentos/');
            }
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };


    return (
        <div>
            <Header as="h2" textAlign="center" color={'black'}>
                Treinamento / Editar
            </Header>
            <Form size="large" onSubmit={updateTreinamento}>
                <Segment stacked>
                    <Form.Input
                        fluid
                        label="Nome"
                        placeholder="Nome"
                        name="nome"
                        value={treinamento?.name}
                        onChange={(event) => setTreinamento({...treinamento, name: event.target.value})}
                    />
                    <Form.Input
                        fluid
                        label="Descrição"
                        placeholder="Descrição"
                        name="lastName"
                        value={treinamento?.description}
                        onChange={(event) => setTreinamento({...treinamento, description: event.target.value})}
                        required
                    />

                    <Table.Row>
                        <Table.HeaderCell>
                            <Form.Button content="Voltar"
                                         icon='arrow left'
                                         onClick={() => navigate('/treinamentos/treinamentos/')} grey/>
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            <Form.Button content="Atualizar"
                                         type={"submit"}
                                         icon='save'
                                         primary/>
                        </Table.HeaderCell>
                    </Table.Row>
                    {error && <Message negative>{error}</Message>}
                </Segment>
            </Form>
        </div>
    );
}

export default EditTreinamentoPage;
