export const ApiConfig = {
    API_ENVIRONMENT: (process.env.NODE_ENV === 'production' ? 'PROD' : 'DEV'),
    API_URL:(process.env.NODE_ENV === 'production' ? 'https://superativa-api.azurewebsites.net' : 'http://localhost:5000'),
    REFRESH_TOKEN_ENDPOINT: 'connect/refresh-token',
    CONNECT_ENDPOINT: 'connect/token',
}

export const ApiErrors = {
    BAD_REQUEST: '[400] Verifique os dados informados',
    NOT_FOUND: 'Não encontrado.',
    UNSUPPORTED_MEDIA_TYPE: 'Recurso usado de maneira incorreta.',
    UNPROCESSABLE_ENTITY:
        'Algum campo foi preenchido incorretamente ou está vazio, tente novamente',
    INTERNAL_SERVER_ERROR: 'Ops, algo deu errado! Tente novamente mais tarde.',
    FORBIDDEN: '[403] Você não possui acesso à esse recurso',
    DEFAULT_ERROR: 'Erro no serviço, tente novamente mais tarde.',
    INVALID_METHOD: 'Verifique o método utilizado na requisição.'
}

export const Colors = {
    PRIMARY: 'orange',
    ALTERNATIVE: 'blue',
    SUCCESS: 'darkgreen',
    ERROR: 'darkred',
    WARNING: 'orange',
}
