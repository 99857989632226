import React, {ChangeEvent, useEffect, useState} from "react";
import CreateTurma, {CreateTurmaRequest} from "../../../../services/sistema-aulas/turmas/create-turma";
import {toast} from "react-toastify";
import {Form, Grid, Header, Segment, Table} from "semantic-ui-react";
import {useNavigate} from "react-router-dom";
import {GetAllClientsToDropdown} from "../../../../services/backoffice/clients/get-clients";
import {GetAllProfessorUsersToDropdown} from "../../../../services/sistema-aulas/professor-user/get-professores";
import {EEstadoAula} from "../../../../enums/eEstadoAula";
import {GetTreinamentosSimplified} from "../../../../services/sistema-aulas/treinamentos/get-treinamentos";
import {useBlockUI} from "../../../../shared/services/block-ui-service";
import {EEstadoTurma} from "../../../../enums/eEstadoTurma";


const CreateTurmaPage = () => {
    const [turma, setTurma] = useState<Partial<CreateTurmaRequest>>({})
    const [clientes, setClients] = useState<any[]>([])
    const [professores, setProfessores] = useState<any[]>([])
    const [treinamentos, setTreinamentos] = useState<any[]>([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const blockUI = useBlockUI();
    useEffect(() => {
        blockUI?.setActive(loading)
    }, [loading]);
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setTurma((prevTurma) => ({
            ...prevTurma,
            [name]: value,
        }));
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);

            await CreateTurma(turma);
            navigate('/treinamentos/turmas/');
            toast.success('Turma criada com sucesso', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (error) {
            console.warn(error);
        } finally {
            setLoading(false);
        }
    };


    const handleDropdownChange = (name: string, data: any) => {
        setTurma((prevTurma) => ({
            ...prevTurma,
            [name]: data.value,
        }));
    };

    useEffect(() => {
        function ListProfessores() {
            GetAllProfessorUsersToDropdown()
                .then((response) => {
                    const professoresData = response.items.map((item:any) => ({
                        key: item.id, // substitua "id" pelo nome da propriedade que contém o ID do professor
                        text: item.firstName + ' ' + item.lastName,
                        value: item.id,
                        // substitua "nome" pelo nome da propriedade que contém o nome do professor
                        // adicione outras propriedades, se necessário
                    }));
                    setProfessores(professoresData);
                })
                .catch((error) => {
                    console.warn(error);
                });
        }

        ListProfessores();
    }, []);
    useEffect(() => {
        function ListTreinamentos() {
            GetTreinamentosSimplified()
                .then((response) => {
                    setTreinamentos(response?.data?.data);
                })
                .catch((error) => {
                    console.warn(error);
                });
        }

        ListTreinamentos();
    }, []);


    useEffect(() => {
        function ListClientes() {
            GetAllClientsToDropdown().then((response) => {
                setClients(response);
            })
                .catch(error => {
                    console.warn(error);
                })
        }

        ListClientes()
    }, [])


    return (
        <div>
            <Grid.Column mobile={16} tablet={8} computer={6}>
                <Header as="h2" textAlign="center" color={'black'}>
                    Turmas / Adicionar
                </Header>
                <Segment>
                    <Form onSubmit={handleSubmit}>
                        <Form.Input
                            label="Nome"
                            name="nome"
                            placeholder="Nome"
                            value={turma?.nome}
                            onChange={handleInputChange}
                        />
                        <Form.Input
                            label="Descrição"
                            name="descricao"
                            placeholder="Descrição"
                            value={turma?.descricao}
                            onChange={handleInputChange}
                        />
                        <Form.Select
                            label={'Cliente ao qual a turma pertence'}
                            options={clientes}
                            placeholder='Selecione uma opção'
                            value={turma?.clientId || ''}
                            required
                            search
                            clearable
                            onChange={(e: any, data: any) => {
                                const selectedValue = data.value;
                                setTurma(prevTurma => ({ ...prevTurma, clientId: selectedValue }));
                            }}
                        />
                        <Form.Select
                            label={'Treinamento que será realizado'}
                            options={treinamentos}
                            placeholder='Selecione uma opção'
                            value={turma?.treinamentoId || ''}
                            required
                            search
                            clearable
                            onChange={(e: any, data: any) => {
                                const selectedValue = data.value;
                                setTurma(prevTurma =>
                                    ({ ...prevTurma, treinamentoId: selectedValue }));
                            }}
                        />
                        <Form.Select
                            label={'Professor da turma'}
                            options={professores}
                            placeholder='Selecione uma opção'
                            value={turma?.professorId || ''}
                            required
                            search
                            clearable
                            onChange={(e: any, data: any) => {
                                const selectedValue = data.value;
                                setTurma(prevTurma => ({ ...prevTurma, professorId: selectedValue }));
                            }}
                        />
                        <Form.Select
                            label={'Qual o estado da turma?'}
                            options={EEstadoTurma}
                            placeholder='Selecione uma opção'
                            value={turma?.estadoTurma || ''}
                            required
                            onChange={(e: any, data: any) => handleDropdownChange('estadoTurma', data)}

                        />
                        <Form.Input
                            required
                            label="Data de Início"
                            name="dataInicio"
                            type="datetime-local"
                            placeholder="Data de Início"
                            value={turma?.dataInicio?.toString()?.replace("Z","")}
                            onChange={(e) => {
                                if(e.target.value.length !== 16) return
                                handleInputChange(e)
                            }}
                        />

                        <Table.Row>
                            <Table.HeaderCell>
                                <Form.Button content="Voltar"
                                             icon='arrow left'
                                             onClick={() => navigate('/treinamentos/turmas/')} grey/>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <Form.Button content="Adicionar"
                                             type={"submit"}
                                             icon='save'
                                             primary/>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Form>
                </Segment>
            </Grid.Column>
        </div>
    )
}

export default CreateTurmaPage;
