import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useBlockUI} from "../../../shared/services/block-ui-service";
import {NormasRegulamentativasFilters} from "../../../filters/normas-regulamentativas-filters";
import SessionStorage from "../../../store/session";
import GetNormasRetulamentativasPagedList
    from "../../../services/backoffice/normas-regulamentativas/get-normas-paged-list";
import {toast} from "react-toastify";
import DeleteNormaRegulamentativa
    from "../../../services/backoffice/normas-regulamentativas/delete-norma-regulamentativa";
import {Button, Dropdown, Header, Icon, Input, Pagination, Segment, Table} from "semantic-ui-react";
import {Norma} from "../../../filters/norma";

const NormasRegulamentativasList = () => {
    const [normas, setNormas] = useState<any[]>([]);
    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const [filter, setFilter] = useState<NormasRegulamentativasFilters>()
    const [totalPages, setTotalPages] = useState<number>()
    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [profile, setProfile] = useState<string>();
    const blockUI = useBlockUI();
    useEffect(() => {
        blockUI?.setActive(loading)
    }, [loading]);

    useEffect(() => {
        async function updateSessionUser() {
            try {
                SessionStorage.getSession().then(user => {
                    if (!user) return
                    const isLogged = user?.isLogged;
                    if (!isLogged) return
                    setProfile(user?.userData?.profile)
                })
            } catch (e) {
            }
        }

        updateSessionUser()
    }, [SessionStorage])

    useEffect(() => {
        listNormasRegulamentativas();
    }, [filter]);


    async function listNormasRegulamentativas() {
        try {
            setLoading(true);
            const response = (await GetNormasRetulamentativasPagedList(filter));
            setTotalPages(response.totalPages);
            setNormas(response.items);
        } catch (error) {
            console.error('Erro ao obter normas:', error);
        } finally {
            setLoading(false);
        }
    }

    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };

    async function deleteNormaRegulamentativa(norma: Norma) {
        try {
            const confirm = window.confirm(`Tem certeza que deseja apagar a norma regulamentadora ${norma.codigo}? `);
            if (confirm) {
                setLoading(true);
                if (norma) {
                    const result = await DeleteNormaRegulamentativa(norma.id);
                    if (result?.success) {
                        toast.success('Norma Regulamentadora deletada com sucesso', {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });

                        setError("deu bom");
                        setTimeout(() => {
                            setError('');
                        }, 3000);

                        await listNormasRegulamentativas();
                    }
                }
                setLoading(false);
            }
        } catch (error: any) {
            toast.warn('Falha ao deletar norma regulamentadora', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setLoading(false);
            setError(error.message);
        }
    }

    function setFilterAndResetPage(newFilter: any) {
        setFilter({...newFilter, Page: 1})
    }


    return (
        <div>
            <Header as="h2" textAlign="center" color={'black'}>
                Normas Regulamentadoras / NR
            </Header>

            <Table.Row>
                <Table.HeaderCell>
                    <Button color="blue"
                            onClick={() => navigate('/backoffice/normas-regulamentadoras/create/')}>

                        <Icon name='plus'/> Novo
                    </Button>
                </Table.HeaderCell>
                <Table.HeaderCell colSpan="4">
                    <Button onClick={toggleFilters} primary>
                        <Icon name={showFilters ? 'hide' : 'unhide'}/>
                        {showFilters ? 'Ocultar filtros' : 'Exibir filtros'}
                    </Button>
                </Table.HeaderCell>
            </Table.Row>

            {showFilters && (
                <Segment>
                    <div style={{padding: 10}}>
                        <div>
                            <Input
                                fluid
                                icon="search"
                                type="text"
                                placeholder="Código da NR"
                                value={filter?.codigo ?? ''}
                                onChange={event =>
                                    setFilterAndResetPage({...filter, codigo: event.target?.value})
                                }
                            />
                        </div>
                        <br/>
                        <div>
                            <Input
                                fluid
                                icon="search"
                                type="text"
                                placeholder="Descrição"
                                value={filter?.descricao ?? ''}
                                onChange={event =>
                                    setFilterAndResetPage({...filter, descricao: event.target?.value})
                                }
                            />
                        </div>
                    </div>
                </Segment>
            )}
            <Table color={"orange"}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>#</Table.HeaderCell>
                        <Table.HeaderCell>Código</Table.HeaderCell>
                        <Table.HeaderCell>Descrição</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {normas?.map((norma) => (
                        <Table.Row key={norma?.id}>
                            <Table.Cell>
                                <Dropdown item icon={'bars'}>
                                    <Dropdown.Menu>
                                        {profile !== 'Operator' && (
                                            <Dropdown.Item
                                                color="blue"
                                                text={'Editar'}
                                                onClick={() => navigate('/backoffice/normas-regulamentadoras/edit/' + norma.id)}
                                            >
                                                <Icon name='edit' color='blue'/>
                                                Editar
                                            </Dropdown.Item>
                                        )}
                                        <Dropdown.Item
                                            text={'Deletar'}
                                            onClick={() => deleteNormaRegulamentativa(norma)}
                                        >
                                            <Icon name='trash' color={'red'}/>
                                            Deletar
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Table.Cell>

                            <Table.Cell>{norma?.codigo}</Table.Cell>
                            <Table.Cell>{norma?.descricao}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <Pagination totalPages={totalPages ?? 0}
                        defaultActivePage={filter?.Page ?? 1}
                        onPageChange={(e, {activePage}) => {
                            setFilter({...filter, Page: activePage as number})
                        }
                        }></Pagination>
        </div>
    );
}

export default NormasRegulamentativasList