import instance from "../../index";
import {TreinamentoFilter} from "../../../filters/treinamento-filter";
import {ApiConfig} from "../../../config/ApiConfig";
import StringUtils from "../../../shared/utils/string-utils";
import {RelatorioTurmasClienteFilter} from "../../../filters/relatorio-turmas-cliente-filter";

export default async function GetTreinamentos(filter?: TreinamentoFilter): Promise<any> {
    return await instance.get('/v1/backoffice/treinamentos', {
        params: filter
    })
}
export async function GetTreinamentosSimplified(): Promise<any> {
    return await instance.get('/v1/backoffice/treinamentos/simplified')
}

export async function GetClienteTreinamentosRelatorioPdf( filter?: RelatorioTurmasClienteFilter) {
    window.open(ApiConfig.API_URL + `/v1/backoffice/relatorios/treinamentos/mensal/pdf` + StringUtils.jsonToQueryString(filter), '_blank')
}


export interface GetTreinamentosResponseData {
    id: string
    name: string
    description: string
}

export interface GetTreinamentosResponse {
    success: boolean;

    data: GetTreinamentosResponseData;
}



