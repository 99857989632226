import instance from "../../index";


export default DeleteOperator

async function DeleteOperator( id:string ) {
    const response = await instance.delete(`/v1/backoffice/operadores/${id}`);
    return response.data;
}

export interface DeleteOperatorRequest {
    id: string;
}

export interface DeleteOperatorResponseData {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    createdAt: Date;
    updatedAt: Date;
    isActive: boolean;

    clientId: string;
}

export interface DeleteOperatorResponse {
    success: boolean;
    data: DeleteOperatorResponseData;
}