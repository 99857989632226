import instance from "../../index";

export default GetTreinamentoById

async function GetTreinamentoById(id: string): Promise<GetTreinamentoByIdResponse> {
    const response = await instance.get(`/v1/backoffice/treinamentos/${id}`).catch();
    return response?.data?.data;
}

export interface GetTreinamentoByIdResponse {
    id: string
    name: string
    description: string
}

export interface GetTreinamentoByIdResponseData {
    data: GetTreinamentoByIdResponse
    success: boolean
}

