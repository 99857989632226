import instance from "../../index";

export default DuplicarTurmaEParticipantes

async function DuplicarTurmaEParticipantes(request: Partial<DuplicateTurmaRequest>, id: string | string[] | undefined): Promise<DuplicateTurmaResponseData> {
    const response = await instance.post(`/v1/backoffice/turmas/${id}/duplicar`, request)
    return response?.data?.data;
}

export interface DuplicateTurmaRequest {
    nome: string
    descricao: string
    dataInicio: Date
    clientId: string
    treinamentoId: string
    professorId: string
    estadoTurma: number
}

export interface DuplicateTurmaResponse {
    id: string
    nome: string
    descricao: string
    dataInicio: Date
    clientId: string
    professorId: string
    estadoTurma: number

}

export interface DuplicateTurmaResponseData {
    data: DuplicateTurmaResponse
    success: boolean
}