import instance from "../../index";

export default UpdateAvaliacaoErgoPreliminar

async function UpdateAvaliacaoErgoPreliminar(request: Partial<UpdateAvaliacaoErgoPreliminarRequest>, id: string | string[] | undefined) : Promise<UpdateAvaliacaoErgoPreliminarResponse> {
    const response = await instance.put(`/v1/backoffice/avaliacoes-ergo-preliminares/${id}`, request)
    return response.data;
}

export interface UpdateAvaliacaoErgoPreliminarRequest {
    id: string
    createdAt: Date
    coletaErgoId: string
    solucoesErgonomicasIds: string[]
    identificacaoVisual: string
    fontesGeradoras: string
    parteDoCorpoAtingida: string
    gravidade:  number | string | undefined
    probabilidade:  number | string | undefined
    classificação: number | string | undefined
    eliminarSolucaoErgonomica: string
    prevencaoProtecaoColetiva: string
    tipoDeAcao: number
    outroTipoDeAcao: string
    exigeInvestimento: number
    responsavel: string
    prazoSugerido: number | string | undefined
    observacoes: string
    responsavelPelaColetaDeDados: string
    posturaInadequadaOmbrosPresente: boolean
    posturaInadequadaOmbrosAusente: boolean
    posturaInadequadaCotovelosPresente: boolean
    posturaInadequadaCotovelosAusente: boolean
    posturaInadequadaPunhosPresente: boolean
    posturaInadequadaPunhosAusente: boolean
    posturaInadequadaPescoçoPresente: boolean
    posturaInadequadaPescoçoAusente: boolean
    posturaInadequadaTroncoPresente: boolean
    posturaInadequadaTroncoAusente: boolean
    posturaInadequadaPernasPresente: boolean
    posturaInadequadaPernasAusente: boolean
    posturaInadequadaOutrosPresente: boolean
    posturaInadequadaOutrosAusente: boolean
    outrosPerigosPosturaisPresente: boolean
    outrosPerigosPosturaisAusente: boolean
    perigosForcasPresente: boolean
    perigosForcasAusente: boolean
    perigoFatorRepetitividadePresente: boolean
    perigoFatorRepetitividadeAusente: boolean
    perigoFatorOrganizacaoDoTrabalhoPresente: boolean
    perigoFatorOrganizacaoDoTrabalhoAusente: boolean
    perigoFatorPsicossocialPresente: boolean
    perigoFatorPsicossocialAusente: boolean
    perigoFatorCognitivoPresente: boolean
    perigoFatorCognitivoAusente: boolean
}

export interface UpdateAvaliacaoErgoPreliminarResponseData {
        id: string,
        createdAt: Date
        coletaErgoId: string
        solucaoErgonomicaId: string
        identificacaoVisual: string
        fontesGeradoras: string
        parteDoCorpoAtingida: string
        gravidade: number
        probabilidade: number
        classificação: number
        eliminarSolucaoErgonomica: string
        prevencaoProtecaoColetiva: string
        tipoDeAcao: number
        outroTipoDeAcao: string
        exigeInvestimento: number
        responsavel: string
        prazoSugerido: number
        observacoes: string
        responsavelPelaColetaDeDados: string
    posturaInadequadaOmbrosPresente: boolean
    posturaInadequadaOmbrosAusente: boolean
    posturaInadequadaCotovelosPresente: boolean
    posturaInadequadaCotovelosAusente: boolean
    posturaInadequadaPunhosPresente: boolean
    posturaInadequadaPunhosAusente: boolean
    posturaInadequadaPescoçoPresente: boolean
    posturaInadequadaPescoçoAusente: boolean
    posturaInadequadaTroncoPresente: boolean
    posturaInadequadaTroncoAusente: boolean
    posturaInadequadaPernasPresente: boolean
    posturaInadequadaPernasAusente: boolean
    posturaInadequadaOutrosPresente: boolean
    posturaInadequadaOutrosAusente: boolean
    outrosPerigosPosturaisPresente: boolean
    outrosPerigosPosturaisAusente: boolean
    perigosForcasPresente: boolean
    perigosForcasAusente: boolean
    perigoFatorRepetitividadePresente: boolean
    perigoFatorRepetitividadeAusente: boolean
    perigoFatorOrganizacaoDoTrabalhoPresente: boolean
    perigoFatorOrganizacaoDoTrabalhoAusente: boolean
    perigoFatorPsicossocialPresente: boolean
    perigoFatorPsicossocialAusente: boolean
    perigoFatorCognitivoPresente: boolean
    perigoFatorCognitivoAusente: boolean
}

export interface UpdateAvaliacaoErgoPreliminarResponse {
    success: boolean;
    data: UpdateAvaliacaoErgoPreliminarResponseData
}