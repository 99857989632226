import instance from "../../index";

export default CreateAula

async function CreateAula(request: Partial<CreateAulaRequest>): Promise<CreateAulaResponse> {
  const response = await instance.post('/v1/backoffice/aulas', request)
  return response?.data?.data;
}

export interface CreateAulaRequest {
    nome: string
    turmaId: string
    data: Date
    estadoAula: number
}

export interface CreateAulaResponseData {
    id: string
    nome: string
    turmaId: string
    data: Date
    estadoAula: number
}

export interface CreateAulaResponse {
    data: CreateAulaResponseData
    success: boolean
}