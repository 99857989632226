import React, {useEffect, useState} from "react";
import {PesquisaSaudeParticipantes} from "../../models/pesquisaSaudeParticipantes";
import {PesquisaSaudeParticipantesFilter} from "../../filters/pesquisa-saude-participantes-filter";
import {SessionUser} from "../../models/session-user";
import {useBlockUI} from "../../shared/services/block-ui-service";
import SessionStorage from "../../store/session";
import GetPesquisasSaudeParticipante from "../../services/sistema-aulas/pesquisa-saude/get-pesquisas";
import {Button, Dropdown, Header, Icon, Input, Pagination, Segment, Select, Table} from "semantic-ui-react";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {Client} from "../../models/client";
import {GetAllClientsToDropdown} from "../../services/backoffice/clients/get-clients";

const PesquisasSaudeList = () => {

    const [pesquisasSaude, setPesquisasSaude] = useState<PesquisaSaudeParticipantes[]>([]);
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [clients, setClients] = useState<Client[]>([]);

    const navigate = useNavigate();
    const [filter,setFilter] = useState<PesquisaSaudeParticipantesFilter>();
    const [totalPages, setTotalPages] = useState();
    const [showFilters, setShowFilters] = useState(false);
    const [sessionUser, setSessionUser] = useState<SessionUser>();
    const blockUI = useBlockUI();
    useEffect(() => {
        blockUI?.setActive(loading)
    }, [loading]);

    useEffect(() => {
        async function updateSessionUser() {
            const user = await SessionStorage.getSession();
            setSessionUser(user)
        }

        updateSessionUser()
    }, [])
    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };
    useEffect(() => {
        listPesquisas();
    }, [filter]);

    async function listPesquisas() {
        try {
            setLoading(true);
            const response = await GetPesquisasSaudeParticipante(filter);
            const data = response;
            setTotalPages(totalPages);
            setPesquisasSaude(response?.data?.items);
        } catch (error) {
            toast('Erro ao obter pesquisas de saúde', {type: 'error'})
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        function ListClients() {
            GetAllClientsToDropdown().then((response) => {
                setClients(response);
            })
                .catch(error => {
                    console.warn(error);
                })
        }

        ListClients()
    }, [])

    function setFilterAndResetPage(newFilter: any){
        setFilter({...newFilter, Page: 1} )
    }

    return (
        <div>
            <Header as="h2" textAlign="center" color={'black'}>
                Pesquisas de Saúde dos Participantes
            </Header>

            <Table.Row>
                {sessionUser?.userData?.profile === 'Admin' && (
                    <Table.HeaderCell>
                        <Button
                            color="blue"
                            onClick={() => navigate("/pesquisa-saude/responder")}
                        >
                            <Icon name="plus"/> Responder Pesquisa
                        </Button>
                    </Table.HeaderCell>
                )}

                <Table.HeaderCell colSpan="4">
                    <Button onClick={toggleFilters} primary>
                        <Icon name={showFilters ? 'hide' : 'unhide'} />
                        {showFilters ? 'Ocultar filtros' : 'Exibir filtros'}
                    </Button>
                </Table.HeaderCell>
            </Table.Row>{showFilters && (
            <Segment>
                <div style={{padding: 10}}>
                    <div>
                        <Input
                            fluid
                            icon="search"
                            type="text"
                            placeholder="Nome do participante"
                            value={filter?.NomeParticipante ?? ''}
                            onChange={event =>
                                setFilterAndResetPage({...filter, NomeParticipante: event.target?.value})
                            }
                        />
                    </div>
                    <br/>
                    <div>
                        <Select clearable
                                placeholder="Selecione uma empresa"
                                fluid
                                search
                                selection
                                options={clients}
                                value={filter?.clientId ?? ''}
                                selectOnBlur={false}
                                onChange={(event, data) =>
                                    //@ts-ignore
                                    setFilterAndResetPage({...filter, clientId: data.value})
                                }
                        />
                    </div>
                </div>
            </Segment>
        )}

            <Table color={"orange"}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>#</Table.HeaderCell>
                        <Table.HeaderCell>Nome do participante</Table.HeaderCell>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                        <Table.HeaderCell>Empresa</Table.HeaderCell>
                        <Table.HeaderCell>Respondido em</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {pesquisasSaude?.map((pesquisa) => (
                        <Table.Row key={pesquisa.id}>
                            {sessionUser?.userData?.profile === 'Admin' &&
                                <Table.Cell>
                                    <Dropdown item icon={'bars'}>
                                        <Dropdown.Menu>
                                            <Dropdown.Item
                                                color="blue"
                                                onClick={async () => {
                                                    setLoading(true);
                                                    await navigate(`/backoffice/pesquisa-saude/visualizar/${pesquisa.id}`);
                                                    setLoading(false);
                                                }}
                                            >
                                                <Icon name='edit' color='blue' />
                                                Visualizar
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Table.Cell>
                            }

                            <Table.Cell>{pesquisa.nomeParticipante}</Table.Cell>
                            <Table.Cell>{pesquisa.emailParticipante}</Table.Cell>
                            <Table.Cell>{pesquisa.nomeCliente}</Table.Cell>
                            <Table.Cell>{moment(pesquisa.createdAt).format('DD/MM/YY HH:mm')}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <Pagination totalPages={totalPages ?? 0}
                        defaultActivePage={filter?.Page ?? 1}
                        onPageChange={(e, {activePage}) => {
                            setFilter({...filter, Page: activePage as number})
                        }
                        }></Pagination>
        </div>
    )
}

export default PesquisasSaudeList
