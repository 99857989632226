import React, {useEffect, useRef, useState} from 'react';
import {Button, Form, Grid, Header, Menu, Progress, Segment} from 'semantic-ui-react';
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import CreateColetaErgo, {
    CreateColetaErgoRequest
} from "../../../../services/backoffice/coleta-ergo/create-coleta-ergo";
import {EPerguntaHorariotrabalho} from "../../../../enums/ePerguntaHorariotrabalho";
import {EPerguntaTempoTrabalhado} from "../../../../enums/ePerguntaTempoTrabalhado";
import {EPerguntaPraticaExercicioFisico} from "../../../../enums/ePerguntaPraticaExercicioFisico";
import {EPerguntaSobreAmbienteDeTrabalho} from "../../../../enums/ePerguntaSobreAmbienteDeTrabalho";
import {EPerguntaDesconfortoUltimosDias} from "../../../../enums/ePerguntaDesconfortoUltimosDias";
import {EPerguntaEsforcoMental} from "../../../../enums/ePerguntaEsforcoMental";
import {EPerguntaEsforcoFisico} from "../../../../enums/ePerguntaEsforcoFisico";
import {EPerguntaFatorDeRiscoGravidade} from "../../../../enums/ePerguntaFatorDeRiscoGravidade";
import {EPerguntaFatorDeProbabilidade} from "../../../../enums/ePerguntaFatorDeProbabilidade";
import {GetAllClientsToDropdown} from "../../../../services/backoffice/clients/get-clients";
import {EPerguntaPredominanciaDaPostura} from "../../../../enums/EPerguntaPredominanciaDaPostura";
import {useBlockUI} from "../../../../shared/services/block-ui-service";

const CreateColetaErgoPage = () => {
    const [coleta, setColeta] = useState<Partial<CreateColetaErgoRequest>>({});
    const [clients, setClients] = useState<any[]>([]);
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const formRef1 = useRef(null);
    const formRef2 = useRef(null);
    const formRef8 = useRef(null);
    const [loading, setLoading] = useState<boolean>(false);
    const blockUI = useBlockUI();
    const [error, setError] = useState<string>('');
    useEffect(() => {
        blockUI?.setActive(loading)
    }, [loading]);

    useEffect(() => {
        function ListClients() {
            GetAllClientsToDropdown().then((response) => {
                setClients(response);
            })
                .catch(error => {
                    console.warn(error);
                })
        }

        ListClients()
    }, [])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setColeta((prevColeta) => ({
            ...prevColeta,
            [name]: value,
        }));
    };

    const handleDropdownChange = (name: string, data: any) => {
        setColeta((prevColeta) => ({
            ...prevColeta,
            [name]: data.value,
        }));
    };

    const handleCheckboxChange = (name: string, value: any) => {
        setColeta((prevColeta) => ({
            ...prevColeta,
            [name]: value,
        }));
    };

    const nextStep = () => {
        setSubmitted(true);
        let hasErrors = false;

        if (step === 1) {
            // @ts-ignore
            formRef1?.current?.props?.children?.forEach(question => {
                const props = question?.props?.children?.props || question?.props;
                if (props?.error || (props?.required && !props?.value)) {
                    // console.warn({required: props?.required}, props?.label)
                    hasErrors = true;
                    return
                }
            });
        }
        if (step === 2) {
            // @ts-ignore
            formRef2?.current?.props?.children?.forEach(question => {
                const props = question?.props?.children?.props || question?.props;
                // console.warn({props})
                if (props?.error || (props?.required && !props?.value)) {
                    console.warn({required: props?.required}, props?.label)
                    hasErrors = true;
                    return
                }
            });
        }
        if (step === 8) {
            // @ts-ignore
            formRef8?.current?.props?.children?.forEach(question => {
                const props = question?.props?.children?.props || question?.props;
                if (props?.error || (props?.required && !props?.value)) {
                    // console.warn({required: props?.required}, props?.label)
                    hasErrors = true;
                    return
                }
            });
        }
        if (hasErrors) {
            toast?.warning("Verifique os dados informados.");
            return
        }

        setSubmitted(false)
        setStep(step + 1);

    };

    const prevStep = () => {
        setStep(step - 1);
    };

    const handleSubmit = async () => {
        if (step !== 9) return;

        try {
            setLoading(true);
            await CreateColetaErgo(coleta);
            navigate('/backoffice/coleta-ergo/');
            toast.success('Coleta criada com sucesso', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            setError('');
        } catch (error:any) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };


    const ProgressBar = ({ totalSteps, currentStep }: any) => {
        const percent = (currentStep / totalSteps) * 100;
        return (
            <div>
                <Progress percent={percent} className="orange" active value={step} total={totalSteps} progress={'ratio'}/>
                <div className="progress-titles">
                    {Array.from({ length: totalSteps }, (_, index) => (
                        <div
                            key={index}
                            className={`progress-title ${index === currentStep - 1 ? 'active' : ''}`}
                        >
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    function renderButtons(top= false) {
        return (
            <>
                { top && <Menu borderless secondary>
                    <Menu.Item>
                        <Button content="Voltar para lista de coletas"
                                icon='arrow left'
                                onClick={() => navigate('/backoffice/coleta-ergo/')} grey/>
                    </Menu.Item>
                </Menu>}
                { !top && <Menu borderless secondary>
                    {step > 1 &&
                        <Menu.Item>
                            <Button onClick={prevStep} primary icon={'arrow left'} floated={'left'}
                                    labelPosition={'left'} content={'Anterior'}></Button>
                        </Menu.Item>}
                    {step != 9 &&
                        <Menu.Item position={"right"}>
                            <Button onClick={nextStep} primary icon={'arrow right'} labelPosition={'right'}
                                    content={'Próximo'}></Button>
                        </Menu.Item>}

                    {step === 9 &&
                        <Menu.Item position={'right'}>
                            <Button type="submit" color="green" fluid size="large"
                                    icon={'save'} content={'Enviar coleta'}
                                    onClick={() => {
                                        handleSubmit()
                                    }}>
                            </Button>
                        </Menu.Item>}
                </Menu>}
            </>
        );
    }


    const renderStep1 = () => {
        const currentStep: number = 1;

        return (

            <Form onSubmit={handleSubmit}
                  warning
                  error
                  ref={formRef1}>
                <Header textAlign={"center"} as={"h2"}>Pesquisa Ergonômica - NR-17</Header>
                <Header as="h3" textAlign="center" color={'black'}>
                   Informações básicas da empresa e do colaborador
                </Header>
                <ProgressBar totalSteps={9} currentStep={currentStep} />
                <Header textAlign={"center"} as={"h4"} content={"justify-center"}>Essa pesquisa tem como
                    objetivo levantamento de dados de saúde, produtividade e é uma forma
                    de ouvir os colaboradores de forma individual sobre melhorias para os postos de
                    trabalho.
                </Header>
                <Form.Field>
                    <Form.Select
                        label={'Qual a Empresa onde o entrevistado trabalha?'}
                        options={clients}
                        placeholder='Selecione uma opção'
                        value={coleta.clientId}
                        onChange={(e: any, data: any) => handleDropdownChange('clientId', data)}
                        required={step === 1}
                        error={submitted && coleta.clientId === undefined}

                    />
                </Form.Field>
                <Form.Input
                    label={'Nome completo do trabalhador entrevistado (pode ser somente iniciais do nome)'}
                    name="nome"
                    type="text"
                    placeholder="Nome"
                    value={coleta.nome}
                    onChange={handleInputChange}
                    required={step === 1}
                    error={submitted && coleta.nome === undefined}
                />

                <Form.Input
                    label={'Qual o setor que trabalha?'}
                    name="setor"
                    type="text"
                    placeholder="Setor"
                    value={coleta.setor}
                    onChange={handleInputChange}
                    required={step === 1}
                    error={submitted && coleta.setor === undefined}

                />

                <Form.Input
                    label={'Qual a função do colaborador (como está registrado CLT)?'}
                    name="funcaoClt"
                    type="text"
                    placeholder="Função Clt"
                    value={coleta.funcaoClt}
                    onChange={handleInputChange}
                    required={step === 1}
                    error={submitted && coleta.funcaoClt === undefined}>

                </Form.Input>

                <Form.Input
                    label={'Nome completo do responsável pelo setor:'}
                    name="nomeSupervisor"
                    type="text"
                    placeholder="Nome do Supervisor"
                    value={coleta.nomeSupervisor}
                    onChange={handleInputChange}
                    required={step === 1}
                    error={submitted && coleta.nomeSupervisor === undefined}

                />

                <Form.Field>
                    <Form.Select
                        label={'Qual o horário de trabalho?'}
                        options={EPerguntaHorariotrabalho}
                        placeholder='Selecione uma opção'
                        value={coleta.horarioTrabalho}
                        onChange={(e: any, data: any) => handleDropdownChange('horarioTrabalho', data)}
                        required={step === 1}
                        error={submitted && coleta.horarioTrabalho === undefined}
                    />
                </Form.Field>

                <Form.Input
                    label={'Qual a idade?'}
                    name="idade"
                    type="number"
                    placeholder="Idade do colaborador"
                    value={coleta.idade}
                    onChange={handleInputChange}
                    required={step === 1}
                    error={submitted && coleta.idade === undefined}

                />

                <Form.Input
                    label={'Atividades principais do colaborador'}
                    name="atividadesPrincipais"
                    type="text"
                    placeholder="Atividades principais do colaborador"
                    value={coleta.atividadesPrincipais}
                    onChange={handleInputChange}
                    required={step === 1}
                    error={submitted && coleta.atividadesPrincipais === undefined}
                />

                <Form.Field>
                    <Form.Select
                        label={'Predominancia da postura no trabalho?'}
                        options={EPerguntaPredominanciaDaPostura}
                        placeholder='Selecione uma opção'
                        value={coleta.perguntaPredominanciaDaPostura}
                        onChange={(e: any, data: any) => handleDropdownChange('perguntaPredominanciaDaPostura', data)}
                        required={step === 1}
                        error={submitted && coleta.perguntaPredominanciaDaPostura === undefined}
                    />
                </Form.Field>

                <Form.Field>
                    <h4>Mobiliario utilizado pelo entrevistado no trabalho</h4>
                    <Form.Checkbox type="checkbox"
                                   label={"Mesa + cadeira"}
                                   id={"mesaeCadeiraPerguntaMobiliarioPerguntaMobiliario"}
                                   onChange={(e, b) => handleCheckboxChange('mesaeCadeiraPerguntaMobiliarioPerguntaMobiliario', b.checked)}
                                   checked={coleta.mesaeCadeiraPerguntaMobiliarioPerguntaMobiliario}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Mesa + cadeira - Computador de mesa + mouse e teclado"}
                                   id={"mesaCadeiraComputadorDeMesaMouseTecladoApoioPunhoMousePerguntaMobiliario"}
                                   onChange={(e, b) => handleCheckboxChange('mesaCadeiraComputadorDeMesaMouseTecladoApoioPunhoMousePerguntaMobiliario', b.checked)}
                                   checked={coleta.mesaCadeiraComputadorDeMesaMouseTecladoApoioPunhoMousePerguntaMobiliario}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Mesa + cadeira - Computador de mesa + mouse e teclado +apoio de punho no mouse"}
                                   id={"mesaCadeiraComputadorDeMesaMouseTecladoPerguntaMobiliario"}
                                   onChange={(e, b) => handleCheckboxChange('mesaCadeiraComputadorDeMesaMouseTecladoPerguntaMobiliario', b.checked)}
                                   checked={coleta.mesaCadeiraComputadorDeMesaMouseTecladoPerguntaMobiliario}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Mesa + cadeira - Computador de mesa + mouse e teclado +apoio de punho no teclado"}
                                   id={"mesaCadeiraComputadorDeMesaMouseTecladoApoioPunhoTecladoPerguntaMobiliario"}
                                   onChange={(e, b) => handleCheckboxChange('mesaCadeiraComputadorDeMesaMouseTecladoApoioPunhoTecladoPerguntaMobiliario', b.checked)}
                                   checked={coleta.mesaCadeiraComputadorDeMesaMouseTecladoApoioPunhoTecladoPerguntaMobiliario}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Mesa + cadeira - Computador de mesa + mouse e teclado +apoio de punho no mouse e teclado"}
                                   id={"mesaCadeiraComputadorDeMesaMouseTecladoApoioPunhoAmbosPerguntaMobiliario"}
                                   onChange={(e, b) => handleCheckboxChange('mesaCadeiraComputadorDeMesaMouseTecladoApoioPunhoAmbosPerguntaMobiliario', b.checked)}
                                   checked={coleta.mesaCadeiraComputadorDeMesaMouseTecladoApoioPunhoAmbosPerguntaMobiliario}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Mesa + cadeira - Notebook + mouse"}
                                   id={"mesaCadeiraNotebookMousePerguntaMobiliario"}
                                   onChange={(e, b) => handleCheckboxChange('mesaCadeiraNotebookMousePerguntaMobiliario', b.checked)}
                                   checked={coleta.mesaCadeiraNotebookMousePerguntaMobiliario}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Mesa + cadeira - Notebook + teclado e mouse externo"}
                                   id={"mesaCadeiraNotebookTecladoEMouseExternoPerguntaMobiliario"}
                                   onChange={(e, b) => handleCheckboxChange('mesaCadeiraNotebookTecladoEMouseExternoPerguntaMobiliario', b.checked)}
                                   checked={coleta.mesaCadeiraNotebookTecladoEMouseExternoPerguntaMobiliario}/>
                    <Form.Checkbox type="checkbox"
                                   label={"Mesa + cadeira - Notebook + teclado e mouse externo + suporte de altura de tela"}
                                   id={"mesaCadeiraNotebookTecladoMouseExternoSupAlturaTelaPerguntaMobiliario"}
                                   onChange={(e, b) => handleCheckboxChange('mesaCadeiraNotebookTecladoMouseExternoSupAlturaTelaPerguntaMobiliario', b.checked)}
                                   checked={coleta.mesaCadeiraNotebookTecladoMouseExternoSupAlturaTelaPerguntaMobiliario}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Mesa + cadeira - Notebook + teclado e mouse externo + suporte de altura de tela + apoios de punho"}
                                   id={"mesaCadeiraNotebookTecladoMouseExternoSupAlturaApoioPunhoPerguntaMobiliario"}
                                   onChange={(e, b) => handleCheckboxChange('mesaCadeiraNotebookTecladoMouseExternoSupAlturaApoioPunhoPerguntaMobiliario', b.checked)}
                                   checked={coleta.mesaCadeiraNotebookTecladoMouseExternoSupAlturaApoioPunhoPerguntaMobiliario}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Bancada"}
                                   id={"bancadaPerguntaMobiliario"}
                                   onChange={(e, b) => handleCheckboxChange('bancadaPerguntaMobiliario', b.checked)}
                                   checked={coleta.bancadaPerguntaMobiliario}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Bancada com cadeira/banqueta"}
                                   id={"bancadaCadeiraBanquetaPerguntaMobiliario"}
                                   onChange={(e, b) => handleCheckboxChange('bancadaCadeiraBanquetaPerguntaMobiliario', b.checked)}
                                   checked={coleta.bancadaCadeiraBanquetaPerguntaMobiliario}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Trabalho em campo (ao ar livre)"}
                                   id={"trabalhoEmCampoArLivrePerguntaMobiliario"}
                                   onChange={(e, b) => handleCheckboxChange('trabalhoEmCampoArLivrePerguntaMobiliario', b.checked)}
                                   checked={coleta.trabalhoEmCampoArLivrePerguntaMobiliario}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Galpão/oficina e outros"}
                                   id={"galpaoOficinaOutrosPerguntaMobiliario"}
                                   onChange={(e, b) => handleCheckboxChange('galpaoOficinaOutrosPerguntaMobiliario', b.checked)}
                                   checked={coleta.galpaoOficinaOutrosPerguntaMobiliario}/>
                </Form.Field>
                <Form.Field>
                    <Form.Input
                        label="Outros"
                        name="outroPerguntaMobiliario"
                        type="text"
                        placeholder="Outros"
                        value={coleta.outroPerguntaMobiliario}
                        onChange={handleInputChange}
                    />
                </Form.Field>

                <Form.Field>
                    <Form.Select
                        label={'A quanto tempo o colaborador trabalha na empresa?'}
                        options={EPerguntaTempoTrabalhado}
                        placeholder='Selecione uma opção'
                        value={coleta.tempoTrabalhado}
                        onChange={(e: any, data: any) => handleDropdownChange('tempoTrabalhado', data)}
                        required={step === 1}
                        error={submitted && coleta.tempoTrabalhado === undefined}
                    />
                </Form.Field>

                <Form.Field>
                    <Form.Select
                        label={'Colaborador pratica exercício físico?'}
                        options={EPerguntaPraticaExercicioFisico}
                        placeholder='Selecione uma opção'
                        value={coleta.praticaExercicioFisico}
                        onChange={(e: any, data: any) => handleDropdownChange('praticaExercicioFisico', data)}
                        required={step === 1}
                        error={submitted && coleta.praticaExercicioFisico === undefined}
                    />
                </Form.Field>

                <h4>Possui hábitos não saudáveis?</h4>
                <Form.Field>

                    <Form.Checkbox type="checkbox"
                                   label={"Fumante"}
                                   id={"fumantePerguntaHabitoQueNaoGeraSaude"}
                                   onChange={(e, b) => handleCheckboxChange('fumantePerguntaHabitoQueNaoGeraSaude', b.checked)}
                                   checked={coleta.fumantePerguntaHabitoQueNaoGeraSaude}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Não ter momento de relaxamento"}
                                   id={"naoDescansaPerguntaHabitoQueNaoGeraSaude"}
                                   onChange={(e, b) => handleCheckboxChange('naoDescansaPerguntaHabitoQueNaoGeraSaude', b.checked)}
                                   checked={coleta.naoDescansaPerguntaHabitoQueNaoGeraSaude}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Uso de bebidas alcólicas"}
                                   id={"bebePerguntaHabitoQueNaoGeraSaude"}
                                   onChange={(e, b) => handleCheckboxChange('bebePerguntaHabitoQueNaoGeraSaude', b.checked)}
                                   checked={coleta.bebePerguntaHabitoQueNaoGeraSaude}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Redes sociais em excesso"}
                                   id={"redesSociaisPerguntaHabitoQueNaoGeraSaude"}
                                   onChange={(e, b) => handleCheckboxChange('redesSociaisPerguntaHabitoQueNaoGeraSaude', b.checked)}
                                   checked={coleta.redesSociaisPerguntaHabitoQueNaoGeraSaude}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Negligenciar a alimentação"}
                                   id={"alimentacaoRuimPerguntaHabitoQueNaoGeraSaude"}
                                   onChange={(e, b) => handleCheckboxChange('alimentacaoRuimPerguntaHabitoQueNaoGeraSaude', b.checked)}
                                   checked={coleta.alimentacaoRuimPerguntaHabitoQueNaoGeraSaude}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Não"}
                                   id={"naoPerguntaHabitoQueNaoGeraSaude"}
                                   onChange={(e, b) => handleCheckboxChange('naoPerguntaHabitoQueNaoGeraSaude', b.checked)}
                                   checked={coleta.naoPerguntaHabitoQueNaoGeraSaude}/>
                </Form.Field>


                <Form.Field>
                    <Form.Input
                        label="Outros"
                        name="outrosPerguntaHabitoQueNaoGeraSaude"
                        type="text"
                        placeholder="Outros hábitos que não geram saúde"
                        value={coleta.outrosPerguntaHabitoQueNaoGeraSaude}
                        onChange={handleInputChange}
                    />
                </Form.Field>

                <Form.Input
                    label={'Qual a altura do colaborador? (Exemplo: 165)'}
                    name="altura"
                    type="number"
                    placeholder="Altura do colaborador"
                    value={coleta.altura}
                    onChange={handleInputChange}
                    required={step === 1}
                    error={submitted && coleta.altura === undefined}

                />

                <Form.Input
                    label={'Qual o peso (kg)? (Exemplo: 72)'}
                    name="peso"
                    type="number"
                    placeholder="Peso do colaborador"
                    value={coleta.peso}
                    onChange={handleInputChange}
                    required={step === 1}
                    error={submitted && coleta.peso === undefined}
                />
                <div>
                </div>
            </Form>
        );
    };

    const renderStep2 = () => {
        const currentStep: number = 2; // Define o valor da etapa atual para RenderStep2
        return (
            <Form onSubmit={handleSubmit}
                  ref={formRef2}>
                {/*#region identificação de riscos para a saúde e opinião do trabalhador*/}
                <Header textAlign={"center"} as={"h2"}>Pesquisa Ergonômica - NR-17</Header>
                <Header as="h3" textAlign="center" color={'black'}>
                    Identificação de riscos para a saúde e opinião do trabalhador
                </Header>
                <ProgressBar totalSteps={9} currentStep={currentStep} />
                <h4>Com relação ao ambiente de trabalho:</h4>
                <Form.Select
                    label={'Ruído o afeta?'}
                    options={EPerguntaSobreAmbienteDeTrabalho}
                    placeholder='Selecione uma opção'
                    value={coleta.ruidoPerguntaSobreAmbienteDeTrabalho}
                    onChange={(e: any, data: any) => handleDropdownChange('ruidoPerguntaSobreAmbienteDeTrabalho', data)}
                    required={step === 2}
                    error={submitted && coleta.ruidoPerguntaSobreAmbienteDeTrabalho === undefined}

                />
                <Form.Select
                    label={'Temperatura o afeta?'}
                    options={EPerguntaSobreAmbienteDeTrabalho}
                    placeholder='Selecione uma opção'
                    value={coleta.temperaturaPerguntaSobreAmbienteDeTrabalho}
                    onChange={(e: any, data: any) => handleDropdownChange('temperaturaPerguntaSobreAmbienteDeTrabalho', data)}
                    required={step === 2}
                    error={submitted && coleta.temperaturaPerguntaSobreAmbienteDeTrabalho === undefined}

                />
                <Form.Select
                    label={'Iluminação o afeta?'}
                    options={EPerguntaSobreAmbienteDeTrabalho}
                    placeholder='Selecione uma opção'
                    value={coleta.iluminacaoPerguntaSobreAmbienteDeTrabalho}
                    onChange={(e: any, data: any) => handleDropdownChange('iluminacaoPerguntaSobreAmbienteDeTrabalho', data)}
                    required={step === 2}
                    error={submitted && coleta.iluminacaoPerguntaSobreAmbienteDeTrabalho === undefined}
                />
                <Form.Select
                    label={'Baixa umidade o afeta?'}
                    options={EPerguntaSobreAmbienteDeTrabalho}
                    placeholder='Selecione uma opção'
                    value={coleta.baixaUmidadePerguntaSobreAmbienteDeTrabalho}
                    onChange={(e: any, data: any) => handleDropdownChange('baixaUmidadePerguntaSobreAmbienteDeTrabalho', data)}
                    required={step === 2}
                    error={submitted && coleta.baixaUmidadePerguntaSobreAmbienteDeTrabalho === undefined}
                />


                <h4>Nos últimos 7 dias o colaborador sentiu alguma dor/desconforto? Se sim,
                    avalie a intensidade dessa dor em cada parte do corpo a partir da
                    escala a seguir: sendo 0 nenhuma dor e 10 a pior dor que já sentiu na sua vida:
                </h4>
                <img src={'/charts/escala_dor.png'} style={{
                    objectFit: 'contain',
                    marginTop: 10,
                    marginBottom: 10,
                    padding: 20,
                    alignSelf: 'center',
                    width: '100%',
                }} alt={'matriz de risco'}/>

                {/*                        TODO? Ver com o warleyzoka a medida da imagem devido a qualidade*/}
                <br/>
                <Form.Select
                    label={'Desconforto ou dores na cabeça'}
                    options={EPerguntaDesconfortoUltimosDias}
                    placeholder='Selecione uma opção'
                    value={coleta.cabecaPerguntaDesconfortoUltimosDias}
                    onChange={(e: any, data: any) => handleDropdownChange('cabecaPerguntaDesconfortoUltimosDias', data)}
                    required={step === 2}
                    error={submitted && coleta.cabecaPerguntaDesconfortoUltimosDias === undefined}
                />
                <Form.Select
                    label={'Desconforto ou dores no pescoço'}
                    options={EPerguntaDesconfortoUltimosDias}
                    placeholder='Selecione uma opção'
                    value={coleta.pescocoPerguntaDesconfortoUltimosDias}
                    onChange={(e: any, data: any) => handleDropdownChange('pescocoPerguntaDesconfortoUltimosDias', data)}
                    required={step === 2}
                    error={submitted && coleta.pescocoPerguntaDesconfortoUltimosDias === undefined}
                />
                <Form.Select
                    label={'Desconforto ou dores nos ombros'}
                    options={EPerguntaDesconfortoUltimosDias}
                    placeholder='Selecione uma opção'
                    value={coleta.ombroPerguntaDesconfortoUltimosDias}
                    onChange={(e: any, data: any) => handleDropdownChange('ombroPerguntaDesconfortoUltimosDias', data)}
                    required={step === 2}
                    error={submitted && coleta.ombroPerguntaDesconfortoUltimosDias === undefined}
                />
                <Form.Select
                    label={'Desconforto ou dores nos braços '}
                    options={EPerguntaDesconfortoUltimosDias}
                    placeholder='Selecione uma opção'
                    value={coleta.bracosPerguntaDesconfortoUltimosDias}
                    onChange={(e: any, data: any) => handleDropdownChange('bracosPerguntaDesconfortoUltimosDias', data)}
                    required={step === 2}
                    error={submitted && coleta.bracosPerguntaDesconfortoUltimosDias === undefined}
                />
                <Form.Select
                    label={'Desconforto ou dores nos punhos '}
                    options={EPerguntaDesconfortoUltimosDias}
                    placeholder='Selecione uma opção'
                    value={coleta.punhosPerguntaDesconfortoUltimosDias}
                    onChange={(e: any, data: any) => handleDropdownChange('punhosPerguntaDesconfortoUltimosDias', data)}
                    required={step === 2}
                    error={submitted && coleta.punhosPerguntaDesconfortoUltimosDias === undefined}
                />
                <Form.Select
                    label={'Desconforto ou dores na coluna '}
                    options={EPerguntaDesconfortoUltimosDias}
                    placeholder='Selecione uma opção'
                    value={coleta.colunaPerguntaDesconfortoUltimosDias}
                    onChange={(e: any, data: any) => handleDropdownChange('colunaPerguntaDesconfortoUltimosDias', data)}
                    required={step === 2}
                    error={submitted && coleta.colunaPerguntaDesconfortoUltimosDias === undefined}
                />
                <Form.Select
                    label={'Desconforto ou dores nas coxas'}
                    options={EPerguntaDesconfortoUltimosDias}
                    placeholder='Selecione uma opção'
                    value={coleta.coxasPerguntaDesconfortoUltimosDias}
                    onChange={(e: any, data: any) => handleDropdownChange('coxasPerguntaDesconfortoUltimosDias', data)}
                    required={step === 2}
                    error={submitted && coleta.coxasPerguntaDesconfortoUltimosDias === undefined}
                />
                <Form.Select
                    label={'Desconforto ou dores nas pernas'}
                    options={EPerguntaDesconfortoUltimosDias}
                    placeholder='Selecione uma opção'
                    value={coleta.pernasPerguntaDesconfortoUltimosDias}
                    onChange={(e: any, data: any) => handleDropdownChange('pernasPerguntaDesconfortoUltimosDias', data)}
                    required={step === 2}
                    error={submitted && coleta.pernasPerguntaDesconfortoUltimosDias === undefined}
                />
                <Form.Select
                    label={'Desconforto ou dores no quadril'}
                    options={EPerguntaDesconfortoUltimosDias}
                    placeholder='Selecione uma opção'
                    value={coleta.quadrilPerguntaDesconfortoUltimosDias}
                    onChange={(e: any, data: any) => handleDropdownChange('quadrilPerguntaDesconfortoUltimosDias', data)}
                    required={step === 2}
                    error={submitted && coleta.quadrilPerguntaDesconfortoUltimosDias === undefined}
                />
                <Form.Select
                    label={'Desconforto ou dores nos joelhos'}
                    options={EPerguntaDesconfortoUltimosDias}
                    placeholder='Selecione uma opção'
                    value={coleta.joelhosPerguntaDesconfortoUltimosDias}
                    onChange={(e: any, data: any) => handleDropdownChange('joelhosPerguntaDesconfortoUltimosDias', data)}
                    required={step === 2}
                    error={submitted && coleta.joelhosPerguntaDesconfortoUltimosDias === undefined}
                />
                <Form.Select
                    label={'Desconforto ou dores nos tornozelos'}
                    options={EPerguntaDesconfortoUltimosDias}
                    placeholder='Selecione uma opção'
                    value={coleta.tornozelosPerguntaDesconfortoUltimosDias}
                    onChange={(e: any, data: any) => handleDropdownChange('tornozelosPerguntaDesconfortoUltimosDias', data)}
                    required={step === 2}
                    error={submitted && coleta.tornozelosPerguntaDesconfortoUltimosDias === undefined}
                />
                <Form.Select
                    label={'Desconforto ou dores nos pés'}
                    options={EPerguntaDesconfortoUltimosDias}
                    placeholder='Selecione uma opção'
                    value={coleta.pesPerguntaDesconfortoUltimosDias}
                    onChange={(e: any, data: any) => handleDropdownChange('pesPerguntaDesconfortoUltimosDias', data)}
                    required={step === 2}
                    error={submitted && coleta.pesPerguntaDesconfortoUltimosDias === undefined}
                />


                <h4>Marque de acordo com a escala: Pensando naquela atividade de trabalho
                    mais desafiadora, em relação ao esforço MENTAL quando eu termino essa tarefa,
                    eu investi um esforço MENTAL de:</h4>
                <img src={'/charts/escala_esforco_mental.png'} style={{
                    objectFit: 'contain',
                    marginTop: 10,
                    marginBottom: 10,
                    padding: 20,
                    alignSelf: 'center',
                    width: '100%',
                }} alt={'matriz de risco'}/>

                <Form.Field>
                    <Form.Select
                        label={'Esforço Mental'}
                        options={EPerguntaEsforcoMental}
                        placeholder='Selecione uma opção'
                        value={coleta.perguntaEsforcoMental}
                        onChange={(e: any, data: any) => handleDropdownChange('perguntaEsforcoMental', data)}
                        required={step === 2}
                        error={submitted && coleta.perguntaEsforcoMental === undefined}
                    />
                </Form.Field>

                <h4>Marque de acordo com a escala: Pensando naquela atividade de trabalho
                    mais desafiadora, em relação ao esforço FÍSICO quando eu termino essa tarefa,
                    eu investi um esforço FÍSICO de:
                </h4>

                <img src={'/charts/escala_esforco_fisico.png'} style={{
                    objectFit: 'contain',
                    marginTop: 10,
                    marginBottom: 10,
                    padding: 20,
                    alignSelf: 'center',
                    width: '100%',
                }} alt={'matriz de risco'}/>
                <Form.Field>

                    <Form.Select
                        label={'Esforço Físico'}
                        options={EPerguntaEsforcoFisico}
                        placeholder='Selecione uma opção'
                        value={coleta.perguntaEsforcoFisico}
                        onChange={(e: any, data: any) => handleDropdownChange('perguntaEsforcoFisico', data)}
                        required={step === 2}
                        error={submitted && coleta.perguntaEsforcoFisico === undefined}
                    />
                </Form.Field>


                <Form.Field>
                    <Form.Input
                        label={'Se você pudesse mudar algo no seu trabalho agora, o que seria?'}
                        name="respostaPerguntaMudarAlgoNoTrabalho"
                        type="text"
                        placeholder="O que você mudaria?"
                        value={coleta.respostaPerguntaMudarAlgoNoTrabalho}
                        onChange={handleInputChange}
                        required={step === 2}
                        error={submitted && coleta.respostaPerguntaMudarAlgoNoTrabalho === undefined}
                    />
                </Form.Field>
            </Form>
        );
    };

    const renderStep3 = () => {
        const currentStep: number = 3;
        return (
            <Form onSubmit={handleSubmit}>
                <Header textAlign={"center"} as={"h2"}>Pesquisa Ergonômica - NR-17</Header>
                <Header as="h3" textAlign="center" color={'black'}>
                    Identificação de riscos de postura inadequada do colaborador
                </Header>
                <ProgressBar totalSteps={9} currentStep={currentStep} />
                <span>Identificação de riscos e perigos ergonômicos relacionados a postura.</span>
                <h4>Marque a postura inadequada do PESCOÇO:</h4>
                <Form.Field>
                    <Form.Checkbox type="checkbox"
                                   label={"Flexão acima de 20 graus"}
                                   id={"flexaoAcima20GrausPerguntaPosturaInadequadaPescoco"}
                                   onChange={(e, b) => handleCheckboxChange('flexaoAcima20GrausPerguntaPosturaInadequadaPescoco', b.checked)}
                                   checked={coleta.flexaoAcima20GrausPerguntaPosturaInadequadaPescoco}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Extensão sem suporte acima de 10 graus"}
                                   id={"extensaoSemSupAcima10GrausPerguntaPosturaInadequadaPescoco"}
                                   onChange={(e, b) => handleCheckboxChange('extensaoSemSupAcima10GrausPerguntaPosturaInadequadaPescoco', b.checked)}
                                   checked={coleta.extensaoSemSupAcima10GrausPerguntaPosturaInadequadaPescoco}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Inclinação acima de 20 Graus"}
                                   id={"inclinacaoAcima20GrausPerguntaPosturaInadequadaPescoco"}
                                   onChange={(e, b) => handleCheckboxChange('inclinacaoAcima20GrausPerguntaPosturaInadequadaPescoco', b.checked)}
                                   checked={coleta.inclinacaoAcima20GrausPerguntaPosturaInadequadaPescoco}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Rotação acima de 30 graus"}
                                   id={"rotacaoAcima30GrausPerguntaPosturaInadequadaPescoco"}
                                   onChange={(e, b) => handleCheckboxChange('rotacaoAcima30GrausPerguntaPosturaInadequadaPescoco', b.checked)}
                                   checked={coleta.rotacaoAcima30GrausPerguntaPosturaInadequadaPescoco}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Não existe risco para o pescoço nessa atividade"}
                                   id={"naoExisteRiscoPescocoPerguntaPosturaInadequadaPescoco"}
                                   onChange={(e, b) => handleCheckboxChange('naoExisteRiscoPescocoPerguntaPosturaInadequadaPescoco', b.checked)}
                                   checked={coleta.naoExisteRiscoPescocoPerguntaPosturaInadequadaPescoco}/>

                    <Form.Input
                        label="Outros"
                        name="outrosPerguntaPosturaInadequadaPescoco"
                        type="text"
                        placeholder="Outros fatores de postura inadequada do pescoço"
                        value={coleta.outrosPerguntaPosturaInadequadaPescoco}
                        onChange={handleInputChange}
                    />
                </Form.Field>


                <h4>Marque a postura inadequada dos Ombros:
                </h4>
                <Form.Field>

                    <Form.Checkbox type="checkbox"
                                   label={"Flexão acima de 45 a 90 graus"}
                                   id={"flexaoAcima45A90GrausPerguntaPosturaInadequadaOmbros"}
                                   onChange={(e, b) => handleCheckboxChange('flexaoAcima45A90GrausPerguntaPosturaInadequadaOmbros', b.checked)}
                                   checked={coleta.flexaoAcima45A90GrausPerguntaPosturaInadequadaOmbros}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Extensão acima de 20 graus"}
                                   id={"extensaoAcima20GrausPerguntaPosturaInadequadaOmbros"}
                                   onChange={(e, b) => handleCheckboxChange('extensaoSemSupAcima10GrausPerguntaPosturaInadequadaPescoco', b.checked)}
                                   checked={coleta.extensaoAcima20GrausPerguntaPosturaInadequadaOmbros}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Adução acima de 45 graus"}
                                   id={"aducaoAcima45GrausPerguntaPosturaInadequadaOmbros"}
                                   onChange={(e, b) => handleCheckboxChange('aducaoAcima45GrausPerguntaPosturaInadequadaOmbros', b.checked)}
                                   checked={coleta.aducaoAcima45GrausPerguntaPosturaInadequadaOmbros}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Rotação acima de 45 graus"}
                                   id={"rotacaoAcima45GrausPerguntaPosturaInadequadaOmbros"}
                                   onChange={(e, b) => handleCheckboxChange('rotacaoAcima45GrausPerguntaPosturaInadequadaOmbros', b.checked)}
                                   checked={coleta.rotacaoAcima45GrausPerguntaPosturaInadequadaOmbros}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Abdução acima de 45 graus"}
                                   id={"abducaoAcima45GrausPerguntaPosturaInadequadaOmbros"}
                                   onChange={(e, b) => handleCheckboxChange('abducaoAcima45GrausPerguntaPosturaInadequadaOmbros', b.checked)}
                                   checked={coleta.abducaoAcima45GrausPerguntaPosturaInadequadaOmbros}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Ombros elevados"}
                                   id={"ombrosElevadosPerguntaPosturaInadequadaOmbros"}
                                   onChange={(e, b) => handleCheckboxChange('ombrosElevadosPerguntaPosturaInadequadaOmbros', b.checked)}
                                   checked={coleta.ombrosElevadosPerguntaPosturaInadequadaOmbros}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Não existem riscos para os ombros nessa atividade"}
                                   id={"naoExisteRiscoOmbroPerguntaPosturaInadequadaOmbros"}
                                   onChange={(e, b) => handleCheckboxChange('naoExisteRiscoOmbroPerguntaPosturaInadequadaOmbros', b.checked)}
                                   checked={coleta.naoExisteRiscoOmbroPerguntaPosturaInadequadaOmbros}/>

                    <Form.Input
                        label="Outros"
                        name="outrosPerguntaPosturaInadequadaOmbros"
                        type="text"
                        placeholder="Outros fatores de postura inadequada dos ombros"
                        value={coleta.outrosPerguntaPosturaInadequadaOmbros}
                        onChange={handleInputChange}
                    />
                </Form.Field>


                <h4>Marque a postura inadequada dos Cotovelos:
                </h4>
                <Form.Field>

                    <Form.Checkbox type="checkbox"
                                   label={"Flexão acima de 100 graus"}
                                   id={"flexaoAcima100GrausPerguntaPosturaInadequadaCotovelos"}
                                   onChange={(e, b) => handleCheckboxChange('flexaoAcima100GrausPerguntaPosturaInadequadaCotovelos', b.checked)}
                                   checked={coleta.flexaoAcima100GrausPerguntaPosturaInadequadaCotovelos}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Extensão acima de 60 graus"}
                                   id={"extensaoAcima60GrausPerguntaPosturaInadequadaCotovelos"}
                                   onChange={(e, b) => handleCheckboxChange('extensaoAcima60GrausPerguntaPosturaInadequadaCotovelos', b.checked)}
                                   checked={coleta.extensaoAcima60GrausPerguntaPosturaInadequadaCotovelos}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Totalmente extendido"}
                                   id={"totalmenteExtendPerguntaPosturaInadequadaCotovelos"}
                                   onChange={(e, b) => handleCheckboxChange('totalmenteExtendPerguntaPosturaInadequadaCotovelos', b.checked)}
                                   checked={coleta.totalmenteExtendPerguntaPosturaInadequadaCotovelos}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Totalmente flexionado"}
                                   id={"totalmenteFlexPerguntaPosturaInadequadaCotovelos"}
                                   onChange={(e, b) => handleCheckboxChange('totalmenteFlexPerguntaPosturaInadequadaCotovelos', b.checked)}
                                   checked={coleta.totalmenteFlexPerguntaPosturaInadequadaCotovelos}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Não existe riscos para os cotovelos nessa atividade"}
                                   id={"naoExisteRiscoCotovelosPerguntaPosturaInadequadaCotovelos"}
                                   onChange={(e, b) => handleCheckboxChange('naoExisteRiscoCotovelosPerguntaPosturaInadequadaCotovelos', b.checked)}
                                   checked={coleta.naoExisteRiscoCotovelosPerguntaPosturaInadequadaCotovelos}/>

                    <Form.Input
                        label="Outros"
                        name="outrosPerguntaPosturaInadequadaCotovelos"
                        type="text"
                        placeholder="Outros fatores de postura inadequada dos cotovelos"
                        value={coleta.outrosPerguntaPosturaInadequadaCotovelos}
                        onChange={handleInputChange}
                    />
                </Form.Field>


                <h4>Marque a postura inadequada dos Punhos:
                </h4>
                <Form.Field>

                    <Form.Checkbox type="checkbox"
                                   label={"Flexão acima de 45 graus"}
                                   id={"flexaoAcima45GrausPerguntaPosturaInadequadaPunhos"}
                                   onChange={(e, b) => handleCheckboxChange('flexaoAcima45GrausPerguntaPosturaInadequadaPunhos', b.checked)}
                                   checked={coleta.flexaoAcima45GrausPerguntaPosturaInadequadaPunhos}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Desvios laterais acima de 10 graus"}
                                   id={"desviosLateraisAcima10GrausPerguntaPosturaInadequadaPunhos"}
                                   onChange={(e, b) => handleCheckboxChange('desviosLateraisAcima10GrausPerguntaPosturaInadequadaPunhos', b.checked)}
                                   checked={coleta.desviosLateraisAcima10GrausPerguntaPosturaInadequadaPunhos}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Extensão acima de 15 graus"}
                                   id={"extensaoAcima15GrausPerguntaPosturaInadequadaPunhos"}
                                   onChange={(e, b) => handleCheckboxChange('extensaoAcima15GrausPerguntaPosturaInadequadaPunhos', b.checked)}
                                   checked={coleta.extensaoAcima15GrausPerguntaPosturaInadequadaPunhos}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Totalmente flexionado/estendido"}
                                   id={"totalmenteFlexionadoEstendidoPerguntaPosturaInadequadaPunhos"}
                                   onChange={(e, b) => handleCheckboxChange('totalmenteFlexionadoEstendidoPerguntaPosturaInadequadaPunhos', b.checked)}
                                   checked={coleta.totalmenteFlexionadoEstendidoPerguntaPosturaInadequadaPunhos}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Não existe riscos para os punhos nessa atividade"}
                                   id={"naoExisteRiscoPunhosPerguntaPosturaInadequadaPunhos"}
                                   onChange={(e, b) => handleCheckboxChange('naoExisteRiscoPunhosPerguntaPosturaInadequadaPunhos', b.checked)}
                                   checked={coleta.naoExisteRiscoPunhosPerguntaPosturaInadequadaPunhos}/>

                    <Form.Input
                        label="Outros"
                        name="outrosPerguntaPosturaInadequadaPunhos"
                        type="text"
                        placeholder="Outros fatores de postura inadequada dos punhos"
                        value={coleta.outrosPerguntaPosturaInadequadaPunhos}
                        onChange={handleInputChange}
                    />
                </Form.Field>


                <h4>Marque a postura inadequada do Tronco:
                </h4>
                <Form.Field>

                    <Form.Checkbox type="checkbox"
                                   label={"Flexão maior ou igual a 20 graus\n"}
                                   id={"flexaoMaiorOu20GrausPerguntaPosturaInadequadaTronco"}
                                   onChange={(e, b) => handleCheckboxChange('flexaoMaiorOu20GrausPerguntaPosturaInadequadaTronco', b.checked)}
                                   checked={coleta.flexaoMaiorOu20GrausPerguntaPosturaInadequadaTronco}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Extensão sem suporte acima de 10 graus.\n"}
                                   id={"extensaoSemSuporteAcima10GrausPerguntaPosturaInadequadaTronco"}
                                   onChange={(e, b) => handleCheckboxChange('extensaoSemSuporteAcima10GrausPerguntaPosturaInadequadaTronco', b.checked)}
                                   checked={coleta.extensaoSemSuporteAcima10GrausPerguntaPosturaInadequadaTronco}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Inclinação acima de 30 graus\n"}
                                   id={"inclinacaoAcimaDe30GrausPerguntaPosturaInadequadaTronco"}
                                   onChange={(e, b) => handleCheckboxChange('inclinacaoAcimaDe30GrausPerguntaPosturaInadequadaTronco', b.checked)}
                                   checked={coleta.inclinacaoAcimaDe30GrausPerguntaPosturaInadequadaTronco}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Rotação Acima de 30 graus\n"}
                                   id={"rotacaoAcimaDe30GrausPerguntaPosturaInadequadaTronco"}
                                   onChange={(e, b) => handleCheckboxChange('rotacaoAcimaDe30GrausPerguntaPosturaInadequadaTronco', b.checked)}
                                   checked={coleta.rotacaoAcimaDe30GrausPerguntaPosturaInadequadaTronco}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Não existe riscos para o tronco nessa atividade"}
                                   id={"naoExisteRiscoTroncoPerguntaPosturaInadequadaTronco"}
                                   onChange={(e, b) => handleCheckboxChange('naoExisteRiscoTroncoPerguntaPosturaInadequadaTronco', b.checked)}
                                   checked={coleta.naoExisteRiscoTroncoPerguntaPosturaInadequadaTronco}/>

                    <Form.Input
                        label="Outros"
                        name="outrosPerguntaPosturaInadequadaTronco"
                        type="text"
                        placeholder="Outros fatores de postura inadequada do tronco"
                        value={coleta.outrosPerguntaPosturaInadequadaTronco}
                        onChange={handleInputChange}
                    />
                </Form.Field>


                <h4>Marque a postura inadequada das Pernas:
                </h4>
                <Form.Field>

                    <Form.Checkbox type="checkbox"
                                   label={"Flexão acima de 90 graus"}
                                   id={"flexaoAcima90GrausPerguntaPosturaInadequadaPernas"}
                                   onChange={(e, b) => handleCheckboxChange('flexaoAcima90GrausPerguntaPosturaInadequadaPernas', b.checked)}
                                   checked={coleta.flexaoAcima90GrausPerguntaPosturaInadequadaPernas}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Não está totalmente apoiada e equilibrada"}
                                   id={"naoTotalmenteApoiadaOuEquilibradaPerguntaPosturaInadequadaPernas"}
                                   onChange={(e, b) => handleCheckboxChange('naoTotalmenteApoiadaOuEquilibradaPerguntaPosturaInadequadaPernas', b.checked)}
                                   checked={coleta.naoTotalmenteApoiadaOuEquilibradaPerguntaPosturaInadequadaPernas}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Não há espaço suficiente para as pernas por mais de 2 horas"}
                                   id={"naoHaEspacoParaAsPernasMaisDe2HorasPerguntaPosturaInadequadaPernas"}
                                   onChange={(e, b) => handleCheckboxChange('naoHaEspacoParaAsPernasMaisDe2HorasPerguntaPosturaInadequadaPernas', b.checked)}
                                   checked={coleta.naoHaEspacoParaAsPernasMaisDe2HorasPerguntaPosturaInadequadaPernas}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Agachado ou ajoelhado"}
                                   id={"agachadoOuAjoelhadoPerguntaPosturaInadequadaPernas"}
                                   onChange={(e, b) => handleCheckboxChange('agachadoOuAjoelhadoPerguntaPosturaInadequadaPernas', b.checked)}
                                   checked={coleta.agachadoOuAjoelhadoPerguntaPosturaInadequadaPernas}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Não existe riscos para pernas nessa atividade"}
                                   id={"naoExisteRiscoPernasPerguntaPosturaInadequadaPernas"}
                                   onChange={(e, b) => handleCheckboxChange('naoExisteRiscoPernasPerguntaPosturaInadequadaPernas', b.checked)}
                                   checked={coleta.naoExisteRiscoPernasPerguntaPosturaInadequadaPernas}/>

                    <Form.Input
                        label="Outros"
                        name="outrosPerguntaPosturaInadequadaPernas"
                        type="text"
                        placeholder="Outros fatores de postura inadequada das pernas"
                        value={coleta.outrosPerguntaPosturaInadequadaPernas}
                        onChange={handleInputChange}
                    />
                </Form.Field>


                <h4>Outros perigos posturais Gerais:
                </h4>
                <Form.Field>

                    <Form.Checkbox type="checkbox"
                                   label={"Postura sentado por longo período\n"}
                                   id={"posturaSentadoLongoPeriodoPerguntaPerigosPosturaisGerais"}
                                   onChange={(e, b) => handleCheckboxChange('posturaSentadoLongoPeriodoPerguntaPerigosPosturaisGerais', b.checked)}
                                   checked={coleta.posturaSentadoLongoPeriodoPerguntaPerigosPosturaisGerais}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Postura em pé por longo período\n"}
                                   id={"posturaEmPeLongoPeriodoPerguntaPerigosPosturaisGerais"}
                                   onChange={(e, b) => handleCheckboxChange('posturaEmPeLongoPeriodoPerguntaPerigosPosturaisGerais', b.checked)}
                                   checked={coleta.posturaEmPeLongoPeriodoPerguntaPerigosPosturaisGerais}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Frequente deslocamento a pé durante a jornada de trabalho\n"}
                                   id={"frequentementeDeslocAPeDuranteTrabalhoPerguntaPerigosPosturaisGerais"}
                                   onChange={(e, b) => handleCheckboxChange('frequentementeDeslocAPeDuranteTrabalhoPerguntaPerigosPosturaisGerais', b.checked)}
                                   checked={coleta.frequentementeDeslocAPeDuranteTrabalhoPerguntaPerigosPosturaisGerais}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Levantamento e transporte manual de cargas ou volumes\n"}
                                   id={"levantamentoTransporteManualDeCargasPerguntaPerigosPosturaisGerais"}
                                   onChange={(e, b) => handleCheckboxChange('levantamentoTransporteManualDeCargasPerguntaPerigosPosturaisGerais', b.checked)}
                                   checked={coleta.levantamentoTransporteManualDeCargasPerguntaPerigosPosturaisGerais}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Frequente execução de movimentos repetitivos\n"}
                                   id={"movimentosRepetitivosFrequentesPerguntaPerigosPosturaisGerais"}
                                   onChange={(e, b) => handleCheckboxChange('movimentosRepetitivosFrequentesPerguntaPerigosPosturaisGerais', b.checked)}
                                   checked={coleta.movimentosRepetitivosFrequentesPerguntaPerigosPosturaisGerais}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Manuseio de ferramentas e/ou objetos pesados por períodos prolongados\n"}
                                   id={"manuseioFerramentasObjetosPesadosLongosPeriodosPerguntaPerigosPosturaisGerais"}
                                   onChange={(e, b) => handleCheckboxChange('manuseioFerramentasObjetosPesadosLongosPeriodosPerguntaPerigosPosturaisGerais', b.checked)}
                                   checked={coleta.manuseioFerramentasObjetosPesadosLongosPeriodosPerguntaPerigosPosturaisGerais}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Compressão de partes do corpo por superfícies rígidas ou com quinas\n"}
                                   id={"compressaoPartesCorpoPorSuperficiesPerguntaPerigosPosturaisGerais"}
                                   onChange={(e, b) => handleCheckboxChange('compressaoPartesCorpoPorSuperficiesPerguntaPerigosPosturaisGerais', b.checked)}
                                   checked={coleta.compressaoPartesCorpoPorSuperficiesPerguntaPerigosPosturaisGerais}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Uso frequente de pedais\n"}
                                   id={"usoFrequentePedaisPerguntaPerigosPosturaisGerais"}
                                   onChange={(e, b) => handleCheckboxChange('usoFrequentePedaisPerguntaPerigosPosturaisGerais', b.checked)}
                                   checked={coleta.usoFrequentePedaisPerguntaPerigosPosturaisGerais}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Uso frequente de alavancas\n"}
                                   id={"usoFrequenteAlavancasPerguntaPerigosPosturaisGerais"}
                                   onChange={(e, b) => handleCheckboxChange('usoFrequenteAlavancasPerguntaPerigosPosturaisGerais', b.checked)}
                                   checked={coleta.usoFrequenteAlavancasPerguntaPerigosPosturaisGerais}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Exposição à vibração de corpo inteiro\n"}
                                   id={"exposicaoAVibracaoDeCorpoInteiroPerguntaPerigosPosturaisGerais"}
                                   onChange={(e, b) => handleCheckboxChange('exposicaoAVibracaoDeCorpoInteiroPerguntaPerigosPosturaisGerais', b.checked)}
                                   checked={coleta.exposicaoAVibracaoDeCorpoInteiroPerguntaPerigosPosturaisGerais}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Exposição à vibração localizada\n"}
                                   id={"exposicaoAVibracaoLocalizadaPerguntaPerigosPosturaisGerais"}
                                   onChange={(e, b) => handleCheckboxChange('exposicaoAVibracaoLocalizadaPerguntaPerigosPosturaisGerais', b.checked)}
                                   checked={coleta.exposicaoAVibracaoLocalizadaPerguntaPerigosPosturaisGerais}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Uso frequente de escadas\n"}
                                   id={"usoFrequenteEscadasPerguntaPerigosPosturaisGerais"}
                                   onChange={(e, b) => handleCheckboxChange('usoFrequenteEscadasPerguntaPerigosPosturaisGerais', b.checked)}
                                   checked={coleta.usoFrequenteEscadasPerguntaPerigosPosturaisGerais}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Trabalho intensivo com teclado ou outros dispositivos de entrada de dados\n"}
                                   id={"trabalhoIntensivoTecladoEntradaDeDadosPerguntaPerigosPosturaisGerais"}
                                   onChange={(e, b) => handleCheckboxChange('trabalhoIntensivoTecladoEntradaDeDadosPerguntaPerigosPosturaisGerais', b.checked)}
                                   checked={coleta.trabalhoIntensivoTecladoEntradaDeDadosPerguntaPerigosPosturaisGerais}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Não existe outros riscos físicos nessa atividade\n"}
                                   id={"naoExisteRiscosNessaAtivPerguntaPerigosPosturaisGerais"}
                                   onChange={(e, b) => handleCheckboxChange('naoExisteRiscosNessaAtivPerguntaPerigosPosturaisGerais', b.checked)}
                                   checked={coleta.naoExisteRiscosNessaAtivPerguntaPerigosPosturaisGerais}/>

                    <Form.Input
                        label="Outros"
                        name="outrosPerguntaPerigosPosturaisGerais"
                        type="text"
                        placeholder="Outros fatores de postura inadequada geral"
                        value={coleta.outrosPerguntaPerigosPosturaisGerais}
                        onChange={handleInputChange}
                    />
                </Form.Field>
            </Form>
        );
    };

    const renderStep4 = () => {
        const currentStep: number = 4;
        return (
            <Form onSubmit={handleSubmit}>
                <Header textAlign={"center"} as={"h2"}>Pesquisa Ergonômica - NR-17</Header>
                <Header as="h3" textAlign="center" color={'black'}>
                    Forças existentes na atividade
                </Header>
                <ProgressBar totalSteps={9} currentStep={currentStep} />
                <h4>Marque as FORÇAS existentes na atividade avaliada:</h4>
                <Form.Field>

                    <Form.Checkbox type="checkbox"
                                   label={"Entre 2 a 10kg (intermitente)"}
                                   id={"entre2A10KGPerguntaForcasExistentesNaAtividadeAvaliada"}
                                   onChange={(e, b) => handleCheckboxChange('entre2A10KGPerguntaForcasExistentesNaAtividadeAvaliada', b.checked)}
                                   checked={coleta.entre2A10KGPerguntaForcasExistentesNaAtividadeAvaliada}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Entre 2 a 10kg (estático ou mais de 4x por min.)"}
                                   id={"entre2A10KGEstaticoPerguntaForcasExistentesNaAtividadeAvaliada"}
                                   onChange={(e, b) => handleCheckboxChange('entre2A10KGEstaticoPerguntaForcasExistentesNaAtividadeAvaliada', b.checked)}
                                   checked={coleta.entre2A10KGEstaticoPerguntaForcasExistentesNaAtividadeAvaliada}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Maior que 10kg (repetitivo ou impacto)"}
                                   id={"maior10KgRepetitivoOuImpactoPerguntaForcasExistentesNaAtividadeAvaliada"}
                                   onChange={(e, b) => handleCheckboxChange('maior10KgRepetitivoOuImpactoPerguntaForcasExistentesNaAtividadeAvaliada', b.checked)}
                                   checked={coleta.maior10KgRepetitivoOuImpactoPerguntaForcasExistentesNaAtividadeAvaliada}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Postura estática (segurar por mais de 1 min. ou ação repetitiva 4x ou mais por minuto)"}
                                   id={"posturaEstaticaSegurarPor1MOuMaisPerguntaForcasExistentesNaAtividadeAvaliada"}
                                   onChange={(e, b) => handleCheckboxChange('posturaEstaticaSegurarPor1MOuMaisPerguntaForcasExistentesNaAtividadeAvaliada', b.checked)}
                                   checked={coleta.posturaEstaticaSegurarPor1MOuMaisPerguntaForcasExistentesNaAtividadeAvaliada}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Mãos em pinça/pressão maior que 0,9 kg"}
                                   id={"maosEmPincaPressaoMaiorQue09KGPerguntaForcasExistentesNaAtividadeAvaliada"}
                                   onChange={(e, b) => handleCheckboxChange('maosEmPincaPressaoMaiorQue09KGPerguntaForcasExistentesNaAtividadeAvaliada', b.checked)}
                                   checked={coleta.maosEmPincaPressaoMaiorQue09KGPerguntaForcasExistentesNaAtividadeAvaliada}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Mãos em agarre maior de 4,5kg"}
                                   id={"maosEmAgarreMaiorQue45KgPerguntaForcasExistentesNaAtividadeAvaliada"}
                                   onChange={(e, b) => handleCheckboxChange('maosEmAgarreMaiorQue45KgPerguntaForcasExistentesNaAtividadeAvaliada', b.checked)}
                                   checked={coleta.maosEmAgarreMaiorQue45KgPerguntaForcasExistentesNaAtividadeAvaliada}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Cotovelos maior que 4,5kg"}
                                   id={"cotovelosMaiorQue45KgPerguntaForcasExistentesNaAtividadeAvaliada"}
                                   onChange={(e, b) => handleCheckboxChange('cotovelosMaiorQue45KgPerguntaForcasExistentesNaAtividadeAvaliada', b.checked)}
                                   checked={coleta.cotovelosMaiorQue45KgPerguntaForcasExistentesNaAtividadeAvaliada}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Ombros maior que 4,5kg"}
                                   id={"ombrosMaiorQue45KgPerguntaForcasExistentesNaAtividadeAvaliada"}
                                   onChange={(e, b) => handleCheckboxChange('ombrosMaiorQue45KgPerguntaForcasExistentesNaAtividadeAvaliada', b.checked)}
                                   checked={coleta.ombrosMaiorQue45KgPerguntaForcasExistentesNaAtividadeAvaliada}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Cervical peso adicional"}
                                   id={"cervicalPesoAdicionalPerguntaForcasExistentesNaAtividadeAvaliada"}
                                   onChange={(e, b) => handleCheckboxChange('cervicalPesoAdicionalPerguntaForcasExistentesNaAtividadeAvaliada', b.checked)}
                                   checked={coleta.cervicalPesoAdicionalPerguntaForcasExistentesNaAtividadeAvaliada}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Coluna maior que 11,3 kg"}
                                   id={"colunaMaiorQue113KgPerguntaForcasExistentesNaAtividadeAvaliada"}
                                   onChange={(e, b) => handleCheckboxChange('colunaMaiorQue113KgPerguntaForcasExistentesNaAtividadeAvaliada', b.checked)}
                                   checked={coleta.colunaMaiorQue113KgPerguntaForcasExistentesNaAtividadeAvaliada}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Pedal maior que 4,5 kg"}
                                   id={"pedalMaiorQue45KgPerguntaForcasExistentesNaAtividadeAvaliada"}
                                   onChange={(e, b) => handleCheckboxChange('pedalMaiorQue45KgPerguntaForcasExistentesNaAtividadeAvaliada', b.checked)}
                                   checked={coleta.pedalMaiorQue45KgPerguntaForcasExistentesNaAtividadeAvaliada}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Transporte manual maior que 15kg/min"}
                                   id={"transporteManual15kgMinutoPerguntaForcasExistentesNaAtividadeAvaliada"}
                                   onChange={(e, b) => handleCheckboxChange('transporteManual15kgMinutoPerguntaForcasExistentesNaAtividadeAvaliada', b.checked)}
                                   checked={coleta.transporteManual15kgMinutoPerguntaForcasExistentesNaAtividadeAvaliada}/>

                    <Form.Checkbox type="checkbox"
                                   label={"5.1 a 10kg mais que 1x/min"}
                                   id={"peso5A10Kg1XPorMinutoPerguntaForcasExistentesNaAtividadeAvaliada"}
                                   onChange={(e, b) => handleCheckboxChange('peso5A10Kg1XPorMinutoPerguntaForcasExistentesNaAtividadeAvaliada', b.checked)}
                                   checked={coleta.peso5A10Kg1XPorMinutoPerguntaForcasExistentesNaAtividadeAvaliada}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Pegar ou manusear peso acima de 175cm"}
                                   id={"pegarPesoAcimaDe175CmPerguntaForcasExistentesNaAtividadeAvaliada"}
                                   onChange={(e, b) => handleCheckboxChange('pegarPesoAcimaDe175CmPerguntaForcasExistentesNaAtividadeAvaliada', b.checked)}
                                   checked={coleta.pegarPesoAcimaDe175CmPerguntaForcasExistentesNaAtividadeAvaliada}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Puxar/empurrar em desnível"}
                                   id={"puxarEmpurrarEmDesnivelPerguntaForcasExistentesNaAtividadeAvaliada"}
                                   onChange={(e, b) => handleCheckboxChange('puxarEmpurrarEmDesnivelPerguntaForcasExistentesNaAtividadeAvaliada', b.checked)}
                                   checked={coleta.puxarEmpurrarEmDesnivelPerguntaForcasExistentesNaAtividadeAvaliada}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Distância horizontal maior que 63 cm (pegar a carga)"}
                                   id={"distanciaHorizontalMaior63CmPegarCargaPerguntaForcasExistentesNaAtividadeAvaliada"}
                                   onChange={(e, b) => handleCheckboxChange('distanciaHorizontalMaior63CmPegarCargaPerguntaForcasExistentesNaAtividadeAvaliada', b.checked)}
                                   checked={coleta.distanciaHorizontalMaior63CmPegarCargaPerguntaForcasExistentesNaAtividadeAvaliada}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Não há riscos/perigos de força nessa atividade"}
                                   id={"naoHaRiscosDeForcaNaAtividadePerguntaForcasExistentesNaAtividadeAvaliada"}
                                   onChange={(e, b) => handleCheckboxChange('naoHaRiscosDeForcaNaAtividadePerguntaForcasExistentesNaAtividadeAvaliada', b.checked)}
                                   checked={coleta.naoHaRiscosDeForcaNaAtividadePerguntaForcasExistentesNaAtividadeAvaliada}/>

                    <Form.Input
                        label="Outros"
                        name="outrosPerguntaForcasExistentesNaAtividadeAvaliada"
                        type="text"
                        placeholder="Outros fatores de força existentes na atividade avaliada"
                        value={coleta.outrosPerguntaForcasExistentesNaAtividadeAvaliada}
                        onChange={handleInputChange}
                    />
                </Form.Field>
            </Form>
        );
    };

    const renderStep5 = () => {
        const currentStep: number = 5;
        return (
            <Form onSubmit={handleSubmit}>
                <Header textAlign={"center"} as={"h2"}>Pesquisa Ergonômica - NR-17</Header>
                <Header  textAlign={"center"} as={"h2"}>Fatores de repetitividade existentes na atividade</Header>
                <ProgressBar totalSteps={9} currentStep={currentStep} />
                <h4>Marque os fatores REPETITIVIDADE existentes na atividade avaliada:
                </h4>
                <Form.Field>
                    <Form.Checkbox
                        type="checkbox"
                        label="Movimento constantes sem pausas"
                        onChange={(e, b) => handleCheckboxChange('movimentoConstanteSemPausasPerguntaFatoresRepetitividadeNaAtividade', b.checked)}
                        id={"movimentoConstanteSemPausasPerguntaFatoresRepetitividadeNaAtividade"}
                        checked={coleta.movimentoConstanteSemPausasPerguntaFatoresRepetitividadeNaAtividade}></Form.Checkbox>

                    <Form.Checkbox type="checkbox"
                                   label={"Movimento frequente com pausas"}
                                   onChange={(e, b) => handleCheckboxChange('movimentoFrequenteComPausaPerguntaFatoresRepetitividadeNaAtividade', b.checked)}
                                   id={"movimentoFrequenteComPausaPerguntaFatoresRepetitividadeNaAtividade"}
                                   checked={coleta.movimentoFrequenteComPausaPerguntaFatoresRepetitividadeNaAtividade}
                    />

                    <Form.Checkbox type="checkbox"
                                   label={"Repetição das mesmas ações técnicas por mais de 50% do ciclo da atividade"}
                                   onChange={(e, b) => handleCheckboxChange('repeticaoDeAcoesTecnicasMaisDaMetadeDoCicloPerguntaFatoresRepetitividadeNaAtividade', b.checked)}
                                   id={"repeticaoDeAcoesTecnicasMaisDaMetadeDoCicloPerguntaFatoresRepetitividadeNaAtividade"}
                                   checked={coleta.repeticaoDeAcoesTecnicasMaisDaMetadeDoCicloPerguntaFatoresRepetitividadeNaAtividade}
                    />

                    <Form.Checkbox type="checkbox"
                                   label={"Não há repetitividade nessa atividade"}
                                   onChange={(e, b) => handleCheckboxChange('naoHaRepetitividadeNaAtividadePerguntaFatoresRepetitividadeNaAtividade', b.checked)}
                                   id={"naoHaRepetitividadeNaAtividadePerguntaFatoresRepetitividadeNaAtividade"}
                                   checked={coleta.naoHaRepetitividadeNaAtividadePerguntaFatoresRepetitividadeNaAtividade}/>

                    <Form.Input
                        label="Outros"
                        name="outrosPerguntaFatoresRepetitividadeNaAtividade"
                        type="text"
                        placeholder="Outros fatores de repetitividade na atividade"
                        value={coleta.outrosPerguntaFatoresRepetitividadeNaAtividade}
                        onChange={handleInputChange}
                    />
                </Form.Field>
            </Form>
        );
    };

    const renderStep6 = () => {
        const currentStep: number = 6;
        return (
            <Form onSubmit={handleSubmit}>
                <Header textAlign={"center"} as={"h2"}>Pesquisa Ergonômica - NR-17</Header>
                <Header  textAlign={"center"} as={"h2"}>Organização do trabalho</Header>
                <ProgressBar totalSteps={9} currentStep={currentStep} />
                <span>Identificação de riscos ergonômicos relacionados a organização do trabalho</span>
                <h4>Marque os fatores ORGANIZAÇÃO DO TRABALHO existentes na atividade avaliada:</h4>

                <Form.Field>
                    <Form.Checkbox type="checkbox"
                                   label={"Trabalho realizado sem pausas pré-definidas para descanso"}
                                   id={"trabalhoSemPausasPreDefinidasPerguntaOrganizacaoDoTrabalho"}
                                   onChange={(e, b) => handleCheckboxChange('trabalhoSemPausasPreDefinidasPerguntaOrganizacaoDoTrabalho', b.checked)}
                                   checked={coleta.trabalhoSemPausasPreDefinidasPerguntaOrganizacaoDoTrabalho}>
                    </Form.Checkbox>

                    <Form.Checkbox type="checkbox"
                                   label={"A pausa é realizada fora do posto de trabalho"}
                                   id={"pausaRealizadaForaDoPostoPerguntaOrganizacaoDoTrabalho"}
                                   onChange={(e, b) => handleCheckboxChange('pausaRealizadaForaDoPostoPerguntaOrganizacaoDoTrabalho', b.checked)}
                                   checked={coleta.pausaRealizadaForaDoPostoPerguntaOrganizacaoDoTrabalho}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Necessidade de manter ritmos intensos de trabalho"}
                                   id={"necessidadadeDeManterRitmosIntensosPerguntaOrganizacaoDoTrabalho"}
                                   onChange={(e, b) => handleCheckboxChange('necessidadadeDeManterRitmosIntensosPerguntaOrganizacaoDoTrabalho', b.checked)}
                                   checked={coleta.necessidadadeDeManterRitmosIntensosPerguntaOrganizacaoDoTrabalho}
                    />

                    <Form.Checkbox type="checkbox"
                                   label={"Trabalho com necessidade de variação de turnos"}
                                   id={"trabalhoComNecessidadeDeVariacaoDeTurnosPerguntaOrganizacaoDoTrabalho"}
                                   onChange={(e, b) => handleCheckboxChange('trabalhoComNecessidadeDeVariacaoDeTurnosPerguntaOrganizacaoDoTrabalho', b.checked)}
                                   checked={coleta.trabalhoComNecessidadeDeVariacaoDeTurnosPerguntaOrganizacaoDoTrabalho}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Monotonia"}
                                   id={"monotoniaPerguntaOrganizacaoDoTrabalho"}
                                   onChange={(e, b) => handleCheckboxChange('monotoniaPerguntaOrganizacaoDoTrabalho', b.checked)}
                                   checked={coleta.monotoniaPerguntaOrganizacaoDoTrabalho}/>

                    <Form.Checkbox
                        type="checkbox"
                        label={"Trabalho noturno"}
                        id={"trabalhoNoturnoPerguntaOrganizacaoDoTrabalho"}
                        onChange={(e, b) => handleCheckboxChange('trabalhoNoturnoPerguntaOrganizacaoDoTrabalho', b.checked)}
                        checked={coleta.trabalhoNoturnoPerguntaOrganizacaoDoTrabalho}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Insuficiência de capacitação para execução da tarefa"}
                                   id={"insuficienciaDeCapacitacaoParaExecucaoPerguntaOrganizacaoDoTrabalho"}
                                   onChange={(e, b) => handleCheckboxChange('insuficienciaDeCapacitacaoParaExecucaoPerguntaOrganizacaoDoTrabalho', b.checked)}
                                   checked={coleta.insuficienciaDeCapacitacaoParaExecucaoPerguntaOrganizacaoDoTrabalho}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Trabalho com utilização rigorosa de metas de produção"}
                                   id={"trabalhoUtilizacaoRigorosaDeMetasDeProdPerguntaOrganizacaoDoTrabalho"}
                                   onChange={(e, b) => handleCheckboxChange('trabalhoUtilizacaoRigorosaDeMetasDeProdPerguntaOrganizacaoDoTrabalho', b.checked)}
                                   checked={coleta.trabalhoUtilizacaoRigorosaDeMetasDeProdPerguntaOrganizacaoDoTrabalho}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Trabalho remunerado por produção"}
                                   id={"trabalhoRemuneradoPorProducaoPerguntaOrganizacaoDoTrabalho"}
                                   onChange={(e, b) => handleCheckboxChange('trabalhoRemuneradoPorProducaoPerguntaOrganizacaoDoTrabalho', b.checked)}
                                   checked={coleta.trabalhoRemuneradoPorProducaoPerguntaOrganizacaoDoTrabalho}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Cadência do trabalho imposta por um equipamento"}
                                   id={"cadenciaDoTrabalhoImpostaPorEquipamentoPerguntaOrganizacaoDoTrabalho"}
                                   onChange={(e, b) => handleCheckboxChange('cadenciaDoTrabalhoImpostaPorEquipamentoPerguntaOrganizacaoDoTrabalho', b.checked)}
                                   checked={coleta.cadenciaDoTrabalhoImpostaPorEquipamentoPerguntaOrganizacaoDoTrabalho}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Desequilíbrio entre tempo de trabalho e tempo de repouso (menos de 11h)"}
                                   id={"desequilibrioEntreTempoTrabalhadoETempoRepousoPerguntaOrganizacaoDoTrabalho"}
                                   onChange={(e, b) => handleCheckboxChange('desequilibrioEntreTempoTrabalhadoETempoRepousoPerguntaOrganizacaoDoTrabalho', b.checked)}
                                   checked={coleta.desequilibrioEntreTempoTrabalhadoETempoRepousoPerguntaOrganizacaoDoTrabalho}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Jornada longa de trabalho de mais de 8 h por dia"}
                                   id={"jornadaLongaDeTrabalhoMaisDe8HPorDiaPerguntaOrganizacaoDoTrabalho"}
                                   onChange={(e, b) => handleCheckboxChange('jornadaLongaDeTrabalhoMaisDe8HPorDiaPerguntaOrganizacaoDoTrabalho', b.checked)}
                                   checked={coleta.jornadaLongaDeTrabalhoMaisDe8HPorDiaPerguntaOrganizacaoDoTrabalho}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Concentrações desequilibradas de trabalho em um dia, semana, mês ou ano"}
                                   id={"concentracoesDesequilibradasDeTrabalhoPerguntaOrganizacaoDoTrabalho"}
                                   onChange={(e, b) => handleCheckboxChange('concentracoesDesequilibradasDeTrabalhoPerguntaOrganizacaoDoTrabalho', b.checked)}
                                   checked={coleta.concentracoesDesequilibradasDeTrabalhoPerguntaOrganizacaoDoTrabalho}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Nenhum fator de risco/perigo organizacional nessa atividade."}
                                   id={"nenhumFatorDeRisgoNaAtivPerguntaOrganizacaoDoTrabalho"}
                                   onChange={(e, b) => handleCheckboxChange('nenhumFatorDeRisgoNaAtivPerguntaOrganizacaoDoTrabalho', b.checked)}
                                   checked={coleta.nenhumFatorDeRisgoNaAtivPerguntaOrganizacaoDoTrabalho}/>

                    <Form.Input
                        label="Outros"
                        name="outrosPerguntaOrganizacaoDoTrabalho"
                        type="text"
                        placeholder="Outros fatores de risco em relação a organização do trabalho"
                        value={coleta.outrosPerguntaOrganizacaoDoTrabalho}
                        onChange={handleInputChange}
                    />
                </Form.Field>
            </Form>
        );
    };

    const renderStep7 = () => {
        const currentStep: number = 7;
        return (
            <Form onSubmit={handleSubmit}>
                <Header textAlign={"center"} as={"h2"}>Pesquisa Ergonômica - NR-17</Header>
                <Header  textAlign={"center"} as={"h2"}>Fatores psicossociais</Header>
                <ProgressBar totalSteps={9} currentStep={currentStep} />
                <span>Identificação de riscos e perigos ergonômicos relacionados a fatores psicossociais</span>
                <h4>Marque os fatores PSICOSSOCIAIS existentes na atividade avaliada:</h4>
                <Form.Field>

                    <Form.Checkbox type="checkbox"
                                   label={"Trabalho em condições de difícil comunicação"}
                                   id={"trabalhoCondicoesDeDificilComunicacaoPerguntaFatoresPsicossociais"}
                                   onChange={(e, b) => handleCheckboxChange('trabalhoCondicoesDeDificilComunicacaoPerguntaFatoresPsicossociais', b.checked)}
                                   checked={coleta.trabalhoCondicoesDeDificilComunicacaoPerguntaFatoresPsicossociais}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Excesso de conflitos hierárquicos no trabalho"}
                                   id={"excessoDeConflitosHierarquicosPerguntaFatoresPsicossociais"}
                                   onChange={(e, b) => handleCheckboxChange('excessoDeConflitosHierarquicosPerguntaFatoresPsicossociais', b.checked)}
                                   checked={coleta.excessoDeConflitosHierarquicosPerguntaFatoresPsicossociais}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Insatisfação no trabalho"}
                                   id={"insatisfacaoNoTrabalhoPerguntaFatoresPsicossociais"}
                                   onChange={(e, b) => handleCheckboxChange('insatisfacaoNoTrabalhoPerguntaFatoresPsicossociais', b.checked)}
                                   checked={coleta.insatisfacaoNoTrabalhoPerguntaFatoresPsicossociais}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Falta de autonomia no trabalho"}
                                   id={"faltaDeAutonomiaNoTrabalhoPerguntaFatoresPsicossociais"}
                                   onChange={(e, b) => handleCheckboxChange('faltaDeAutonomiaNoTrabalhoPerguntaFatoresPsicossociais', b.checked)}
                                   checked={coleta.faltaDeAutonomiaNoTrabalhoPerguntaFatoresPsicossociais}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Estresse no trabalho"}
                                   id={"extresseNoTrabalhoPerguntaFatoresPsicossociais"}
                                   onChange={(e, b) => handleCheckboxChange('extresseNoTrabalhoPerguntaFatoresPsicossociais', b.checked)}
                                   checked={coleta.extresseNoTrabalhoPerguntaFatoresPsicossociais}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Não há nenhum fator de risco/perigo psicossocial na atividade."}
                                   id={"naoHaFatorDeRiscoPsicossocialPerguntaFatoresPsicossociais"}
                                   onChange={(e, b) => handleCheckboxChange('naoHaFatorDeRiscoPsicossocialPerguntaFatoresPsicossociais', b.checked)}
                                   checked={coleta.naoHaFatorDeRiscoPsicossocialPerguntaFatoresPsicossociais}/>

                    <Form.Input
                        label="Outros"
                        name="outrosPerguntaFatoresPsicossociais"
                        type="text"
                        placeholder="Outros fatores de risco em relação a fatores psicossociais do trabalho"
                        value={coleta.outrosPerguntaFatoresPsicossociais}
                        onChange={handleInputChange}
                    />
                </Form.Field>
            </Form>
        );
    };

    const renderStep8 = () => {
        const currentStep: number = 8;
        return (
            <Form onSubmit={handleSubmit}
                  ref={formRef8}>
                <Header textAlign={"center"} as={"h2"}>Pesquisa Ergonômica - NR-17</Header>
                <Header  textAlign={"center"} as={"h2"}>Matriz de riscos</Header>
                <ProgressBar totalSteps={9} currentStep={currentStep} />
                <span>Dados para classificação do nível de risco.</span>
                <img src={'/charts/matriz_risco.png'} style={{
                    objectFit: 'contain',
                    marginTop: 10,
                    marginBottom: 10,
                    padding: 20,
                    alignSelf: 'center',
                    width: '100%',

                }} alt={'matriz de risco'}/>

                <Form.Field>
                    <Form.Select
                        label={'Marque o fator GRAVIDADE existentes na atividade avaliada:'}
                        options={EPerguntaFatorDeRiscoGravidade}
                        placeholder='Selecione uma opção'
                        value={coleta.perguntaFatorDeRiscoGravidade}
                        onChange={(e: any, data: any) => handleDropdownChange('perguntaFatorDeRiscoGravidade', data)}
                        required={step === 8}
                        error={submitted && coleta.perguntaFatorDeRiscoGravidade === undefined}

                    />
                </Form.Field>

                <Form.Field>
                    <Form.Select
                        label={'Marque o fator PROBABILIDADE existentes na atividade avaliada:'}
                        options={EPerguntaFatorDeProbabilidade}
                        placeholder='Selecione uma opção'
                        value={coleta.perguntaFatorDeProbabilidade}
                        onChange={(e: any, data: any) => handleDropdownChange('perguntaFatorDeProbabilidade', data)}
                        required
                        error={submitted && coleta.perguntaFatorDeProbabilidade === undefined}
                    />
                </Form.Field>

                <Form.Field>
                    <Form.Input
                        label={'Sugestão do coletador sobre o posto de trabalho e outras informações importantes:'}
                        name="sugestaoDoColetador"
                        type="text"
                        placeholder="Sugestão do coletador..."
                        value={coleta.sugestaoDoColetador}
                        onChange={handleInputChange}
                        required
                        error={submitted && coleta.sugestaoDoColetador === undefined}
                    />
                </Form.Field>
            </Form>
        );
    };

    const renderStep9 = () => {
        const currentStep: number = 9;
        return (
            <Form onSubmit={handleSubmit}>
                <Header textAlign={"center"} as={"h2"}>Pesquisa Ergonômica - NR-17</Header>
                <Header  textAlign={"center"} as={"h2"} >Fatores cognitivos</Header>
                <ProgressBar totalSteps={9} currentStep={currentStep} />
                <h4>Identificação de riscos e perigos ergonômicos relacionados a fatores cognitivos</h4>
                <Form.Field>
                    <Form.Checkbox type="checkbox"
                                   label={"Trabalho exige concentração"}
                                   id={"trabalhoExigeConcentracaoPerguntaFatoresCognitivos"}
                                   onChange={(e, b) => handleCheckboxChange('trabalhoExigeConcentracaoPerguntaFatoresCognitivos', b.checked)}
                                   checked={coleta.trabalhoExigeConcentracaoPerguntaFatoresCognitivos}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Trabalho exige atenção"}
                                   id={"trabalhoExigeAtencaoPerguntaFatoresCognitivos"}
                                   onChange={(e, b) => handleCheckboxChange('trabalhoExigeAtencaoPerguntaFatoresCognitivos', b.checked)}
                                   checked={coleta.trabalhoExigeAtencaoPerguntaFatoresCognitivos}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Trabalho exige que memorize senha, números ou outros\n"}
                                   id={"trabalhoExigeMemorizeSenhaNumerosPerguntaFatoresCognitivos"}
                                   onChange={(e, b) => handleCheckboxChange('trabalhoExigeMemorizeSenhaNumerosPerguntaFatoresCognitivos', b.checked)}
                                   checked={coleta.trabalhoExigeMemorizeSenhaNumerosPerguntaFatoresCognitivos}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Usabilidade no trabalho - Fácil de trabalhar (Sistemas, máquinas e equipamentos)"}
                                   id={"usabilidadeNoTrabalhoFacilDeTrabalharPerguntaFatoresCognitivos"}
                                   onChange={(e, b) => handleCheckboxChange('usabilidadeNoTrabalhoFacilDeTrabalharPerguntaFatoresCognitivos', b.checked)}
                                   checked={coleta.usabilidadeNoTrabalhoFacilDeTrabalharPerguntaFatoresCognitivos}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Usabilidade no trabalho - Engajamento (Sistemas, máquinas e equipamentos)\n"}
                                   id={"usabilidadeNoTrabalhoEngajamentoPerguntaFatoresCognitivos"}
                                   onChange={(e, b) => handleCheckboxChange('usabilidadeNoTrabalhoEngajamentoPerguntaFatoresCognitivos', b.checked)}
                                   checked={coleta.usabilidadeNoTrabalhoEngajamentoPerguntaFatoresCognitivos}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Usabilidade no trabalho - Aplicável (Sistemas, máquinas e equipamentos)"}
                                   id={"usabilidadeNoTrabalhoAplicavelPerguntaFatoresCognitivos"}
                                   onChange={(e, b) => handleCheckboxChange('usabilidadeNoTrabalhoAplicavelPerguntaFatoresCognitivos', b.checked)}
                                   checked={coleta.usabilidadeNoTrabalhoAplicavelPerguntaFatoresCognitivos}/>

                    <Form.Checkbox type="checkbox"
                                   label={"O trabalho tem sistema de retorno de informação (Sistemas, máquinas e equipamentos)"}
                                   id={"oTrabalhoTemSistemaDeRetornoDeInfoPerguntaFatoresCognitivos"}
                                   onChange={(e, b) => handleCheckboxChange('oTrabalhoTemSistemaDeRetornoDeInfoPerguntaFatoresCognitivos', b.checked)}
                                   checked={coleta.oTrabalhoTemSistemaDeRetornoDeInfoPerguntaFatoresCognitivos}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Affordance (Sistemas, máquinas, equipamentos e rotas)"}
                                   id={"affordanceSistemasMaquinasEquipamentosERotasPerguntaFatoresCognitivos"}
                                   onChange={(e, b) => handleCheckboxChange('affordanceSistemasMaquinasEquipamentosERotasPerguntaFatoresCognitivos', b.checked)}
                                   checked={coleta.affordanceSistemasMaquinasEquipamentosERotasPerguntaFatoresCognitivos}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Sistema de Ciclo aberto (Sistemas, máquinas e equipamentos)"}
                                   id={"sistemaDeCicloAbertoSistemasMaquinasEquipamentosPerguntaFatoresCognitivos"}
                                   onChange={(e, b) => handleCheckboxChange('sistemaDeCicloAbertoSistemasMaquinasEquipamentosPerguntaFatoresCognitivos', b.checked)}
                                   checked={coleta.sistemaDeCicloAbertoSistemasMaquinasEquipamentosPerguntaFatoresCognitivos}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Sistema de Ciclo fechado (Sistemas, máquinas e equipamentos)"}
                                   id={"sistemaDeCicloFechadoSistemasMaquinasEquipamentosPerguntaFatoresCognitivos"}
                                   onChange={(e, b) => handleCheckboxChange('sistemaDeCicloFechadoSistemasMaquinasEquipamentosPerguntaFatoresCognitivos', b.checked)}
                                   checked={coleta.sistemaDeCicloFechadoSistemasMaquinasEquipamentosPerguntaFatoresCognitivos}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Operar mais de um equipamento ao mesmo tempo (Sistemas, máquinas e equipamentos)"}
                                   id={"operarMaisDeUmEquipamentoAoMesmoTempoPerguntaFatoresCognitivos"}
                                   onChange={(e, b) => handleCheckboxChange('operarMaisDeUmEquipamentoAoMesmoTempoPerguntaFatoresCognitivos', b.checked)}
                                   checked={coleta.operarMaisDeUmEquipamentoAoMesmoTempoPerguntaFatoresCognitivos}/>

                    <Form.Checkbox type="checkbox"
                                   label={"Não há nenhum fator de risco/perigo cognitivo na atividade"}
                                   id={"naoHaNenhumFatorDeRiscoPerguntaFatoresCognitivos"}
                                   onChange={(e, b) => handleCheckboxChange('naoHaNenhumFatorDeRiscoPerguntaFatoresCognitivos', b.checked)}
                                   checked={coleta.naoHaNenhumFatorDeRiscoPerguntaFatoresCognitivos}/>

                </Form.Field>
                <Form.Field>
                    <Form.Input
                        label="Outros"
                        name="outrosPerguntaFatoresCognitivos"
                        type="text"
                        placeholder="Outros"
                        value={coleta.outrosPerguntaFatoresCognitivos}
                        onChange={handleInputChange}
                    />
                </Form.Field>
            </Form>
        );
    };

    // const renderStep10 = () => {
    //     const currentStep: number = 10;
    //     return (
    //         <Form onSubmit={handleSubmit}>
    //             <Header  textAlign={"center"} as={"h2"} >Envio de imagens para avaliação</Header>
    //             <ProgressBar totalSteps={10} currentStep={currentStep} />
    //             <h2>Envio de imagem</h2>
    //             <Form.Field>
    //                 <Form.Input
    //                     label='Enviar Imagem'
    //                     type='file'
    //                     accept='image/*'
    //                     onChange={handleInputChange}
    //                     // TODO: Talvez precise inserir um handleImageChanger
    //                 />
    //             </Form.Field>
    //
    //         </Form>
    //     );
    // };

    return (
        <div>
            <Grid.Column mobile={16} tablet={8} computer={6}>
                <Header as="h2" textAlign="center" color={'black'}>
                    Coleta Ergonômica / Adicionar
                </Header>
                {renderButtons(true)}
                <Segment>
                    {step === 1 && renderStep1()}
                    {step === 2 && renderStep2()}
                    {step === 3 && renderStep3()}
                    {step === 4 && renderStep4()}
                    {step === 5 && renderStep5()}
                    {step === 6 && renderStep6()}
                    {step === 7 && renderStep7()}
                    {step === 8 && renderStep8()}
                    {step === 9 && renderStep9()}
                    {renderButtons()}
                </Segment>
            </Grid.Column>
        </div>
    );
};

export default CreateColetaErgoPage;
