import React, {useCallback, useEffect, useState} from "react";
import {RelatorioProfessores} from "../../../../../models/relatorioProfessores";
import {SessionUser} from "../../../../../models/session-user";
import {RelatorioProfessoresFilter} from "../../../../../filters/relatorio-professores-filter";
import {
    GetRelatoriosProfessores, GetRelatoriosProfessoresPdf
} from "../../../../../services/sistema-aulas/relatorios/professores/get-relatorios-professores";
import {toast} from "react-toastify";
import {useLocation, useNavigate} from "react-router-dom";
import {Button, Form, Header, Icon, Input, Message, Segment, Select, Table} from "semantic-ui-react";
import {EMes} from "../../../../../enums/eMes";
import SessionStorage from "../../../../../store/session";
import {GetAllClientsToDropdown} from "../../../../../services/backoffice/clients/get-clients";
import {GetAllProfessorUsersToDropdown} from "../../../../../services/sistema-aulas/professor-user/get-professores";
import {GetAllTurmas} from "../../../../../services/sistema-aulas/turmas/get-turmas";
import {Turma} from "../../../../../models/turma";
import {GetTreinamentosSimplified} from "../../../../../services/sistema-aulas/treinamentos/get-treinamentos";
import StringUtils from "../../../../../shared/utils/string-utils";
import {EEstadoAula} from "../../../../../enums/eEstadoAula";
import {useBlockUI} from "../../../../../shared/services/block-ui-service";
import {GetTurmaRelatorioPresencaPdf} from "../../../../../services/sistema-aulas/turmas/get-turma-relatorio";

const ProfessoresRelatorioPage = () => {
    const [relatorios, setRelatorios] = useState<RelatorioProfessores>();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>();

    const [showFilters, setShowFilters] = useState(false)
    const [profile, setProfile] = useState<string>();


    const [clients, setClients] = useState<any[]>([]);
    const [professores, setProfessores] = useState<any[]>([])
    const [treinamentos, setTreinamentos] = useState<any[]>([]);
    const [turmas, setTurmas] = useState<any[]>([]);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const mesParam = queryParams.get("mes") ?? (new Date().getMonth() + 1).toString();
    const anoParam = queryParams.get("ano") ?? new Date().getFullYear().toString();
    const [filter, setFilter] = useState<RelatorioProfessoresFilter>({
        mes: parseInt(mesParam),
        ano: parseInt(anoParam)
    });

    const blockUI = useBlockUI();

    useEffect(() => {
        blockUI?.setActive(loading);
    }, [blockUI, loading]);

    const listRelatorios = useCallback(async () => {
        try {
            if (!filter.clientId) return;
            if (!(filter?.mes && filter.ano && filter?.mes >= 1 && filter?.mes <= 12 && filter?.ano > 2000 && filter?.ano < 3000)) {
                console.error("Filtros inválidos:", filter);
                throw new Error("Filtros inválidos");
            }
            setLoading(true);
            const response = await GetRelatoriosProfessores(filter);
            setRelatorios(response);
        } catch (error) {
            toast.warn('Falha ao capturar relatórios', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            console.error('Erro ao obter lista de relatórios:', error);
        } finally {
            setLoading(false);
        }
    }, [filter, setRelatorios]);

    useEffect(() => {
        listRelatorios();
    }, [filter, listRelatorios]);


    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };

    useEffect(() => {
        function ListClientes() {
            GetAllClientsToDropdown().then((response) => {
                setClients(response);
            })
                .catch(error => {
                    console.warn(error);
                })
        }

        ListClientes()
    }, []);

    function getEstadoAulaText(key: any) {
        const estadoAula = EEstadoAula.find((item) => item.value === key);
        return estadoAula ? estadoAula.text : "";
    }

    useEffect(() => {
        function ListProfessores() {
            GetAllProfessorUsersToDropdown()
                .then((response) => {
                    const professoresData = response.items.map((item: any) => ({
                        key: item.id,
                        text: item.firstName + ' ' + item.lastName,
                        value: item.id,
                    }));
                    setProfessores(professoresData);
                })
                .catch((error) => {
                    console.warn(error);
                });
        }

        ListProfessores();
    }, []);

    useEffect(() => {
        function fetchTurmas() {
            GetAllTurmas()
                .then((response) => {
                    const turmasData = response?.map((item: Turma) => ({
                        key: item.id,
                        text: `[${item.clientName}] ${item.nome} `,
                        value: item.id,
                    }));
                    setTurmas(turmasData);
                })
                .catch((error) => {
                    console.warn(error);
                });
        }

        fetchTurmas();
    }, []);

    useEffect(() => {
        function ListTreinamentos() {
            GetTreinamentosSimplified()
                .then((response) => {
                    setTreinamentos(response?.data?.data);
                })
                .catch((error) => {
                    console.warn(error);
                });
        }

        ListTreinamentos();
    }, []);


    useEffect(() => {
        async function updateSessionUser() {
            try {
                const user = await SessionStorage.getSession();
                if (user?.isLogged) {
                    setProfile(user.userData?.profile);
                }
            } catch (e) {
            }
        }

        updateSessionUser();
    }, []);


    return (
        <div>
            <Header as="h2" textAlign="center" color={'black'}>
                Relatório de Aulas - Professores/Clientes<p></p>
            </Header>

            <div className={'table-wrapper'}>

                <Table.HeaderCell>
                    <Button onClick={toggleFilters} primary>
                        {showFilters ? 'Ocultar filtros' : 'Exibir filtros'}
                    </Button>
                    {!loading && <Button onClick={() => {
                        if (!!filter)
                            GetRelatoriosProfessoresPdf(filter)
                    }}>
                        <Icon name={'print'}></Icon>
                        Imprimir</Button>}
                </Table.HeaderCell>

                <Segment>
                    <div style={{padding: 10}}>
                        <Form>
                            <Form.Field>
                                <Form.Select
                                    label={'Selecione um cliente'}
                                    options={clients}
                                    placeholder='Selecione um cliente para continuar'
                                    value={filter?.clientId}
                                    onChange={(event, data) =>
                                        //@ts-ignore
                                        setFilter({...filter, clientId: data?.value})
                                    }
                                    search
                                    clearable
                                    required
                                    selectOnBlur={false}
                                    error={!filter.clientId}
                                />
                            </Form.Field>

                            <Form.Group widths="equal">
                                <Form.Field
                                    control={Select}
                                    fluid
                                    label="Mês"
                                    placeholder="Mês"
                                    search
                                    selection
                                    options={EMes}
                                    value={filter?.mes}
                                    selectOnBlur={false}
                                    onChange={(_: React.ChangeEvent<HTMLInputElement>, data: {
                                        value: string;
                                    }) => {
                                        setFilter({...filter, mes: Number(data.value as string)});
                                    }}
                                />
                                <Form.Field
                                    control={Input}
                                    fluid
                                    label="Ano"
                                    placeholder="2023"
                                    type="number"
                                    value={filter?.ano}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setFilter({
                                            ...filter,
                                            ano: Number(e.target.value),
                                        })
                                    }
                                />
                            </Form.Group>
                        </Form>

                    </div>
                </Segment>


                {showFilters && (
                    <Segment>
                        <div style={{padding: 10}}>
                            <Form>
                                <Form.Field>
                                    <Form.Select
                                        label={'Professor'}
                                        options={professores}
                                        placeholder='Selecione um professor'
                                        value={filter?.professorId}
                                        onChange={(event, data) =>
                                            //@ts-ignore
                                            setFilter({...filter, professorId: data?.value})
                                        }
                                        search
                                        clearable
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <Form.Select
                                        label={'Turma'}
                                        options={turmas}
                                        placeholder='Selecione uma turma'
                                        value={filter?.turmaId}
                                        onChange={(event, data) =>
                                            //@ts-ignore
                                            setFilter({...filter, turmaId: data?.value})
                                        }
                                        search
                                        clearable
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <Form.Select
                                        label={'Treinamento'}
                                        options={treinamentos}
                                        placeholder='Selecione um treinamento'
                                        value={filter?.treinamentoId}
                                        onChange={(event, data) =>
                                            //@ts-ignore
                                            setFilter({...filter, treinamentoId: data?.value})
                                        }
                                        search
                                        clearable
                                    />
                                </Form.Field>
                                {/*</Form.Group>*/}

                            </Form>


                        </div>
                    </Segment>
                )}

                <div>
                    <br/>
                    <Header as="h2">Cliente: {relatorios?.treinamentos[0]?.turmas[0]?.clientName} </Header>

                    {relatorios?.treinamentos ? (
                            <div>
                                <Header as="h3">Treinamentos existentes no periodo</Header>
                                <Table celled>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Nome do Treinamento</Table.HeaderCell>
                                            <Table.HeaderCell>Data de Criação</Table.HeaderCell>
                                            <Table.HeaderCell>Descrição</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {relatorios?.treinamentos.map((treinamento, treinamentoIndex) => (
                                            <Table.Row key={treinamentoIndex}>
                                                <Table.Cell>{treinamento?.name}</Table.Cell>
                                                <Table.Cell>{StringUtils.formatToBrazilianShortDateTime(treinamento?.createdAt.toString())}</Table.Cell>
                                                <Table.Cell>{treinamento?.description}</Table.Cell>
                                            </Table.Row>
                                        ))}
                                    </Table.Body>
                                </Table>
                            </div>
                        ) :
                        <Segment>
                            <Message
                                icon={'warning'}
                                warning
                                content={"Nenhum relatório encontrado para o filtro informado, verifique os campos e tente novamente."}></Message>
                        </Segment>
                    }
                    <br/>
                </div>


                {relatorios?.treinamentos && (
                    <div>

                        {relatorios?.treinamentos.map((treinamento, treinamentoIndex) => (
                            <div key={treinamentoIndex}>
                                <Header as="h3">Treinamento: {treinamento.name}</Header>
                                {/* Tabela de Turmas */}
                                {Array.isArray(treinamento?.turmas) && treinamento?.turmas?.length > 0 ? (
                                    <div>
                                        {treinamento?.turmas.map((turma, turmaIndex) => (
                                            <div key={turmaIndex}>
                                                <Header as="h4">Turma: {turma.nome} /
                                                    Professor: {turma.userProfessorName}</Header>
                                                <Table celled>
                                                    <Table.Header>
                                                        <Table.Row>
                                                            <Table.HeaderCell>Nome da Aula</Table.HeaderCell>
                                                            <Table.HeaderCell>Estado da Aula</Table.HeaderCell>
                                                            <Table.HeaderCell>Data da Aula</Table.HeaderCell>
                                                            <Table.HeaderCell>Total de Alunos
                                                                Presentes</Table.HeaderCell>
                                                            <Table.HeaderCell>Total de Alunos
                                                                Matriculados</Table.HeaderCell>
                                                        </Table.Row>
                                                    </Table.Header>
                                                    <Table.Body>
                                                        {Array.isArray(turma?.aulas) && turma?.aulas?.length > 0 ? (
                                                            turma?.aulas.map((aula, aulaIndex) => (
                                                                <Table.Row key={aulaIndex}>
                                                                    <Table.Cell>{aula?.nomeAula}</Table.Cell>
                                                                    <Table.Cell>{getEstadoAulaText(aula?.estadoAula)}</Table.Cell>
                                                                    <Table.Cell>{StringUtils.formatToBrazilianShortDateTime(aula?.data.toString())}</Table.Cell>
                                                                    <Table.Cell>{aula?.totalAlunosPresentes}</Table.Cell>
                                                                    <Table.Cell>{aula?.totalAlunosMatriculados}</Table.Cell>
                                                                </Table.Row>
                                                            ))
                                                        ) : (
                                                            <Table.Row>
                                                                <Table.Cell colSpan="5">Sem aulas
                                                                    disponíveis</Table.Cell>
                                                            </Table.Row>
                                                        )}
                                                    </Table.Body>
                                                </Table>
                                                <br/>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <p>Sem turmas disponíveis</p>
                                )}
                            </div>
                        ))}
                    </div>
                )}


            </div>
        </div>
    )

}
export default ProfessoresRelatorioPage