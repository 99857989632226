import React, {useEffect, useState} from "react";
import CreateTreinamento, {CreateTreinamentoRequest} from "../../../../services/sistema-aulas/treinamentos/create-treinamento";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {Form, Grid, Header, Segment, Table} from "semantic-ui-react";
import {useBlockUI} from "../../../../shared/services/block-ui-service";

const CreateTreinamentoPage = () => {
    const [treinamento, setTreinamento] = useState<Partial<CreateTreinamentoRequest>>({})
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const blockUI = useBlockUI();
    useEffect(() => {
        blockUI?.setActive(loading)
    }, [loading]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setTreinamento((prevTreinamento) => ({
            ...prevTreinamento,
            [name]: value,
        }));
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            await CreateTreinamento(treinamento);
            navigate('/treinamentos/treinamentos/');
            toast.success('Treinamento criado com sucesso', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (error) {
            console.warn(error);
        } finally {
            setLoading(false);
        }
    };



    return (
        <div>
            <Grid.Column mobile={16} tablet={8} computer={6}>
                <Header as="h2" textAlign="center" color={'black'}>
                    Treinamentos / Adicionar
                </Header>
                <Segment>
                    <Form onSubmit={handleSubmit}>
                        <Form.Input
                            label="Nome"
                            name="name"
                            placeholder="Nome"
                            value={treinamento?.name}
                            onChange={handleInputChange}
                        />
                        <Form.Input
                            label="Descrição"
                            name="description"
                            placeholder="Descrição"
                            value={treinamento?.description}
                            onChange={handleInputChange}
                        />
                        <Table.Row>
                            <Table.HeaderCell>
                                <Form.Button content="Voltar"
                                             icon='arrow left'
                                             onClick={() => navigate('/treinamentos/treinamentos/')} grey/>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <Form.Button content="Adicionar"
                                             type={"submit"}
                                             icon='save'
                                             primary/>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Form>
                </Segment>
            </Grid.Column>
        </div>
    )
}

export default CreateTreinamentoPage;
