import React, {useEffect, useState} from 'react';
import {Button, Form, Header, Icon, Message, Segment, Table} from 'semantic-ui-react';
import {useLoaderData, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import GetAllClients from "../../../../services/backoffice/clients/get-all-clients";
import PutOperator, {EditOperatorRequest} from "../../../../services/backoffice/operators/put-operator";
import GetOperator from "../../../../services/backoffice/operators/get-operator";
import {useBlockUI} from "../../../../shared/services/block-ui-service";

export async function editOperatorLoader({params}: any) {
    return await GetOperator(params.id);
}

const EditOperatorUserPage = () => {
    const [operator, setOperator] = useState<Partial<EditOperatorRequest>>();
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const loader: any = useLoaderData();
    const [clients, setClients] = useState<any[]>([]);
    useEffect(() => {
        function ListClients() {
            GetAllClients().then((response) => {
                setClients(response);
            })
                .catch(error => {
                    console.warn(error);
                })
        }

        ListClients()
    }, [])
    useEffect(() => {
        setOperator(loader)
    }, [])
    const updateOperator = async () => {
        try {
            setLoading(true);
            if (operator) {
                const result = await PutOperator(operator, operator.id);
                if (result.success) navigate('/backoffice/operators/')
                toast.success('Operador atualizado com sucesso', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
            setLoading(false);
        } catch (error: any) {
            toast.warn('Falha ao atualizar operador', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setLoading(false);
        }
    };

    return (
        <div>
            <Header as="h2" textAlign="center" color={'black'}>
                Operadores / Editar
            </Header>
            <Form size="large" onSubmit={updateOperator}>
                <Segment stacked>
                    <Form.Input
                        fluid
                        label="Nome"
                        placeholder="Nome"
                        name="firstName"
                        value={operator?.firstName}
                        onChange={(event) => setOperator({...operator, firstName: event.target.value})}
                    />
                    <Form.Input
                        fluid
                        label="Sobrenome"
                        placeholder="Sobrenome"
                        name="lastName"
                        value={operator?.lastName}
                        onChange={(event) => setOperator({...operator, lastName: event.target.value})}
                    />
                    <Form.Input
                        fluid
                        label="E-mail"
                        placeholder="E-mail"
                        name="email"
                        value={operator?.email}
                        onChange={(event) => setOperator({...operator, email: event.target.value})}
                        type="email"
                    />
                    <Form.Field>
                        <Form.Select
                            label={'Clientes vinculados'}
                            options={clients}
                            placeholder='Selecione uma ou mais opções'
                            value={operator?.clients}
                            multiple
                            clearable
                            search
                            onChange={(evento: any, data: any) => {
                                setOperator({
                                    ...operator,
                                    clients: data.value,
                                });
                            }}
                        />
                    </Form.Field>
                    <Table.Row>
                        <Table.HeaderCell>
                            <Form.Button content="Voltar"
                                         icon='arrow left'
                                         onClick={() => navigate('/backoffice/operators/')} grey/>
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            <Form.Button content="Atualizar"
                                         type={"submit"}
                                         icon='save'
                                         primary/>
                        </Table.HeaderCell>
                    </Table.Row>
                    {error && <Message negative>{error}</Message>}
                </Segment>
            </Form>
        </div>
    );
};

export default EditOperatorUserPage;
