import instance from "../index";

export default RequestPasswordReset

async function RequestPasswordReset(request: Partial<RequestPasswordResetRequest>) : Promise<RequestPasswordResetResponse> {
    const response = await instance.post('v1/identity/account/request-password-reset', request);
    return response?.data;
}

export interface RequestPasswordResetRequest {
    email:string
}



export interface RequestPasswordResetResponse {
    success: boolean;
}