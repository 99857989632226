import {UserFilter} from "../../../filters/user-filter";
import instance from "../../index";

export default async function GetProfessorUsers(filter?: UserFilter) : Promise<any> {
    return await instance.get('/v1/backoffice/professores', {
        params: filter
    })
}

export async function GetAllProfessorUsersToDropdown(filter?: UserFilter) : Promise<any> {
    const response = await instance.get('/v1/backoffice/professores', {
        params: filter
    })
    return response?.data?.data;
}

export interface GetProfessoresResponseData {
    id: string
    firstName: string
    lastName: string
    email: string
    createdAt: string
    updatedAt: string
    isActive: boolean
}

export interface GetProfessoresResponse {
    success: boolean;
    data: GetProfessoresResponseData;
}