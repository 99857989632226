import instance from "../../index";

export default GetProfessorById

async function GetProfessorById(id: string): Promise<GetProfessorResponseData> {
    const response = await instance.get(`/v1/backoffice/professores/${id}`).catch();
    return response?.data?.data;
}
export interface GetProfessorRequest {
    id: string;
}
export interface GetProfessorResponseData {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    createdAt: Date;
    updatedAt: Date | null
    isActive: boolean;
}

export interface GetProfessorResponse {
    success: boolean;
    data: GetProfessorResponseData;
}
