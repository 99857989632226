import React, {useEffect, useState} from 'react';
import {Button, Dropdown, Header, Icon, Input, Message, Pagination, Segment, Select, Table} from 'semantic-ui-react';
import {useNavigate} from "react-router-dom";
import SessionStorage from "../../../store/session";
import {toast} from "react-toastify";
import GetAllPreliminarAvaliations
    , {
    GetAllPreliminarAvaliationsPaged
} from "../../../services/backoffice/avaliacao-ergo-preliminar/get-all-avaliacoes-ergo";
import DeleteAvaliacaoErgo from "../../../services/backoffice/avaliacao-ergo-preliminar/delete-avaliacao-ergo";
import {AvaliacoesErgoFilters} from "../../../filters/avaliacoes-ergo-filters";
import {GetColetasErgoShortList} from "../../../services/backoffice/coleta-ergo/get-coletasErgo";
import moment from "moment/moment";
import {useBlockUI} from "../../../shared/services/block-ui-service";

const AvaliacoesErgonomicasList = () => {
    const [avaliacoes, setAvaliacoes] = useState<any[]>([]);
    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const [filter, setFilter] = useState<AvaliacoesErgoFilters>()
    const [totalPages, setTotalPages] = useState()
    const [showFilters, setShowFilters] = useState(false);
    const [coletas, setColetas] = useState<any[]>(  []);
    const [profile, setProfile] = useState<string>();
    const blockUI = useBlockUI();
    useEffect(() => {
        async function updateSessionUser() {
            try {
                SessionStorage.getSession().then(user => {
                    if (!user) return
                    const isLogged = user?.isLogged;
                    if (!isLogged) return
                    setProfile(user?.userData?.profile)
                })
            } catch (e) {
            }
        }

        updateSessionUser()
    }, [SessionStorage])

    useEffect(() => {
        blockUI?.setActive(loading)
    }, [loading]);

    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };

    useEffect(() => {
        listAvaliacoes();
    }, [filter]);

    useEffect(() => {
        function listColetas() {
            GetColetasErgoShortList().then((response) => {
                setColetas(response);
            })
                .catch(error => {
                    console.warn(error);
                })
        }

        listColetas()
    }, [])


    async function listAvaliacoes() {
        try {
            setLoading(true);
            const response = await GetAllPreliminarAvaliationsPaged(filter);
            setTotalPages(response.totalPages);
            setAvaliacoes(response.items);
            console.table(response.items);
        } catch (error) {
            console.error("Error fetching avaliacoes:", error);
        } finally {
            setLoading(false);
        }
    }


    async function deleteAvaliacao(avaliacao: any) {
        try {
            const confirm = window.confirm(`Tem certeza que deseja apagar a Avaliação Ergonômica Preliminar ?`);
            if (confirm) {
                setLoading(true);

                if (avaliacao) {
                    const result = await DeleteAvaliacaoErgo(avaliacao.id);
                    if (result?.success) {
                        toast.success('Avaliação Ergonômica Preliminar deletada com sucesso', {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });

                        setError("");
                        setTimeout(() => {
                            setError("");
                        }, 3000);

                        await listAvaliacoes();
                    }
                }
            }
        } catch (error: any) {
            toast.warn('Falha ao deletar Avaliação Ergonômica Preliminar', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } finally {
            setLoading(false);
        }
    }

    function setFilterAndResetPage(newFilter: any){
        setFilter({...newFilter, Page: 1} )
    }

    return (
        <div>
            <Header as="h2" textAlign="center" color={'black'}>
                Avaliações Ergonômicas Preliminares
            </Header>
            {error && <Message
                floating
                style={{
                    position: 'fixed',
                    top: 0,
                    left: '50%',
                    transform: 'translateX(10%)',
                    zIndex: 9999,
                }}
            >
                <p>{error}</p>
            </Message>}

            <Table.Row>
                <Table.HeaderCell>
                    <Button color="blue"
                            onClick={() => navigate('/backoffice/avaliacoes-ergo/create/')}>

                        <Icon name='plus'/> Novo
                    </Button>
                </Table.HeaderCell>
                <Table.HeaderCell colSpan="4">
                    <Button onClick={toggleFilters} primary>
                        <Icon name={showFilters ? 'hide' : 'unhide'}/>
                        {showFilters ? 'Ocultar filtros' : 'Exibir filtros'}
                    </Button>
                </Table.HeaderCell>
            </Table.Row>

            {showFilters && (
                <Segment>
                    <div style={{padding: 10}}>
                        <div>
                            <Input
                                fluid
                                icon="search"
                                type="text"
                                placeholder="Nome do Cliente"
                                value={filter?.clientName ?? ''}
                                onChange={event =>
                                    setFilterAndResetPage({...filter, clientName: event.target?.value})
                                }
                            />
                        </div>
                        <br/>
                        <div>
                            <Input
                                fluid
                                icon="search"
                                type="text"
                                placeholder="Nome do colaborador entrevistado"
                                value={filter?.participanteEntrevistado ?? ''}
                                onChange={event =>
                                    setFilterAndResetPage({...filter, participanteEntrevistado: event.target?.value})
                                }
                            />
                        </div>
                        <br/>
                        <div>
                            <Input
                                fluid
                                icon="search"
                                type="text"
                                placeholder="Nome do coletor"
                                value={filter?.responsavelPelaColetaDeDados ?? ''}
                                onChange={event =>
                                    setFilterAndResetPage({...filter, responsavelPelaColetaDeDados: event.target?.value})
                                }
                            />
                        </div>
                        <br/>
                        <div>
                            <Input
                                fluid
                                icon="search"
                                type="text"
                                placeholder="Nome do responsavel pela Avaliação"
                                value={filter?.responsavel ?? ''}
                                onChange={event =>
                                    setFilterAndResetPage({...filter, responsavelPelaColetaDeDados: event.target?.value})
                                }
                            />
                        </div>
                        <br/>
                    </div>
                </Segment>
            )}
            <Table color={"orange"}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>#</Table.HeaderCell>
                        <Table.HeaderCell>Cliente</Table.HeaderCell>
                        <Table.HeaderCell>Colaborador/Entrevistado</Table.HeaderCell>
                        <Table.HeaderCell>Coletor</Table.HeaderCell>
                        <Table.HeaderCell>Responsavel pelo Laudo</Table.HeaderCell>
                        <Table.HeaderCell>Data da Avaliação</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {avaliacoes?.map((avaliacao) => (
                        <Table.Row key={avaliacao.id}>
                            <Table.Cell>
                                <Button.Group>
                                    {profile !== 'Operator' && <Button color="blue"
                                                                       onClick={() => navigate('/backoffice/avaliacoes-ergo/edit/' + avaliacao.id)}
                                                                       icon={'edit'}/>}
                                    <Button color="red" onClick={() => deleteAvaliacao(avaliacao)}
                                            icon={'trash'}></Button>
                                    <Button color="green"
                                            onClick={() => navigate('/backoffice/avaliacoes-ergo/details/' + avaliacao.id)}
                                            icon={'search'}/>
                                </Button.Group>
                            </Table.Cell>
                            <Table.Cell>{avaliacao.clientName}</Table.Cell>
                            <Table.Cell>{avaliacao.nomeDoEntrevistado}</Table.Cell>
                            <Table.Cell>{avaliacao.responsavelPelaColetaDeDados}</Table.Cell>
                            <Table.Cell>{avaliacao.responsavel}</Table.Cell>
                            <Table.Cell>{moment(avaliacao?.createdAt?.replace("Z", "")).format('DD/MM/YY HH:mm')}</Table.Cell>

                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <Pagination totalPages={totalPages ?? 0}
                        defaultActivePage={filter?.Page ?? 1}
                        onPageChange={(e, {activePage}) => {
                            setFilter({...filter, Page: activePage as number})
                        }
                        }></Pagination>
        </div>
    );
};

export default AvaliacoesErgonomicasList
