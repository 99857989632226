import instance from "../../index";

export default CreateSolucaoErgo



async function CreateSolucaoErgo(request: Partial<CreateSolucaoErgoRequest>) : Promise<CreateSolucaoErgoResponse> {
  const response = await instance.post('/v1/backoffice/solucoes-ergo', request)
  return response?.data?.data;
}

export interface CreateSolucaoErgoRequest {
    tipoSolucaoErgonomica: number,
    tipoDeAcaoSolucaoErgo: number,
    descricao: string,
    normasRegulamentativas: string[]
}

export interface CreateSolucaoErgoResponseData {
    id: string
    tipoSolucaoErgonomica: number,
    tipoDeAcaoSolucaoErgo: number,
    descricao: string,
    normasRegulamentativas: string[]
    createdAt: Date,
}

export interface CreateSolucaoErgoResponse {
    success: boolean;
    data: CreateSolucaoErgoResponseData
}

