import React, { forwardRef, useState, useImperativeHandle } from 'react';
import {Audio, Radio, ThreeDots} from 'react-loader-spinner';

interface BlockUIProps {
    active: boolean;
}

export interface BlockUIRef {
    setActive: (active: boolean) => void;
}

const BlockUI: React.ForwardRefRenderFunction<BlockUIRef, BlockUIProps> = (
    { active },
    ref
) => {
    const [isActive, setIsActive] = useState(active);

    const blockStyle: React.CSSProperties = {
        flexDirection: 'column',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(255, 255, 255, 0.8)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
    };

    useImperativeHandle(ref, () => ({
        setActive: (newActive: boolean) => {
            setIsActive(newActive);
        },
    }));

    if (!isActive) {
        return null;
    }

    return (
        <div style={blockStyle}>
            <img src={'/logo_slogan.png'} style={{
                maxHeight: '8rem',
                objectFit: 'contain',
                marginTop: 10,
                marginBottom: 10,
                paddingBottom: 5,
                alignSelf: 'center',
                width: '100%',
            }} alt={'superativa'}/>
            <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="orange"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                visible={true}
            />
        </div>
    );
};

export default forwardRef(BlockUI);
