import React, { useMemo } from "react";
import { Bar } from "react-chartjs-2";
import {
    CategoryScale,
    Chart as ChartJS,
    LinearScale,
    BarElement,
    Title,
    Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { DashboardInfoCliente, HabitoQueNaoGeraSaude } from "../../../../models/dashboardInfoCliente";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Legend, ChartDataLabels);

interface GraficoHabitosNaoSaudaveisProps {
    dashboardInfo?: DashboardInfoCliente;
    habitosNaoSaudaveis?: HabitoQueNaoGeraSaude;
}

const GraficoHabitosNaoSaudaveis: React.FC<GraficoHabitosNaoSaudaveisProps> = ({ habitosNaoSaudaveis }) => {
    const labels = useMemo(
        () => [
            "Fumante",
            "Uso de Bebidas Alcoólicas",
            "Falta de Relaxamento",
            "Redes Sociais em Excesso",
            "Negligenciar Alimentação",
            "Sem Hábitos Ruins",
        ],
        []
    );

    const data = useMemo(
        () => [
            habitosNaoSaudaveis?.fumante,
            habitosNaoSaudaveis?.usoBebidasAlcoolicas,
            habitosNaoSaudaveis?.naoTerMomentoRelaxamento,
            habitosNaoSaudaveis?.redesSociaisEmExcesso,
            habitosNaoSaudaveis?.negligenciarAlimentacao,
            habitosNaoSaudaveis?.naoTenhoHabitosRuinsDeSaude,
        ],
        [habitosNaoSaudaveis]
    );

    const datasets = useMemo(
        () => [
            {
                label: "Porcentagem de Hábitos Não Saudáveis",
                data,
                backgroundColor: Array(data.length).fill("rgb(19, 83, 138)"), // Cor ajustada para rgb(19, 83, 138)
                borderColor: "rgba(0,0,0,1)",
                borderWidth: 1,
                borderRadius: 5, // Adiciona borda arredondada
            },
        ],
        [data]
    );

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: "Hábitos Que Não Geram Saúde",
            },
            datalabels: {
                anchor: "end",
                align: "top",
                formatter: (value: number) => {
                    if (typeof value === "number") {
                        return `${value.toFixed(0)}%`;
                    }
                    return "";
                },
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                max: 100,
            },
        },
        // Desativando tooltips globalmente
        interaction: {
            mode: 'index',
            intersect: false,
        },
        // Removendo tooltips
        tooltips: {
            enabled: false,
        },
    };

    return (
        <div style={{ position: 'relative', width: '100%', height: '60vh' }}>
            {/*@ts-ignore*/}
            <Bar data={{ labels, datasets }} options={chartOptions} />
        </div>
    );
};

export default GraficoHabitosNaoSaudaveis;
