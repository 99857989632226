import React, {useEffect, useState} from "react";
import {useLoaderData, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {Form, Grid, Header, Segment, Table} from "semantic-ui-react";
import CreateAula, {CreateAulaRequest} from "../../../../services/sistema-aulas/aulas/create-aula";
import {EEstadoAula} from "../../../../enums/eEstadoAula";
import {GetAllTurmas} from "../../../../services/sistema-aulas/turmas/get-turmas";
import {Turma} from "../../../../models/turma";
import {SelectListDefault} from "../../../../models/selectListDefault";
import {useBlockUI} from "../../../../shared/services/block-ui-service";
import GetTurmaById from "../../../../services/sistema-aulas/turmas/get-turma";
import moment from "moment";

export async function createAulaLoader({params}: any) {
    return await GetTurmaById(params.turmaId)
}

const CrateAulaPage = () => {
    const [aula, setAula] = useState<Partial<CreateAulaRequest>>({})
    const [turmas, setTurmas] = useState<SelectListDefault[]>([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const blockUI = useBlockUI();
    const loader: any = useLoaderData();
    useEffect(() => {
        blockUI?.setActive(loading)
    }, [loading]);

    useEffect(() => {
        if (loader) {
            setAula({...aula, turmaId: loader.id});
        }
    }, [loader]);

    useEffect(() => {
        function fetchTurmas() {
            GetAllTurmas()
                .then((response) => {
                    const turmasData = response?.map((item: Turma) => ({
                        key: item.id,
                        text: `[${item.clientName}] ${item.nome} `,
                        value: item.id,
                    }));
                    setTurmas(turmasData);
                })
                .catch((error) => {
                    console.warn(error);
                });
        }

        fetchTurmas();
    }, []);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setAula((prevAula) => ({
            ...prevAula,
            [name]: value,
        }));
    };

    const handleDropdownChange = (name: string, data: any) => {
        setAula((prevAula: any) => ({
            ...prevAula,
            [name]: data.value,
        }));
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            await CreateAula(aula);
            var paramMes = "&mes=" + moment(aula?.data).format('MM');
            var paramAno = "&ano=" + moment(aula?.data).format('YYYY');
            navigate('/treinamentos/aulas/?turmaId=' + aula?.turmaId + paramMes + paramAno);
            toast.success('Aula criada com sucesso', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (error) {
            // handle error
            console.warn(error);
        } finally {
            setLoading(false);
        }
    };


    return (
        <div>
            <Grid.Column mobile={16} tablet={8} computer={6}>
                <Header as="h2" textAlign="center" color={'black'}>
                    Criar nova Aula / Adicionar
                </Header>
                <Segment>
                    <Form>
                        <Form.Select
                            label={'Turma'}
                            options={turmas}
                            placeholder='Selecione uma opção'
                            value={aula?.turmaId || ''}
                            disabled
                            onChange={(evento: any, data: any) => {
                                handleDropdownChange('turmaId', data);
                            }}
                            required
                        />
                        <Form.Input
                            label="Nome"
                            name="nome"
                            placeholder="Nome"
                            value={aula?.nome || ''}
                            onChange={handleInputChange}
                        />
                        <Form.Select
                            label={'Qual o estado da aula?'}
                            options={EEstadoAula}
                            placeholder='Selecione uma opção'
                            value={aula?.estadoAula || ''}
                            onChange={(e: any, data: any) => handleDropdownChange('estadoAula', data)}
                            required
                        />
                        <Form.Input
                            label="Data de Início"
                            name="data"
                            type="datetime-local"
                            placeholder="Data de Início"
                            value={aula?.data || ''}
                            onChange={(e) => {
                                if(e.target.value.length !== 16) return
                                handleInputChange(e)
                            }}
                        />
                        <Table.Row>
                            <Table.HeaderCell>
                                <Form.Button content="Voltar"
                                             icon='arrow left'
                                             onClick={() => navigate(`/treinamentos/aulas/?turmaId=${aula.turmaId}`)}
                                             grey/>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <Form.Button content="Adicionar"
                                             disabled={loading}
                                             onClick={() => handleSubmit()}
                                             icon='save'
                                             primary/>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Form>
                </Segment>
            </Grid.Column>
        </div>
    )
}

export default CrateAulaPage;
