import React, {useEffect, useState} from "react";
import CreateNormaRegulamentativa, {
    CreateNormaRegulamentativaRequest
} from "../../../../services/backoffice/normas-regulamentativas/create-norma-regulamentativa";
import {useNavigate} from "react-router-dom";
import {useBlockUI} from "../../../../shared/services/block-ui-service";
import {toast} from "react-toastify";
import {Form, Grid, Header, Segment, Table} from "semantic-ui-react";

const CreateNormaRegulamentativaPage = () => {
    const [norma, setNorma] = useState<Partial<CreateNormaRegulamentativaRequest>>({});
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const blockUI = useBlockUI();
    useEffect(() => {
        blockUI?.setActive(loading)
    }, [loading]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setNorma((prevNorma) => ({
            ...prevNorma,
            [name]: value,
        }));
    };


    const handleSubmit = async () => {
        try {
            setLoading(true);
            await CreateNormaRegulamentativa(norma);
            navigate('/backoffice/normas-regulamentadoras/');
            toast.success('Norma Regulamentadora criada com sucesso', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (error) {
            toast.warn('Falha ao criar norma regulamentadoras', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            console.warn(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <Grid.Column mobile={16} tablet={8} computer={6}>
                <Header as="h2" textAlign="center" color={'black'}>
                    Norma Regulamentadora / Adicionar
                </Header>
                <Segment>
                    <Form onSubmit={handleSubmit}>
                        <Form.Field>
                            <Form.Input
                                label="Código da NR"
                                name="codigo"
                                type="text"
                                placeholder="Código NR"
                                value={norma.codigo}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input
                                label="Descrição"
                                name="descricao"
                                type="text"
                                placeholder="Descrição da NR"
                                value={norma.descricao}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Field>

                        <Table.Row>
                            <Table.HeaderCell>
                                <Form.Button content="Voltar"
                                             icon='arrow left'
                                             onClick={() => navigate('/backoffice/normas-regulamentadoras/')} grey/>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <Form.Button content="Adicionar"
                                             type={"submit"}
                                             icon='save'
                                             primary/>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Form>
                </Segment>
            </Grid.Column>
        </div>
    );
}

export default CreateNormaRegulamentativaPage;