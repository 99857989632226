import ColetasErgoList from "../pages/backoffice/coletas-ergo";
import EditColetaErgoPage, {editColetasLoader} from "../pages/backoffice/coletas-ergo/edit";
import React from "react";
import Home from "../pages/backoffice/home";
import CollectorsUserList from "../pages/backoffice/collectors";
import {createBrowserRouter, createRoutesFromElements, Route} from "react-router-dom";
import ClientsList from "../pages/backoffice/clients";
import BackofficeUsersList from "../pages/backoffice/users";
import CreateColetaErgoPage from "../pages/backoffice/coletas-ergo/create";
import DetailsColetaErgo, {viewColetaErgoLoader} from "../pages/backoffice/coletas-ergo/details";
import MasterContainer from "../shared/components/container";
import AvaliacoesErgonomicasList from "../pages/backoffice/avaliacoes-ergo";
import CreateAvaliacaoErgoPage from "../pages/backoffice/avaliacoes-ergo/create";
import EditAvaliacaoErgoPage, {editAvaliacaoErgoLoader} from "../pages/backoffice/avaliacoes-ergo/edit";
import ListaRelatoriosTreinamentos from "../pages/ergo-activities/turmas/relatorio/relatorio-geral-cliente";
import SolucoesErgoList from "../pages/backoffice/solucoes-ergo";
import CreateSolucaoErgoPage from "../pages/backoffice/solucoes-ergo/create";
import UpdateSolucaoErgoPage, {editSolucaoErgoLoader} from "../pages/backoffice/solucoes-ergo/update";
import NormasRegulamentativasList from "../pages/backoffice/normas-regulamentativas";
import CreateNormaRegulamentativaPage from "../pages/backoffice/normas-regulamentativas/create";
import UpdateNormaRegulamentativaPage, {
    editNormaRegulamentativaLoader
} from "../pages/backoffice/normas-regulamentativas/update";
import PesquisasSaudeList from "../pages/pesquisa-saude";
import DetailsPesquisaSaude from "../pages/pesquisa-saude/details";
import CreatePesquisaSaudePage from "../pages/pesquisa-saude/create";
import AgradecimentoPesquisaSaudePage from "../pages/pesquisa-saude/sucesso";


export const collectorRoutes = (profile: string, isLogged: boolean, username: string) => createBrowserRouter(createRoutesFromElements(<>
    <Route path="home" element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><Home/></MasterContainer>}/>
    <Route path="/" element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><Home/></MasterContainer>}/>
    <Route path="backoffice/clients" element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><ClientsList/></MasterContainer>}/>
    <Route path="backoffice/collectors" element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><CollectorsUserList/></MasterContainer>}/>
    <Route path="backoffice/coleta-ergo/create" element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><CreateColetaErgoPage/></MasterContainer>}/>
    <Route
        path="backoffice/coleta-ergo/edit/:id"
        element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><EditColetaErgoPage/></MasterContainer>}
        loader={editColetasLoader}
    />
    <Route
        path="backoffice/coleta-ergo/details/:id"
        element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><DetailsColetaErgo/></MasterContainer>}
        loader={viewColetaErgoLoader}
    />
    <Route path="backoffice/coleta-ergo/" element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><ColetasErgoList/></MasterContainer>}/>
    <Route path="*" element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><BackofficeUsersList/></MasterContainer>}/>

    <Route path="backoffice/avaliacoes-ergo" element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><AvaliacoesErgonomicasList/></MasterContainer>}/>
    <Route path="backoffice/avaliacoes-ergo/create" element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><CreateAvaliacaoErgoPage/></MasterContainer>}/>
    <Route
        path="backoffice/aval

        iacoes-ergo/edit/:id"
        element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><EditAvaliacaoErgoPage/></MasterContainer>}
        loader={editAvaliacaoErgoLoader}
    />

    <Route
        path="treinamentos/relatorios/clients/"
        element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><ListaRelatoriosTreinamentos/></MasterContainer>}
    />

    <Route path="backoffice/solucoes-ergo"
           element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><SolucoesErgoList/></MasterContainer>}/>
    <Route path="backoffice/solucoes-ergo/create"
           element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><CreateSolucaoErgoPage/></MasterContainer>}/>
    <Route
        path="backoffice/solucoes-ergo/edit/:id"
        element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><UpdateSolucaoErgoPage/></MasterContainer>}
        loader={editSolucaoErgoLoader}
    />

    <Route path="backoffice/normas-regulamentadoras"
           element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><NormasRegulamentativasList/></MasterContainer>}/>
    <Route path="backoffice/normas-regulamentadoras/create"
           element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><CreateNormaRegulamentativaPage/></MasterContainer>}/>
    <Route
        path="backoffice/normas-regulamentadoras/edit/:id"
        element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><UpdateNormaRegulamentativaPage/></MasterContainer>}
        loader={editNormaRegulamentativaLoader}
    />

        <Route path="backoffice/pesquisa-saude"
               element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><PesquisasSaudeList/></MasterContainer>}/>

    <Route
        path="backoffice/pesquisa-saude/visualizar/:pesquisaId"
        element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><DetailsPesquisaSaude/></MasterContainer>}
    />

    <Route
        path="pesquisa-saude/responder"
        element={<CreatePesquisaSaudePage/>}/>

    <Route
        path="pesquisa-saude/sucesso"
        element={<AgradecimentoPesquisaSaudePage/>}/>

    </>

));

