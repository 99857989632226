import React, {useEffect, useState} from 'react';
import {Button, Dropdown, Header, Icon, Input, Label, Message, Pagination, Segment, Table} from 'semantic-ui-react';
import ActiveUser from "services/backoffice/users/active-user";
import DeactiveUser from "services/backoffice/users/deactive-user";
import GetCollectors from "services/backoffice/collectors/get-collectors";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {CollectorFilter} from "../../../filters/collector-filter";
import {toast} from "react-toastify";
import DeleteClient from "../../../services/backoffice/clients/delete-client";
import {useBlockUI} from "../../../shared/services/block-ui-service";
import DeleteCollector from "../../../services/backoffice/collectors/delete-collector";
import DeleteOperator from "../../../services/backoffice/operators/delete-operator";

const CollectorsUserList = () => {
    const [collectors, setCollectors] = useState<any[]>([]);
    const [error, setError] = useState<string>();
    //@ts-ignore
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const [filter, setFilter] = useState<CollectorFilter>()
    const [totalPages, setTotalPages] = useState()
    const [showFilters, setShowFilters] = useState(false);
    const blockUI = useBlockUI();
    useEffect(() => {
        blockUI?.setActive(loading)
    }, [loading]);

    const toggleFilters = () => {
        setShowFilters(!showFilters);
    }
    useEffect(() => {
        listCollectors();
    }, [filter]);

    const listCollectors = async () => {
        try {
            setLoading(true);
            const response = await GetCollectors(filter);
            const data = response?.data?.data;
            setTotalPages(data.totalPages);
            setCollectors(data.items);
        } catch (error) {
            console.error('Erro ao obter coletores:', error);
        } finally {
            setLoading(false);
        }
    };

    const toggleActivationCollector = async (collector: any) => {
        try {
            const result = window.confirm(`Tem certeza que deseja ${collector.isActive ? 'desativar' : 'ativar'} o coletor 
        ${collector.firstName} ${collector?.lastName}?`);

            if (result) {
                setLoading(true);

                if (collector) {
                    const activationResult = !collector.isActive ? await ActiveUser(collector.id) : await DeactiveUser(collector.id);

                    if (activationResult.success) {
                        toast.success(`O Coletor foi ${collector.isActive ? 'desativado' : 'ativado'} com sucesso`, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });

                        setTimeout(() => {
                            setError('');
                        }, 3000);

                        await listCollectors(); // Usar 'await' para esperar a conclusão da função
                    }
                }
            }
        } catch (error:any) {
            toast.warn(`Falha ao ${collector.isActive ? 'desativar' : 'ativar'} coletor`, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            setError(error.message);
        } finally {
            setLoading(false);
        }
    };



    async function deleteCollector(collector: any) {
        try {
            const confirm = window.confirm(`Tem certeza que deseja apagar o usuário ${collector.firstName} ${collector.lastName}?`);
            if (confirm) {
                setLoading(true);

                if (collector) {
                    await DeleteCollector(collector.id);
                    toast.success('Coletor deletado com sucesso', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    await listCollectors();
                }

                setLoading(false);
            }
        } catch (error: any) {
            toast.warn(`Falha ao deletar Coletor`, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setLoading(false);
            setError(error.message);
        }
    }


    function setFilterAndResetPage(newFilter: any){
        setFilter({...newFilter, Page: 1} )
    }

    return (
        <div>
            <Header as="h2" textAlign="center" color={'black'}>
                Coletores
            </Header>
            {error && <Message
                floating
                style={{
                    position: 'fixed',
                    top: 0,
                    left: '50%',
                    transform: 'translateX(10%)',
                    zIndex: 9999,
                }}
            >
                <p>{error}</p>
            </Message>}
            <Table.Row>
                <Table.HeaderCell>
                    <Button color="blue"
                            onClick={() => navigate('/backoffice/collectors/create/')}>

                        <Icon name='plus'/> Novo
                    </Button>
                </Table.HeaderCell>
                <Table.HeaderCell colSpan="4">
                    <Button onClick={toggleFilters} primary>
                        <Icon name={showFilters ? 'hide' : 'unhide'} />
                        {showFilters ? 'Ocultar filtros' : 'Exibir filtros'}
                    </Button>
                </Table.HeaderCell>
            </Table.Row>
            {showFilters && (
            <Segment>
                <div style={{padding: 10}}>
                    <div>
                        <Input
                            fluid
                            icon="search"
                            type="text"
                            placeholder="Nome do Coletor"
                            value={filter?.Name ?? ''}
                            onChange={event =>
                                setFilterAndResetPage({...filter, Name: event.target?.value})
                            }
                        />
                    </div>
                    <br/>
                    <div>
                        <Input
                            fluid
                            icon="search"
                            type="text"
                            placeholder="E-mail"
                            value={filter?.Email ?? ''}
                            onChange={event =>
                                setFilterAndResetPage({...filter, Email: event.target?.value})
                            }
                        />
                    </div>
                </div>
            </Segment>
        )}

            <Table color={"orange"}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>#</Table.HeaderCell>
                        <Table.HeaderCell>Nome</Table.HeaderCell>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                        <Table.HeaderCell>Criado em</Table.HeaderCell>
                        <Table.HeaderCell>Ativo</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {collectors?.map((collector) => (
                        <Table.Row key={collector.id}>
                            <Table.Cell>
                                <Dropdown item icon={'bars'}>
                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            color="blue"
                                            text={'Editar'}
                                            onClick={() => navigate('/backoffice/collectors/edit/' + collector.id)}
                                        >
                                            <Icon name='edit' color='blue' />
                                            Editar
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            text={'Deletar'}
                                            onClick={() => deleteCollector(collector)}
                                        >
                                            <Icon name='trash' color={'red'} />
                                            Deletar
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            color={collector.isActive ? 'red' : 'green'}
                                            text={collector.isActive ? 'Desativar' : 'Ativar'}
                                            onClick={() => toggleActivationCollector(collector)}
                                        >
                                            <Icon name={collector.isActive ? 'power off' : 'power'} color={collector.isActive ? 'red' : 'green'} />
                                            {collector.isActive ? 'Desativar' : 'Ativar'}
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Table.Cell>

                            <Table.Cell>{`${collector.firstName} ${collector?.lastName ?? ''}`}</Table.Cell>
                            <Table.Cell>{collector.email}</Table.Cell>
                            <Table.Cell>{moment(collector.createdAt).format('DD/MM/YY HH:mm')}</Table.Cell>
                            <Table.Cell>
                                <Label key={collector.id} color={!collector.isActive ? 'red' : 'green'}>{collector.isActive ? 'Sim' : 'Não'}</Label>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <Pagination totalPages={totalPages ?? 0}
                        defaultActivePage={filter?.Page ?? 1}
                        onPageChange={(e, {activePage}) => {
                            setFilter({...filter, Page: activePage as number})
                        }
                        }>
            </Pagination>
        </div>
    );
};

export default CollectorsUserList;
