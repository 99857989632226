import instance from "../../index";
import {SolucoesErgoFilter} from "../../../filters/solucoes-ergo-filters";

export default async function GetAllSoluctions(filter?: SolucoesErgoFilter): Promise<any> {
    return await instance.get('/v1/backoffice/solucoes-ergo/all', {
        params: filter
    })
}

export async function GetSolucoesErgoShortList(): Promise<any> {
    return (await instance.get('/v1/backoffice/solucoes-ergo/short')).data.data
}

export async function GetSolucoesErgoDescricaoShortList(): Promise<any> {
    return (await instance.get('/v1/backoffice/solucoes-ergo/short-descricao')).data.data
}

export interface GetAllSoluctionsResponseData {
    id: string
    tipoSolucaoErgonomica: number
    tipoDeAcaoSolucaoErgo: number,
    descricao: string
    normasRegulamentativas: string[]
    createdAt: Date
    updatedAt: Date
}

export interface GetAllSoluctionsResponse {
    success: boolean;
    data: GetAllSoluctionsResponseData;
}