import instance from "../index";

export default ResetPassword

async function ResetPassword(request: Partial<ResetPasswordRequest>) : Promise<ResetPasswordResponse> {
    const response = await instance.post('v1/identity/account/password-reset', request);
    return response?.data;
}

export interface ResetPasswordRequest {
    token: string;
    password: string;
    passwordConfirmation: string;
    email: string
}



export interface ResetPasswordResponse {
    success: boolean;
}