import instance from "../../index";

export default CreateOperator

async function CreateOperator(request: Partial<CreateOperatorRequest>): Promise<CreateOperatorResponse> {
    const response = await instance.post(`/v1/backoffice/operadores/`, request);
    return response.data.data;
}
export interface CreateOperatorRequest
{
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    passwordConfirmation: string;
    isActive: boolean;
    clients: string[];

}
export interface CreateOperatorResponseData {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    passwordConfirmation: string;
    isActive: boolean;
    clients: string[];
}

export interface CreateOperatorResponse {
    success: boolean;
    data: CreateOperatorResponseData;
}
