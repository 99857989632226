import React, {useEffect, useState} from 'react';
import {Button, Dropdown, Header, Icon, Input, Message, Pagination, Segment, Select, Table} from 'semantic-ui-react';
import {useNavigate} from "react-router-dom";
import {ColetaErgoFilter} from "../../../filters/coletas-ergo-filters";
import GetColetasErgo from "../../../services/backoffice/coleta-ergo/get-coletasErgo";
import {GetAllClientsToDropdown} from "../../../services/backoffice/clients/get-clients";
import SessionStorage from "../../../store/session";
import {SessionUser} from "../../../models/session-user";
import {useBlockUI} from "../../../shared/services/block-ui-service";

const ColetasErgoList = () => {
    const [coletas, setColetas] = useState<any[]>([]);
    const [clients, setClients] = useState<any[]>([]);
    const [error, setError] = useState<string>();
    const navigate = useNavigate();
    const [filter, setFilter] = useState<ColetaErgoFilter>()
    const [totalPages, setTotalPages] = useState()
    const [sessionUser, setSessionUser] = useState<SessionUser>();
    const [showFilters, setShowFilters] = useState(false);
    const [profile, setProfile] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const blockUI = useBlockUI();
    useEffect(() => {
        blockUI?.setActive(loading)
    }, [loading]);

    useEffect(() => {
        async function updateSessionUser() {
            try {
                SessionStorage.getSession().then(user => {
                    if (!user) return
                    const isLogged =  user?.isLogged;
                    if(!isLogged) return
                    setProfile(user?.userData?.profile)
                })
            } catch (e) {
                console.log('Falha ao obter sessão do usuário', e)
            }
        }

        updateSessionUser()
    }, [SessionStorage])

    useEffect(() => {
        function ListClients() {
            GetAllClientsToDropdown().then((response) => {
                setClients(response);
            })
                .catch(error => {
                    console.warn(error);
                })
        }
        ListClients()
    }, [])
    useEffect(() => {
        listColetas();
    }, [filter]);
    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };


    const listColetas = async () => {
        try {
            setLoading(true);
            const response = await GetColetasErgo(filter);
            const data = response?.data?.data;
            setTotalPages(data.totalPages);
            setColetas(data.items);
            setError('');
        } catch (error:any) {
            console.error('Erro ao obter coletas:', error);

            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    function setFilterAndResetPage(newFilter: any){
        setFilter({...newFilter, Page: 1} )
    }

    return (
        <div>
            <Header as="h2" textAlign="center" color={'black'}>
                Coletas Ergonômicas
            </Header>
            {error && <Message
                floating
                style={{
                    position: 'fixed',
                    top: 0,
                    left: '50%',
                    transform: 'translateX(10%)',
                    zIndex: 9999,
                }}
            >
                <p>{error}</p>
            </Message>}

            <Table.Row>
                {profile != 'Operator' &&
                <Table.HeaderCell>
                    <Button color="blue"
                            onClick={() => navigate('/backoffice/coleta-ergo/create/')}>

                        <Icon name='plus'/> Novo
                    </Button>
                </Table.HeaderCell> }

                <Table.HeaderCell colSpan="4">
                    <Button onClick={toggleFilters} primary>
                        <Icon name={showFilters ? 'hide' : 'unhide'}/>
                        {showFilters ? 'Ocultar filtros' : 'Exibir filtros'}
                    </Button>
                </Table.HeaderCell>
            </Table.Row>
            {showFilters && (
                <Segment>
                    <div style={{padding: 10}}>
                        <div>
                            <Input
                                fluid
                                icon="search"
                                type="text"
                                placeholder="Nome"
                                value={filter?.Nome ?? ''}
                                onChange={event =>
                                    setFilterAndResetPage({...filter, Nome: event.target?.value})
                                }
                            />
                        </div>
                        <br/>
                        <div>
                            <Select clearable 
                                placeholder="Selecione um cliente"
                                fluid
                                search
                                selection
                                options={clients}
                                value={filter?.ClientId}
                                    selectOnBlur={false}
                                    onChange={(event, data) =>
                                    //@ts-ignore
                                    setFilterAndResetPage({...filter, ClientId: data.value})
                                }
                            />
                        </div>
                        <br/>
                        <div>
                            <Input
                                fluid
                                icon="search"
                                type="text"
                                placeholder="Nome do supervisor"
                                value={filter?.NomeSupervisor ?? ''}
                                onChange={event =>
                                    setFilterAndResetPage({...filter, NomeSupervisor: event.target?.value})
                                }
                            />
                        </div>
                        <br/>
                    </div>
                </Segment>
            )}
            <Table color={"orange"}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>#</Table.HeaderCell>
                        <Table.HeaderCell>Nome do Entrevistado</Table.HeaderCell>
                        <Table.HeaderCell>Cliente</Table.HeaderCell>
                        <Table.HeaderCell>Nome do Supervisor</Table.HeaderCell>
                        <Table.HeaderCell>Coletor</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {coletas?.map((coleta) => (
                        <Table.Row key={coleta.id}>
                            <Table.Cell>
                                <Dropdown item icon={'bars'}>
                                    <Dropdown.Menu>
                                        {profile !== 'Operator' && (
                                            <Dropdown.Item
                                                color="blue"
                                                text={'Editar'}
                                                onClick={() => navigate('/backoffice/coleta-ergo/edit/' + coleta.id)}
                                            >
                                                <Icon name='edit' color='blue' />
                                                Editar
                                            </Dropdown.Item>
                                        )}
                                        <Dropdown.Item
                                            color="green"
                                            text={'Detalhes'}
                                            onClick={() => navigate('/backoffice/coleta-ergo/details/' + coleta.id)}
                                        >
                                            <Icon name='search' color='green' />
                                            Detalhes
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Table.Cell>

                            <Table.Cell>{coleta.nome}</Table.Cell>
                            <Table.Cell>{coleta.clientName}</Table.Cell>
                            <Table.Cell>{coleta.nomeSupervisor}</Table.Cell>
                            <Table.Cell>{coleta.userName}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <Pagination totalPages={totalPages ?? 0}
                        defaultActivePage={filter?.Page ?? 1}
                        onPageChange={(e, {activePage}) => {
                            setFilter({...filter, Page: activePage as number})
                        }
                        }></Pagination>
        </div>
    );
};

export default ColetasErgoList;
