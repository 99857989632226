import React, {useEffect, useState} from 'react';
import {Button, Form, Grid, Header, Icon, Segment, Table} from 'semantic-ui-react';
import CreateCollector, {CreateCollectorRequest} from "services/backoffice/collectors/create-collector";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {useBlockUI} from "../../../../shared/services/block-ui-service";
import GetAllClients from "../../../../services/backoffice/clients/get-all-clients";


const CreateCollectorUserPage = () => {
    const [collector, setCollector] = useState<Partial<CreateCollectorRequest>>({});
    const navigate = useNavigate();
    const [clients, setClients] = useState<any[]>([]);
    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const blockUI = useBlockUI();
    useEffect(() => {
        blockUI?.setActive(loading)
    }, [loading]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setCollector((prevCollector) => ({
            ...prevCollector,
            [name]: value,
        }));
    };

    useEffect(() => {
                function ListClients() {
                        setLoading(true)
                        GetAllClients().then((response) => {
                                setClients(response);
                            })
                            .catch(error => {
                                    console.warn(error);
                                })
                            .finally(()=> setLoading(false))
                    }

                ListClients()
            }, [])

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            setLoading(true);

            await CreateCollector(collector);

            navigate('/backoffice/collectors');
            toast.success('Coletor criado com sucesso!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            setError('');
        } catch (error:any) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };


    return (
        <div>
            <Grid.Column mobile={16} tablet={8} computer={6}>
                <Header as="h2" textAlign="center" color={'black'}>
                   Coletores / Adicionar
                </Header>
                <Segment>
                    <Form onSubmit={handleSubmit}>
                        <Form.Input
                            label="Email"
                            name="email"
                            type="email"
                            placeholder="Email"
                            value={collector.email}
                            onChange={handleInputChange}
                            required
                        />
                        <Form.Input
                            label="Nome"
                            name="firstName"
                            type="text"
                            placeholder="Nome"
                            value={collector.firstName}
                            onChange={handleInputChange}
                            required
                        />
                        <Form.Input
                            label="Sobrenome"
                            name="lastName"
                            type="text"
                            placeholder="Sobrenome"
                            value={collector.lastName}
                            onChange={handleInputChange}
                            required
                        />
                        <Form.Input
                            label="Senha"
                            name="password"
                            type="password"
                            placeholder="Senha"
                            value={collector.password}
                            onChange={handleInputChange}
                            required
                        />
                        <Form.Input
                            label="Confirmação da senha"
                            name="passwordConfirmation"
                            type="password"
                            placeholder="Confirmação da senha"
                            value={collector.passwordConfirmation}
                            onChange={handleInputChange}
                            required
                        />
                        <Form.Select
                            label={'Vincule clientes ao coletor'}
                            options={clients}
                            placeholder='Selecione uma opção'
                            value={collector?.clients}
                            onChange={(evento: any, data: any) => {
                                setCollector({
                                    ...collector,
                                    clients: data.value,
                                });
                            }}
                            multiple
                            search
                            required
                        />
                        <Table.Row>
                            <Table.HeaderCell>
                                <Form.Button content="Voltar"
                                             icon='arrow left'
                                             onClick={() => navigate('/backoffice/collectors/')} grey/>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <Form.Button content="Adicionar"
                                             type={"submit"}
                                             icon='save'
                                             primary/>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Form>
                </Segment>
            </Grid.Column>
        </div>
    );
};

export default CreateCollectorUserPage;
