import {createBrowserRouter, createRoutesFromElements, Route} from "react-router-dom";
import Login from "pages/auth/login";
import React from "react";
import ResetPasswordPage from "../pages/password/reset-password";
import LostPasswordPage from "../pages/password/lost-password";
import CreatePesquisaSaudePage from "../pages/pesquisa-saude/create";
import AgradecimentoPesquisaSaudePage from "../pages/pesquisa-saude/sucesso";

export const authRoutes = createBrowserRouter(createRoutesFromElements(<>
        <Route
            path="/"
            element={<Login/>}/>
        <Route
            path="*"
            element={<Login/>}/>

    <Route
        path="change-password/:token"
        element={<ResetPasswordPage/>}/>

    <Route
        path="lost-password"
        element={<LostPasswordPage/>}/>

    <Route
        path="pesquisa-saude/responder"
        element={<CreatePesquisaSaudePage/>}/>

    <Route
        path="pesquisa-saude/sucesso"
        element={<AgradecimentoPesquisaSaudePage/>}/>
    </>));
