import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Form, Grid, Header, Icon, Segment, Table} from 'semantic-ui-react';
import {useLoaderData, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {
    GetSolucoesErgoDescricaoShortList,
    GetSolucoesErgoShortList
} from "../../../../services/backoffice/solucoes-ergo/get-all-solucoes-ergo";
import UpdateAvaliacaoErgoPreliminar, {
    UpdateAvaliacaoErgoPreliminarRequest
} from "../../../../services/backoffice/avaliacao-ergo-preliminar/update-avaliacao-ergo";
import GetSolucaoErgoById from "../../../../services/backoffice/solucoes-ergo/get-solucao-ergo";
import {GetColetasErgoShortList} from "../../../../services/backoffice/coleta-ergo/get-coletasErgo";
import GetColetaById from "../../../../services/backoffice/coleta-ergo/get-coleta-ergo";
import {EPerguntaTempoTrabalhado} from "../../../../enums/ePerguntaTempoTrabalhado";
import {EPerguntaPraticaExercicioFisico} from "../../../../enums/ePerguntaPraticaExercicioFisico";
import {EPerguntaPredominanciaDaPostura} from "../../../../enums/EPerguntaPredominanciaDaPostura";
import {EPerguntaSobreAmbienteDeTrabalho} from "../../../../enums/ePerguntaSobreAmbienteDeTrabalho";
import {EPerguntaEsforcoMental} from "../../../../enums/ePerguntaEsforcoMental";
import {EPerguntaEsforcoFisico} from "../../../../enums/ePerguntaEsforcoFisico";
import {EPerguntaFatorDeRiscoGravidade} from "../../../../enums/ePerguntaFatorDeRiscoGravidade";
import {EPerguntaFatorDeProbabilidade} from "../../../../enums/ePerguntaFatorDeProbabilidade";
import {ExigeInvestimentoAvaliacaoErgo} from "../../../../enums/eExigeInvestimentoAvaliacaoErgo";
import {useBlockUI} from "../../../../shared/services/block-ui-service";
import GetAvaliacaoErgoById from "../../../../services/backoffice/avaliacao-ergo-preliminar/get-avaliacao-ergo";
import GetNormasRegulamentativasToDropdown
    from "../../../../services/backoffice/normas-regulamentativas/get-normas-short-list";
import {ETiposSolucoesErgonomicas} from "../../../../enums/ePerguntaSolucoesCognitivas";
import {ETipoDeAcaoSolucaoErgo} from "../../../../enums/eTipoDeAcaoSolucaoErgo";
import {EClassificacaoAvaliacaoErgonomica} from "../../../../enums/eClassificacaoDeProbrabilidadeGravidade";
import {EPrazoSugeridoAvaliacaoErgo} from "../../../../enums/ePrazoSugeridoAvaliacaoErgo";
import {SolucaoErgonomica} from "../../../../models/solucaoErgonomica";
import {ColetaErgonomica} from "../../../../models/coletaErgonomica";
import DeleteAvaliacaoErgoSolucaoErgo
    from "../../../../services/backoffice/avaliacao-ergo-preliminar/avaliacao-ergo-solucao-ergo/delete-avaliacao-ergo-solucao-ergo";
import CreateAvaliacaoErgoSolucaoErgo
    from "../../../../services/backoffice/avaliacao-ergo-preliminar/avaliacao-ergo-solucao-ergo/create-avaliacao-ergo-solucao-ergo";

export async function editAvaliacaoErgoLoader({params}: any) {
    return await GetAvaliacaoErgoById(params.id);
}

const EditAvaliacaoErgoPage = () => {
    const [avaliacao, setAvaliacao] = useState<Partial<UpdateAvaliacaoErgoPreliminarRequest>>();
    const navigate = useNavigate();
    const [coletasErgo, setColetasErgo] = useState<ColetaErgonomica[]>([]);
    const [solucoes, setSolucoes] = useState<SolucaoErgonomica[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [currentColeta, setCurrentColeta] = useState<any>();
    const [coletaSelecionada, setColetaSelecionada] = useState<any>();
    const [solucoesSelecionadas, setSolucoesSelecionadas] = useState<any[]>([]);
    const [coletaId, setColetaId] = useState('');
    const [normasRegulamentadoras, setNormasRegulamentadoras] = useState<any[]>([]);
    const blockUI = useBlockUI();
    const loader: any = useLoaderData();
    useEffect(() => {
        setAvaliacao(loader)
    }, [])

    useEffect(() => {
        blockUI?.setActive(loading)
    }, [loading]);

    useEffect(() => {
        function ListNormasRegulamentadoras() {
            GetNormasRegulamentativasToDropdown().then((response) => {
                setNormasRegulamentadoras(response);
            })
                .catch(error => {
                    console.warn(error);
                })
        }

        ListNormasRegulamentadoras()
    }, [])

    function getNormasRegulamentadorasCodigo(normasRegulamentativas: string[]) {
        const codigosNormas = normasRegulamentativas.map((id) => {
            const norma = normasRegulamentadoras.find((item) => item.key === id);
            return norma ? norma.text : "";
        });
        return codigosNormas.join(", ");
    }

    function getTipoSolucaoText(key: any) {
        const tipoSolucao = ETiposSolucoesErgonomicas.find((item) => item.value === key);
        return tipoSolucao ? tipoSolucao.text : "";
    }

    function getTipoAcaoText(key: any) {
        const tipoAcaoSolucaoErgo = ETipoDeAcaoSolucaoErgo.find((item) => item.value === key);
        return tipoAcaoSolucaoErgo ? tipoAcaoSolucaoErgo.text : "";
    }

    useEffect(() => {
        function ListColetas() {
            GetColetasErgoShortList().then((response) => {
                setColetasErgo(response);
            })
                .catch(error => {
                    console.warn(error);
                })
        }

        ListColetas()
    }, [])

    useEffect(() => {
        async function fetchData() {
            try {
                const coletaErgoId = avaliacao?.coletaErgoId;
                if (coletaErgoId) {
                    const coletaCompleta = await GetColetaById(coletaErgoId);
                    setCurrentColeta(coletaCompleta);
                } else {
                    console.warn('Coleta ergonômica não está definida na avaliação.');
                }
            } catch (error) {
                console.error('Erro ao obter a coleta:', error);
            } finally {
                setLoading(false);
            }
        }

        fetchData();
    }, [avaliacao?.coletaErgoId]);

    useEffect(() => {
        function ListSolucoes() {
            GetSolucoesErgoDescricaoShortList().then((response) => {
                setSolucoes(response);
            })
                .catch(error => {
                    console.warn(error);
                })
        }

        ListSolucoes()
    }, [])

    // Função para adicionar uma nova relação entre Avaliação e Solução
    const adicionarRelacaoAvaliacaoSolucao = async (avaliacaoErgoId: any, solucaoErgoId:any) => {
        try {
            setLoading(true)
            await CreateAvaliacaoErgoSolucaoErgo({ avaliacaoErgoId, solucaoErgoId});
            toast.success('Solução Ergonômica selecionada com sucesso', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

        } catch (error) {
            console.error('Erro ao criar relação Avaliação Ergonômica - Solução Ergonômica:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setAvaliacao((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));
    };

    const handleDropdownChange = (name: string, data: any) => {
        setAvaliacao((prevSolucao) => ({
            ...prevSolucao,
            [name]: data.value,
        }));
    };


    const cellColors = ['#94e7a3', '#fee066', '#ffbf65', '#ff8983']; // Cores mais suaves: verde escuro, amarelo escuro, laranja escuro, vermelho escuro

    const getCellColor = (index: any) => {
        if (index < 3) {
            return '#fee066'; // Verde escuro
        } else if (index < 6) {
            return '#ffbf65'; // Laranja
        } else {
            return '#ff8983'; // Vermelho
        }
    };

    const handleColetaErgoIdChange = async (e: any, data: any) => {
        const id = data.value;
        setLoading(true);
        setColetaId(id);
        try {
            const coletaCompleta = await GetColetaById(id);
            setColetaSelecionada(coletaCompleta);
            setCurrentColeta(coletaCompleta);
            setAvaliacao((avaliacao) => ({
                ...avaliacao,
                ColetaErgoId: id,
            }));
        } catch (error) {
            console.error('Erro ao obter os detalhes da coleta:', error);
        } finally {
            setLoading(false);
        }
    };



    useEffect(() => {

        const handleSolucaoErgoIdChange = async () => {
            try {
                const selectedIds = avaliacao?.solucoesErgonomicasIds ?? [];

                const solucoesErgos = await Promise.all(
                    selectedIds.map(GetSolucaoErgoById)
                )
                    setSolucoesSelecionadas(solucoesErgos);

            } catch (error) {
                console.error('Erro ao obter os detalhes das soluções ergonômicas:', error);
            }
        };

        if (avaliacao) {
            handleSolucaoErgoIdChange();
        }
    }, [avaliacao?.solucoesErgonomicasIds]);


    async function handleRemoveSolucao(solucaoErgoId: string, avaliacaoErgoId: string) {
        try {
            setLoading(true);

            if (window.confirm("Tem certeza que deseja remover a solução ergonômica?")) {
                DeleteAvaliacaoErgoSolucaoErgo(avaliacaoErgoId,solucaoErgoId)
                    .then(()=>{
                        toast.success(`Solução Ergonômica removida com sucesso`, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        GetAvaliacaoErgoById(avaliacaoErgoId).then((aval: any) => {
                            setAvaliacao(aval)
                        })
                    })
            }
        } finally {
            setLoading(false);
        }
    };


    const getTextFromEnumValue = (enumValue: any) => {
        switch (enumValue) {
            case 'Leve':
                return 'Leve';
            case 'Moderado':
                return 'Moderado';
            case 'Intenso':
                return 'Intenso';
            default:
                return '';
        }
    };

    const getCellBackgroundColor = (enumValue: any) => {
        switch (enumValue) {
            case 'Leve':
                return cellColors[1];
            case 'Moderado':
                return cellColors[2];
            case 'Intenso':
                return cellColors[3];
            default:
                return '';
        }
    };


    // useEffect(() => {
    //     calcularResultado();
    // }, [currentColeta]);

    // const calcularResultado = () => {
    //     const gravidade = currentColeta?.perguntaFatorDeRiscoGravidade || 0;
    //     const probabilidade = currentColeta?.perguntaFatorDeProbabilidade || 0;
    //
    //     const gravidadeEnum = EPerguntaFatorDeRiscoGravidade.find((item) => item.value === gravidade);
    //     const probabilidadeEnum = EPerguntaFatorDeProbabilidade.find((item) => item.value === probabilidade);
    //
    //     const gravidadeWeight = gravidadeEnum?.weight || 0;
    //     const probabilidadeWeight = probabilidadeEnum?.weight || 0;
    //
    //     const resultadoCalculado = gravidadeWeight * probabilidadeWeight;
    //     let classificacao = '';
    //     let prazoSugerido = '';
    //
    //     if (resultadoCalculado >= 1 && resultadoCalculado <= 2.5) {
    //         classificacao = 'MuitoLeve';
    //         prazoSugerido = 'Baixo';
    //     } else if (resultadoCalculado > 2.5 && resultadoCalculado <= 4.5) {
    //         classificacao = 'Leve';
    //         prazoSugerido = 'Moderado';
    //     } else if (resultadoCalculado > 4.5 && resultadoCalculado <= 9) {
    //         classificacao = 'Moderado';
    //         prazoSugerido = 'Alto';
    //     } else if (resultadoCalculado > 9 && resultadoCalculado <= 12) {
    //         classificacao = 'Alto';
    //         prazoSugerido = 'MuitoAlto';
    //     } else if (resultadoCalculado > 12 && resultadoCalculado <= 20) {
    //         classificacao = 'MuitoAlto';
    //         prazoSugerido = 'Altissimo';
    //     }
    //     const classificacaoEnumItem = EClassificacaoAvaliacaoErgonomica.find(item => item.value === classificacao);
    //
    //     if (classificacaoEnumItem) {
    //         classificacao = classificacaoEnumItem.value;
    //     }
    //     const prazoSugeridoEnumItem = EPrazoSugeridoAvaliacaoErgo.find(item => item.value === prazoSugerido);
    //
    //     if (prazoSugeridoEnumItem) {
    //         prazoSugerido = prazoSugeridoEnumItem.value;
    //     }
    //     setAvaliacao((avaliacao) => ({
    //         ...avaliacao,
    //         classificação: classificacaoEnumItem?.value || '',
    //         prazoSugerido: prazoSugeridoEnumItem?.value || '',
    //     }));
    // };

    function getEnumText(enumArray: any, value: any) {
        const enumItem = enumArray.find((item: any) => item.value === value);
        return enumItem ? enumItem.text : '';
    }

    const classificacaoTexto = getEnumText(EClassificacaoAvaliacaoErgonomica, avaliacao?.classificação);
    const prazoTexto = getEnumText(EPrazoSugeridoAvaliacaoErgo, avaliacao?.prazoSugerido);


    const updateAvaliacao = async () => {
        try {
            setLoading(true);

            if (avaliacao) {
                const result = await UpdateAvaliacaoErgoPreliminar(avaliacao, avaliacao.id);
                if (result.success) {
                    navigate('/backoffice/avaliacoes-ergo/');
                    toast.success('Avaliação Ergonômica atualizada com sucesso', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            }
        } catch (error) {
            toast.warn('Falha ao atualizar avaliação ergonômica', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            console.warn(error);
        } finally {
            setLoading(false);
        }
    };


    return (
        <div>
            <Grid.Column mobile={16} tablet={8} computer={6}>
                <Header as="h2" textAlign="center" color={'black'}>
                    Avaliação Ergonômica Preliminar / Atualizar
                </Header>
                <Segment>
                    <Form>
                        <Form.Field>
                            <Form.Select
                                label={'Qual a Coleta Ergonômica para essa avaliação?'}
                                options={coletasErgo}
                                placeholder='Selecione uma opção'
                                value={avaliacao?.coletaErgoId}
                                onChange={handleColetaErgoIdChange}
                                search
                                clearable
                                required
                            />
                        </Form.Field>
                        <Grid stackable columns={2}>
                            <Grid.Column>
                                <Form.Field>
                                    <Form.Input
                                        label={'Cliente que a coleta foi realizada'}
                                        placeholder='Nome do Cliente (Nome Fantasia)'
                                        value={currentColeta?.clientName}
                                        readOnly
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <Form.Input
                                        label="Nome do colaborador entrevistado"
                                        name="nome"
                                        type="text"
                                        placeholder="Nome do coleborador entrevistado"
                                        value={currentColeta?.nome}
                                        onChange={handleInputChange}
                                        readOnly
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <Form.Input
                                        label={'Função/Cargo'}
                                        name="funcaoClt"
                                        type="text"
                                        placeholder="Função Clt"
                                        value={currentColeta?.funcaoClt}
                                        readOnly
                                    >
                                    </Form.Input>
                                </Form.Field>

                                <Form.Field>
                                    <Form.Input
                                        label={'Altura do colaborador'}
                                        name="altura"
                                        type="number"
                                        placeholder="Altura do colaborador"
                                        value={currentColeta?.altura}
                                        readOnly
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <Form.Select
                                        label={'A quanto tempo trabalha na empresa'}
                                        options={EPerguntaTempoTrabalhado}
                                        placeholder='Selecione uma opção'
                                        value={currentColeta?.tempoTrabalhado}
                                        readOnly
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <Form.Input
                                        label="Hábitos (outros)"
                                        name="outrosPerguntaHabitoQueNaoGeraSaude"
                                        type="text"
                                        placeholder="Outros hábitos que não geram saúde"
                                        value={currentColeta?.outrosPerguntaHabitoQueNaoGeraSaude}
                                        readOnly/>
                                </Form.Field>

                                <Form.Field>
                                    <Form.Input
                                        label={'Responsável do setor'}
                                        name="nomeSupervisor"
                                        type="text"
                                        placeholder="Nome do Supervisor"
                                        value={currentColeta?.nomeSupervisor}
                                        readOnly
                                    />
                                </Form.Field>
                            </Grid.Column>

                            <Grid.Column>
                                <Form.Field>
                                    <Form.Input
                                        label="CNPJ"
                                        name="cnpj"
                                        type="number"
                                        placeholder="CNPJ do cliente"
                                        value={currentColeta?.clientCnpj}
                                        readOnly
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <Form.Input
                                        label={'Idade do colaborador entrevistado:'}
                                        name="idade"
                                        type="number"
                                        placeholder="Idade do colaborador"
                                        value={currentColeta?.idade}
                                        readOnly
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <Form.Input
                                        label={'Qual o peso do colaborador (kg)? (Exemplo: 72)'}
                                        name="peso"
                                        type="number"
                                        placeholder="Peso do colaborador"
                                        value={currentColeta?.peso}
                                        readOnly
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <Form.Select
                                        label={'Hábitos exercícios:'}
                                        options={EPerguntaPraticaExercicioFisico}
                                        placeholder='Selecione uma opção'
                                        value={currentColeta?.praticaExercicioFisico}
                                        readOnly
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <Form.Input
                                        label={'Principais atividades de trabalho:'}
                                        name="atividadesPrincipais"
                                        type="text"
                                        placeholder="Atividades principais do colaborador"
                                        value={currentColeta?.atividadesPrincipais}
                                        readOnly
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <Form.Input
                                        label={'Qual o setor que trabalha:'}
                                        name="setor"
                                        type="text"
                                        placeholder="Setor"
                                        value={currentColeta?.setor}
                                        readOnly
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <Form.Select
                                        label={'Predominancia Biomecânica:'}
                                        options={EPerguntaPredominanciaDaPostura}
                                        placeholder='Selecione uma opção'
                                        value={currentColeta?.perguntaPredominanciaDaPostura}
                                        readOnly
                                    />
                                </Form.Field>
                            </Grid.Column>
                        </Grid>

                        <Form.Field>
                            <div>
                                <div>
                                    <br/>
                                    <h2>Opinião e percepção do trabalhador</h2>
                                    <Table color={"orange"}>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>Condições ambientais de trabalho</Table.HeaderCell>
                                                {EPerguntaSobreAmbienteDeTrabalho.map((item, index) => (
                                                    <Table.HeaderCell
                                                        key={item.value}
                                                        style={{backgroundColor: cellColors[index]}}
                                                    >
                                                        {item.text}
                                                    </Table.HeaderCell>
                                                ))}
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell>Ruído me afeta</Table.Cell>
                                                {EPerguntaSobreAmbienteDeTrabalho.map((item, index) => (
                                                    <Table.Cell
                                                        key={item.value}
                                                        style={{
                                                            backgroundColor:
                                                                currentColeta?.ruidoPerguntaSobreAmbienteDeTrabalho === item.value
                                                                    ? cellColors[index]
                                                                    : '',
                                                        }}
                                                    >
                                                        {item.text}
                                                    </Table.Cell>
                                                ))}
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>Temperatura me afeta</Table.Cell>
                                                {EPerguntaSobreAmbienteDeTrabalho.map((item, index) => (
                                                    <Table.Cell
                                                        key={item.value}
                                                        className={
                                                            currentColeta?.temperaturaPerguntaSobreAmbienteDeTrabalho === item.value
                                                                ? 'marked-cell'
                                                                : ''
                                                        }
                                                        style={{
                                                            backgroundColor:
                                                                currentColeta?.temperaturaPerguntaSobreAmbienteDeTrabalho === item.value
                                                                    ? cellColors[index]
                                                                    : '',
                                                        }}
                                                    >
                                                        {item.text}
                                                    </Table.Cell>
                                                ))}
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>Iluminação me afeta</Table.Cell>
                                                {EPerguntaSobreAmbienteDeTrabalho.map((item, index) => (
                                                    <Table.Cell
                                                        key={item.value}
                                                        className={
                                                            currentColeta?.iluminacaoPerguntaSobreAmbienteDeTrabalho === item.value
                                                                ? 'marked-cell'
                                                                : ''
                                                        }
                                                        style={{
                                                            backgroundColor:
                                                                currentColeta?.iluminacaoPerguntaSobreAmbienteDeTrabalho === item.value
                                                                    ? cellColors[index]
                                                                    : '',
                                                        }}
                                                    >
                                                        {item.text}
                                                    </Table.Cell>
                                                ))}
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>Baixa umidade me afeta</Table.Cell>
                                                {EPerguntaSobreAmbienteDeTrabalho.map((item, index) => (
                                                    <Table.Cell
                                                        key={item.value}
                                                        className={
                                                            currentColeta?.baixaUmidadePerguntaSobreAmbienteDeTrabalho === item.value
                                                                ? 'marked-cell'
                                                                : ''
                                                        }
                                                        style={{
                                                            backgroundColor:
                                                                currentColeta?.baixaUmidadePerguntaSobreAmbienteDeTrabalho === item.value
                                                                    ? cellColors[index]
                                                                    : '',
                                                        }}
                                                    >
                                                        {item.text}
                                                    </Table.Cell>
                                                ))}
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                </div>
                            </div>
                            <br/>
                        </Form.Field>
                        <br/>
                        <Form.Field>
                            <Header as="h2">Avaliação de percepção do esforço mental pelo trabalhador em atividade
                                desafiadora - (Escala de Pass)</Header>
                            <Table color={"orange"}>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Esforço Mental do trabalhador</Table.HeaderCell>
                                        {EPerguntaEsforcoMental.map((item, index) => (
                                            <Table.HeaderCell
                                                key={item.value}
                                                style={{backgroundColor: getCellColor(index)}}
                                            >
                                                {item.text}
                                            </Table.HeaderCell>
                                        ))}
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>Esforço Mental</Table.Cell>
                                        {EPerguntaEsforcoMental.map((item, index) => (
                                            <Table.Cell
                                                key={item.value}
                                                style={{
                                                    backgroundColor:
                                                        currentColeta?.perguntaEsforcoMental === item.value ? getCellColor(index) : '',
                                                }}
                                                className={currentColeta?.perguntaEsforcoMental === item.value ? 'marked-cell' : ''}
                                            >
                                                {item.text}
                                            </Table.Cell>
                                        ))}
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                            <br/>
                            <Header as="h2">Avaliação da percepção de esforço físico do trabalhador (Escala de
                                Borg):</Header>
                            <Table color={"orange"}>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Esforço Físico do trabalhador</Table.HeaderCell>
                                        {EPerguntaEsforcoFisico.map((item, index) => (
                                            <Table.HeaderCell
                                                key={item.value}
                                                style={{backgroundColor: getCellColor(index)}}
                                            >
                                                {item.text}
                                            </Table.HeaderCell>
                                        ))}
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>Esforço Físico</Table.Cell>
                                        {EPerguntaEsforcoFisico.map((item, index) => (
                                            <Table.Cell
                                                key={item.value}
                                                style={{
                                                    backgroundColor:
                                                        currentColeta?.perguntaEsforcoFisico === item.value ? getCellColor(index) : '',
                                                }}
                                                className={currentColeta?.perguntaEsforcoFisico === item.value ? 'marked-cell' : ''}
                                            >
                                                {item.text}
                                            </Table.Cell>
                                        ))}
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </Form.Field>
                        <br/>
                        {/*#region Identificação dos Riscos */}
                        <Form.Field>
                            <h3>Identificação dos Riscos (Fator de risco/perigo)</h3>
                            <Table color={"orange"} celled>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>#</Table.HeaderCell>
                                        <Table.HeaderCell>Postura Inadequada</Table.HeaderCell>
                                        <Table.HeaderCell>Presente</Table.HeaderCell>
                                        <Table.HeaderCell>Risco</Table.HeaderCell>
                                        <Table.HeaderCell>Ausente</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Row>
                                    <Table.Cell>
                                    </Table.Cell>
                                    <Table.Cell>
                                        Postura inadequada do pescoço
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Checkbox
                                            toggle
                                            checked={
                                                currentColeta?.flexaoAcima20GrausPerguntaPosturaInadequadaPescoco ||
                                                currentColeta?.extensaoSemSupAcima10GrausPerguntaPosturaInadequadaPescoco ||
                                                currentColeta?.inclinacaoAcima20GrausPerguntaPosturaInadequadaPescoco ||
                                                currentColeta?.rotacaoAcima30GrausPerguntaPosturaInadequadaPescoco ||
                                                currentColeta?.naoExisteRiscoPescocoPerguntaPosturaInadequadaPescoco
                                            }
                                        />
                                    </Table.Cell>
                                    <Table.Cell>
                                        {currentColeta?.flexaoAcima20GrausPerguntaPosturaInadequadaPescoco && "Flexão acima de 20 graus, "}
                                        {currentColeta?.extensaoSemSupAcima10GrausPerguntaPosturaInadequadaPescoco && "Extensão sem suporte acima de 10 graus, "}
                                        {currentColeta?.inclinacaoAcima20GrausPerguntaPosturaInadequadaPescoco && "Inclinação acima de 20 graus, "}
                                        {currentColeta?.rotacaoAcima30GrausPerguntaPosturaInadequadaPescoco && "Rotação acima de 30 graus, "}
                                        {currentColeta?.naoExisteRiscoPescocoPerguntaPosturaInadequadaPescoco && "Não existe risco"}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Checkbox
                                            toggle
                                            checked={
                                                !currentColeta?.flexaoAcima20GrausPerguntaPosturaInadequadaPescoco &&
                                                !currentColeta?.extensaoSemSupAcima10GrausPerguntaPosturaInadequadaPescoco &&
                                                !currentColeta?.inclinacaoAcima20GrausPerguntaPosturaInadequadaPescoco &&
                                                !currentColeta?.rotacaoAcima30GrausPerguntaPosturaInadequadaPescoco &&
                                                !currentColeta?.naoExisteRiscoPescocoPerguntaPosturaInadequadaPescoco
                                            }
                                        />
                                    </Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell>
                                    </Table.Cell>
                                    <Table.Cell>
                                        Postura Inadequada Ombros
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Checkbox
                                            toggle
                                            readOnly
                                            checked={
                                                currentColeta?.flexaoAcima45A90GrausPerguntaPosturaInadequadaOmbros ||
                                                currentColeta?.extensaoAcima20GrausPerguntaPosturaInadequadaOmbros ||
                                                currentColeta?.aducaoAcima45GrausPerguntaPosturaInadequadaOmbros ||
                                                currentColeta?.rotacaoAcima45GrausPerguntaPosturaInadequadaOmbros ||
                                                currentColeta?.abducaoAcima45GrausPerguntaPosturaInadequadaOmbros ||
                                                currentColeta?.ombrosElevadosPerguntaPosturaInadequadaOmbros
                                            }
                                        />
                                    </Table.Cell>
                                    <Table.Cell>
                                        {currentColeta?.flexaoAcima45A90GrausPerguntaPosturaInadequadaOmbros && "Flexão acima de 45 a 90 graus, "}
                                        {currentColeta?.extensaoAcima20GrausPerguntaPosturaInadequadaOmbros && "Extensão acima de 20 graus, "}
                                        {currentColeta?.aducaoAcima45GrausPerguntaPosturaInadequadaOmbros && "Adução acima de 45 graus, "}
                                        {currentColeta?.rotacaoAcima45GrausPerguntaPosturaInadequadaOmbros && "Rotação acima de 45 graus, "}
                                        {currentColeta?.abducaoAcima45GrausPerguntaPosturaInadequadaOmbros && "Abdução acima de 45 graus, "}
                                        {currentColeta?.ombrosElevadosPerguntaPosturaInadequadaOmbros && "Ombros elevados"}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Checkbox
                                            toggle
                                            readOnly
                                            checked={
                                                !currentColeta?.flexaoAcima45A90GrausPerguntaPosturaInadequadaOmbros &&
                                                !currentColeta?.extensaoAcima20GrausPerguntaPosturaInadequadaOmbros &&
                                                !currentColeta?.aducaoAcima45GrausPerguntaPosturaInadequadaOmbros &&
                                                !currentColeta?.rotacaoAcima45GrausPerguntaPosturaInadequadaOmbros &&
                                                !currentColeta?.abducaoAcima45GrausPerguntaPosturaInadequadaOmbros &&
                                                !currentColeta?.ombrosElevadosPerguntaPosturaInadequadaOmbros
                                            }
                                        />
                                    </Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell>
                                    </Table.Cell>
                                    <Table.Cell>
                                        Postura Inadequada Cotovelos
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Checkbox
                                            toggle
                                            readOnly
                                            checked={
                                                currentColeta?.flexaoAcima100GrausPerguntaPosturaInadequadaCotovelos ||
                                                currentColeta?.extensaoAcima60GrausPerguntaPosturaInadequadaCotovelos ||
                                                currentColeta?.totalmenteExtendPerguntaPosturaInadequadaCotovelos ||
                                                currentColeta?.totalmenteFlexPerguntaPosturaInadequadaCotovelos
                                            }
                                        />
                                    </Table.Cell>
                                    <Table.Cell>
                                        {currentColeta?.flexaoAcima100GrausPerguntaPosturaInadequadaCotovelos && "Flexão acima de 100 graus, "}
                                        {currentColeta?.extensaoAcima60GrausPerguntaPosturaInadequadaCotovelos && "Extensão acima de 60 graus, "}
                                        {currentColeta?.totalmenteExtendPerguntaPosturaInadequadaCotovelos && "Totalmente extendido, "}
                                        {currentColeta?.totalmenteFlexPerguntaPosturaInadequadaCotovelos && "Totalmente flexionado"}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Checkbox
                                            toggle
                                            readOnly
                                            checked={
                                                !currentColeta?.flexaoAcima100GrausPerguntaPosturaInadequadaCotovelos &&
                                                !currentColeta?.extensaoAcima60GrausPerguntaPosturaInadequadaCotovelos &&
                                                !currentColeta?.totalmenteExtendPerguntaPosturaInadequadaCotovelos &&
                                                !currentColeta?.totalmenteFlexPerguntaPosturaInadequadaCotovelos
                                            }
                                        />
                                    </Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell>
                                    </Table.Cell>
                                    <Table.Cell>
                                        Postura Inadequada Punhos
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Checkbox
                                            toggle
                                            readOnly
                                            checked={
                                                currentColeta?.flexaoAcima45GrausPerguntaPosturaInadequadaPunhos ||
                                                currentColeta?.desviosLateraisAcima10GrausPerguntaPosturaInadequadaPunhos ||
                                                currentColeta?.extensaoAcima15GrausPerguntaPosturaInadequadaPunhos ||
                                                currentColeta?.totalmenteFlexionadoEstendidoPerguntaPosturaInadequadaPunhos
                                            }
                                        />
                                    </Table.Cell>
                                    <Table.Cell>
                                        {currentColeta?.flexaoAcima45GrausPerguntaPosturaInadequadaPunhos && "Flexão acima de 45 graus, "}
                                        {currentColeta?.desviosLateraisAcima10GrausPerguntaPosturaInadequadaPunhos && "Desvios laterais acima de 10 graus, "}
                                        {currentColeta?.extensaoAcima15GrausPerguntaPosturaInadequadaPunhos && "Extensão acima de 15 graus, "}
                                        {currentColeta?.totalmenteFlexionadoEstendidoPerguntaPosturaInadequadaPunhos && "Totalmente flexionado/estendido"}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Checkbox
                                            toggle
                                            readOnly
                                            checked={
                                                !currentColeta?.flexaoAcima45GrausPerguntaPosturaInadequadaPunhos &&
                                                !currentColeta?.desviosLateraisAcima10GrausPerguntaPosturaInadequadaPunhos &&
                                                !currentColeta?.extensaoAcima15GrausPerguntaPosturaInadequadaPunhos &&
                                                !currentColeta?.totalmenteFlexionadoEstendidoPerguntaPosturaInadequadaPunhos
                                            }
                                        />
                                    </Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell>
                                    </Table.Cell>
                                    <Table.Cell>
                                        Postura Inadequada Tronco
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Checkbox
                                            toggle
                                            readOnly
                                            checked={
                                                currentColeta?.flexaoMaiorOu20GrausPerguntaPosturaInadequadaTronco ||
                                                currentColeta?.extensaoSemSuporteAcima10GrausPerguntaPosturaInadequadaTronco ||
                                                currentColeta?.inclinacaoAcimaDe30GrausPerguntaPosturaInadequadaTronco ||
                                                currentColeta?.rotacaoAcimaDe30GrausPerguntaPosturaInadequadaTronco
                                            }
                                        />
                                    </Table.Cell>
                                    <Table.Cell>
                                        {currentColeta?.flexaoMaiorOu20GrausPerguntaPosturaInadequadaTronco && "Flexão maior que 20 graus, "}
                                        {currentColeta?.extensaoSemSuporteAcima10GrausPerguntaPosturaInadequadaTronco && "Extensão sem suporte acima de 10 graus, "}
                                        {currentColeta?.inclinacaoAcimaDe30GrausPerguntaPosturaInadequadaTronco && "Inclinação acima de 30 graus, "}
                                        {currentColeta?.rotacaoAcimaDe30GrausPerguntaPosturaInadequadaTronco && "Rotação acima de 30 graus"}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Checkbox
                                            toggle
                                            readOnly
                                            checked={
                                                !currentColeta?.flexaoMaiorOu20GrausPerguntaPosturaInadequadaTronco &&
                                                !currentColeta?.extensaoSemSuporteAcima10GrausPerguntaPosturaInadequadaTronco &&
                                                !currentColeta?.inclinacaoAcimaDe30GrausPerguntaPosturaInadequadaTronco &&
                                                !currentColeta?.rotacaoAcimaDe30GrausPerguntaPosturaInadequadaTronco
                                            }
                                        />
                                    </Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell>
                                    </Table.Cell>
                                    <Table.Cell>
                                        Postura Inadequada Pernas
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Checkbox
                                            toggle
                                            readOnly
                                            checked={
                                                currentColeta?.flexaoAcima90GrausPerguntaPosturaInadequadaPernas ||
                                                currentColeta?.naoTotalmenteApoiadaOuEquilibradaPerguntaPosturaInadequadaPernas ||
                                                currentColeta?.naoHaEspacoParaAsPernasMaisDe2HorasPerguntaPosturaInadequadaPernas ||
                                                currentColeta?.agachadoOuAjoelhadoPerguntaPosturaInadequadaPernas
                                            }
                                        />
                                    </Table.Cell>
                                    <Table.Cell>
                                        {currentColeta?.flexaoAcima90GrausPerguntaPosturaInadequadaPernas && "Flexão acima de 90 graus, "}
                                        {currentColeta?.naoTotalmenteApoiadaOuEquilibradaPerguntaPosturaInadequadaPernas && "Não totalmente apoiada ou equilibrada, "}
                                        {currentColeta?.naoHaEspacoParaAsPernasMaisDe2HorasPerguntaPosturaInadequadaPernas && "Não há espaço para as pernas por mais de 2 horas, "}
                                        {currentColeta?.agachadoOuAjoelhadoPerguntaPosturaInadequadaPernas && "Agachado ou ajoelhado"}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Checkbox
                                            toggle
                                            readOnly
                                            checked={
                                                !currentColeta?.flexaoAcima90GrausPerguntaPosturaInadequadaPernas &&
                                                !currentColeta?.naoTotalmenteApoiadaOuEquilibradaPerguntaPosturaInadequadaPernas &&
                                                !currentColeta?.naoHaEspacoParaAsPernasMaisDe2HorasPerguntaPosturaInadequadaPernas &&
                                                !currentColeta?.agachadoOuAjoelhadoPerguntaPosturaInadequadaPernas
                                            }
                                        />
                                    </Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell>
                                    </Table.Cell>
                                    <Table.Cell>
                                        Perigos Posturais Gerais
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Checkbox
                                            toggle
                                            readOnly
                                            checked={
                                                currentColeta?.posturaSentadoLongoPeriodoPerguntaPerigosPosturaisGerais ||
                                                currentColeta?.posturaEmPeLongoPeriodoPerguntaPerigosPosturaisGerais ||
                                                currentColeta?.frequentementeDeslocAPeDuranteTrabalhoPerguntaPerigosPosturaisGerais ||
                                                currentColeta?.levantamentoTransporteManualDeCargasPerguntaPerigosPosturaisGerais ||
                                                currentColeta?.movimentosRepetitivosFrequentesPerguntaPerigosPosturaisGerais ||
                                                currentColeta?.manuseioFerramentasObjetosPesadosLongosPeriodosPerguntaPerigosPosturaisGerais ||
                                                currentColeta?.compressaoPartesCorpoPorSuperficiesPerguntaPerigosPosturaisGerais ||
                                                currentColeta?.usoFrequentePedaisPerguntaPerigosPosturaisGerais ||
                                                currentColeta?.usoFrequenteAlavancasPerguntaPerigosPosturaisGerais ||
                                                currentColeta?.exposicaoAVibracaoDeCorpoInteiroPerguntaPerigosPosturaisGerais ||
                                                currentColeta?.exposicaoAVibracaoLocalizadaPerguntaPerigosPosturaisGerais ||
                                                currentColeta?.usoFrequenteEscadasPerguntaPerigosPosturaisGerais ||
                                                currentColeta?.trabalhoIntensivoTecladoEntradaDeDadosPerguntaPerigosPosturaisGerais ||
                                                false
                                            }
                                        />
                                    </Table.Cell>
                                    <Table.Cell>
                                        {currentColeta?.posturaSentadoLongoPeriodoPerguntaPerigosPosturaisGerais && "Postura Sentado por Longos Períodos, "}
                                        {currentColeta?.posturaEmPeLongoPeriodoPerguntaPerigosPosturaisGerais && "Postura em Pé por Longos Períodos, "}
                                        {currentColeta?.frequentementeDeslocAPeDuranteTrabalhoPerguntaPerigosPosturaisGerais && "Frequentemente Desloca a Pé Durante o Trabalho, "}
                                        {currentColeta?.levantamentoTransporteManualDeCargasPerguntaPerigosPosturaisGerais && "Levantamento/Transporte Manual de Cargas, "}
                                        {currentColeta?.movimentosRepetitivosFrequentesPerguntaPerigosPosturaisGerais && "Movimentos Repetitivos Frequentes, "}
                                        {currentColeta?.manuseioFerramentasObjetosPesadosLongosPeriodosPerguntaPerigosPosturaisGerais && "Manuseio de Ferramentas/Objetos Pesados por Longos Períodos, "}
                                        {currentColeta?.compressaoPartesCorpoPorSuperficiesPerguntaPerigosPosturaisGerais && "Compressão de Partes do Corpo por Superfícies, "}
                                        {currentColeta?.usoFrequentePedaisPerguntaPerigosPosturaisGerais && "Uso Frequente de Pedais, "}
                                        {currentColeta?.usoFrequenteAlavancasPerguntaPerigosPosturaisGerais && "Uso Frequente de Alavancas, "}
                                        {currentColeta?.exposicaoAVibracaoDeCorpoInteiroPerguntaPerigosPosturaisGerais && "Exposição a Vibração de Corpo Inteiro, "}
                                        {currentColeta?.exposicaoAVibracaoLocalizadaPerguntaPerigosPosturaisGerais && "Exposição a Vibração Localizada, "}
                                        {currentColeta?.usoFrequenteEscadasPerguntaPerigosPosturaisGerais && "Uso Frequente de Escadas, "}
                                        {currentColeta?.trabalhoIntensivoTecladoEntradaDeDadosPerguntaPerigosPosturaisGerais && "Trabalho Intensivo com Teclado/Entrada de Dados, "}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Checkbox
                                            toggle
                                            readOnly
                                            checked={
                                                !currentColeta?.posturaSentadoLongoPeriodoPerguntaPerigosPosturaisGerais &&
                                                !currentColeta?.posturaEmPeLongoPeriodoPerguntaPerigosPosturaisGerais &&
                                                !currentColeta?.frequentementeDeslocAPeDuranteTrabalhoPerguntaPerigosPosturaisGerais &&
                                                !currentColeta?.levantamentoTransporteManualDeCargasPerguntaPerigosPosturaisGerais &&
                                                !currentColeta?.movimentosRepetitivosFrequentesPerguntaPerigosPosturaisGerais &&
                                                !currentColeta?.manuseioFerramentasObjetosPesadosLongosPeriodosPerguntaPerigosPosturaisGerais &&
                                                !currentColeta?.compressaoPartesCorpoPorSuperficiesPerguntaPerigosPosturaisGerais &&
                                                !currentColeta?.usoFrequentePedaisPerguntaPerigosPosturaisGerais &&
                                                !currentColeta?.usoFrequenteAlavancasPerguntaPerigosPosturaisGerais &&
                                                !currentColeta?.exposicaoAVibracaoDeCorpoInteiroPerguntaPerigosPosturaisGerais &&
                                                !currentColeta?.exposicaoAVibracaoLocalizadaPerguntaPerigosPosturaisGerais &&
                                                !currentColeta?.usoFrequenteEscadasPerguntaPerigosPosturaisGerais &&
                                                !currentColeta?.trabalhoIntensivoTecladoEntradaDeDadosPerguntaPerigosPosturaisGerais &&
                                                true
                                            }
                                        />
                                    </Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell>
                                    </Table.Cell>
                                    <Table.Cell>
                                        Forças Existentes na Atividade
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Checkbox
                                            toggle
                                            readOnly
                                            checked={
                                                currentColeta?.entre2A10KGPerguntaForcasExistentesNaAtividadeAvaliada ||
                                                currentColeta?.entre2A10KGEstaticoPerguntaForcasExistentesNaAtividadeAvaliada ||
                                                currentColeta?.maior10KgRepetitivoOuImpactoPerguntaForcasExistentesNaAtividadeAvaliada ||
                                                currentColeta?.posturaEstaticaSegurarPor1MOuMaisPerguntaForcasExistentesNaAtividadeAvaliada ||
                                                currentColeta?.maosEmPincaPressaoMaiorQue09KGPerguntaForcasExistentesNaAtividadeAvaliada ||
                                                currentColeta?.maosEmAgarreMaiorQue45KgPerguntaForcasExistentesNaAtividadeAvaliada ||
                                                currentColeta?.cotovelosMaiorQue45KgPerguntaForcasExistentesNaAtividadeAvaliada ||
                                                currentColeta?.ombrosMaiorQue45KgPerguntaForcasExistentesNaAtividadeAvaliada ||
                                                currentColeta?.cervicalPesoAdicionalPerguntaForcasExistentesNaAtividadeAvaliada ||
                                                currentColeta?.colunaMaiorQue113KgPerguntaForcasExistentesNaAtividadeAvaliada ||
                                                currentColeta?.pedalMaiorQue45KgPerguntaForcasExistentesNaAtividadeAvaliada ||
                                                currentColeta?.transporteManual15kgMinutoPerguntaForcasExistentesNaAtividadeAvaliada ||
                                                currentColeta?.peso5A10Kg1XPorMinutoPerguntaForcasExistentesNaAtividadeAvaliada ||
                                                currentColeta?.pegarPesoAcimaDe175CmPerguntaForcasExistentesNaAtividadeAvaliada ||
                                                currentColeta?.puxarEmpurrarEmDesnivelPerguntaForcasExistentesNaAtividadeAvaliada ||
                                                currentColeta?.distanciaHorizontalMaior63CmPegarCargaPerguntaForcasExistentesNaAtividadeAvaliada
                                            }
                                        />
                                    </Table.Cell>
                                    <Table.Cell>
                                        {currentColeta?.entre2A10KGPerguntaForcasExistentesNaAtividadeAvaliada && "Entre 2 e 10 kg, "}
                                        {currentColeta?.entre2A10KGEstaticoPerguntaForcasExistentesNaAtividadeAvaliada && "Entre 2 e 10 kg (estático), "}
                                        {currentColeta?.maior10KgRepetitivoOuImpactoPerguntaForcasExistentesNaAtividadeAvaliada && "Maior que 10 kg (repetitivo ou impacto), "}
                                        {currentColeta?.posturaEstaticaSegurarPor1MOuMaisPerguntaForcasExistentesNaAtividadeAvaliada && "Postura estática segurando por 1 minuto ou mais, "}
                                        {currentColeta?.maosEmPincaPressaoMaiorQue09KGPerguntaForcasExistentesNaAtividadeAvaliada && "Mãos em pinça com pressão maior que 0.9 kg, "}
                                        {currentColeta?.maosEmAgarreMaiorQue45KgPerguntaForcasExistentesNaAtividadeAvaliada && "Mãos em agarre com mais de 4.5 kg, "}
                                        {currentColeta?.cotovelosMaiorQue45KgPerguntaForcasExistentesNaAtividadeAvaliada && "Cotovelos com mais de 4.5 kg, "}
                                        {currentColeta?.ombrosMaiorQue45KgPerguntaForcasExistentesNaAtividadeAvaliada && "Ombros com mais de 4.5 kg, "}
                                        {currentColeta?.cervicalPesoAdicionalPerguntaForcasExistentesNaAtividadeAvaliada && "Cervical com peso adicional, "}
                                        {currentColeta?.colunaMaiorQue113KgPerguntaForcasExistentesNaAtividadeAvaliada && "Coluna com mais de 113 kg, "}
                                        {currentColeta?.pedalMaiorQue45KgPerguntaForcasExistentesNaAtividadeAvaliada && "Pedal com mais de 4.5 kg, "}
                                        {currentColeta?.transporteManual15kgMinutoPerguntaForcasExistentesNaAtividadeAvaliada && "Transporte manual de 15 kg por minuto, "}
                                        {currentColeta?.peso5A10Kg1XPorMinutoPerguntaForcasExistentesNaAtividadeAvaliada && "Pegar peso de 5 a 10 kg 1x por minuto, "}
                                        {currentColeta?.pegarPesoAcimaDe175CmPerguntaForcasExistentesNaAtividadeAvaliada && "Pegar peso acima de 175 cm, "}
                                        {currentColeta?.puxarEmpurrarEmDesnivelPerguntaForcasExistentesNaAtividadeAvaliada && "Puxar ou empurrar em desnível, "}
                                        {currentColeta?.distanciaHorizontalMaior63CmPegarCargaPerguntaForcasExistentesNaAtividadeAvaliada && "Distância horizontal maior que 63 cm para pegar carga, "}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Checkbox
                                            toggle
                                            readOnly
                                            checked={
                                                !currentColeta?.entre2A10KGPerguntaForcasExistentesNaAtividadeAvaliada &&
                                                !currentColeta?.entre2A10KGEstaticoPerguntaForcasExistentesNaAtividadeAvaliada &&
                                                !currentColeta?.maior10KgRepetitivoOuImpactoPerguntaForcasExistentesNaAtividadeAvaliada &&
                                                !currentColeta?.posturaEstaticaSegurarPor1MOuMaisPerguntaForcasExistentesNaAtividadeAvaliada &&
                                                !currentColeta?.maosEmPincaPressaoMaiorQue09KGPerguntaForcasExistentesNaAtividadeAvaliada &&
                                                !currentColeta?.maosEmAgarreMaiorQue45KgPerguntaForcasExistentesNaAtividadeAvaliada &&
                                                !currentColeta?.cotovelosMaiorQue45KgPerguntaForcasExistentesNaAtividadeAvaliada &&
                                                !currentColeta?.ombrosMaiorQue45KgPerguntaForcasExistentesNaAtividadeAvaliada &&
                                                !currentColeta?.cervicalPesoAdicionalPerguntaForcasExistentesNaAtividadeAvaliada &&
                                                !currentColeta?.colunaMaiorQue113KgPerguntaForcasExistentesNaAtividadeAvaliada &&
                                                !currentColeta?.pedalMaiorQue45KgPerguntaForcasExistentesNaAtividadeAvaliada &&
                                                !currentColeta?.transporteManual15kgMinutoPerguntaForcasExistentesNaAtividadeAvaliada &&
                                                !currentColeta?.peso5A10Kg1XPorMinutoPerguntaForcasExistentesNaAtividadeAvaliada &&
                                                !currentColeta?.pegarPesoAcimaDe175CmPerguntaForcasExistentesNaAtividadeAvaliada &&
                                                !currentColeta?.puxarEmpurrarEmDesnivelPerguntaForcasExistentesNaAtividadeAvaliada &&
                                                !currentColeta?.distanciaHorizontalMaior63CmPegarCargaPerguntaForcasExistentesNaAtividadeAvaliada
                                            }
                                        />
                                    </Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell>
                                    </Table.Cell>
                                    <Table.Cell>
                                        Organização do Trabalho
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Checkbox
                                            toggle
                                            readOnly
                                            checked={
                                                currentColeta?.trabalhoSemPausasPreDefinidasPerguntaOrganizacaoDoTrabalho ||
                                                currentColeta?.pausaRealizadaForaDoPostoPerguntaOrganizacaoDoTrabalho ||
                                                currentColeta?.necessidadadeDeManterRitmosIntensosPerguntaOrganizacaoDoTrabalho ||
                                                currentColeta?.trabalhoComNecessidadeDeVariacaoDeTurnosPerguntaOrganizacaoDoTrabalho ||
                                                currentColeta?.monotoniaPerguntaOrganizacaoDoTrabalho ||
                                                currentColeta?.trabalhoNoturnoPerguntaOrganizacaoDoTrabalho ||
                                                currentColeta?.insuficienciaDeCapacitacaoParaExecucaoPerguntaOrganizacaoDoTrabalho ||
                                                currentColeta?.trabalhoUtilizacaoRigorosaDeMetasDeProdPerguntaOrganizacaoDoTrabalho ||
                                                currentColeta?.trabalhoRemuneradoPorProducaoPerguntaOrganizacaoDoTrabalho ||
                                                currentColeta?.cadenciaDoTrabalhoImpostaPorEquipamentoPerguntaOrganizacaoDoTrabalho ||
                                                currentColeta?.desequilibrioEntreTempoTrabalhadoETempoRepousoPerguntaOrganizacaoDoTrabalho ||
                                                currentColeta?.jornadaLongaDeTrabalhoMaisDe8HPorDiaPerguntaOrganizacaoDoTrabalho ||
                                                currentColeta?.concentracoesDesequilibradasDeTrabalhoPerguntaOrganizacaoDoTrabalho ||
                                                currentColeta?.nenhumFatorDeRisgoNaAtivPerguntaOrganizacaoDoTrabalho
                                            }
                                        />
                                    </Table.Cell>
                                    <Table.Cell>
                                        {currentColeta?.trabalhoSemPausasPreDefinidasPerguntaOrganizacaoDoTrabalho && "Trabalho sem pausas pré-definidas, "}
                                        {currentColeta?.pausaRealizadaForaDoPostoPerguntaOrganizacaoDoTrabalho && "Pausa realizada fora do posto, "}
                                        {currentColeta?.necessidadadeDeManterRitmosIntensosPerguntaOrganizacaoDoTrabalho && "Necessidade de manter ritmos intensos, "}
                                        {currentColeta?.trabalhoComNecessidadeDeVariacaoDeTurnosPerguntaOrganizacaoDoTrabalho && "Trabalho com necessidade de variação de turnos, "}
                                        {currentColeta?.monotoniaPerguntaOrganizacaoDoTrabalho && "Monotonia, "}
                                        {currentColeta?.trabalhoNoturnoPerguntaOrganizacaoDoTrabalho && "Trabalho noturno, "}
                                        {currentColeta?.insuficienciaDeCapacitacaoParaExecucaoPerguntaOrganizacaoDoTrabalho && "Insuficiência de capacitação para execução, "}
                                        {currentColeta?.trabalhoUtilizacaoRigorosaDeMetasDeProdPerguntaOrganizacaoDoTrabalho && "Trabalho com utilização rigorosa de metas de produção, "}
                                        {currentColeta?.trabalhoRemuneradoPorProducaoPerguntaOrganizacaoDoTrabalho && "Trabalho remunerado por produção, "}
                                        {currentColeta?.cadenciaDoTrabalhoImpostaPorEquipamentoPerguntaOrganizacaoDoTrabalho && "Cadência do trabalho imposta por equipamento, "}
                                        {currentColeta?.desequilibrioEntreTempoTrabalhadoETempoRepousoPerguntaOrganizacaoDoTrabalho && "Desequilíbrio entre tempo trabalhado e tempo de repouso, "}
                                        {currentColeta?.jornadaLongaDeTrabalhoMaisDe8HPorDiaPerguntaOrganizacaoDoTrabalho && "Jornada longa de trabalho (mais de 8 horas por dia), "}
                                        {currentColeta?.concentracoesDesequilibradasDeTrabalhoPerguntaOrganizacaoDoTrabalho && "Concentrações desequilibradas de trabalho, "}
                                        {currentColeta?.nenhumFatorDeRisgoNaAtivPerguntaOrganizacaoDoTrabalho && "Nenhum fator de risco na atividade, "}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Checkbox
                                            toggle
                                            readOnly
                                            checked={
                                                !currentColeta?.trabalhoSemPausasPreDefinidasPerguntaOrganizacaoDoTrabalho &&
                                                !currentColeta?.pausaRealizadaForaDoPostoPerguntaOrganizacaoDoTrabalho &&
                                                !currentColeta?.necessidadadeDeManterRitmosIntensosPerguntaOrganizacaoDoTrabalho &&
                                                !currentColeta?.trabalhoComNecessidadeDeVariacaoDeTurnosPerguntaOrganizacaoDoTrabalho &&
                                                !currentColeta?.monotoniaPerguntaOrganizacaoDoTrabalho &&
                                                !currentColeta?.trabalhoNoturnoPerguntaOrganizacaoDoTrabalho &&
                                                !currentColeta?.insuficienciaDeCapacitacaoParaExecucaoPerguntaOrganizacaoDoTrabalho &&
                                                !currentColeta?.trabalhoUtilizacaoRigorosaDeMetasDeProdPerguntaOrganizacaoDoTrabalho &&
                                                !currentColeta?.trabalhoRemuneradoPorProducaoPerguntaOrganizacaoDoTrabalho &&
                                                !currentColeta?.cadenciaDoTrabalhoImpostaPorEquipamentoPerguntaOrganizacaoDoTrabalho &&
                                                !currentColeta?.desequilibrioEntreTempoTrabalhadoETempoRepousoPerguntaOrganizacaoDoTrabalho &&
                                                !currentColeta?.jornadaLongaDeTrabalhoMaisDe8HPorDiaPerguntaOrganizacaoDoTrabalho &&
                                                !currentColeta?.concentracoesDesequilibradasDeTrabalhoPerguntaOrganizacaoDoTrabalho &&
                                                !currentColeta?.nenhumFatorDeRisgoNaAtivPerguntaOrganizacaoDoTrabalho
                                            }
                                        />
                                    </Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell>
                                    </Table.Cell>
                                    <Table.Cell>
                                        Fatores Psicossociais
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Checkbox
                                            toggle
                                            readOnly
                                            checked={
                                                currentColeta?.trabalhoCondicoesDeDificilComunicacaoPerguntaFatoresPsicossociais ||
                                                currentColeta?.excessoDeConflitosHierarquicosPerguntaFatoresPsicossociais ||
                                                currentColeta?.insatisfacaoNoTrabalhoPerguntaFatoresPsicossociais ||
                                                currentColeta?.faltaDeAutonomiaNoTrabalhoPerguntaFatoresPsicossociais ||
                                                currentColeta?.extresseNoTrabalhoPerguntaFatoresPsicossociais ||
                                                currentColeta?.naoHaFatorDeRiscoPsicossocialPerguntaFatoresPsicossociais
                                            }
                                        />
                                    </Table.Cell>
                                    <Table.Cell>
                                        {currentColeta?.trabalhoCondicoesDeDificilComunicacaoPerguntaFatoresPsicossociais && "Condições de difícil comunicação no trabalho, "}
                                        {currentColeta?.excessoDeConflitosHierarquicosPerguntaFatoresPsicossociais && "Excesso de conflitos hierárquicos no trabalho, "}
                                        {currentColeta?.insatisfacaoNoTrabalhoPerguntaFatoresPsicossociais && "Insatisfação no trabalho, "}
                                        {currentColeta?.faltaDeAutonomiaNoTrabalhoPerguntaFatoresPsicossociais && "Falta de autonomia no trabalho, "}
                                        {currentColeta?.extresseNoTrabalhoPerguntaFatoresPsicossociais && "Estresse no trabalho, "}
                                        {currentColeta?.naoHaFatorDeRiscoPsicossocialPerguntaFatoresPsicossociais && "Não há fator de risco psicossocial, "}
                                    </Table.Cell>

                                    <Table.Cell>
                                        <Checkbox
                                            toggle
                                            readOnly
                                            checked={
                                                !currentColeta?.trabalhoCondicoesDeDificilComunicacaoPerguntaFatoresPsicossociais &&
                                                !currentColeta?.excessoDeConflitosHierarquicosPerguntaFatoresPsicossociais &&
                                                !currentColeta?.insatisfacaoNoTrabalhoPerguntaFatoresPsicossociais &&
                                                !currentColeta?.faltaDeAutonomiaNoTrabalhoPerguntaFatoresPsicossociais &&
                                                !currentColeta?.extresseNoTrabalhoPerguntaFatoresPsicossociais &&
                                                !currentColeta?.naoHaFatorDeRiscoPsicossocialPerguntaFatoresPsicossociais &&
                                                (
                                                    currentColeta?.trabalhoCondicoesDeDificilComunicacaoPerguntaFatoresPsicossociais === false ||
                                                    currentColeta?.excessoDeConflitosHierarquicosPerguntaFatoresPsicossociais === false ||
                                                    currentColeta?.insatisfacaoNoTrabalhoPerguntaFatoresPsicossociais === false ||
                                                    currentColeta?.faltaDeAutonomiaNoTrabalhoPerguntaFatoresPsicossociais === false ||
                                                    currentColeta?.extresseNoTrabalhoPerguntaFatoresPsicossociais === false ||
                                                    currentColeta?.naoHaFatorDeRiscoPsicossocialPerguntaFatoresPsicossociais === false
                                                )
                                            }
                                        />
                                    </Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell>
                                    </Table.Cell>
                                    <Table.Cell>
                                        Fatores Cognitivos
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Checkbox
                                            toggle
                                            readOnly
                                            checked={
                                                currentColeta?.trabalhoExigeConcentracaoPerguntaFatoresCognitivos ||
                                                currentColeta?.trabalhoExigeAtencaoPerguntaFatoresCognitivos ||
                                                currentColeta?.trabalhoExigeMemorizeSenhaNumerosPerguntaFatoresCognitivos ||
                                                currentColeta?.usabilidadeNoTrabalhoFacilDeTrabalharPerguntaFatoresCognitivos ||
                                                currentColeta?.usabilidadeNoTrabalhoEngajamentoPerguntaFatoresCognitivos ||
                                                currentColeta?.usabilidadeNoTrabalhoAplicavelPerguntaFatoresCognitivos ||
                                                currentColeta?.oTrabalhoTemSistemaDeRetornoDeInfoPerguntaFatoresCognitivos ||
                                                currentColeta?.affordanceSistemasMaquinasEquipamentosERotasPerguntaFatoresCognitivos ||
                                                currentColeta?.sistemaDeCicloAbertoSistemasMaquinasEquipamentosPerguntaFatoresCognitivos ||
                                                currentColeta?.sistemaDeCicloFechadoSistemasMaquinasEquipamentosPerguntaFatoresCognitivos ||
                                                currentColeta?.operarMaisDeUmEquipamentoAoMesmoTempoPerguntaFatoresCognitivos ||
                                                currentColeta?.naoHaNenhumFatorDeRiscoPerguntaFatoresCognitivos
                                            }
                                        />
                                    </Table.Cell>
                                    <Table.Cell>
                                        {currentColeta?.trabalhoExigeConcentracaoPerguntaFatoresCognitivos && "Trabalho exige concentração, "}
                                        {currentColeta?.trabalhoExigeAtencaoPerguntaFatoresCognitivos && "Trabalho exige atenção, "}
                                        {currentColeta?.trabalhoExigeMemorizeSenhaNumerosPerguntaFatoresCognitivos && "Trabalho exige memorização de senhas/números, "}
                                        {currentColeta?.usabilidadeNoTrabalhoFacilDeTrabalharPerguntaFatoresCognitivos && "Usabilidade no trabalho - Fácil de trabalhar, "}
                                        {currentColeta?.usabilidadeNoTrabalhoEngajamentoPerguntaFatoresCognitivos && "Usabilidade no trabalho - Engajamento, "}
                                        {currentColeta?.usabilidadeNoTrabalhoAplicavelPerguntaFatoresCognitivos && "Usabilidade no trabalho - Aplicável, "}
                                        {currentColeta?.oTrabalhoTemSistemaDeRetornoDeInfoPerguntaFatoresCognitivos && "O trabalho tem sistema de retorno de informações, "}
                                        {currentColeta?.affordanceSistemasMaquinasEquipamentosERotasPerguntaFatoresCognitivos && "Affordance - Sistemas, máquinas, equipamentos e rotas, "}
                                        {currentColeta?.sistemaDeCicloAbertoSistemasMaquinasEquipamentosPerguntaFatoresCognitivos && "Sistema de ciclo aberto - Sistemas, máquinas, equipamentos, "}
                                        {currentColeta?.sistemaDeCicloFechadoSistemasMaquinasEquipamentosPerguntaFatoresCognitivos && "Sistema de ciclo fechado - Sistemas, máquinas, equipamentos, "}
                                        {currentColeta?.operarMaisDeUmEquipamentoAoMesmoTempoPerguntaFatoresCognitivos && "Operar mais de um equipamento ao mesmo tempo, "}
                                        {currentColeta?.naoHaNenhumFatorDeRiscoPerguntaFatoresCognitivos && "Não há nenhum fator de risco, "}
                                    </Table.Cell>

                                    <Table.Cell>
                                        <Checkbox
                                            toggle
                                            readOnly
                                            checked={
                                                !currentColeta?.trabalhoExigeConcentracaoPerguntaFatoresCognitivos &&
                                                !currentColeta?.trabalhoExigeAtencaoPerguntaFatoresCognitivos &&
                                                !currentColeta?.trabalhoExigeMemorizeSenhaNumerosPerguntaFatoresCognitivos &&
                                                !currentColeta?.usabilidadeNoTrabalhoFacilDeTrabalharPerguntaFatoresCognitivos &&
                                                !currentColeta?.usabilidadeNoTrabalhoEngajamentoPerguntaFatoresCognitivos &&
                                                !currentColeta?.usabilidadeNoTrabalhoAplicavelPerguntaFatoresCognitivos &&
                                                !currentColeta?.oTrabalhoTemSistemaDeRetornoDeInfoPerguntaFatoresCognitivos &&
                                                !currentColeta?.affordanceSistemasMaquinasEquipamentosERotasPerguntaFatoresCognitivos &&
                                                !currentColeta?.sistemaDeCicloAbertoSistemasMaquinasEquipamentosPerguntaFatoresCognitivos &&
                                                !currentColeta?.sistemaDeCicloFechadoSistemasMaquinasEquipamentosPerguntaFatoresCognitivos &&
                                                !currentColeta?.operarMaisDeUmEquipamentoAoMesmoTempoPerguntaFatoresCognitivos &&
                                                !currentColeta?.naoHaNenhumFatorDeRiscoPerguntaFatoresCognitivos
                                            }
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            </Table>
                        </Form.Field>
                        <br/>
                        <Form.Field>
                            <Form.Input
                                label={'Parte do corpo atingida:'}
                                name="parteDoCorpoAtingida"
                                type="text"
                                placeholder="Parte do corpo atingida"
                                value={avaliacao?.parteDoCorpoAtingida}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input
                                label={'Fontes geradoras (principais causas dos riscos):'}
                                name="fontesGeradoras"
                                type="text"
                                placeholder="Fontes geradoras"
                                value={avaliacao?.fontesGeradoras}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Field>
                        <br/>

                        <Form.Field>
                            <Header as="h3">Queixas de dores ou desconforto relatado pelo colaborador </Header>
                            <Table color={"orange"} celled>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Cabeça</Table.HeaderCell>
                                        <Table.HeaderCell>Pescoço</Table.HeaderCell>
                                        <Table.HeaderCell>Ombro</Table.HeaderCell>
                                        <Table.HeaderCell>Braços</Table.HeaderCell>
                                        <Table.HeaderCell>Punhos</Table.HeaderCell>
                                        <Table.HeaderCell>Coluna</Table.HeaderCell>
                                        <Table.HeaderCell>Coxas</Table.HeaderCell>
                                        <Table.HeaderCell>Pernas</Table.HeaderCell>
                                        <Table.HeaderCell>Quadril</Table.HeaderCell>
                                        <Table.HeaderCell>Joelhos</Table.HeaderCell>
                                        <Table.HeaderCell>Tornozelos</Table.HeaderCell>
                                        <Table.HeaderCell>Pés</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell
                                            style={{
                                                backgroundColor: getCellBackgroundColor(currentColeta?.cabecaPerguntaDesconfortoUltimosDias),
                                            }}
                                        >
                                            {getTextFromEnumValue(currentColeta?.cabecaPerguntaDesconfortoUltimosDias)}
                                        </Table.Cell>
                                        <Table.Cell
                                            style={{
                                                backgroundColor: getCellBackgroundColor(currentColeta?.pescocoPerguntaDesconfortoUltimosDias),
                                            }}
                                        >
                                            {getTextFromEnumValue(currentColeta?.pescocoPerguntaDesconfortoUltimosDias)}
                                        </Table.Cell>
                                        <Table.Cell
                                            style={{
                                                backgroundColor: getCellBackgroundColor(currentColeta?.ombroPerguntaDesconfortoUltimosDias),
                                            }}
                                        >
                                            {getTextFromEnumValue(currentColeta?.ombroPerguntaDesconfortoUltimosDias)}
                                        </Table.Cell>
                                        <Table.Cell
                                            style={{
                                                backgroundColor: getCellBackgroundColor(currentColeta?.bracosPerguntaDesconfortoUltimosDias),
                                            }}
                                        >
                                            {getTextFromEnumValue(currentColeta?.bracosPerguntaDesconfortoUltimosDias)}
                                        </Table.Cell>
                                        <Table.Cell
                                            style={{
                                                backgroundColor: getCellBackgroundColor(currentColeta?.punhosPerguntaDesconfortoUltimosDias),
                                            }}
                                        >
                                            {getTextFromEnumValue(currentColeta?.punhosPerguntaDesconfortoUltimosDias)}
                                        </Table.Cell>
                                        <Table.Cell
                                            style={{
                                                backgroundColor: getCellBackgroundColor(currentColeta?.colunaPerguntaDesconfortoUltimosDias),
                                            }}
                                        >
                                            {getTextFromEnumValue(currentColeta?.colunaPerguntaDesconfortoUltimosDias)}
                                        </Table.Cell>
                                        <Table.Cell
                                            style={{
                                                backgroundColor: getCellBackgroundColor(currentColeta?.coxasPerguntaDesconfortoUltimosDias),
                                            }}
                                        >
                                            {getTextFromEnumValue(currentColeta?.coxasPerguntaDesconfortoUltimosDias)}
                                        </Table.Cell>
                                        <Table.Cell
                                            style={{
                                                backgroundColor: getCellBackgroundColor(currentColeta?.pernasPerguntaDesconfortoUltimosDias),
                                            }}
                                        >
                                            {getTextFromEnumValue(currentColeta?.pernasPerguntaDesconfortoUltimosDias)}
                                        </Table.Cell>
                                        <Table.Cell
                                            style={{
                                                backgroundColor: getCellBackgroundColor(currentColeta?.quadrilPerguntaDesconfortoUltimosDias),
                                            }}
                                        >
                                            {getTextFromEnumValue(currentColeta?.quadrilPerguntaDesconfortoUltimosDias)}
                                        </Table.Cell>
                                        <Table.Cell
                                            style={{
                                                backgroundColor: getCellBackgroundColor(currentColeta?.joelhosPerguntaDesconfortoUltimosDias),
                                            }}
                                        >
                                            {getTextFromEnumValue(currentColeta?.joelhosPerguntaDesconfortoUltimosDias)}
                                        </Table.Cell>
                                        <Table.Cell
                                            style={{
                                                backgroundColor: getCellBackgroundColor(currentColeta?.tornozelosPerguntaDesconfortoUltimosDias),
                                            }}
                                        >
                                            {getTextFromEnumValue(currentColeta?.tornozelosPerguntaDesconfortoUltimosDias)}
                                        </Table.Cell>
                                        <Table.Cell
                                            style={{
                                                backgroundColor: getCellBackgroundColor(currentColeta?.pesPerguntaDesconfortoUltimosDias),
                                            }}
                                        >
                                            {getTextFromEnumValue(currentColeta?.pesPerguntaDesconfortoUltimosDias)}
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </Form.Field>

                        <Form.Field>
                            <Form.Input
                                label={'Dificuldade relatada pelo colaborador/oportunidade para melhoria:'}
                                name="respostaPerguntaMudarAlgoNoTrabalho"
                                type="text"
                                placeholder="O que você mudaria?"
                                value={currentColeta?.respostaPerguntaMudarAlgoNoTrabalho}
                                onChange={handleInputChange}
                                readOnly
                            />
                        </Form.Field>

                        <h3>Índice de Gestão (Matriz de avaliação prioridade do risco)</h3>
                        <Form.Field>
                            <Form.Select
                                label={'Gravidade:'}
                                options={EPerguntaFatorDeRiscoGravidade}
                                placeholder='Selecione uma opção'
                                value={currentColeta?.perguntaFatorDeRiscoGravidade}
                                onChange={(e: any, data: any) => handleDropdownChange('perguntaFatorDeRiscoGravidade', data)}
                                readOnly
                            />
                        </Form.Field>

                        <Form.Field>
                            <Form.Select
                                label={'Probabilidade:'}
                                options={EPerguntaFatorDeProbabilidade}
                                placeholder='Selecione uma opção'
                                value={currentColeta?.perguntaFatorDeProbabilidade}
                                onChange={(e: any, data: any) => handleDropdownChange('perguntaFatorDeProbabilidade', data)}
                                readOnly
                            />
                        </Form.Field>

                        <Form.Field>
                            <Form.Input
                                label={'Classificação:'}
                                value={classificacaoTexto}
                                readOnly
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Select
                                label={'Soluções Ergonômicas'}
                                options={solucoes}
                                placeholder='Selecione uma opção'
                                value={avaliacao?.solucoesErgonomicasIds}
                                multiple
                                required
                                clearable
                                onChange={async (e: any, data: any) => {
                                    const solucaoErgoId = data.value[data.value.length - 1]; // Pega o último valor selecionado
                                    setAvaliacao((prevAvaliacao: any) => ({
                                        ...prevAvaliacao,
                                        solucoesErgonomicasIds: data.value,
                                    }));
                                    // Chame o serviço para criar a relação entre Avaliação e Solução
                                    await adicionarRelacaoAvaliacaoSolucao(avaliacao?.id, solucaoErgoId);
                                }}
                            />
                        </Form.Field>

                        {solucoesSelecionadas.length > 0 && (
                            <Table color={"orange"}>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>#</Table.HeaderCell>
                                        <Table.HeaderCell>Tipo de Ação</Table.HeaderCell>
                                        <Table.HeaderCell>Solução</Table.HeaderCell>
                                        <Table.HeaderCell>NR</Table.HeaderCell>
                                        <Table.HeaderCell>Descrição</Table.HeaderCell>
                                        <Table.HeaderCell>Ações</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {solucoesSelecionadas.map((solucao, index) => (
                                        <Table.Row key={index}>
                                            <Table.Cell>{index + 1}</Table.Cell>
                                            <Table.Cell>{getTipoAcaoText(solucao?.tipoDeAcaoSolucaoErgo)}</Table.Cell>
                                            <Table.Cell>{getTipoSolucaoText(solucao?.tipoSolucaoErgonomica)}</Table.Cell>
                                            <Table.Cell>{getNormasRegulamentadorasCodigo(solucao?.normasRegulamentativas)}</Table.Cell>
                                            <Table.Cell>{solucao?.descricao}</Table.Cell>
                                            <Table.Cell>
                                                <Button color="red" icon="trash"
                                                        onClick={() => handleRemoveSolucao(solucao.id, avaliacao?.id!)}/>
                                            </Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        )}
                        {/*#endregion*/}

                        <h3>Controle de Risco</h3>
                        <Form.Field>
                            <Form.Select
                                label={'Investimento:'}
                                options={ExigeInvestimentoAvaliacaoErgo}
                                placeholder='Selecione uma opção'
                                value={avaliacao?.exigeInvestimento}
                                onChange={(e: any, data: any) => handleDropdownChange('exigeInvestimento', data)}
                                required
                            />
                        </Form.Field>

                        <Form.Field>
                            <Form.Input
                                label={'Prazo sugerido:'}
                                placeholder='Prazo sugerido'
                                value={prazoTexto}
                                readOnly
                            />
                        </Form.Field>

                        <Form.Field>
                            <Form.Input
                                label={'Responsavel pela coleta de dados:'}
                                placeholder='Responsável pela coleta ergonômica'
                                name="responsavelPelaColetaDeDados"
                                value={avaliacao?.responsavelPelaColetaDeDados}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Field>

                        <Form.Field>
                            <Form.Input
                                label={'Responsável pela avaliação:'}
                                placeholder='Responsável pela avaliação'
                                name="responsavel"
                                value={avaliacao?.responsavel}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Field>

                        <Button type="submit" color="blue" fluid size="large" onClick={updateAvaliacao}>
                            <Icon name='save'/>Atualizar
                        </Button>
                    </Form>
                </Segment>
            </Grid.Column>

        </div>
    );
};
export default EditAvaliacaoErgoPage;
