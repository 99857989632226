import instance from "services";

export default ActiveClient

async function ActiveClient(id:string ) : Promise<ActiveClientResponse> {
    const response = await instance.put(`/v1/backoffice/clientes/active/${id}`);
    return response.data;
}
export interface ActiveUserRequest {
    id: string;
}
export interface ActiveClientResponseData {
    id: string
    createdAt: string
    updatedAt: string
    fantasyName: string
    businessName: string
    cnpj: string
    cnae: string
    address: string
    addressNumber: number
    addressComplement: string
    addressNeighborhood: string
    zipCode: number
    city: string
    state: string
    isActive: boolean

}

export interface ActiveClientResponse {
    success: boolean;
    data: ActiveClientResponseData;
}
