import instance from "services";

export default GetCollectorById

async function GetCollectorById(id: string): Promise<GetCollectorResponse> {
    const response = await instance.get(`/v1/backoffice/coletores/${id}`);
    return response.data.data;
}
export interface GetCollectorRequest {
    id: string;
}
export interface GetCollectorResponseData {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    createdAt: Date;
    updatedAt: Date;
    isActive: boolean;
}

export interface GetCollectorResponse {
    success: boolean;
    data: GetCollectorResponseData;
}
