import React, {useEffect, useState} from 'react';
import {Button, Form, Header, Icon, Input, Label, Message, Segment, Select, Table,} from 'semantic-ui-react';
import {GetAllClientsToDropdown} from 'services/backoffice/clients/get-clients';
import {SelectListDefault} from 'models/selectListDefault';
import SessionStorage from 'store/session';
import {useBlockUI} from 'shared/services/block-ui-service';
import {
    GetRelatorioTreinamentosMensal,
    RelatorioTreinamentosMensalResponse
} from "services/sistema-aulas/relatorios/clientes/treinamentos/mensal";
import {EMes} from "enums/eMes";
import {RelatorioTurmasClienteFilter} from "filters/relatorio-turmas-cliente-filter";
import {
    GetRelatorioTreinamentosAnual,
    RelatorioAnualTurmasPorClienteResponse
} from 'services/sistema-aulas/relatorios/clientes/treinamentos/anual';
import moment from "moment";
import StringUtils from "../../../../../shared/utils/string-utils";
import {TurmaRelatorioPresencasFilter} from "../../../../../models/turmaRelatorioPresencasFilter";
import {useLocation} from "react-router-dom";
import {GetTurmaRelatorioPresencaPdf} from "../../../../../services/sistema-aulas/turmas/get-turma-relatorio";
import {GetClienteTreinamentosRelatorioPdf} from "../../../../../services/sistema-aulas/treinamentos/get-treinamentos";

const ListaRelatoriosTreinamentos = () => {
    const [relatorioMensal, setRelatorioMensal] = useState<RelatorioTreinamentosMensalResponse|undefined>();
    const [relatorioAnual, setRelatorioAnual] = useState<RelatorioAnualTurmasPorClienteResponse|undefined>();
    const [loading, setLoading] = useState<boolean>(false);
    const [clientsList, setClientsList] = useState<SelectListDefault[]>([]);
    const [error, setError] = useState<string>();
    const [profile, setProfile] = useState<string>();
    const [showFilters, setShowFilters] = useState<boolean>(true);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const mesParam = queryParams.get("mes") ?? (new Date().getMonth() + 1).toString();
    const anoParam = queryParams.get("ano") ?? new Date().getFullYear().toString();
    const [filter, setFilter] = useState<RelatorioTurmasClienteFilter>({
        mes: parseInt(mesParam),
        ano: parseInt(anoParam)
    });
    const blockUI = useBlockUI();
    useEffect(() => {
        blockUI?.setActive(loading)
    }, [loading]);

    useEffect(() => {
        async function updateSessionUser() {
            try {
                const user = await SessionStorage.getSession();
                if (user?.isLogged) {
                    setProfile(user.userData?.profile);
                }
            } catch (e) {
            }
        }

        updateSessionUser();
    }, []);

    useEffect(() => {
        const fetchClientsList = async () => {
            try {
                setLoading(true)
                const clients = await GetAllClientsToDropdown().finally(() => setLoading(false));
                setClientsList(clients);
            } catch (error) {
                console.error('Erro ao obter a lista de clientes:', error);
            }
        };

        fetchClientsList();
    }, []);

    useEffect(() => {
        const fetchRelatorios = async () => {
            setLoading(true);
            setError(undefined); // Resetar o erro antes de cada requisição
            try {
                const relatorioMensalResponse = await GetRelatorioTreinamentosMensal(filter);
                const relatorioAnualResponse = await GetRelatorioTreinamentosAnual(filter);

                setRelatorioMensal(relatorioMensalResponse);
                setRelatorioAnual(relatorioAnualResponse);

            } catch (error) {
                setRelatorioMensal(undefined);
                setRelatorioAnual(undefined);
                setError('Erro ao obter os relatórios.');
            } finally {
                setLoading(false);
            }
        };

        if (!!filter?.clientId
            && !!filter.mes
            && filter.ano
            && filter?.mes >= 1 && filter?.mes <= 12
            && filter?.ano > 2000 && filter?.ano < 3000
        )
            fetchRelatorios();
    }, [filter]);

    // Função para alternar a exibição dos filtros
    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };

    return (
        <div>

            {!loading && <Button onClick={() => {
                if (!!relatorioMensal?.ano && !!relatorioMensal?.mes && !!relatorioMensal?.clienteNome)
                    GetClienteTreinamentosRelatorioPdf(filter)
            }}>
                <Icon name={'print'}></Icon>
                Imprimir</Button>}
            <Header as="h2" textAlign="center" color="black">
                Relatório de participação do cliente
            </Header>

            <Button onClick={toggleFilters} primary>
                {showFilters ? 'Ocultar filtros' : 'Exibir filtros'}
            </Button>

            {showFilters && (
                <Segment>
                    <div style={{padding: 10}}>
                        <Form>
                            <Form.Group widths='equal'>
                                    <Form.Field
                                        width={'8'}
                                        control={Select}
                                        fluid
                                        clearable
                                        label="Cliente"
                                        placeholder="Selecione um cliente"
                                        selection
                                        search
                                        selectOnBlur={false}
                                        options={clientsList}
                                        value={filter?.clientId || ''}
                                        onChange={(_: React.ChangeEvent<HTMLInputElement>, data: {
                                            value: string;
                                        }) => {
                                            setFilter({...filter, clientId: data.value as string});
                                        }}
                                    >
                                    </Form.Field>


                                <Form.Field
                                    width={'4'}
                                    control={Select}
                                    fluid
                                    clearable
                                    label="Mês"
                                    placeholder="Mês"
                                    search
                                    selection
                                    selectOnBlur={false}
                                    options={EMes}
                                    value={filter?.mes}
                                    onChange={(_: React.ChangeEvent<HTMLInputElement>, data: { value: string; }) => {
                                        setFilter({...filter, mes: Number(data.value as string)});
                                    }}



                                />
                                <Form.Field
                                    width={'4'}
                                    control={Input}
                                    fluid
                                    label="Ano"
                                    placeholer="2023"
                                    type="number"
                                    value={filter?.ano}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setFilter({
                                            ...filter,
                                            ano: Number(e.target.value),
                                        })
                                    }
                                />
                            </Form.Group>
                        </Form>
                        {
                            ((!filter?.clientId && profile !== 'Operator')
                                || !filter?.mes
                                || !filter?.ano)
                            && (<Message
                                icon={'info'}
                                size={'small'}
                                info
                                content={"Preencha os filtros para continuar."}></Message>)
                        }
                    </div>
                </Segment>
            )}
            {(!!relatorioMensal) ?
                <Table celled color="orange">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell colSpan={2}>
                                <Header textAlign={'center'} as={'h3'}>{relatorioMensal?.clienteNome}</Header>
                            </Table.HeaderCell>
                            <Table.HeaderCell colSpan={2}>
                                <Header textAlign={'center'} as={'h4'}>{relatorioMensal?.periodoDescricao}</Header>
                            </Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell colSpan={2}>Turma + Treinamento</Table.HeaderCell>
                            <Table.HeaderCell>Setores</Table.HeaderCell>
                            <Table.HeaderCell textAlign={'center'}>% Participação</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {relatorioMensal?.turmas.map(turmaRelatorioMensal => {
                            return (
                                <Table.Row>
                                    <Table.Cell colSpan={2}>{turmaRelatorioMensal.turmaNome + ' - ' + turmaRelatorioMensal.turmaTreinamento}</Table.Cell>
                                    <Table.Cell>{turmaRelatorioMensal.turmaSetores}</Table.Cell>
                                    <Table.Cell textAlign={'center'}><Label
                                        content={`${StringUtils.formatPercentNumber(turmaRelatorioMensal.turmaPorcentagemMedia)}`}></Label></Table.Cell>
                                </Table.Row>
                            )
                        })}
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.Cell colSpan={3}>
                            </Table.Cell>
                            <Table.Cell textAlign={'center'} colSpan={1}>
                                <b>
                                   MÉDIA DO MÊS DE {(moment().month(relatorioMensal.mes - 1).format('MMMM')).toUpperCase()}/{relatorioMensal.ano}: {StringUtils.formatPercentNumber(relatorioMensal.participacaoPorcentagem)}
                                </b>
                            </Table.Cell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
                :
                <Segment>
                    <Message
                        icon={'warning'}
                        warning
                        content={"Nenhum registro encontrado para o período informado."}></Message>
                </Segment>
            }
            {(!!relatorioAnual) &&
                <Table celled color="orange">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell >
                                <Header textAlign={'center'} as={'h3'}>{relatorioAnual?.clienteNome}</Header>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <Header textAlign={'center'} as={'h4'}>{relatorioAnual?.periodoDescricao}</Header>
                            </Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell textAlign={'center'}>Mês</Table.HeaderCell>
                            <Table.HeaderCell textAlign={'right'}>% Participação</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {relatorioAnual?.meses.map(mesRelatorioAnual => {
                            return (
                                <Table.Row>
                                    <Table.Cell textAlign={'center'}>
                                        <b>
                                            {StringUtils.capitalizeFirstLetter(moment().month(mesRelatorioAnual.mes - 1).format('MMMM'))} / {mesRelatorioAnual.ano}
                                        </b>
                                    </Table.Cell>
                                    <Table.Cell textAlign={'right'}><Label
                                        content={`${StringUtils.formatPercentNumber(mesRelatorioAnual.participacaoPorcentagem)}`}></Label></Table.Cell>
                                </Table.Row>
                            )
                        })}
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.Cell colSpan={1}>
                            </Table.Cell>
                            <Table.Cell textAlign={'right'} colSpan={1}>
                                <b>
                                   MÉDIA DO ANO DE {relatorioAnual.ano}: {StringUtils.formatPercentNumber(relatorioAnual.participacaoPorcentagem)}
                                </b>
                            </Table.Cell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            }

        </div>
    );
};

export default ListaRelatoriosTreinamentos;
