import instance from "../../index";


export default EditTurma

async function EditTurma(request: Partial<EditTurmaRequest>, id: string | string[] | undefined): Promise<EditTurmaResponseData> {
    const response = await instance.put(`/v1/backoffice/turmas/${id}`, request)
    return response.data;
}

export interface EditTurmaRequest {
    id: string
    nome: string
    descricao: string
    dataInicio: Date
    clientId: string
    professorId: string
    treinamentoId: string
    estadoTurma: string | number

}

export interface EditTurmaResponse {
    id: string
    nome: string
    descricao: string
    dataInicio: Date
    clientId: string
    professorId: string
    estadoTurma: number

}

export interface EditTurmaResponseData {
    data: EditTurmaResponse
    success: boolean

}
