import React, {useEffect, useState} from 'react';
import {Button, Dropdown, Header, Icon, Input, Label, Pagination, Segment, Table} from 'semantic-ui-react';
import DeleteClient from "services/backoffice/clients/delete-client";
import GetClients from "services/backoffice/clients/get-clients";
import ActiveClient from "services/backoffice/clients/active-client";
import DeactiveClient from "services/backoffice/clients/deactive-client";
import {useNavigate} from "react-router-dom";
import moment from "moment/moment";
import {ClientFilter} from "../../../filters/client-filter";
import {toast} from "react-toastify";
import {SessionUser} from "../../../models/session-user";
import SessionStorage from "../../../store/session";
import StringUtils from "../../../shared/utils/string-utils";
import {useBlockUI} from "../../../shared/services/block-ui-service";

const ClientsList = () => {
    const [clients, setClients] = useState<any[]>([]);
    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const [filter, setFilter] = useState<ClientFilter>()
    const [totalPages, setTotalPages] = useState()
    const [showFilters, setShowFilters] = useState(false);
    const [sessionUser, setSessionUser] = useState<SessionUser>();
    const blockUI = useBlockUI();
    useEffect(() => {
        blockUI?.setActive(loading)
    }, [loading]);

    useEffect(() => {
        async function updateSessionUser() {
            const user = await SessionStorage.getSession();
            setSessionUser(user)
        }

        updateSessionUser()
    }, [])
    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };
    useEffect(() => {
        listClients();
    }, [filter]);

    async function listClients() {
        try {
            setLoading(true);

            const response = await GetClients(filter);
            const data = response;
            setTotalPages(data?.totalPages);
            setClients(data?.items);
        } catch (error) {
            console.error('Erro ao obter clientes:', error);
        } finally {
            setLoading(false);
        }
    }

    const toggleActivationClient = async (client: any, activate?: boolean) => {
        try {
            const result = window.confirm(`Tem certeza que deseja ${client.isActive ? 'desativar' : 'ativar'} o Cliente ${client.fantasyName}`);
            if (!result) return;

            setLoading(true);

            if (client) {
                const result = !client.isActive ? await ActiveClient(client.id) : await DeactiveClient(client.id);
                if (result.success) {
                    toast.success((`O cliente foi ${client.isActive ? 'desativado' : 'ativado'} com sucesso`), {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });

                    setTimeout(() => {
                        setError('');
                    }, 3000);

                    await listClients(); // Usar 'await' para esperar a conclusão da função
                }
            }

            setLoading(false);
        } catch (error: any) {
            toast.warn(`Falha ao ${client.isActive ? 'desativar' : 'ativar'} cliente`, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            setLoading(false);
            setError(error.message);
        }
    };

    async function deleteClient(client: any) {
        try {
            const confirm = window.confirm(`Tem certeza que deseja apagar o usuário ${client?.fantasyName}?`);
            if (confirm) {
                setLoading(true);

                if (client) {
                    await DeleteClient(client.id);
                    toast.success('Cliente deletado com sucesso', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    await listClients();
                }

                setLoading(false);
            }
        } catch (error: any) {
            toast.warn(`Falha ao deletar cliente`, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setLoading(false);
            setError(error.message);
        }
    }


    function setFilterAndResetPage(newFilter: any){
        setFilter({...newFilter, Page: 1} )
    }

    return (
        <div>
            <Header as="h2" textAlign="center" color={'black'}>
                Clientes
            </Header>
            <Table.Row>
                {sessionUser?.userData?.profile === 'Admin' &&
                    <Table.HeaderCell>
                        <Button
                            color="blue"
                            onClick={async () => {
                                setLoading(true);
                                await navigate('/backoffice/clients/create/');
                                setLoading(false);
                            }}
                        >
                            <Icon name='plus'/> Novo
                        </Button>
                    </Table.HeaderCell> }

                <Table.HeaderCell colSpan="4">
                    <Button onClick={toggleFilters} primary>
                        <Icon name={showFilters ? 'hide' : 'unhide'} />
                        {showFilters ? 'Ocultar filtros' : 'Exibir filtros'}
                    </Button>
                </Table.HeaderCell>
            </Table.Row>{showFilters && (
            <Segment>
                <div style={{padding: 10}}>
                    <div>
                        <Input
                            fluid
                            icon="search"
                            type="text"
                            placeholder="Nome fantasia"
                            value={filter?.FantasyName ?? ''}
                            onChange={event =>
                                setFilterAndResetPage({...filter, FantasyName: event.target?.value})
                            }
                        />
                    </div>
                    <br/>
                    <div>
                        <Input
                            fluid
                            icon="search"
                            type="text"
                            placeholder="Razão social"
                            value={filter?.BusinessName ?? ''}
                            onChange={event =>
                                setFilterAndResetPage({...filter, BusinessName: event.target?.value})
                            }
                        />
                    </div>
                    <br/>
                    <div>
                        <Input
                            fluid
                            icon="search"
                            type="number"
                            placeholder="CNPJ"
                            value={filter?.Cnpj ?? ''}
                            onChange={event =>
                                setFilterAndResetPage({...filter, Cnpj: event.target?.value})
                            }
                        />
                    </div>
                </div>
            </Segment>
        )}

            <Table color={"orange"}>
                <Table.Header>
                    <Table.Row>
                        {sessionUser?.userData?.profile === 'Admin' &&<Table.HeaderCell>#</Table.HeaderCell>}
                        <Table.HeaderCell>Nome Fantasia</Table.HeaderCell>
                        <Table.HeaderCell>Razão social</Table.HeaderCell>
                        <Table.HeaderCell>CNPJ</Table.HeaderCell>
                        <Table.HeaderCell>Criado em</Table.HeaderCell>
                        <Table.HeaderCell>Ativo</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {clients?.map((client) => (
                        <Table.Row key={client.id}>
                            {sessionUser?.userData?.profile === 'Admin' &&
                                <Table.Cell>
                                    <Dropdown item icon={'bars'}>
                                        <Dropdown.Menu>
                                            <Dropdown.Item
                                                color="blue"
                                                onClick={async () => {
                                                    setLoading(true);
                                                    await navigate('/backoffice/clients/edit/' + client.id);
                                                    setLoading(false);
                                                }}
                                            >
                                                <Icon name='edit' color='blue' />
                                                Editar
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                text={'Deletar'}
                                                onClick={() => deleteClient(client)}
                                            >
                                                <Icon name='trash' color={'red'} />
                                                Deletar
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                color={client.isActive ? 'red' : 'green'}
                                                text={client.isActive ? 'Desativar' : 'Ativar'}
                                                onClick={() => toggleActivationClient(client)}
                                            >
                                                <Icon name={client.isActive ? 'power off' : 'power'} color={client.isActive ? 'red' : 'green'} />
                                                {client.isActive ? 'Desativar' : 'Ativar'}
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Table.Cell>
                            }

                            <Table.Cell>{client.fantasyName}</Table.Cell>
                            <Table.Cell>{client.businessName}</Table.Cell>
                            <Table.Cell>{StringUtils.formatCnpj(client.cnpj)}</Table.Cell>
                            <Table.Cell>{moment(client.createdAt).format('DD/MM/YY HH:mm')}</Table.Cell>
                            <Table.Cell>
                                <Label key={client.id}
                                       color={!client.isActive ? 'red' : 'green'}>{client.isActive ? 'Sim' : 'Não'}</Label>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <Pagination totalPages={totalPages ?? 0}
                        defaultActivePage={filter?.Page ?? 1}
                        onPageChange={(e, {activePage}) => {
                            setFilter({...filter, Page: activePage as number})
                        }
                        }></Pagination>
        </div>
    );
};

export default ClientsList;
