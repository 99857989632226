import instance from "../../index";

export default EditNormaRegulamentativa

async function EditNormaRegulamentativa(request: Partial<EditNormaRegulamentativaRequest>, id: string | string[] | undefined): Promise<EditNormaRegulamentativaResponse> {
    const response = await instance.put(`/v1/backoffice/normas-regulamentativas/${id}`, request);
    return response?.data?.data;
}

export interface EditNormaRegulamentativaRequest {
    id: string;
    codigo: string;
    descricao: string;
}

export interface EditNormaRegulamentativaResponseData {
    id: string;
    codigo: string;
    descricao: string;
}

export interface EditNormaRegulamentativaResponse {
    success: boolean;
    data: EditNormaRegulamentativaResponseData
}