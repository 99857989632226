import React, {useCallback, useEffect, useState} from "react";
import {SessionUser} from "../../../models/session-user";
import {useLocation, useNavigate} from "react-router-dom";
import SessionStorage from "../../../store/session";
import {toast} from "react-toastify";
import {
    Button,
    Dropdown,
    Form,
    Header,
    Icon,
    Input,
    Modal,
    Pagination,
    Segment,
    Select,
    Table,
} from "semantic-ui-react";
import {AulaFilter} from "../../../filters/aula-filter";
import DeleteAula from "../../../services/sistema-aulas/aulas/delete-aula";
import 'moment/locale/pt-br'; // Importa a localização em português
import moment from 'moment';
import {GetAulasPaginada} from "../../../services/sistema-aulas/aulas/get-aulas";
import {Aula} from "../../../models/aula";
import {useBlockUI} from "../../../shared/services/block-ui-service";
import {EEstadoAula} from "../../../enums/eEstadoAula";
import CreateAula, {CreateAulaRequest} from "../../../services/sistema-aulas/aulas/create-aula";
import {SelectListDefault} from "../../../models/selectListDefault";
import {GetAllTurmas} from "../../../services/sistema-aulas/turmas/get-turmas";
import {Turma} from "../../../models/turma";
import GetAulaById from "../../../services/sistema-aulas/aulas/get-aula";
import GetTurmaById from "../../../services/sistema-aulas/turmas/get-turma";
import {EMes} from "../../../enums/eMes";
import CreateRecorrencia, {
    CreateRecorrenciaRequest
} from "../../../services/sistema-aulas/recorrencias/create-response";
import {EDiasDaSemana} from "../../../enums/eDiasDaSemana";

const AulasList = () => {
    const [aulas, setAulas] = useState<Aula[]>([]);
    const [turmas, setTurmas] = useState<SelectListDefault[]>([]);
    const [turma, setTurma] = useState<Turma>();
    const [loading, setLoading] = useState<boolean>(false);
    const [totalPages, setTotalPages] = useState(1);
    const [showFilters, setShowFilters] = useState(false);
    const [sessionUser, setSessionUser] = useState<SessionUser>();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalRecorrenciaOpen, setIsModalRecorrenciaOpen] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const turmaId = queryParams.get("turmaId");
    const mesParam = queryParams.get("mes") ?? (new Date().getMonth() + 1).toString();
    const anoParam = queryParams.get("ano") ?? new Date().getFullYear().toString();
    const [filter, setFilter] = useState<AulaFilter>({
        mes: parseInt(mesParam),
        ano: parseInt(anoParam)
    });
    const [modalAula, setModalAula] = useState<Partial<CreateAulaRequest>>({});
    const [modalRecorrencia, setModalRecorrencia] = useState<Partial<CreateRecorrenciaRequest>>({});

    const navigate = useNavigate();
    const blockUI = useBlockUI();

    useEffect(() => {
        blockUI?.setActive(loading);
    }, [blockUI, loading]);

    useEffect(() => {
        async function updateSessionUser() {
            const user = await SessionStorage.getSession();
            setSessionUser(user);
        }

        updateSessionUser();
    }, []);

    useEffect(() => {
        function buscarTurma() {
            GetTurmaById(turmaId!).then(t => setTurma(t))
        }

        buscarTurma();
    }, [turmaId]);

    const listAulas = useCallback(async () => {
        try {
            setLoading(true);
            const response = await GetAulasPaginada({turmaId: turmaId, ...filter});
            setAulas(response?.items);
            setTotalPages(response?.totalPages);
        } catch (error: any) {
            console.error(error)
        } finally {
            setLoading(false);
        }
    }, [filter, turmaId])

    useEffect(() => {
        if (!!filter?.mes
            && filter.ano
            && filter?.mes >= 1 && filter?.mes <= 12
            && filter?.ano > 2000 && filter?.ano < 3000
        ) {
            listAulas();
        }
    }, [filter, listAulas, turmaId]);

    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };

    function getEstadoAulaText(key: any) {
        const estadoAula = EEstadoAula.find((item) => item.value === key);
        return estadoAula ? estadoAula.text : "";
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setModalAula((prevAula: any) => ({
            ...prevAula,
            [name]: value,
        }));
    };

    const handleInputChangeModalRecorrencia = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setModalRecorrencia((prevRecorrencia: any) => ({
            ...prevRecorrencia,
            [name]: value,
        }));
    };

    useEffect(() => {
        function ListTurmas() {
            GetAllTurmas()
                .then((response) => {
                    const turmasData = response?.map((item: Turma) => ({
                        key: item.id,
                        text: item.nome,
                        value: item.id,
                    }));
                    setTurmas(turmasData);
                })
                .catch((error) => {
                    console.warn(error);
                });
        }

        ListTurmas();
    }, []);

    const handleDuplicateClick = async (aulaId: string) => {
        try {
            setLoading(true);
            const response = await GetAulaById(aulaId);
            if (response) {
                setModalAula({
                    ...response,
                });
                console.log("aula selecionada", response);
                setIsModalOpen(true);
            } else {
                console.error("Não foi possível duplicar a aula.");
            }
        } catch (error) {
            console.error("Erro ao buscar detalhes da aula:", error);
            toast.error("Erro ao buscar detalhes da aula. Por favor, tente novamente.", {});
        } finally {
            setLoading(false);
        }
    };

    const handleRecorrenciaModalClick = () => {
        if (turmaId != null) {
            modalRecorrencia.turmaId = turmaId;
        }
        setIsModalRecorrenciaOpen(true);
    }

    const handleSubmitRecorrencia = async () => {
        try {
            setLoading(true);
            const {...recorrenciaData} = modalRecorrencia;
            await CreateRecorrencia(recorrenciaData);
            setIsModalRecorrenciaOpen(false);
            listAulas();
            toast.success("Recorrencia criada com sucesso", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (error) {
            console.warn(error);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            const {...aulaData} = modalAula;
            await CreateAula(aulaData);
            setIsModalOpen(false);
            listAulas();
            toast.success("Aula criada com sucesso", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (error) {
            console.warn(error);
        } finally {
            setLoading(false);
        }
    };

    async function deleteAula(aula: any) {
        try {
            const confirm = window.confirm(`Tem certeza que deseja apagar a Aula ?`);

            if (confirm) {
                if (aula) {
                    await DeleteAula(aula.id);
                    toast.success("Aula deletada com sucesso", {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
                listAulas();
                setLoading(false);
            }
        } catch (error: any) {
            setLoading(false);
        }
    }

    useEffect(() => {
        const storedFilter = sessionStorage.getItem('aulasFilter');
        if (storedFilter) {
            setFilter(JSON.parse(storedFilter));
        }
    }, []);

    // @ts-ignore
    return (
        <div>
            <Header as="h2" textAlign="center" color={"black"}>
                Aulas
            </Header>
            <Header as="h3" textAlign="center" color={"black"}>
                {turma?.clientName} | Turma: {turma?.nome}
            </Header>

            <Table.Row>
                <Table.HeaderCell>
                    <Button content="Voltar para lista de turmas"
                            icon='arrow left'
                            onClick={() =>
                                navigate('/treinamentos/turmas')} grey/>
                </Table.HeaderCell>

                {sessionUser?.userData?.profile === 'Admin' && (
                    <Table.HeaderCell>
                        <Button
                            color="blue"
                            onClick={() => navigate("/treinamentos/aulas/create/" + turma?.id)}
                        >
                            <Icon name="plus"/> Nova Aula
                        </Button>
                    </Table.HeaderCell>
                )}

                {sessionUser?.userData?.profile === 'Admin' && (
                    <Table.HeaderCell>
                        <Button
                            color="blue"
                            onClick={() => handleRecorrenciaModalClick()}
                        >
                            <Icon name="plus"/> Nova Recorrência
                        </Button>
                    </Table.HeaderCell>
                )}

                <Table.HeaderCell colSpan="4">
                    <Button onClick={toggleFilters} primary>
                        <Icon name={showFilters ? "hide" : "unhide"}/>
                        {showFilters ? "Ocultar filtros" : "Exibir filtros"}
                    </Button>
                </Table.HeaderCell>
            </Table.Row>
            {showFilters && (
                <Segment>
                    <div style={{padding: 10}}>
                        <Form>
                            <Input
                                fluid
                                icon="search"
                                type="text"
                                placeholder="Aula"
                                value={filter?.nome ?? ""}
                                onChange={(event) =>
                                    //@ts-ignore
                                    setFilter({...filter, nome: event.target?.value})
                                }
                            />
                        </Form>
                        <br/>
                        <div>
                            <Input
                                fluid
                                icon="search"
                                type="text"
                                placeholder="Nome do treinamento"
                                value={filter?.treinamentoNome ?? ""}
                                onChange={(event) =>
                                    //@ts-ignore
                                    setFilter({...filter, treinamentoNome: event.target?.value})
                                }
                            />
                        </div>
                        <br/>
                    </div>
                </Segment>
            )}
            <Segment>
                <div style={{padding: 10}}>
                    <Form>
                        <Form.Group widths='equal'>
                            <Form.Field
                                width={'4'}
                                control={Select}
                                fluid
                                label="Mês"
                                placeholder="Mês"
                                selection
                                required
                                search
                                selectOnBlur={false}
                                options={EMes}
                                value={filter?.mes}
                                onChange={(_: React.ChangeEvent<HTMLInputElement>, data: { value: string; }) => {
                                    setFilter({...filter, mes: Number(data.value as string)});
                                }}
                            />
                            <Form.Field
                                width={'4'}
                                control={Input}
                                fluid
                                label="Ano"
                                placeholder="2023"
                                required
                                type="number"
                                value={filter?.ano}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setFilter({
                                        ...filter,
                                        ano: Number(e.target.value),
                                    })
                                }
                            />
                        </Form.Group>
                    </Form>

                </div>
            </Segment>
            <Table color={"orange"}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell colSpan={12}>
                            <Header textAlign={'center'} as={'h4'}>
                                {`Aulas do mês de 
                                ${moment(
                                    new Date(filter.ano ?? new Date().getFullYear(),
                                        (filter.mes ?? 0)  - 1  ?? new Date().getMonth(),
                                        1))
                                    .format("MMMM/YYYY")}`
                                    .toUpperCase()}
                            </Header>
                        </Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell>#</Table.HeaderCell>
                        <Table.HeaderCell>Nome da aula</Table.HeaderCell>
                        <Table.HeaderCell>Treinamento</Table.HeaderCell>
                        <Table.HeaderCell>Turma</Table.HeaderCell>
                        <Table.HeaderCell>Data/Hora</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell>Modificado Por</Table.HeaderCell>
                        {/*<Table.HeaderCell>Modificado Em</Table.HeaderCell>*/}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {aulas && aulas.length > 0 ? (
                        aulas.map((aula) => (
                            <Table.Row key={aula?.id}>
                                <Table.Cell>
                                    <Dropdown item icon={'bars'}>
                                        <Dropdown.Menu>
                                            {/* Verificar se a data atual é maior que a data da aula */}
                                            {new Date() >= new Date(aula.data) && (
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        sessionStorage.setItem('aulasFilter', JSON.stringify(filter));
                                                        navigate("/treinamentos/aulas/chamada/" + aula.id)}}
                                                >
                                                    <Icon name='check square outline'/>
                                                    Chamada
                                                </Dropdown.Item>
                                            )}

                                            {sessionUser?.userData?.profile === 'Admin' && (
                                                <Dropdown.Item
                                                    onClick={() => handleDuplicateClick(aula.id)}>
                                                    <Icon name='copy'/>
                                                    Duplicar
                                                </Dropdown.Item>
                                            )}
                                            {(sessionUser?.userData?.profile === 'Admin') &&
                                                <Dropdown.Item
                                                    onClick={() => navigate(`/treinamentos/aulas/edit/${aula.id}?&turmaId=${turma?.id}`)}
                                                >
                                                    <Icon name='edit'/>
                                                    Editar
                                                </Dropdown.Item>}
                                            {(sessionUser?.userData?.profile === 'Admin') &&
                                                <Dropdown.Item
                                                    onClick={() => deleteAula(aula)}>
                                                    <Icon name='trash' color={'red'}/>
                                                    DELETAR
                                                </Dropdown.Item>}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Table.Cell>
                                <Table.Cell>{aula?.nome}</Table.Cell>
                                <Table.Cell>{aula?.treinamentoNome}</Table.Cell>
                                <Table.Cell>{aula?.turmaNome}</Table.Cell>
                                <Table.Cell>
                                    {moment(aula?.data?.toString().replace("Z", "")).format(
                                        "dddd, DD/MM/YY HH:mm"
                                    )}
                                </Table.Cell>
                                <Table.Cell>{getEstadoAulaText(aula?.estadoAula)}</Table.Cell>
                                <Table.Cell>{aula?.modificadoPor || '--'}</Table.Cell>
                                {/*<Table.Cell>*/}
                                {/*    {moment(aula?.modificadoEm?.toString().replace("Z", "")).format(*/}
                                {/*        "DD/MM/YY HH:mm"*/}
                                {/*    )}*/}
                                {/*</Table.Cell>*/}

                            </Table.Row>
                        ))
                    ) : (
                        <Table.Row>
                            <Table.Cell colSpan={6}>
                                {`A turma selecionada ainda não tem nenhuma aula no mês selecionado ( 
                                ${moment(
                                    new Date(filter.ano ?? new Date().getFullYear(),
                                        (filter.mes ?? 0)  - 1  ?? new Date().getMonth(),
                                        1))
                                    .format("MMMM/YYYY")})`
                                    .toUpperCase()}.
                            </Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>

            <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <Modal.Header>Duplicar Aula</Modal.Header>
                <Modal.Content>
                    <Form size="large">
                        <Segment stacked>
                            <Form.Select
                                label="Turma"
                                name="turmaId"
                                placeholder="Selecione uma turma"
                                options={turmas}
                                value={modalAula?.turmaId}
                                onChange={(evento: any, data: any) => {
                                    setModalAula({
                                        ...modalAula,
                                        turmaId: data.value,
                                    });
                                }}
                                required
                                disabled
                            />
                            <Form.Input
                                label="Nome"
                                name="nome"
                                placeholder="Nome"
                                value={modalAula?.nome}
                                onChange={(event) =>
                                    setModalAula({...modalAula, nome: event.target.value})
                                }
                            />
                            <Form.Select
                                label="Qual o estado da aula?"
                                name="estadoAula"
                                placeholder="Selecione uma opção"
                                options={EEstadoAula}
                                value={modalAula?.estadoAula}
                                onChange={(evento: any, data: any) => {
                                    setModalAula({
                                        ...modalAula,
                                        estadoAula: data.value,
                                    });
                                }}
                            />
                            <Form.Input
                                label="Data de Início"
                                name="data"
                                type="datetime-local"
                                placeholder="Data de Início"
                                value={modalAula?.data?.toString()?.replace("Z", "")}
                                onChange={handleInputChange}
                            />
                        </Segment>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="blue" onClick={handleSubmit}>
                        Criar
                    </Button>
                    <Button color="red" onClick={() => setIsModalOpen(false)}>
                        Fechar
                    </Button>
                </Modal.Actions>
            </Modal>

            <Modal open={isModalRecorrenciaOpen} onClose={() => setIsModalRecorrenciaOpen(false)}>
                <Modal.Header>Criar lote de aulas agendadas</Modal.Header>
                <Modal.Content>
                    <Form size="large">
                        <Segment stacked>
                            <Form.Select
                                label="Turma"
                                name="turmaId"
                                placeholder="Selecione uma turma"
                                options={turmas}
                                value={modalRecorrencia?.turmaId}
                                onChange={(evento: any, data: any) => {
                                    setModalRecorrencia({
                                        ...modalRecorrencia,
                                        turmaId: data.value,
                                    });
                                }}
                                required
                                disabled
                            />
                            <Form.Input
                                label="Nome"
                                name="nome"
                                placeholder="Nome"
                                value={modalRecorrencia?.nome}
                                onChange={(event) =>
                                    setModalRecorrencia({...modalRecorrencia, nome: event.target.value})
                                }
                            />
                            <Form.Input
                                label="Data de Início"
                                name="dataInicio"
                                type="datetime-local"
                                placeholder="Data de Início"
                                value={modalRecorrencia?.dataInicio?.toString()?.replace("Z", "")}
                                onChange={(e) => {
                                    if(e.target.value.length !== 16) return
                                    handleInputChangeModalRecorrencia(e)
                                }}
                            />

                            <Form.Input
                                label="Data Final"
                                name="dataFim"
                                type="date"
                                placeholder="Data Final"
                                value={modalRecorrencia?.dataFim?.toString()?.replace("Z", "")}
                                onChange={(e) => {
                                    if(e.target.value.length !== 10) return
                                    handleInputChangeModalRecorrencia(e)
                                }}
                            />
                            <Form.Select
                                label="Qual os dias da semana que a aula deve ocorrer?"
                                name="estadoAula"
                                placeholder="Selecione uma opção"
                                options={EDiasDaSemana}
                                multiple
                                clearable
                                value={modalRecorrencia?.diasDaSemana}
                                onChange={(evento: any, data: any) => {
                                    setModalRecorrencia({
                                        ...modalRecorrencia,
                                        diasDaSemana: data.value,
                                    });
                                }}
                            />


                        </Segment>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="blue" onClick={handleSubmitRecorrencia}>
                        Criar
                    </Button>
                    <Button color="red" onClick={() => setIsModalRecorrenciaOpen(false)}>
                        Fechar
                    </Button>
                </Modal.Actions>
            </Modal>

            <Pagination
                totalPages={totalPages ?? 0}
                defaultActivePage={filter?.Page ?? 1}
                onPageChange={(e, {activePage}) => {
                    //@ts-ignore
                    setFilter({...filter, page: activePage.toString()});
                }}
            ></Pagination>
        </div>
    );
};

export default AulasList;
