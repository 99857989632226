import React, {useMemo} from "react";
import {Bar} from "react-chartjs-2";
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {DashboardInfoCliente} from "../../../../models/dashboardInfoCliente";
import {Grid} from "semantic-ui-react";

ChartJS.register(
    CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels
);

interface GraficoNaoParticipacaoGeralProps {
    dashboardInfo?: DashboardInfoCliente;
    PeriodoInicio?: string;
    PeriodoFim?: string;
}

function formatarNomeTurma(nomeTurma:string) {
    const limite = 255; // Limite de caracteres
    if (nomeTurma.length > limite) {
        return nomeTurma.substring(0, limite - 1) + '...'; // Abrevia o nome
    }
    return nomeTurma;
}

const GraficoNaoParticipacaoGeral: React.FC<GraficoNaoParticipacaoGeralProps> = ({ dashboardInfo }) => {
    const turmasFiltradas = useMemo(() => {
        return dashboardInfo?.turmas?.filter(turma => turma.porcentagemNaoParticipantes > 40) || [];
    }, [dashboardInfo]);

    const labels = useMemo(() => {
        return dashboardInfo?.turmas?.filter(turma => turma.porcentagemNaoParticipantes > 40) // Filtra as turmas com participação maior que zero
            .map((turma, index) => `${index + 1}`); // Adiciona um número antes do nome da turma
    }, [dashboardInfo]);

    const legendas = useMemo(() => {
        return dashboardInfo?.turmas?.filter(turma => turma.porcentagemNaoParticipantes > 40) // Filtra as turmas com participação maior que zero
            .map((turma, index) => `${index + 1}) ${formatarNomeTurma(turma.nomeTurma)}`); // Adiciona um número antes do nome da turma
    }, [dashboardInfo]);

    const datasets = useMemo(() => {
        return [{
            data: turmasFiltradas.map(turma => turma.porcentagemNaoParticipantes),
            backgroundColor: 'rgb(19, 83, 138)',
            borderRadius: 3,
        }];
    }, [turmasFiltradas]);

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: '',
            },
            datalabels: {
                anchor: 'end',
                align: 'top',
                formatter: (value: number) => {
                    if (typeof value === "number") {
                        return `${value.toFixed(0)}%`;
                    }
                    return "";
                },

                color: '#444',
            },
        },
        scales: {
            x: {
                barPercentage: 0.5,
                categoryPercentage: 1.0,
                grid: {
                    display: false
                }
            },
            y: {
                barPercentage: 0.8,
                categoryPercentage: 2.0,
                beginAtZero: true,
                grid: {
                    display: true
                }
            },
        },
    };

    return (
        <Grid columns='equal'>
            <Grid.Row>
                <Grid.Column width={4}>
                    <div style={{ position: 'absolute', backgroundColor: '#f5f5f5' }}>
                        <ul>{legendas?.map(legenda =>
                            <li style={{listStyleType: 'none'}}>{legenda}</li>
                        )}</ul>
                    </div>
                </Grid.Column>

                <Grid.Column>
                    <div style={{ position: 'relative', width: '100%',minWidth: '1500px' ,height: '90vh' }}>
                        {/*@ts-ignore*/}
                        <Bar data={{ labels, datasets }} options={chartOptions} />
                    </div>

                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default GraficoNaoParticipacaoGeral;
