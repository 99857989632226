import instance from "../../index";
import {NormasRegulamentativasFilters} from "../../../filters/normas-regulamentativas-filters";
import {BaseApiResponse} from "../../../filters/baseApiResponse";
import {PagedList} from "../../../filters/paged-list";
import {Norma} from "../../../filters/norma";

export default async function GetNormasRetulamentativasPagedList(filter?: NormasRegulamentativasFilters) : Promise<PagedList<Norma>> {
    const pagedList = (await instance.get<BaseApiResponse<PagedList<Norma>>>(`/v1/backoffice/normas-regulamentativas/list/`, {
        params: filter
    })).data.data;
    return pagedList
}