import instance from "../../index";

export default CreateParticipante

async function CreateParticipante(request: Partial<CreateParticipanteRequest>): Promise<CreateParticipanteResponse> {
    const response = await instance.post('/v1/backoffice/participantes', request)
    return response?.data?.data;
}

export interface CreateParticipanteRequest {
    nome:string
    setor:string
    clientId:string
}

export interface CreateParticipanteResponseData {
    id: string
    nome:string
    setor:string
    clientId:string
}

export interface CreateParticipanteResponse {
    data: CreateParticipanteResponseData
    success: boolean

}