import React, {useMemo} from "react";
import {Bar} from "react-chartjs-2";
import {
    BarController,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip
} from "chart.js";
import {DashboardInfoCliente} from "../../../../models/dashboardInfoCliente";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {Grid} from "semantic-ui-react";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    BarController, ChartDataLabels
);

interface GraficoParticipacaoGeralProps {
    dashboardInfo?: DashboardInfoCliente;
    PeriodoInicio?: string;
    PeriodoFim?: string;
}

function formatarNomeTurma(nomeTurma:string) {
    const limite = 255; // Limite de caracteres
    if (nomeTurma.length > limite) {
        return nomeTurma.substring(0, limite - 1) + '...'; // Abrevia o nome
    }
    return nomeTurma;
}

const GraficoParticipacaoGeral: React.FC<GraficoParticipacaoGeralProps> = ({ dashboardInfo }) => {
    const labels = useMemo(() => {
        return dashboardInfo?.turmas?.filter(turma => turma.porcentagemParticipacaoGeral > 0) // Filtra as turmas com participação maior que zero
            .map((turma, index) => `${index + 1}`); // Adiciona um número antes do nome da turma
    }, [dashboardInfo]);

    const legendas = useMemo(() => {
        return dashboardInfo?.turmas?.filter(turma => turma.porcentagemParticipacaoGeral > 0) // Filtra as turmas com participação maior que zero
            .map((turma, index) => `${index + 1}) ${formatarNomeTurma(turma.nomeTurma)}`); // Adiciona um número antes do nome da turma
    }, [dashboardInfo]);

    const datasets = useMemo(() => {
        const participacoes = dashboardInfo?.turmas?.filter(turma => turma.porcentagemParticipacaoGeral > 0) // Filtra as turmas com participação maior que zero
            .map(turma => turma.porcentagemParticipacaoGeral);

        return [{
            label: "Participação",
            data: participacoes,
            backgroundColor: 'rgb(255, 130, 16)',
            borderRadius: 3,
        }];
    }, [dashboardInfo]);




    const chartOptions = useMemo(() => {
        return {
            responsive: true,
            plugins: {
                legend: {
                    display: false,
                },
                title: {
                    display: true,
                    text: '',
                },
                datalabels: {
                    anchor: 'end',
                    align: 'top',
                    formatter: (value: number) => {
                        if (typeof value === "number") {
                            return `${value.toFixed(0)}%`;
                        }
                        return "";
                    },

                    color: '#444',
                },
            },
            scales: {
                x: {
                    barPercentage: 0.8, // Reduz a largura das barras
                    categoryPercentage: 1.2, // Ajusta o espaçamento entre as categorias
                    grid: {
                        display: false // Remove as linhas de grade horizontais
                    },
                    // ticks: {
                    //     maxRotation: 90, // Rótulos rotacionados em 90 graus
                    //     minRotation: 90, // Rótulos rotacionados em 90 graus
                    // }
                },
                y: {
                    barPercentage: 1.5, // Reduz a largura das barras
                    categoryPercentage: 2.0,
                    beginAtZero: true,
                    grid: {
                        display: true // Remove as linhas de grade verticais
                    }
                },

            },
            layout: {
                padding: {
                    bottom: 50, // Aumente o espaço na parte inferior, se necessário
                }
            },
        };
    }, []);

    return (
            <Grid columns='equal'>
                <Grid.Row>
                    <Grid.Column width={4}>
                        <div style={{ position: 'absolute', backgroundColor: '#f5f5f5' }}>
                            <ul>{legendas?.map(legenda =>
                                <li style={{listStyleType: 'none'}}>{legenda}</li>
                            )}</ul>
                        </div>
                    </Grid.Column>

                    <Grid.Column>
                        <div style={{ position: 'relative', width: '100%',minWidth: '1500px' ,height: '90vh' }}>
                        {/*@ts-ignore*/}
                        <Bar data={{ labels, datasets }} options={chartOptions} />
                        </div>

                    </Grid.Column>
                </Grid.Row>
            </Grid>

    );
};

export default GraficoParticipacaoGeral;
