import instance from "../../index";

export default CreateTurma

async function CreateTurma(request: Partial<CreateTurmaRequest>): Promise<CreateTurmaResponse> {
  const response = await instance.post<CreateTurmaResponse>('/v1/backoffice/turmas', request)
  return response.data;
}

export interface CreateTurmaRequest {
    nome: string
    descricao: string
    dataInicio: Date
    clientId: string
    treinamentoId: string
    professorId: string
    estadoTurma: number
}

export interface CreateTurmaResponse {
    id: string
    nome: string
    descricao: string
    dataInicio: Date
    clientId: string
    professorId: string
    estadoTurma: number

}

export interface CreateTurmaResponseData {
    data: CreateTurmaResponse
    success: boolean
}
