import instance from "services";

export default GetUserById

async function GetUserById(id: string): Promise<GetUserResponse> {
    const response = await instance.get(`/v1/backoffice/backoffice-users/${id}`);
    return response?.data?.data;
}
export interface GetUserRequest {
    id: string;
}
export interface GetUserResponseData {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    createdAt: Date;
    updatedAt: Date;
    isActive: boolean;
}

export interface GetUserResponse {
    success: boolean;
    data: GetUserResponseData;
}
