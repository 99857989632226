import React, {useEffect, useState} from 'react';
import {Button, Form, Grid, Header, Icon, Segment, Table} from 'semantic-ui-react';
import CreateUser, {CreateUserRequest} from "services/backoffice/users/post-users";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {useBlockUI} from "../../../../shared/services/block-ui-service";

const CreateBackofficeUserPage = () => {
    const [user, setUser] = useState<Partial<CreateUserRequest>>({});
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const blockUI = useBlockUI();
    useEffect(() => {
        blockUI?.setActive(loading)
    }, [loading]);


    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setUser((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            await CreateUser(user);
            setLoading(false);

            navigate('/backoffice/users/');
            toast.success('Usuário criado com sucesso', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (error) {
            setLoading(false);

            toast.warn('Falha ao criar usuário', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            console.warn(error);
        }
    };
    return (
        <div>
            <Grid.Column mobile={16} tablet={8} computer={6}>
                <Header as="h2" textAlign="center" color={'black'}>
                    Usuários do backoffice / Adicionar
                </Header>
                <Segment>
                    <Form onSubmit={handleSubmit}>
                        <Form.Input
                            label="Email"
                            name="email"
                            type="email"
                            placeholder="Email"
                            value={user.email}
                            onChange={handleInputChange}
                            required
                        />
                        <Form.Input
                            label="Nome"
                            name="firstName"
                            type="text"
                            placeholder="Nome"
                            value={user.firstName}
                            onChange={handleInputChange}
                            required
                        />
                        <Form.Input
                            label="Sobrenome"
                            name="lastName"
                            type="text"
                            placeholder="Sobrenome"
                            value={user.lastName}
                            onChange={handleInputChange}
                            required
                        />
                        <Form.Input
                            label="Senha"
                            name="password"
                            type="password"
                            placeholder="Senha"
                            value={user.password}
                            onChange={handleInputChange}
                            required
                        />
                        <Form.Input
                            label="Confirmação da senha"
                            name="passwordConfirmation"
                            type="password"
                            placeholder="Confirmação da senha"
                            value={user.passwordConfirmation}
                            onChange={handleInputChange}
                            required
                        />
                        <Table.Row>
                            <Table.HeaderCell>
                                <Form.Button content="Voltar"
                                             icon='arrow left'
                                             onClick={() => navigate('/backoffice/users/')} grey/>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <Form.Button content="Adicionar"
                                             type={"submit"}
                                             icon='save'
                                             primary/>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Form>
                </Segment>
            </Grid.Column>
        </div>
    );
};

export default CreateBackofficeUserPage;
