import React, {useEffect, useState} from "react";
import {useLoaderData, useLocation, useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {Form, Grid, Header, Segment, Table} from "semantic-ui-react";
import GetAulaById from "../../../../services/sistema-aulas/aulas/get-aula";
import EditAula, {EditAulaRequest} from "../../../../services/sistema-aulas/aulas/edit-aula";
import {GetAllTurmas} from "../../../../services/sistema-aulas/turmas/get-turmas";
import {EEstadoAula} from "../../../../enums/eEstadoAula";
import {Turma} from "../../../../models/turma";
import {SelectListDefault} from "../../../../models/selectListDefault";
import {useBlockUI} from "../../../../shared/services/block-ui-service";

const EditAulaPage = () => {
    const [aula, setAula] = useState<Partial<EditAulaRequest>>();
    const [turmas, setTurmas] = useState<SelectListDefault[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const turmaId = queryParams.get("turmaId");
    const { aulaId } = useParams();
    const blockUI = useBlockUI();

    useEffect(() => {
        if (aulaId) {
            const loadAulaDetails = async () => {
                try {
                    setLoading(true);
                    const response = await GetAulaById(aulaId);
                    setAula(response);
                } catch (error:any) {
                    setError(error.message);
                } finally {
                    setLoading(false);
                }
            };
            loadAulaDetails();
        }
    }, [aulaId]);

    useEffect(() => {
        blockUI?.setActive(loading)
    }, [loading]);

    useEffect(() => {
        function ListTurmas() {
            GetAllTurmas()
                .then((response) => {
                    const turmasData = response?.map((item:Turma) => ({
                        key: item.id,
                        text: item.nome,
                        value: item.id,
                    }));
                    setTurmas(turmasData);
                })
                .catch((error) => {
                    console.warn(error);
                });
        }
        ListTurmas();
    }, []);

    const handleDropdownChange = (name: string, data: any) => {
        setAula((prevAula: any) => ({
            ...prevAula,
            [name]: data.value,
        }));
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setAula((prevAula: any) => ({
            ...prevAula,
            [name]: value,
        }));
    };

    const updateAula = async () => {
        try {
            setLoading(true);
            if (aula) {
                const result = await EditAula(aula, aula.id);
                if (result.data) navigate(`/treinamentos/aulas/?turmaId=${turmaId}`);
                toast.success('Aula atualizada com sucesso', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } catch (error: any) {
            console.warn(error);
        } finally {
            setLoading(false);
        }
    };


    return (
        <div>
            <Grid.Column mobile={16} tablet={8} computer={6}>
                <Header as="h2" textAlign="center" color="black">
                    Atualizar Aula
                </Header>
                <Segment>
                    <Form>
                        <Form.Input
                            label="Nome"
                            name="nome"
                            placeholder="Nome"
                            value={aula?.nome}
                            onChange={(event) => setAula({...aula, nome: event.target.value})}

                        />
                        <Form.Select
                            label="Turma"
                            name="turmaId"
                            placeholder="Selecione uma turma"
                            options={turmas}
                            value={aula?.turmaId}
                            onChange={(evento: any, data: any) => {
                                setAula({
                                    ...aula,
                                    turmaId: data.value,
                                });
                            }}

                            required
                        />
                        <Form.Select
                            label="Qual o estado da aula?"
                            name="estadoAula"
                            placeholder="Selecione uma opção"
                            options={EEstadoAula}
                            value={aula?.estadoAula}
                            onChange={(evento: any, data: any) => {
                                setAula({
                                    ...aula,
                                    estadoAula: data.value,
                                });
                            }}
                        />
                        <Form.Input
                            label="Data de Início"
                            name="data"
                            type="datetime-local"
                            placeholder="Data de Início"
                            value={aula?.data?.toString()?.replace("Z","")}
                            onChange={(e) => {
                                if(e.target.value.length !== 16) return
                                handleInputChange(e)
                            }}
                        />
                        <Table.Row>
                            <Table.HeaderCell>
                                <Form.Button content="Voltar"
                                             icon='arrow left'
                                             onClick={() => navigate(`/treinamentos/aulas/?turmaId=${turmaId}`)} grey/>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <Form.Button content="Atualizar"
                                             onClick={() => updateAula()}
                                             type={"submit"}
                                             icon='save'
                                             primary/>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Form>
                </Segment>
            </Grid.Column>
        </div>
    );
}

export default EditAulaPage;
