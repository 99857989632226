import React, {useEffect, useState} from 'react';
import {Button, Form, Grid, Header, Segment} from 'semantic-ui-react';
import GetToken from "services/auth/get-token";
import SessionStorage from "store/session";
import {useNavigate} from "react-router-dom";
import {useBlockUI} from "../../shared/services/block-ui-service";
import {toast} from "react-toastify";

const LoginForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate()
    const blockUI = useBlockUI();
    const [loading, setLoading] = useState<boolean>(false);
    useEffect(() => {
        blockUI?.setActive(loading)
    }, [loading]);

    const handleSubmit = async () => {
        setLoading(true);

        try {
            const user = (await GetToken({email, password}))?.data?.data;
            sessionStorage.setItem('participantesFilter', JSON.stringify({}));
            sessionStorage.setItem('turmasFilter', JSON.stringify({}));
            user.isLogged = true;
            await SessionStorage.saveSession(user);

            navigate('home');
            window.location.reload();
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };




    return (
        <div suppressContentEditableWarning
             style={{backgroundImage: 'url(/login_bg.png)', backgroundSize: 'cover'}}>
            <Grid textAlign='center' style={{height: '100vh'}} verticalAlign='middle'>
                <Grid.Column style={{maxWidth: 450}}>
                    <img src={'/logotipo_retangulo.png'} style={{
                        maxHeight: '12rem',
                        objectFit: 'contain',
                        marginTop: 10,
                        marginBottom: 10,
                        paddingBottom: 5,
                        alignSelf: 'center',
                        width: '100%',
                    }} alt={'superativa'}/>
                    <Form size='large'>
                        <Header as={'h3'} color={'black'}>O seu Portal</Header>
                        <Segment stacked>
                            <Form.Input
                                fluid
                                icon='user'
                                type={'email'}
                                iconPosition='left'
                                placeholder='E-mail'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <Form.Input
                                fluid
                                icon='lock'
                                iconPosition='left'
                                placeholder='Senha'
                                type='password'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />

                            <Button fluid size='large' color={'blue'} onClick={handleSubmit}>
                                Login
                            </Button>

                            <div style={{ marginTop: '10px' }}>
                                <Button fluid size='large' basic
                                        onClick={
                                            () => navigate('/lost-password/')
                                        }>
                                    Esqueci a senha
                                </Button>
                            </div>
                        </Segment>
                    </Form>
                </Grid.Column>
            </Grid>
        </div>
    );
};

export default LoginForm;
