import instance from "../../index";
import {AulaFilter} from "../../../filters/aula-filter";
import {PagedList} from "../../../filters/paged-list";
import {Aula} from "../../../models/aula";

export default async function GetAulas(filter?: AulaFilter): Promise<any> {
    const response = await instance.get(`/v1/backoffice/aulas`, {
        params: filter
    });
    return response?.data?.data;
}

export async function GetAulasPaginada(filter?: AulaFilter) : Promise<PagedList<Aula>> {
    const pagedList = (await instance.get<PagedList<Aula>>(`/v1/backoffice/aulas/lista-paginada`, {
        params: filter
    })).data;
    return pagedList
}

export interface GetAulasResponse {
    data: GetAulasResponseData;
    success: boolean
}

export interface GetAulasResponseData {
    id: string
    nome: string
    turmaId: string
    turmaNome: string
    estadoAula: number
    data: Date
}
