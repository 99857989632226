import instance from "services";

export default EditUser

async function EditUser(request: Partial<EditUserRequest>, id: string | string[] | undefined): Promise<EditUserResponse> {
    const response = await instance.put(`/v1/backoffice/backoffice-users/${id}`, request);
    return response?.data;
}
export interface EditUserRequest {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
}
export interface EditUserResponseData {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    createdAt: Date;
    updatedAt: Date;
    isActive: boolean;
}

export interface EditUserResponse {
    success: boolean;
    data: EditUserResponseData;
}
