import React, {useEffect, useState} from "react";
import GetTurmas from "../../../services/sistema-aulas/turmas/get-turmas";
import {SessionUser} from "../../../models/session-user";
import {useLocation, useNavigate} from "react-router-dom";
import {TurmasFilter} from "../../../filters/turmas-filters";
import SessionStorage from "../../../store/session";
import {toast} from "react-toastify";
import DeleteTurma, {DeleteTurmaConfirm} from "../../../services/sistema-aulas/turmas/delete-turma";
import {
    Button,
    Dropdown,
    Form,
    Header,
    Icon,
    Input,
    Modal,
    Pagination,
    Segment,
    Select,
    Table
} from "semantic-ui-react";
import moment from "moment";
import {GetAllClientsToDropdown} from "../../../services/backoffice/clients/get-clients";
import {GetAllProfessorUsersToDropdown} from "../../../services/sistema-aulas/professor-user/get-professores";
import {GetTreinamentosSimplified} from "../../../services/sistema-aulas/treinamentos/get-treinamentos";
import {useBlockUI} from "../../../shared/services/block-ui-service";
import GetTurmaById from "../../../services/sistema-aulas/turmas/get-turma";
import {EEstadoTurma} from "../../../enums/eEstadoTurma";
import {Client} from "../../../models/client";
import StringUtils from "../../../shared/utils/string-utils";
import swal from "sweetalert";
import {AxiosError} from "axios";
import DuplicarTurmaEParticipantes, {
    DuplicateTurmaRequest
} from "../../../services/sistema-aulas/turmas/duplicar-turma-participantes";

export interface ConfirmacaoDelecaoTurma {
    turmaId?: string,
    confirmadoDelecao: boolean
}

const TurmasList = () => {
    const [turmas, setTurmas] = useState<any[]>([]);
    const [modalTurma, setModalTurma] = useState<Partial<DuplicateTurmaRequest>>({});
    // const [duplicateTurma, setDuplicateTurma] = useState<Partial<DuplicateTurmaRequest>>({});
    const [turmaIdParaDuplicar, setTurmaIdParaDuplicar] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [totalPages, setTotalPages] = useState<number | undefined>(undefined);
    const [showFilters, setShowFilters] = useState(false)
    const [sessionUser, setSessionUser] = useState<SessionUser>();
    const [clients, setClients] = useState<Client[]>([]);
    const [professores, setProfessores] = useState<any[]>([])
    const [treinamentos, setTreinamentos] = useState<any[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();
    const blockUI = useBlockUI();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [filter, setFilter] = useState<TurmasFilter>(JSON.parse(sessionStorage.getItem('turmasFilter') ?? '{}') ??{
        nome: queryParams.get("nome") ?? '',
        descricao: queryParams.get("descricao") ?? '',
        clientId: queryParams.get("clientId") ?? '',
        treinamentoId: queryParams.get("treinamentoId") ?? '',
        userProfessorId: queryParams.get("userProfessorId") ?? '',
    })

    useEffect(() => {
        blockUI?.setActive(loading)
    }, [loading]);


    useEffect(() => {
        try {
            setFilter(JSON.parse(sessionStorage.getItem('turmasFilter') ?? '{}'));
        } catch (e) {
            console.warn(e)
        }
    }, []);

    useEffect(() => {
        !!filter && sessionStorage.setItem('turmasFilter', JSON.stringify(filter));
        listTurmas();
    }, [filter]);

    useEffect(() => {
        ListProfessores();
        ListTreinamentos();
        ListClients()
        updateSessionUser()
    }, [])

    function setFilterAndResetPage(newFilter: any) {
        setFilter({...newFilter, Page: 1})
    }

    function ListProfessores() {
        GetAllProfessorUsersToDropdown()
            .then((response) => {
                const professoresData = response.items.map((item: any) => ({
                    key: item.id,
                    text: item.firstName + ' ' + item.lastName,
                    value: item.id,
                }));
                setProfessores(professoresData);
            })
            .catch((error) => {
                console.warn(error);
            });
    }

    async function updateSessionUser() {
        const user = await SessionStorage.getSession();
        setSessionUser(user)
    }

    function ListClients() {
        GetAllClientsToDropdown().then((response) => {
            setClients(response);
        })
            .catch(error => {
                console.warn(error);
            })
    }

    function ListTreinamentos() {
        GetTreinamentosSimplified()
            .then((response) => {
                setTreinamentos(response?.data?.data);
            })
            .catch((error) => {
                console.warn(error);
            });
    }

    async function listTurmas() {
        try {
            setLoading(true);
            const response = await GetTurmas(filter);
            setTurmas(response?.items);
            setTotalPages(response.totalPages);
        } catch (error) {
            // handle error if needed
        } finally {
            setLoading(false);
        }
    }

    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };

    const handleDropdownChange = (name: string, data: any) => {
        setModalTurma((prevTurma) => ({
            ...prevTurma,
            [name]: data.value,
        }));
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setModalTurma((prevTurma) => ({
            ...prevTurma,
            [name]: value,
        }));
    };

    const handleProfessorIdChange = (e: any, data: any) => {
        const selectedValue = data.value;
        setModalTurma(prevTurma => ({...prevTurma, professorId: selectedValue}));
    };

    const handleClientIdChange = (e: any, data: any) => {
        const selectedValue = data.value;
        setModalTurma(prevTurma => ({...prevTurma, clientId: selectedValue}));
    };

    function getEstadoTurmaText(key: any) {
        const estadoTurma = EEstadoTurma.find(item => item.value === key);
        return estadoTurma ? estadoTurma.text : '';
    }

    const handleDuplicateClick = async (turmaId: any) => {
        try {
            setLoading(true);
            const response = await GetTurmaById(turmaId);
            if (response) {
                setModalTurma({
                    ...response
                });
                setTurmaIdParaDuplicar(turmaId);
                setIsModalOpen(true);
            } else {
                console.error('Não foi possível duplicar a turma.');
            }
        } catch (error) {
            console.error('Erro ao buscar detalhes da turma:', error);
            toast.error('Erro ao buscar detalhes da turma. Por favor, tente novamente.', {});
        } finally {
            setLoading(false);
        }
    };


    const handleSubmit = async () => {
        if (!turmaIdParaDuplicar) {
            console.error("ID da turma não está definido");
            return;
        }
        try {
            setLoading(true);
            const {...turmaData} = modalTurma;
            await DuplicarTurmaEParticipantes(turmaData, turmaIdParaDuplicar);
            setIsModalOpen(false);
            await listTurmas();
            toast.success('Turma duplicada com sucesso', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (error) {
            toast.warn('Falha ao duplicar turma', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            console.warn(error);
        } finally {
            setLoading(false);
        }
    };

    async function deleteTurma(turma: any) {
        try {
            setLoading(true);

            // const confirm = window.confirm(``);
            const willDelete = await swal({
                buttons: ["Cancelar", "Deletar"],
                title: "Tem certeza que deseja apagar a turma ?",
                text: "",
                icon: "warning",
                dangerMode: true,
            });

            if (willDelete) {
                if (turma) {
                    await DeleteTurma(turma.id).catch(async (error) => {
                        const axiosError = error as AxiosError;
                        if (axiosError.response?.status === 400
                            //@ts-ignore
                            && (axiosError.response.data?.errors[0] as string).includes('Essa turma já tem aulas associadas a ela')
                        ) {

                            const willDeleteConfirmation = await swal({
                                buttons: ["Cancelar", "Deletar"],
                                title: "Essa turma já tem aulas associadas a ela",
                                text: "Tem certeza que deseja deletar?",
                                icon: "warning",
                                dangerMode: true,
                            });
                            if (!willDeleteConfirmation) return;
                            await DeleteTurmaConfirm(turma.id)
                            toast.success(`Turma deletada com sucesso`, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                            await listTurmas();
                        }
                    });
                }
            }
        } catch (error: any) {
            toast.warn(`Falha ao deletar turma`, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } finally {
            setLoading(false);
            listTurmas();
        }
    };
    return (
        <div>
            <Header as="h2" textAlign="center" color={'black'}>
                Turmas / Lista
            </Header>

            <Table.Row>
                {(sessionUser?.userData?.profile === 'Admin') &&
                    <Table.HeaderCell>
                        <Button color="blue"
                                onClick={() => navigate('/treinamentos/turmas/create/')}>

                            <Icon name='plus'/> Novo
                        </Button>
                    </Table.HeaderCell>}
                <Table.HeaderCell colSpan="4">
                    <Button onClick={toggleFilters} primary>
                        <Icon name={showFilters ? 'hide' : 'unhide'}/>
                        {showFilters ? 'Ocultar filtros' : 'Exibir filtros'}
                    </Button>
                </Table.HeaderCell>
            </Table.Row>
            {showFilters && (
                <Segment>
                    <div style={{padding: 10}}>
                        <div>
                            <Input
                                fluid
                                icon="search"
                                type="text"
                                placeholder="Nome da turma"
                                value={filter?.nome ?? ''}
                                onChange={event =>
                                    setFilterAndResetPage({...filter, nome: event.target?.value, Page: 1})
                                }
                            />
                        </div>
                        <br/>
                        <div>
                            <Select clearable
                                    placeholder="Treinamento"
                                    fluid
                                    search
                                    selection
                                    basic
                                    selectOnBlur={false}
                                    options={treinamentos}
                                    value={filter?.treinamentoId ?? ''}
                                    onChange={(event, data) =>
                                        //@ts-ignore
                                        setFilterAndResetPage({...filter, treinamentoId: data.value})
                                    }
                            />
                        </div>
                        <br/>
                        <div>
                            <Input
                                fluid
                                icon="search"
                                type="text"
                                placeholder="Descrição da turma"
                                value={filter?.descricao ?? ''}
                                onChange={event =>
                                    setFilterAndResetPage({...filter, descricao: event.target?.value})
                                }
                            />
                        </div>
                        <br/>
                        <div>
                            <Select clearable
                                    placeholder="Professor da turma"
                                    fluid
                                    search
                                    selection
                                    options={professores}
                                    closeOnEscape
                                    selectOnBlur={false}
                                    value={filter?.userProfessorId ?? ''}
                                    onChange={(e: any, data:any) =>
                                        //@ts-ignore
                                        setFilterAndResetPage({...filter, userProfessorId: data.value})
                                    }
                            />
                        </div>
                        <br/>
                        <div>
                            <Select clearable
                                    selection
                                    options={clients}
                                    closeOnChange
                                    placeholder="Cliente da turma"
                                    fluid
                                    selectOnBlur={false}
                                    scrolling={true}
                                    search={true}
                                    allowAdditions
                                    value={filter.clientId ?? ''}
                                    onChange={(e:any, data:any) =>
                                        //@ts-ignore
                                        setFilterAndResetPage({...filter, clientId: data.value})
                                    }

                            />
                        </div>
                        <br/>
                    </div>
                </Segment>
            )}
            <Table color={"orange"}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>#</Table.HeaderCell>
                        <Table.HeaderCell>Nome da turma</Table.HeaderCell>
                        <Table.HeaderCell>Treinamento</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell>Descrição</Table.HeaderCell>
                        <Table.HeaderCell>Professor</Table.HeaderCell>
                        <Table.HeaderCell>Cliente</Table.HeaderCell>
                        <Table.HeaderCell>Início da turma</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {turmas?.map((turma) => (
                        <Table.Row key={turma.id}>
                            <Table.Cell>
                                <Dropdown item icon={'bars'}>
                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            onClick={() => {
                                                navigate(`/treinamentos/turmas/${turma.id}/lista-participantes${StringUtils.jsonToQueryString(filter)}`)
                                            }}>
                                            <Icon name='users'/>
                                            Participantes</Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => {
                                                navigate(`/treinamentos/turmas/${turma.id}/relatorio`)
                                            }}>
                                            <Icon name='dashboard'/>
                                            Relatório geral
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => {
                                                navigate(`/treinamentos/turmas/${turma.id}/relatorio/presenca`)
                                            }}>
                                            <Icon name='clipboard check'/>
                                            Relatório de presença
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => {
                                                navigate(`/treinamentos/aulas/?turmaId=${turma.id}`);
                                            }}>
                                            <Icon name='clipboard'/>
                                            Aulas
                                        </Dropdown.Item>

                                        {(sessionUser?.userData?.profile === 'Admin') &&
                                            <Dropdown.Item
                                                onClick={() => handleDuplicateClick(turma.id)}>
                                                <Icon name='copy'/>
                                                Duplicar
                                            </Dropdown.Item>
                                        }
                                        {(sessionUser?.userData?.profile === 'Admin') &&
                                            <Dropdown.Item
                                                onClick={() => navigate(`/treinamentos/turmas/edit/${turma.id}`)}>
                                                <Icon name='edit'/>
                                                Editar
                                            </Dropdown.Item>}
                                        {(sessionUser?.userData?.profile === 'Admin') &&
                                            <Dropdown.Item
                                                onClick={() => deleteTurma(turma)}>
                                                <Icon name='trash' color={'red'}/>
                                                DELETAR
                                            </Dropdown.Item>}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Table.Cell>
                            <Table.Cell>{turma?.nome}</Table.Cell>
                            <Table.Cell>{turma?.treinamentoNome}</Table.Cell>
                            <Table.Cell>{getEstadoTurmaText(turma?.estadoTurma)}</Table.Cell>
                            <Table.Cell>{turma?.descricao}</Table.Cell>
                            <Table.Cell>{turma?.userProfessorName}</Table.Cell>
                            <Table.Cell>{turma?.clientName}</Table.Cell>
                            <Table.Cell>{moment(turma?.dataInicio?.replace("Z", "")).format('DD/MM/YY HH:mm')}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <Modal.Header>Duplicar Turma</Modal.Header>
                <Modal.Content>
                    <Form size="large">
                        <Segment stacked>
                            <Form.Input
                                fluid
                                label="Nome da turma"
                                placeholder="Nome"
                                name="nome"
                                value={modalTurma?.nome}
                                onChange={(event) => setModalTurma({...modalTurma, nome: event.target.value})}
                                required
                            />
                            <Form.Input
                                fluid
                                label="Descrição"
                                placeholder="Descrição"
                                name="lastName"
                                value={modalTurma?.descricao}
                                onChange={(event) => setModalTurma({...modalTurma, descricao: event.target.value})}
                                required
                            />
                            <Form.Group widths="equal">
                                <Form.Select
                                    label={'Cliente ao qual a turma pertence'}
                                    options={clients}
                                    placeholder='Selecione uma opção'
                                    value={modalTurma?.clientId || ''}
                                    required
                                    onChange={handleClientIdChange}
                                />
                                <Form.Input
                                    label="Data de Início"
                                    name="dataInicio"
                                    type="datetime-local"
                                    placeholder="Data de Início"
                                    value={modalTurma?.dataInicio?.toString()?.replace("Z", "")}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                            <Form.Group widths="equal">
                                <Form.Select
                                    label={'Treinamento que será realizado'}
                                    options={treinamentos}
                                    placeholder='Selecione uma opção'
                                    value={modalTurma?.treinamentoId || ''}
                                    required
                                    onChange={(e: any, data: any) => {
                                        const selectedValue = data.value;
                                        setModalTurma(prevTurma =>
                                            ({...prevTurma, treinamentoId: selectedValue}));
                                    }}
                                />
                                <Form.Select
                                    label={'Professor'}
                                    options={professores}
                                    placeholder='Selecione uma opção'
                                    value={modalTurma?.professorId || ''}
                                    required
                                    onChange={handleProfessorIdChange}
                                />
                            </Form.Group>
                            <Form.Group widths="equal">
                                <Form.Select
                                    label={'Qual o estado da turma?'}
                                    options={EEstadoTurma}
                                    placeholder='Selecione uma opção'
                                    value={modalTurma?.estadoTurma || ''}
                                    required
                                    onChange={(e: any, data: any) => handleDropdownChange('estadoTurma', data)}
                                />
                            </Form.Group>
                        </Segment>
                    </Form>

                </Modal.Content>
                <Modal.Actions>
                    <Button color="blue" onClick={handleSubmit}>Criar</Button>
                    <Button color="red" onClick={() => setIsModalOpen(false)}>Fechar</Button>
                </Modal.Actions>
            </Modal>
            <Pagination totalPages={totalPages ?? 0}
                        defaultActivePage={filter?.Page ?? 1}
                        onPageChange={(e, {activePage}) => {
                            setFilter({...filter, Page: activePage as number})
                        }
                        }></Pagination>
        </div>
    )
}

export default TurmasList;
