import instance from "services";

export default EditProfessorUser

async function EditProfessorUser(request: Partial<EditProfessorUserRequest>, id: string | string[] | undefined): Promise<EditProfessorUserResponse> {
    const response = await instance.put(`/v1/backoffice/professores/${id}`, request);
    return response?.data?.data;
}
export interface EditProfessorUserRequest {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
}
export interface EditProfessorUserResponseData {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    createdAt: Date;
    updatedAt: Date;
    isActive: boolean;
}

export interface EditProfessorUserResponse {
    success: boolean;
    data: EditProfessorUserResponseData;
}
