import AsyncStorage from '@react-native-async-storage/async-storage';
import {SessionUser} from "models/session-user";

class SessionStorage {
    static async saveSession(session: SessionUser) {
        try {
            const jsonSession = JSON.stringify(session);
            await AsyncStorage.setItem('session', jsonSession);
            return true;
        } catch (error) {
            console.warn(error);
            return false;
        }
    }

    static async getSession() {
        try {
            const jsonSession = await AsyncStorage.getItem('session');
            return jsonSession != null ? JSON.parse(jsonSession) : null;
        } catch (error) {
            console.warn(error);
            return null;
        }
    }

    static async deleteSession() {
        try {
            await AsyncStorage.removeItem('session');
            return true;
        } catch (error) {
            console.warn(error);
            return false;
        }
    }
}
export default SessionStorage;
