import {createBrowserRouter, createRoutesFromElements, Route} from "react-router-dom";
import Home from "../pages/backoffice/home";
import ClientsList from "../pages/backoffice/clients";
import DetailsColetaErgo, {viewColetaErgoLoader} from "../pages/backoffice/coletas-ergo/details";
import ColetasErgoList from "../pages/backoffice/coletas-ergo";
import BackofficeUsersList from "../pages/backoffice/users";
import React from "react";
import MasterContainer from "../shared/components/container";
import AulasList from "../pages/ergo-activities/aulas";
import EditAulaPage from "../pages/ergo-activities/aulas/edit";
import AulaChamadaPage, {aulaChamadaLoader} from "../pages/ergo-activities/aulas/chamada";
import TreinamentosList from "../pages/ergo-activities/treinamentos";
import EditTreinamentoPage from "../pages/ergo-activities/treinamentos/edit";
import TurmaRelatorioPage, {viewTurmaRelatorioLoader} from "../pages/ergo-activities/turmas/relatorio";
import TurmaRelatorioPresencaPage from "../pages/ergo-activities/turmas/relatorio/presenca";
import TurmasList from "../pages/ergo-activities/turmas";
import EditTurmaPage from "../pages/ergo-activities/turmas/edit";
import TurmaParticipantesList, {
    viewTurmaParticipantesLoader
} from "../pages/ergo-activities/turmas/lista-participantes";
import ParticipantesList from "../pages/ergo-activities/participantes";
import EditParticipantePage, {editParticipanteLoader} from "../pages/ergo-activities/participantes/edit";
import TurmasRelatoriosList from "../pages/ergo-activities/relatorios/turma";
import ListaRelatoriosTreinamentos from "../pages/ergo-activities/turmas/relatorio/relatorio-geral-cliente";
import PesquisasSaudeList from "../pages/pesquisa-saude";

export const operatorRoutes = (profile: string, isLogged: boolean, username: string) => createBrowserRouter(createRoutesFromElements(<>
    <Route path="home" element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><Home/></MasterContainer>}/>
    <Route path="backoffice/clients" element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><ClientsList/></MasterContainer>}/>
    <Route
        path="backoffice/coleta-ergo/details/:id"
        element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><DetailsColetaErgo/></MasterContainer>}
        loader={viewColetaErgoLoader}
    />
    <Route path="backoffice/coleta-ergo/" element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><ColetasErgoList/></MasterContainer>}/>
    <Route path="/" element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><Home/></MasterContainer>}/>
    <Route path="*" element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><BackofficeUsersList/></MasterContainer>}/>

    <Route path="treinamentos/aulas"
           element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><AulasList/></MasterContainer>}
    />
    <Route
        path="treinamentos/aulas/edit/:aulaId"
        element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><EditAulaPage/></MasterContainer>}
    />

    <Route
        path="treinamentos/aulas/chamada/:id"
        element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><AulaChamadaPage/></MasterContainer>}
        loader={aulaChamadaLoader}
    />


    <Route path="treinamentos/treinamentos"
           element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><TreinamentosList/></MasterContainer>}/>
    <Route
        path="treinamentos/treinamentos/edit/:treinamentoId"
        element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><EditTreinamentoPage/></MasterContainer>}
    />

    <Route path="treinamentos/turmas/:id/relatorio"
           element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><TurmaRelatorioPage/></MasterContainer>}
           loader={viewTurmaRelatorioLoader}/>

    <Route path="treinamentos/turmas/:turmaId/relatorio/presenca"
           element={<MasterContainer profile={profile} username={username}
                                     isLogged={isLogged}><TurmaRelatorioPresencaPage/></MasterContainer>}
    />


    <Route path="treinamentos/turmas"
           element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><TurmasList/></MasterContainer>}/>
    <Route
        path="treinamentos/turmas/edit/:id"
        element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><EditTurmaPage/></MasterContainer>}
    />

    <Route path="treinamentos/turmas/:id/lista-participantes"
           element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><TurmaParticipantesList/></MasterContainer>}
           loader={viewTurmaParticipantesLoader}
    />

    <Route path="treinamentos/turmas/lista-participantes"
           element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><TurmaParticipantesList/></MasterContainer>}
           loader={viewTurmaParticipantesLoader}
    />

    <Route path="treinamentos/participantes"
           element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><ParticipantesList/></MasterContainer>}
    />

    <Route
        path="treinamentos/participantes/edit/:id"
        element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><EditParticipantePage/></MasterContainer>}
        loader={editParticipanteLoader}
    />

    <Route path="treinamentos/relatorios/turma"
           element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><TurmasRelatoriosList/></MasterContainer>}
    />

    <Route
        path="treinamentos/relatorios/clients/"
        element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><ListaRelatoriosTreinamentos/></MasterContainer>}
    />
    <Route path="*" element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><Home/></MasterContainer>}/>

    <Route path="backoffice/pesquisa-saude"
           element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><PesquisasSaudeList/></MasterContainer>}/>



</>));

