import React, {useEffect, useState} from 'react';
import {Form, Grid, Header, Segment, Table} from 'semantic-ui-react';
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import CreateSolucaoErgo, {
    CreateSolucaoErgoRequest
} from "../../../../services/backoffice/solucoes-ergo/create-solucao-ergo";
import {ETiposSolucoesErgonomicas} from "../../../../enums/ePerguntaSolucoesCognitivas";
import {useBlockUI} from "../../../../shared/services/block-ui-service";
import GetNormasRegulamentativasToDropdown
    from "../../../../services/backoffice/normas-regulamentativas/get-normas-short-list";
import {ETipoDeAcaoSolucaoErgo} from "../../../../enums/eTipoDeAcaoSolucaoErgo";

const CreateSolucaoErgoPage = () => {
    const [solucao, setSolucao] = useState<Partial<CreateSolucaoErgoRequest>>({});
    const navigate = useNavigate();
    const [normasRegulamentativas, setNormasRegulamentativas] = useState<any[]>([]);

    const [loading, setLoading] = useState<boolean>(false);
    const blockUI = useBlockUI();
    useEffect(() => {
        blockUI?.setActive(loading)
    }, [loading]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setSolucao((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));
    };

    const handleDropdownChange = (name: string, data: any) => {
        setSolucao((prevSolucao) => ({
            ...prevSolucao,
            [name]: data.value,
        }));
    };

    useEffect(() => {
        function ListNormasRegulamentativas() {
            GetNormasRegulamentativasToDropdown().then((response) => {
                setNormasRegulamentativas(response);
            })
                .catch(error => {
                    console.warn(error);
                })
        }
        ListNormasRegulamentativas()
    }, [])


    const handleSubmit = async () => {
        try {
            setLoading(true);
            await CreateSolucaoErgo(solucao);
            navigate('/backoffice/solucoes-ergo/');
            toast.success('Solução Ergonômica criada com sucesso', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (error) {
            toast.warn('Falha ao criar solução ergonômica', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            console.warn(error);
        } finally {
            setLoading(false);
        }
    };


    return (
        <div>
            <Grid.Column mobile={16} tablet={8} computer={6}>
                <Header as="h2" textAlign="center" color={'black'}>
                    Solução Ergonômica / Adicionar
                </Header>
                <Segment>
                    <Form onSubmit={handleSubmit}>
                        <Form.Field>
                            <Form.Select
                                label={'Tipo de Ação'}
                                options={ETipoDeAcaoSolucaoErgo}
                                placeholder='Selecione uma opção'
                                value={solucao.tipoDeAcaoSolucaoErgo}
                                onChange={(e: any, data: any) => handleDropdownChange('tipoDeAcaoSolucaoErgo', data)}
                                required
                            />
                        </Form.Field>

                        <Form.Field>
                            <Form.Select
                                label={'Tipo de Solução Ergonômica'}
                                options={ETiposSolucoesErgonomicas}
                                placeholder='Selecione uma opção'
                                value={solucao.tipoSolucaoErgonomica}
                                onChange={(e: any, data: any) => handleDropdownChange('tipoSolucaoErgonomica', data)}
                                required
                            />
                        </Form.Field>

                        <Form.Field>
                            <Form.Input
                                label="Descrição"
                                name="descricao"
                                type="text"
                                placeholder="Solução encontrada"
                                value={solucao?.descricao}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Field>

                        <Form.Field>
                            <Form.Select
                                label={'Normas Regulamentativas'}
                                options={normasRegulamentativas}
                                placeholder='Selecione uma opção'
                                value={solucao?.normasRegulamentativas}
                                multiple
                                clearable
                                search
                                onChange={(evento: any, data: any) => {
                                    setSolucao({
                                        ...solucao,
                                        normasRegulamentativas: data.value,
                                    });
                                }}
                            />
                        </Form.Field>

                        <Table.Row>
                            <Table.HeaderCell>
                                <Form.Button content="Voltar"
                                             icon='arrow left'
                                             onClick={() => navigate('/backoffice/solucoes-ergo/')} grey/>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <Form.Button content="Adicionar"
                                             type={"submit"}
                                             icon='save'
                                             primary/>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Form>
                </Segment>
            </Grid.Column>
        </div>
    );
};

export default CreateSolucaoErgoPage;
