import React, {useEffect, useState} from "react";
import {SessionUser} from "../../../models/session-user";
import {useNavigate} from "react-router-dom";
import SessionStorage from "../../../store/session";
import {toast} from "react-toastify";
import {
    Button,
    Dropdown,
    Header,
    Icon,
    Input,
    Message,
    Modal,
    Pagination,
    Segment,
    Select,
    Table
} from "semantic-ui-react";
import GetParticipantes, {
    GetParticipantesResponseData
} from "../../../services/sistema-aulas/participante/get-participantes";
import {ParticipanteFilter} from "../../../filters/participante-filters";
import DeleteParticipante from "../../../services/sistema-aulas/participante/delete-participante";
import {GetAllClientsToDropdown} from "../../../services/backoffice/clients/get-clients";
import CreateParticipanteLote from "../../../services/sistema-aulas/participante/excel-participante-import";
import {useBlockUI} from "../../../shared/services/block-ui-service";

const ParticipantesList = () => {
    const [participantes, setParticipantes] = useState<GetParticipantesResponseData[]>([])
    const [error, setError] = useState<string>()
    const [loading, setLoading] = useState<boolean>(false)
    const [modal, setModal] = useState<boolean>(false)
    const [filter, setFilter] = useState<ParticipanteFilter>(JSON.parse(sessionStorage.getItem('participantesFilter') ?? '{}'))
    const [totalPages, setTotalPages] = useState()
    const [showFilters, setShowFilters] = useState(false)
    const [sessionUser, setSessionUser] = useState<SessionUser>();
    const navigate = useNavigate();
    const [clients, setClients] = useState<any[]>([]);

    const [selectedFilename, setSelectedFilename] = useState<string>();
    const [buffer, setBuffer] = useState();
    const blockUI = useBlockUI();
    useEffect(() => {
        blockUI?.setActive(loading)
    }, [loading]);

    useEffect(() => {
        try {
            const storedFilter = sessionStorage.getItem('participantesFilter');
            !!storedFilter && setFilter(JSON.parse(sessionStorage.getItem('participantesFilter') ?? '{}'));
        } catch (e) {
            console.warn(e)
        }

    }, []);

    useEffect(() => {
        !!filter && sessionStorage.setItem('participantesFilter', JSON.stringify(filter));
    }, [filter]);

    function setFilterAndResetPage(newFilter: any) {
        setFilter({...newFilter, Page: 1})
    }

    useEffect(() => {
        function ListClients() {
            GetAllClientsToDropdown().then((response) => {
                setClients(response);
            })
                .catch(error => {
                    console.warn(error);
                })
        }

        ListClients()
    }, [])
    useEffect(() => {
        async function updateSessionUser() {
            const user = await SessionStorage.getSession();
            setSessionUser(user)
        }

        updateSessionUser()
    }, [])

    useEffect(() => {
        listParticipantes();
    }, [filter]);

    async function listParticipantes() {
        try {
            setLoading(true);

            const response = await GetParticipantes(filter);
            setTotalPages(response.totalPages);
            setParticipantes(response.items);
        } catch (error) {
            // handle error
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };


    async function deleteParticipante(participante: any) {
        try {
            const confirm = window.confirm(`Tem certeza que deseja apagar o participante?`);
            if (confirm) {
                setLoading(true);
                if (participante) {
                    await DeleteParticipante(participante.id);
                    toast.success('Participante deletado com sucesso', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    await listParticipantes();
                }
            }
        } catch (error: any) {
            toast.warn('Falha ao deletar participante', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } finally {
            setLoading(false);
        }
    }


    const toBase64 = (file: any) => {
        const fileName = file.name;
        if (!fileName.includes("xls")) {
            toast.error("Deve ser um arquivo excel válido.");
            return
        }
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                resolve({
                    fileName,
                    //@ts-ignore
                    contentFile: btoa(e.target.result),
                });
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsBinaryString(file);
        });
    };
    const handleFileChange = (event: any) => {
        //@ts-ignore
        toBase64(event?.target?.files[0])
            ?.then((resp: any) => {
                setSelectedFilename(resp.fileName)
                setBuffer(resp.contentFile)
            })
            .catch((error) => {
                toast.error("Arquivo inválido");
                console.error(error);
            });
    };
    const sendFile = async () => {
        if (!selectedFilename || !buffer) {
            toast.error("Envie um arquivo válido para continuar");
            return;
        }
        setLoading(true);
        try {
            await CreateParticipanteLote(buffer, selectedFilename);
            toast.success("Participantes importados com sucesso");
            setSelectedFilename(undefined);
            setBuffer(undefined);
            setModal(false);
            await listParticipantes();
        } catch (error) {
            toast.error("Erro ao importar participantes");
        } finally {
            setLoading(false);
        }
    };


    return (
        <div>
            <Header as="h2" textAlign="center" color={'black'}>
                Colaboradores/Participantes
            </Header>
            <Modal
                onClose={() => setModal(false)}
                onOpen={() => setModal(true)}
                open={modal}
            >
                <Modal.Header>Importação de colaboradores por Excel</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Header>Selecione uma planilha para continuar:</Header>
                        <Input
                            type="file"
                            placeholder='File...'
                            onChange={handleFileChange}/>
                        <hr/>
                        {!!selectedFilename && <Message>
                            Arquivo selecionado: {selectedFilename}
                        </Message>}
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setModal(false)}>
                        Cancelar
                    </Button>
                    <Button
                        disabled={!buffer}
                        content="Enviar"
                        labelPosition='left'
                        icon='upload'
                        onClick={() => {
                            setModal(false)
                            sendFile()
                        }}
                        positive
                    />
                </Modal.Actions>
            </Modal>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            {sessionUser?.userData?.profile === 'Admin' &&
                                <Button color="blue"
                                        icon="plus"
                                        content={"Novo participante"}
                                        onClick={() => navigate('/treinamentos/participantes/create/')}>
                                </Button>}
                            {sessionUser?.userData?.profile === 'Admin' &&
                                <Button color="green"
                                        onClick={() => setModal(true)}>
                                    <Icon name='upload'/> Importar participantes
                                </Button>}
                            <Button onClick={toggleFilters} primary>
                                <Icon name={showFilters ? 'hide' : 'unhide'}/>
                                {showFilters ? 'Ocultar filtros' : 'Exibir filtros'}
                            </Button>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
            </Table>
            {showFilters && (
                <Segment>
                    <div style={{padding: 10}}>
                        <div>
                            <Input
                                fluid
                                icon="search"
                                type="text"
                                placeholder="Nome do participante"
                                value={filter?.Nome ?? ''}
                                onChange={event =>
                                    setFilterAndResetPage({...filter, Nome: event.target?.value})
                                }
                            />
                        </div>
                        <br/>
                        <div>
                            <Input
                                fluid
                                icon="search"
                                type="text"
                                placeholder="Setor"
                                value={filter?.Setor ?? ''}
                                onChange={event =>
                                    setFilterAndResetPage({...filter, Setor: event.target?.value})
                                }
                            />
                        </div>
                        <br/>
                        <div>
                            <Select clearable
                                    placeholder="Selecione um cliente"
                                    fluid
                                    search
                                    selection
                                    options={clients}
                                    closeOnEscape
                                    selectOnBlur={false}
                                    value={filter?.ClientId ?? ''}
                                    onChange={(event, data) =>
                                        //@ts-ignore
                                        setFilterAndResetPage({...filter, ClientId: data.value})
                                    }
                            />
                        </div>
                    </div>
                </Segment>
            )}


            <Table color={"orange"}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>#</Table.HeaderCell>
                        <Table.HeaderCell>Nome do participante</Table.HeaderCell>
                        <Table.HeaderCell>Setor</Table.HeaderCell>
                        <Table.HeaderCell>Cliente/Empresa</Table.HeaderCell>
                        <Table.HeaderCell>Data de criação</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                {(sessionUser?.userData?.profile === 'Admin' || sessionUser?.userData?.profile === 'Operator') &&

                    <Table.Body>
                        {participantes?.map((participante) => (
                            <Table.Row key={participante.id}>
                                <Table.Cell>
                                    <Dropdown item icon={'bars'}>
                                        <Dropdown.Menu>
                                            {sessionUser?.userData?.profile === 'Admin' && (
                                                <Dropdown.Item
                                                    color="blue"
                                                    text={'Editar'}
                                                    onClick={() => navigate('/treinamentos/participantes/edit/' + participante.id)}
                                                >
                                                    <Icon name='edit' color='blue'/>
                                                    Editar
                                                </Dropdown.Item>
                                            )}
                                            <Dropdown.Item
                                                text={'Deletar'}
                                                onClick={() => deleteParticipante(participante)}
                                            >
                                                <Icon name='trash' color={'red'}/>
                                                Deletar
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Table.Cell>

                                <Table.Cell>{`${participante?.nome}`}</Table.Cell>
                                <Table.Cell>{`${participante?.setor}`}</Table.Cell>
                                <Table.Cell>{participante?.clientName}</Table.Cell>
                                <Table.Cell>{participante?.formattedCreatedAt}</Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>}
            </Table>
            <Pagination totalPages={totalPages ?? 0}
                        defaultActivePage={filter?.Page ?? 1}
                        onPageChange={(e, {activePage}) => {
                            setFilter({...filter, Page: activePage as number})
                        }
                        }>
            </Pagination>
        </div>
    )
}

export default ParticipantesList;
