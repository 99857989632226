import React, {useEffect, useState} from "react";
import {Button, Grid, Header} from "semantic-ui-react";
import {useBlockUI} from "../../../shared/services/block-ui-service";
import {useNavigate} from "react-router-dom";

const AgradecimentoPesquisaSaudePage = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const [error, setError] = useState<string>('');
    const blockUI = useBlockUI();
    useEffect(() => {
        blockUI?.setActive(loading)
    }, [loading]);



    return(
        <div suppressContentEditableWarning
             style={{backgroundImage: 'url(/login_bg.png)', backgroundSize: 'cover'}}>
            <Grid textAlign='center' style={{height: '100vh'}} verticalAlign='middle'>
                <Grid.Column style={{maxWidth: 450}}>
                    <img src={'/logotipo_retangulo.png'} style={{
                        maxHeight: '5rem',
                        objectFit: 'contain',
                        marginTop: 10,
                        marginBottom: -20,
                        paddingBottom: 5,
                        alignSelf: 'center',
                        width: '100%',
                    }} alt={'fleetgame'}/>
                    <Header as="h3" textAlign="center" color={'black'}>
                        Sua resposta foi enviada com sucesso!
                    </Header>

                    <Button content="Ir para o Início" fluid size='large'
                    onClick={() =>
                    navigate('/')} color={'blue'}/>
                </Grid.Column>
            </Grid>
        </div>
    );

}

export default AgradecimentoPesquisaSaudePage;