import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import {DashboardInfoCliente} from "../../../../models/dashboardInfoCliente";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface AnaliseSaude {
    porcentagemAnsiedade: number;
    porcentagemDepressao: number;
    porcentagemEstresse: number;
}

interface SaudeGraphProps {
    analiseSaude?: AnaliseSaude;
    dashboardInfo?: DashboardInfoCliente; // Adicione isso se necessário
}


const SaudeGraph: React.FC<SaudeGraphProps> = ({ analiseSaude }) => {
    const {
        porcentagemAnsiedade = 0,
        porcentagemDepressao = 0,
        porcentagemEstresse = 0
    } = analiseSaude || {};

    const formatarValor = (valor:any) => {
        return Number(valor.toString().slice(0, 2)) ;
    };

    const data = {
        labels: ['Ansiedade', 'Depressão', 'Estresse'],
        datasets: [
            {
                label: '',
                data: [formatarValor(porcentagemAnsiedade),
                    formatarValor(porcentagemDepressao),
                    formatarValor(porcentagemEstresse)],
                backgroundColor: ['rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)', 'rgba(75, 192, 192, 0.2)'],
                borderColor: ['rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)', 'rgba(75, 192, 192, 1)'],
                borderWidth: 1,
            },
        ],
    };

    const chartOptions = {
        indexAxis: 'y',
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
                legend: {
                    display: false, // Aqui desabilitamos a legenda
                },
            title: {
                display: true,
                text: 'Análise de Saúde - Teste de Ansiedade, Depressão e Estresse',
            },
            datalabels: {
                anchor: "end",
                align: "top",
                formatter: (value: number) => {
                    if (typeof value === "number") {
                        return `${value.toFixed(0)}%`;
                    }
                    return "";
                },
            },
        },
    };

    return (
        <div style={{ position: 'relative', width: '100%', height: '60vh' }}>
            {/*@ts-ignore*/}
            <Bar data={data} options={chartOptions} />
        </div>
    );
};

export default SaudeGraph;
