import instance from "../../index";
import {RelatoriosTurmasFilter} from "../../../filters/relatorios-turmas-filter";

export default GetRelatoriosTurmas

async function GetRelatoriosTurmas(filter?: RelatoriosTurmasFilter): Promise<any> {
    const response = await instance.get('/v1/backoffice/relatorios-treinamentos-turmas', {params: filter})
    return response?.data?.data
}

export interface GetRelatoriosTurmasResponseData {
    id: string
    porcentagemParticipacaoTurma:string

    turmaId:string
    treinamentoId:string
}

export interface GetRelatoriosTurmasResponse {
    data: GetRelatoriosTurmasResponseData
    success: boolean
}