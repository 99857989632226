import instance from "../../index";
import {TurmasFilter} from "../../../filters/turmas-filters";
import {Turma} from "../../../models/turma";

// TODO: GET TURMAS LISTA PAGINADA AGORA SÓ CONTÉM UM DATA DEVIDO A TROCA DO SERVIÇO NO BACKEND...
export default async function GetTurmas(filter?: TurmasFilter) : Promise<any> {
    const response = await instance.get('/v1/backoffice/turmas/lista-paginada', { params: filter })
    return response?.data
}

export async function GetAllTurmas(filter?: TurmasFilter) : Promise<Turma[]> {
    const response = await instance.get('/v1/backoffice/turmas', { params: filter })
    return response?.data?.data;
}




export interface GetTurmasResponseData {
    id: string
    nome: string
    descricao: string
    dataInicio: Date
    clientId: string
    clientName: string
    professorId: string
    userProfessorName: string
    estadoTurma: number

}

export interface GetTurmasResponse {
    success: boolean;
    data: GetTurmasResponseData;

}

