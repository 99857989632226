import ColetasErgoList from "../pages/backoffice/coletas-ergo";
import EditColetaErgoPage, {editColetasLoader} from "../pages/backoffice/coletas-ergo/edit";
import React from "react";
import Home from "../pages/backoffice/home";
import CollectorsUserList from "../pages/backoffice/collectors";
import {createBrowserRouter, createRoutesFromElements, Route} from "react-router-dom";
import EditBackofficeUserPage, {editUserLoader} from "../pages/backoffice/users/edit";
import EditCollectorUserPage, {editCollectorLoader} from "../pages/backoffice/collectors/edit";
import ClientsList from "../pages/backoffice/clients";
import CreateClientPage from "../pages/backoffice/clients/create";
import CreateCollectorUserPage from "../pages/backoffice/collectors/create";
import BackofficeUsersList from "../pages/backoffice/users";
import CreateBackofficeUserPage from "../pages/backoffice/users/create";
import EditClientPage, {editClientLoader} from "../pages/backoffice/clients/edit";
import CreateColetaErgoPage from "../pages/backoffice/coletas-ergo/create";
import DetailsColetaErgo, {viewColetaErgoLoader} from "../pages/backoffice/coletas-ergo/details";
import CreateOperatorUserPage from "../pages/backoffice/operators/create";
import OperatorUserList from "../pages/backoffice/operators";
import EditOperatorUserPage, {editOperatorLoader} from "../pages/backoffice/operators/edit";
import Login from "pages/auth/login";
import MasterContainer from "../shared/components/container";
import SolucoesErgoList from "../pages/backoffice/solucoes-ergo";
import CreateSolucaoErgoPage from "../pages/backoffice/solucoes-ergo/create";
import UpdateSolucaoErgoPage, {editSolucaoErgoLoader} from "../pages/backoffice/solucoes-ergo/update";
import ViewSolucaoErgoPage, {viewSolucaoErgoLoader} from "../pages/backoffice/solucoes-ergo/details";
import AvaliacoesErgonomicasList from "../pages/backoffice/avaliacoes-ergo";
import CreateAvaliacaoErgoPage from "../pages/backoffice/avaliacoes-ergo/create";
import EditAvaliacaoErgoPage, {editAvaliacaoErgoLoader} from "../pages/backoffice/avaliacoes-ergo/edit";
import ProfessorUsersList from "../pages/ergo-activities/professor";
import EditProfessorUserPage, {editProfessorUserLoader} from "../pages/ergo-activities/professor/edit";
import CreateProfessorUserPage from "../pages/ergo-activities/professor/create";
import AulasList from "../pages/ergo-activities/aulas";
import EditAulaPage from "../pages/ergo-activities/aulas/edit";
import CrateAulaPage, {createAulaLoader} from "../pages/ergo-activities/aulas/create";
import TreinamentosList from "../pages/ergo-activities/treinamentos";
import CreateTreinamentoPage from "../pages/ergo-activities/treinamentos/create";
import EditTreinamentoPage from "../pages/ergo-activities/treinamentos/edit";
import TurmasList from "../pages/ergo-activities/turmas";
import EditTurmaPage from "../pages/ergo-activities/turmas/edit";
import CreateTurmaPage from "../pages/ergo-activities/turmas/create";
import TurmaParticipantesList, {
    viewTurmaParticipantesLoader
} from "../pages/ergo-activities/turmas/lista-participantes";
import CreateTurmaParticipantePage, {
    createTurmaParticipanteLoader
} from "../pages/ergo-activities/turmas/lista-participantes/create";
import AulaChamadaPage, {aulaChamadaLoader} from "../pages/ergo-activities/aulas/chamada";
import CreateParticipantePage from "../pages/ergo-activities/participantes/create";
import ParticipantesList from "../pages/ergo-activities/participantes";
import EditParticipantePage, {editParticipanteLoader} from "../pages/ergo-activities/participantes/edit";
import TurmasRelatoriosList from "../pages/ergo-activities/relatorios/turma";
import TurmaRelatorio, {viewTurmaRelatorioLoader} from "../pages/ergo-activities/turmas/relatorio";
import TurmaRelatorioPresencaPage from "../pages/ergo-activities/turmas/relatorio/presenca";
import ListaRelatoriosTreinamentos from "../pages/ergo-activities/turmas/relatorio/relatorio-geral-cliente";
import NormasRegulamentativasList from "../pages/backoffice/normas-regulamentativas";
import CreateNormaRegulamentativaPage from "../pages/backoffice/normas-regulamentativas/create";
import UpdateNormaRegulamentativaPage, {
    editNormaRegulamentativaLoader
} from "../pages/backoffice/normas-regulamentativas/update";
import ChangePasswordPage from "../pages/password";
import ViewAvaliacaoErgoPage, {viewAvaliacaoErgoLoader} from "../pages/backoffice/avaliacoes-ergo/details";
import ProfessoresRelatorioPage from "../pages/ergo-activities/turmas/relatorio/professores";
import PesquisasSaudeList from "../pages/pesquisa-saude";
import DetailsPesquisaSaude from "../pages/pesquisa-saude/details";
import CreatePesquisaSaudePage from "../pages/pesquisa-saude/create";
import AgradecimentoPesquisaSaudePage from "../pages/pesquisa-saude/sucesso";
import DashboardChart from "../pages/backoffice/dashboard/treinamentos";
import DashboardSaudeChart from "../pages/backoffice/dashboard/saude";

export const adminRoutes = (profile: string, isLogged: boolean, username: string) => createBrowserRouter(createRoutesFromElements(<>
    <Route path="login" element={<Login/>}/>
    <Route path="home" element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><Home/></MasterContainer>}/>
    <Route path="/" element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><Home/></MasterContainer>}/>
    <Route path="backoffice/users"
           element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><BackofficeUsersList/></MasterContainer>}/>
    <Route
        path="backoffice/users/edit/:id"
        element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><EditBackofficeUserPage/></MasterContainer>}/>
    loader={editUserLoader}
    <Route path="backoffice/users/create" element={<MasterContainer profile={profile} username={username}
                                                                    isLogged={isLogged}><CreateBackofficeUserPage/></MasterContainer>}/>
    <Route path="backoffice/clients"
           element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><ClientsList/></MasterContainer>}/>
    <Route
        path="backoffice/clients/edit/:id"
        element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><EditClientPage/></MasterContainer>}
        loader={editClientLoader}
    />
    <Route path="backoffice/clients/create"
           element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><CreateClientPage/></MasterContainer>}/>
    <Route path="backoffice/collectors"
           element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><CollectorsUserList/></MasterContainer>}/>
    <Route
        id={'collectors-edit'}
        path="backoffice/collectors/edit/:id"
        element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><EditCollectorUserPage/></MasterContainer>}
        loader={editCollectorLoader}
    />
    <Route path="backoffice/collectors/create" element={<MasterContainer profile={profile} username={username}
                                                                         isLogged={isLogged}><CreateCollectorUserPage/></MasterContainer>}/>
    <Route path="backoffice/coleta-ergo/create"
           element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><CreateColetaErgoPage/></MasterContainer>}/>
    <Route
        path="backoffice/coleta-ergo/edit/:id"
        element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><EditColetaErgoPage/></MasterContainer>}
        loader={editColetasLoader}
    />
    <Route
        path="backoffice/coleta-ergo/details/:id"
        element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><DetailsColetaErgo/></MasterContainer>}
        loader={viewColetaErgoLoader}
    />
    <Route path="backoffice/coleta-ergo/"
           element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><ColetasErgoList/></MasterContainer>}/>
    <Route
        path="backoffice/coleta-ergo/edit/:id"
        element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><EditColetaErgoPage/></MasterContainer>}
        loader={editColetasLoader}
    />

    <Route path="backoffice/operators"
           element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><OperatorUserList/></MasterContainer>}/>
    <Route path="backoffice/operators/create" element={<MasterContainer profile={profile} username={username}
                                                                        isLogged={isLogged}><CreateOperatorUserPage/></MasterContainer>}/>
    <Route
        path="backoffice/operators/edit/:id"
        element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><EditOperatorUserPage/></MasterContainer>}
        loader={editOperatorLoader}
    />

    <Route path="backoffice/solucoes-ergo"
           element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><SolucoesErgoList/></MasterContainer>}/>
    <Route path="backoffice/solucoes-ergo/create"
           element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><CreateSolucaoErgoPage/></MasterContainer>}/>
    <Route
        path="backoffice/solucoes-ergo/edit/:id"
        element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><UpdateSolucaoErgoPage/></MasterContainer>}
        loader={editSolucaoErgoLoader}
    />
    <Route
        path="backoffice/solucoes-ergo/details/:id"
        element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><ViewSolucaoErgoPage/></MasterContainer>}
        loader={viewSolucaoErgoLoader}
    />

    <Route path="backoffice/avaliacoes-ergo" element={<MasterContainer profile={profile} username={username}
                                                                       isLogged={isLogged}><AvaliacoesErgonomicasList/></MasterContainer>}/>

    <Route path="backoffice/avaliacoes-ergo/create" element={<MasterContainer profile={profile} username={username}
                                                                              isLogged={isLogged}><CreateAvaliacaoErgoPage/></MasterContainer>}/>
    <Route
        path="backoffice/avaliacoes-ergo/edit/:id"
        element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><EditAvaliacaoErgoPage/></MasterContainer>}
        loader={editAvaliacaoErgoLoader}
    />

    <Route
        path="backoffice/avaliacoes-ergo/details/:id"
        element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><ViewAvaliacaoErgoPage/></MasterContainer>}
        loader={viewAvaliacaoErgoLoader}
    />

    <Route path="treinamentos/professor"
           element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><ProfessorUsersList/></MasterContainer>}/>
    <Route
        path="treinamentos/professor/edit/:id"
        element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><EditProfessorUserPage/></MasterContainer>}
        loader={editProfessorUserLoader}
    />
    <Route path="treinamentos/professor/create" element={<MasterContainer profile={profile} username={username}
                                                                             isLogged={isLogged}><CreateProfessorUserPage/></MasterContainer>}/>


    <Route path="treinamentos/aulas"
           element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><AulasList/></MasterContainer>}
    />

    <Route
        path="treinamentos/aulas/edit/:aulaId"
        element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><EditAulaPage/></MasterContainer>}
    />

    <Route
        path="treinamentos/aulas/chamada/:id"
        element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><AulaChamadaPage/></MasterContainer>}
        loader={aulaChamadaLoader}
    />

    <Route path="treinamentos/aulas/create/:turmaId"
           loader={createAulaLoader}
           element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><CrateAulaPage/></MasterContainer>}/>

    <Route path="treinamentos/treinamentos"
           element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><TreinamentosList/></MasterContainer>}/>
    <Route
        path="treinamentos/treinamentos/edit/:treinamentoId"
        element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><EditTreinamentoPage/></MasterContainer>}
    />
    <Route path="treinamentos/treinamentos/create"
           element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><CreateTreinamentoPage/></MasterContainer>}/>


    <Route path="treinamentos/turmas"
           element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><TurmasList/></MasterContainer>}/>
    <Route
        path="treinamentos/turmas/edit/:turmaId"
        element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><EditTurmaPage/></MasterContainer>}
    />
    <Route path="treinamentos/turmas/create"
           element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><CreateTurmaPage/></MasterContainer>}
    />


    <Route path="treinamentos/turmas/:id/relatorio"
           element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><TurmaRelatorio/></MasterContainer>}
           loader={viewTurmaRelatorioLoader}/>

    <Route path="treinamentos/turmas/:turmaId/relatorio/presenca"
           element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><TurmaRelatorioPresencaPage/></MasterContainer>}
    />
    <Route path="treinamentos/turmas/:id/lista-participantes"
           element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><TurmaParticipantesList/></MasterContainer>}
           loader={viewTurmaParticipantesLoader}
    />
    <Route path="treinamentos/turmas/:id/lista-participantes/create" element={<MasterContainer profile={profile} username={username}
                                                                                              isLogged={isLogged}><CreateTurmaParticipantePage/></MasterContainer>}
           loader={createTurmaParticipanteLoader}/>

    <Route path="treinamentos/participantes/create" element={<MasterContainer profile={profile} username={username}
                                                                                 isLogged={isLogged}><CreateParticipantePage/></MasterContainer>}/>
    <Route path="treinamentos/participantes"
           element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><ParticipantesList/></MasterContainer>}
    />

    <Route
        path="treinamentos/participantes/edit/:id"
        element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><EditParticipantePage/></MasterContainer>}
        loader={editParticipanteLoader}
    />

    <Route path="treinamentos/relatorios/turma"
           element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><TurmasRelatoriosList/></MasterContainer>}
    />

    <Route path="treinamentos/relatorios/professores"
           element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><ProfessoresRelatorioPage/></MasterContainer>}
    />



    <Route
        //TODO: Defininco o caminho do link;
        path="treinamentos/relatorios/clients/"
        element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><ListaRelatoriosTreinamentos/></MasterContainer>}
    />

    <Route path="backoffice/normas-regulamentadoras"
           element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><NormasRegulamentativasList/></MasterContainer>}/>
    <Route path="backoffice/normas-regulamentadoras/create"
           element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><CreateNormaRegulamentativaPage/></MasterContainer>}/>
    <Route
        path="backoffice/normas-regulamentadoras/edit/:id"
        element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><UpdateNormaRegulamentativaPage/></MasterContainer>}
        loader={editNormaRegulamentativaLoader}
    />

    <Route path="backoffice/password"
           element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><ChangePasswordPage/></MasterContainer>}/>

    <Route path="backoffice/pesquisa-saude"
           element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><PesquisasSaudeList/></MasterContainer>}/>

    <Route
        path="backoffice/pesquisa-saude/visualizar/:pesquisaId"
        element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><DetailsPesquisaSaude/></MasterContainer>}
    />

    <Route
        path="pesquisa-saude/responder"
        element={<CreatePesquisaSaudePage/>}/>

    <Route
        path="pesquisa-saude/sucesso"
        element={<AgradecimentoPesquisaSaudePage/>}/>

    <Route
        path="treinamentos/dashboard/turmas"
        element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><DashboardChart/></MasterContainer>}
        />

    <Route
        path="treinamentos/dashboard/saude"
        element={<MasterContainer profile={profile} username={username} isLogged={isLogged}><DashboardSaudeChart/></MasterContainer>}
    />
</>));
