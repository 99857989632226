import instance from "../../index";
import {CreateTurmaRequest, CreateTurmaResponse} from "../turmas/create-turma";

export default CreateTreinamento

async function CreateTreinamento(request: Partial<CreateTreinamentoRequest>): Promise<CreateTreinamentoResponse> {
    const response = await instance.post('/v1/backoffice/treinamentos', request)
    return response?.data?.data;
}

export interface CreateTreinamentoRequest {
    name: string
    description: string
}

export interface CreateTreinamentoResponse {
    id: string
    name: string
    description: string

}

export interface CreateTreinamentoResponseData {
    data: CreateTreinamentoResponse
    success: boolean

}