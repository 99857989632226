import React, {useEffect, useState} from 'react';
import {
    Button,
    Dropdown,
    Header,
    Icon,
    Input,
    Label,
    Message,
    Pagination,
    Segment,
    Select,
    Table
} from 'semantic-ui-react';
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import GetOperators from "../../../services/backoffice/operators/get-operators";
import ActiveOperator from "../../../services/backoffice/operators/active-operator";
import DeactiveOperator from "../../../services/backoffice/operators/deactive-operator";
import DeleteOperator from "../../../services/backoffice/operators/delete-operator";
import GetClients, {GetAllClientsToDropdown} from "../../../services/backoffice/clients/get-clients";
import {OperatorFilter} from "../../../filters/operator-filter";
import {useBlockUI} from "../../../shared/services/block-ui-service";

const OperatorUserList = () => {
    const [operators, setOperators] = useState<any[]>([]);
    const [error, setError] = useState<string>();
    const [clients, setClients] = useState<any[]>([]);
    //@ts-ignore
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const [filter, setFilter] = useState<OperatorFilter>()
    const [totalPages, setTotalPages] = useState()
    const [showFilters, setShowFilters] = useState(false);
    const [clientes, setClientes] = useState<any[]>([]);

    const blockUI = useBlockUI();
    useEffect(() => {
        blockUI?.setActive(loading)
    }, [loading]);

    const toggleFilters = () => {
        setShowFilters(!showFilters);
    }
    useEffect(() => {
        listOperators();
    }, [filter]);

    useEffect(() => {
        function ListClients() {
            GetClients().then((response) => {
                setClients(response.items);
            })
                .catch(error => {
                    console.warn(error);
                })
        }

        ListClients()
    }, [])


    function setFilterAndResetPage(newFilter: any) {
        setFilter({...newFilter, Page: 1})
    }

    async function listOperators() {
        try {
            setLoading(true);

            const response = await GetOperators(filter);
            const responseData = response?.data?.data;

            setTotalPages(responseData.totalPages);
            setOperators(responseData.items);

            setError('');
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        function ListClientes() {
            GetAllClientsToDropdown().then((response) => {
                setClientes(response);
            })
                .catch(error => {
                    console.warn(error);
                })
        }

        ListClientes()
    }, []);

    function getClientesNome(clients: string[]) {
        const nomeClientes = clients.map((id) => {
            const cliente = clientes.find((item) => item.key === id);
            return cliente ? cliente.text : "";
        });
        return nomeClientes.join(', ');
    }

    const clientOptions = clients.map((client) => ({
        key: client.id,
        value: client.id,
        text: client.fantasyName,
    }));


    const toggleActivationCollector = async (operator: any) => {
        try {
            setLoading(true);

            const result = window.confirm(`Tem certeza que deseja ${operator.isActive ? 'desativar' : 'ativar'} o operador ${operator.firstName} ${operator?.lastName}?`);
            if (!result) {
                setLoading(false);
                return;
            }

            if (operator) {
                const result = !operator.isActive ? await ActiveOperator(operator.id) : await DeactiveOperator(operator.id);
                if (result.success) {
                    toast.success((`O Operador foi ${operator.isActive ? 'desativado' : 'ativado'} com sucesso`), {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setTimeout(() => {
                        setError('')
                    }, 3000);
                    await listOperators();
                }
            }

            setLoading(false);
        } catch (error: any) {
            toast.warn(`Falha ao ${operator.isActive ? 'desativar' : 'ativar'} operador`, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setLoading(false);
            setError(error.message);
        }
    };


    async function deleteOperator(operator: any) {
        try {
            const confirm = window.confirm(`Tem certeza que deseja apagar o usuário ${operator.firstName} ${operator.lastName}?`);
            if (confirm) {
                setLoading(true);

                if (operator) {
                    await DeleteOperator(operator.id);
                    toast.success('Operador deletado com sucesso', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    await listOperators();
                }

                setLoading(false);
            }
        } catch (error: any) {
            toast.warn(`Falha ao deletar Operador`, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setLoading(false);
            setError(error.message);
        }
    }


    return (
        <div>
            <Header as="h2" textAlign="center" color={'black'}>
                Operadores
            </Header>
            {error && <Message
                floating
                style={{
                    position: 'fixed',
                    top: 0,
                    left: '50%',
                    transform: 'translateX(10%)',
                    zIndex: 9999,
                }}
            >
                <p>{error}</p>
            </Message>}
            <Table.Row>
                <Table.HeaderCell>
                    <Button color="blue"
                            onClick={() => navigate('/backoffice/operators/create/')}>
                        <Icon name='plus'/> Novo
                    </Button>
                </Table.HeaderCell>
                <Table.HeaderCell colSpan="4">
                    <Button onClick={toggleFilters} primary>
                        <Icon name={showFilters ? 'hide' : 'unhide'}/>
                        {showFilters ? 'Ocultar filtros' : 'Exibir filtros'}
                    </Button>
                </Table.HeaderCell>
            </Table.Row>{showFilters && (
            <Segment>
                <div style={{padding: 10}}>
                    <div>
                        <Input
                            fluid
                            icon="search"
                            type="text"
                            placeholder="Nome do Operador"
                            value={filter?.Name ?? ''}
                            onChange={event =>
                                setFilterAndResetPage({...filter, Name: event.target?.value})
                            }
                        />
                    </div>
                    <br/>
                    <div>
                        <Input
                            fluid
                            icon="search"
                            type="text"
                            placeholder="E-mail"
                            value={filter?.Email ?? ''}
                            onChange={event =>
                                setFilterAndResetPage({...filter, Email: event.target?.value})
                            }
                        />
                    </div>
                    <br/>
                    <div>
                        <Select clearable
                                placeholder="Selecione um cliente"
                                fluid
                                search
                                selection
                                closeOnEscape
                                options={clientOptions}
                                value={filter?.ClientId}
                                onChange={(event, data) =>
                                    //@ts-ignore
                                    setFilterAndResetPage({...filter, ClientId: data?.value})
                                }
                        />
                    </div>
                </div>
            </Segment>
        )}

            <Table color={"orange"}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>#</Table.HeaderCell>
                        <Table.HeaderCell>Nome</Table.HeaderCell>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                        <Table.HeaderCell>Cliente</Table.HeaderCell>
                        <Table.HeaderCell>Criado em</Table.HeaderCell>
                        <Table.HeaderCell>Ativo</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {operators?.map((operator) => (
                        <Table.Row key={operator.id}>
                            <Table.Cell>
                                <Dropdown item icon={'bars'}>
                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            color="blue"
                                            text={'Editar'}
                                            onClick={() => navigate('/backoffice/operators/edit/' + operator.id)}
                                        >
                                            <Icon name='edit' color='blue'/>
                                            Editar
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            text={'Deletar'}
                                            onClick={() => deleteOperator(operator)}
                                        >
                                            <Icon name='trash' color={'red'}/>
                                            Deletar
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            color={operator.isActive ? 'red' : 'green'}
                                            text={operator.isActive ? 'Desativar' : 'Ativar'}
                                            onClick={() => toggleActivationCollector(operator)}
                                        >
                                            <Icon name={operator.isActive ? 'power off' : 'power'}
                                                  color={operator.isActive ? 'red' : 'green'}/>
                                            {operator.isActive ? 'Desativar' : 'Ativar'}
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Table.Cell>

                            <Table.Cell>{`${operator.firstName} ${operator?.lastName ?? ''}`}</Table.Cell>
                            <Table.Cell>{operator.email}</Table.Cell>
                            <Table.Cell>{getClientesNome(operator?.clients)}</Table.Cell>
                            <Table.Cell>{moment(operator.createdAt).format('DD/MM/YY HH:mm')}</Table.Cell>
                            <Table.Cell>
                                <Label key={operator.id}
                                       color={!operator.isActive ? 'red' : 'green'}>{operator.isActive ? 'Sim' : 'Não'}</Label>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <Pagination totalPages={totalPages ?? 0}
                        defaultActivePage={filter?.Page ?? 1}
                        onPageChange={(e, {activePage}) => {
                            setFilter({...filter, Page: activePage as number})
                        }
                        }>
            </Pagination>
        </div>
    );
};

export default OperatorUserList;
