import {PesquisaSaudeParticipantes} from "../../../models/pesquisaSaudeParticipantes";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useBlockUI} from "../../../shared/services/block-ui-service";
import GetAulaById from "../../../services/sistema-aulas/aulas/get-aula";
import GetPesquisaSaudeParticipanteById from "../../../services/sistema-aulas/pesquisa-saude/get-pesquisa";
import {Button, Form, Grid, Header, Segment, Table} from "semantic-ui-react";
import {
    EPerguntaOqueSeAplicouParaVoceNaUltimaSemana
} from "../../../enums/EPerguntaOqueSeAplicouParaVoceNaUltimaSemana";
import { EPerguntaDesconfortoUltimosDiasPesquisaSaude } from "enums/EPerguntaDesconfortoUltimosDiasPesquisaSaude";
import { EPerguntaSobreAmbienteDeTrabalhoPesquisaSaude } from "enums/EPerguntaSobreAmbienteDeTrabalhoPesquisaSaude";

const DetailsPesquisaSaude = () => {
    const [pesquisa, setPesquisa] = useState<PesquisaSaudeParticipantes>();
    const[loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const { pesquisaId } = useParams();
    const blockUI = useBlockUI();

    useEffect(() => {
        if (pesquisaId) {
            const loadPesquisaDetails = async () => {
                try {
                    setLoading(true);
                    const response = await GetPesquisaSaudeParticipanteById(pesquisaId);
                    setPesquisa(response);
                } catch (error:any) {
                    setError(error.message);
                } finally {
                    setLoading(false);
                }
            };
            loadPesquisaDetails();
        }
    }, [pesquisaId]);

    useEffect(() => {
        blockUI?.setActive(loading)
    }, [loading]);
    return (
        <div>

            <Grid textAlign='center' style={{height: '200'}} verticalAlign='middle' centered >
                <Grid.Column style={{maxWidth: 800}} color={'orange'} >
                    <Segment>
                        <Table.HeaderCell>
                            <Button content="Voltar para lista de pesquisas"
                                    icon='arrow left'
                                    onClick={() =>
                                        navigate('/backoffice/pesquisa-saude')} grey/>
                        </Table.HeaderCell>
                        <img src={'/logotipo_retangulo.png'} style={{
                            maxHeight: '10rem',
                            objectFit: 'contain',
                            marginTop: 0,
                            marginBottom: -30,
                            paddingBottom: 5,
                            alignSelf: 'center',
                            width: '100%',
                        }} alt={'superativa'}/>

                        <Header textAlign={"center"} as={"h2"}>Pesquisa de Nível de Saúde - Ergonomia organizacional,
                            cognitiva e comportamental.</Header>
                        <Form
                              warning
                              error>
                            <Header as="h4" textAlign="center" color={'black'}>
                                Essa pesquisa tem como objetivo levantamento de dados de saúde, para conhecermos o super time Bee2be
                                de forma individual para projeção de programas de saúde.
                            </Header>
                            <br/>
                            <Form.Field>
                            <Form.Input
                                label={'Qual a Empresa onde trabalha?'}
                                name={"nomeCliente"}
                                placeholder='Selecione uma opção'
                                value={pesquisa?.nomeCliente}
                                required
                                readOnly
                            />
                        </Form.Field>

                            <Form.Group widths="equal" stackable>
                                <Form.Field>
                                    <Form.Input
                                        label={'Escreva aqui seu nome completo'}
                                        name="nomeParticipante"
                                        type="text"
                                        placeholder="Nome"
                                        value={pesquisa?.nomeParticipante}
                                        required
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <Form.Input
                                        label={'Escreva aqui seu email'}
                                        name="emailParticipante"
                                        type="text"
                                        placeholder="Email"
                                        value={pesquisa?.emailParticipante}
                                    />
                                </Form.Field>
                            </Form.Group>

                            <Form.Group widths="equal">
                                <Form.Field>
                                    <Form.Input
                                        label={'Qual sua função no time?'}
                                        name="funcaoNoTime"
                                        type="text"
                                        placeholder="Função"
                                        value={pesquisa?.funcaoNoTime}
                                        readOnly
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <Form.Input
                                        label={'Qual o seu setor de trabalho?'}
                                        name="setorTrabalhado"
                                        type="text"
                                        placeholder="Setor"
                                        value={pesquisa?.setorTrabalhado}
                                        readOnly
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group widths="equal">
                                <Form.Field>
                                    <Form.Input
                                        label={'Escreva aqui sua idade:'}
                                        name="idade"
                                        type="number"
                                        placeholder="Ex: 30"
                                        value={pesquisa?.idade}
                                        readOnly
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <Form.Input
                                        label={'Qual sua altura em cm? (Exemplo: 165)'}
                                        name="altura"
                                        type="number"
                                        placeholder="Altura"
                                        value={pesquisa?.altura}
                                        readOnly
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <Form.Input
                                        label={'Qual seu peso em Kg? (Exemplo: 80)'}
                                        name="peso"
                                        type="number"
                                        placeholder="Peso"
                                        value={pesquisa?.peso}
                                        readOnly
                                    />
                                </Form.Field>
                            </Form.Group>


                            <br/>
                            <Form.Field>
                                <Form.Input
                                    label={'Quais as suas TAREFAS/ATIVIDADES PRINCIPAIS de trabalho?'}
                                    name="tarefasPrincipaisTrabalho"
                                    type="text"
                                    placeholder="Sua resposta"
                                    value={pesquisa?.tarefasPrincipaisTrabalho}
                                    readOnly
                                />
                            </Form.Field>
                            <br/>
                            <Form.Field>
                                <Header as="h5" textAlign="left" color={'black'}>
                                    Você pratica exercício físico?
                                </Header>

                                <Form.Checkbox type="checkbox"
                                               label={"Pratico exercícios diariamente\n"}
                                               id={"praticoExerciciosDiariamentePerguntaExercicioFisico"}
                                               checked={pesquisa?.praticoExerciciosDiariamentePerguntaExercicioFisico}
                                               readOnly/>

                                <Form.Checkbox type="checkbox"
                                               label={"Pratico exercícios 3x na semana\n"}
                                               id={"praticoExercicios3VezesNaSemanaPerguntaExercicioFisico"}
                                               checked={pesquisa?.praticoExercicios3VezesNaSemanaPerguntaExercicioFisico}readOnly/>

                                <Form.Checkbox type="checkbox"
                                               label={"Pratico exercícios 2x na semana\n"}
                                               id={"praticoExercicios2VezesNaSemanaPerguntaExercicioFisico"}
                                               checked={pesquisa?.praticoExercicios2VezesNaSemanaPerguntaExercicioFisico}readOnly/>

                                <Form.Checkbox type="checkbox"
                                               label={"Não pratico exercícios\n"}
                                               id={"naoPraticoExerciciosFísicosPerguntaExercicioFisico"}
                                               checked={pesquisa?.naoPraticoExerciciosFísicosPerguntaExercicioFisico}
                                               readOnly/>

                            </Form.Field>
                            <br/>
                            <Form.Field>
                                <Header as="h5" textAlign="left" color={'black'}>
                                    Você tem algum hábito que não te gera saúde?
                                </Header>

                                <Form.Checkbox type="checkbox"
                                               label={"Fumante"}
                                               id={"fumantePerguntaHabitoQueNaoGeraSaude"}
                                               checked={pesquisa?.fumantePerguntaHabitoQueNaoGeraSaude}
                                               readOnly/>

                                <Form.Checkbox type="checkbox"
                                               label={"Não ter momento de relaxamento\n"}
                                               id={"naoTerMomentoRelaxamentoPerguntaHabitoQueNaoGeraSaude"}
                                               checked={pesquisa?.naoTerMomentoRelaxamentoPerguntaHabitoQueNaoGeraSaude}
                                               readOnly/>

                                <Form.Checkbox type="checkbox"
                                               label={"Uso de bebidas alcólicas\n"}
                                               id={"usoBebidasAlcoolicasPerguntaHabitoQueNaoGeraSaude"}
                                               checked={pesquisa?.usoBebidasAlcoolicasPerguntaHabitoQueNaoGeraSaude}
                                               readOnly/>

                                <Form.Checkbox type="checkbox"
                                               label={"Rede social em excesso\n"}
                                               id={"redesSociaisEmExcessoPerguntaHabitoQueNaoGeraSaude"}
                                               checked={pesquisa?.redesSociaisEmExcessoPerguntaHabitoQueNaoGeraSaude}
                                               readOnly/>

                                <Form.Checkbox type="checkbox"
                                               label={"Negligenciar a alimentação\n"}
                                               id={"negligenciarAlimentacaoPerguntaHabitoQueNaoGeraSaude"}
                                               checked={pesquisa?.negligenciarAlimentacaoPerguntaHabitoQueNaoGeraSaude}
                                               readOnly/>

                                <Form.Checkbox type="checkbox"
                                               label={"Não"}
                                               id={"naoTenhoHabitosPrejudiciaisPerguntaHabitoQueNaoGeraSaude"}
                                               checked={pesquisa?.naoTenhoHabitosPrejudiciaisPerguntaHabitoQueNaoGeraSaude}
                                               readOnly/>

                                <Form.Input
                                    label={'Outro'}
                                    name="outroPerguntaHabitoQueNaoGeraSaude"
                                    type="text"
                                    placeholder="Outro"
                                    value={pesquisa?.outroPerguntaHabitoQueNaoGeraSaude}
                                    readOnly
                                />
                            </Form.Field>
                           <br/>
                            <Form.Field>
                                <Header as="h5" textAlign="left" color={'black'}>
                                    Com relação ao ambiente de trabalho, você se sente incomodado com o:
                                </Header>
                                <Form.Group widths="equal">
                                    <Form.Field>
                                        <label>Ruído</label>
                                        <Form.Select
                                            options={EPerguntaSobreAmbienteDeTrabalhoPesquisaSaude}
                                            placeholder='Selecione uma opção'
                                            value={pesquisa?.ruidoPerguntaIncomodoAmbienteTrabalho}
                                            readOnly
                                        />
                                    </Form.Field>

                                    <Form.Field>
                                        <label>Temperatura</label>
                                        <Form.Select
                                            options={EPerguntaSobreAmbienteDeTrabalhoPesquisaSaude}
                                            placeholder='Selecione uma opção'
                                            value={pesquisa?.temperaturaPerguntaIncomodoAmbienteTrabalho}
                                            readOnly
                                        />
                                    </Form.Field>

                                    {/* Adicione mais Form.Fields conforme necessário */}
                                </Form.Group>

                                <Form.Group widths="equal">
                                    <Form.Field>
                                        <label>Iluminação</label>
                                        <Form.Select
                                            options={EPerguntaSobreAmbienteDeTrabalhoPesquisaSaude}
                                            placeholder='Selecione uma opção'
                                            value={pesquisa?.iluminacaoPerguntaIncomodoAmbienteTrabalho}
                                            readOnly
                                        />
                                    </Form.Field>

                                    <Form.Field>
                                        <label>Umidade</label>
                                        <Form.Select
                                            options={EPerguntaSobreAmbienteDeTrabalhoPesquisaSaude}
                                            placeholder='Selecione uma opção'
                                            value={pesquisa?.umidadePerguntaIncomodoAmbienteTrabalho}
                                            readOnly
                                        />
                                    </Form.Field>

                                    {/* Adicione mais Form.Fields conforme necessário */}
                                </Form.Group>

                                <Form.Group widths="equal">
                                    <Form.Field>
                                        <label>Cadeira</label>
                                        <Form.Select
                                            options={EPerguntaSobreAmbienteDeTrabalhoPesquisaSaude}
                                            placeholder='Selecione uma opção'
                                            value={pesquisa?.cadeiraPerguntaIncomodoAmbienteTrabalho}
                                            readOnly
                                        />
                                    </Form.Field>

                                    <Form.Field>
                                        <label>Mesa</label>
                                        <Form.Select
                                            options={EPerguntaSobreAmbienteDeTrabalhoPesquisaSaude}
                                            placeholder='Selecione uma opção'
                                            value={pesquisa?.mesaPerguntaIncomodoAmbienteTrabalho}
                                            readOnly

                                        />
                                    </Form.Field>

                                    {/* Adicione mais Form.Fields conforme necessário */}
                                </Form.Group>


                                <Form.Select
                                    label={'Equipamentos de trabalho (computador, notebook)\t\n'}
                                    options={EPerguntaSobreAmbienteDeTrabalhoPesquisaSaude}
                                    placeholder='Selecione uma opção'
                                    value={pesquisa?.equipamentosDeTrabalhoPerguntaIncomodoAmbienteTrabalho}
                                    readOnly
                                />
                            </Form.Field>
                            <br/>
                            <Form.Field>
                                <Header as="h5" textAlign="left" color={'black'}>
                                    Nos últimos 7 dias você sentiu alguma dor/desconforto? Se sim,
                                    avalie a intensidade dessa dor em cada parte do corpo a partir da
                                    escala a seguir: sendo 0 nenhuma dor e 10 a pior dor que já sentiu na sua vida:
                                </Header>

                                <img src={'/charts/escala_dor.png'} style={{
                                    objectFit: 'contain',
                                    marginTop: 10,
                                    marginBottom: 10,
                                    padding: 20,
                                    alignSelf: 'center',
                                    width: '100%',
                                }} alt={'matriz de risco'}/>

                                <Form.Group widths="equal">
                                    <Form.Field>
                                        <label>Desconforto ou dores na cabeça</label>
                                        <Form.Select
                                            options={EPerguntaDesconfortoUltimosDiasPesquisaSaude}
                                            placeholder='Selecione uma opção'
                                            value={pesquisa?.cabecaPerguntaDesconfortoUltimosDias}
                                        />
                                    </Form.Field>

                                    <Form.Field>
                                        <label>Desconforto ou dores no pescoço</label>
                                        <Form.Select
                                            options={EPerguntaDesconfortoUltimosDiasPesquisaSaude}
                                            placeholder='Selecione uma opção'
                                            value={pesquisa?.pescocoPerguntaDesconfortoUltimosDias}
                                        />
                                    </Form.Field>
                                </Form.Group>

                                <Form.Group widths="equal">
                                    <Form.Field>
                                        <label>Desconforto ou dores nos ombros</label>
                                        <Form.Select
                                            options={EPerguntaDesconfortoUltimosDiasPesquisaSaude}
                                            placeholder='Selecione uma opção'
                                            value={pesquisa?.ombroPerguntaDesconfortoUltimosDias}
                                        />
                                    </Form.Field>

                                    <Form.Field>
                                        <label>Desconforto ou dores nos braços</label>
                                        <Form.Select
                                            options={EPerguntaDesconfortoUltimosDiasPesquisaSaude}
                                            placeholder='Selecione uma opção'
                                            value={pesquisa?.bracosPerguntaDesconfortoUltimosDias}
                                        />
                                    </Form.Field>

                                </Form.Group>

                                <Form.Group widths="equal">
                                    <Form.Field>
                                        <label>Desconforto ou dores nos punhos</label>
                                        <Form.Select
                                            options={EPerguntaDesconfortoUltimosDiasPesquisaSaude}
                                            placeholder='Selecione uma opção'
                                            value={pesquisa?.punhosPerguntaDesconfortoUltimosDias}
                                        />
                                    </Form.Field>

                                    <Form.Field>
                                        <label>Desconforto ou dores nas mãos</label>
                                        <Form.Select
                                            options={EPerguntaDesconfortoUltimosDiasPesquisaSaude}
                                            placeholder='Selecione uma opção'
                                            value={pesquisa?.maosPerguntaDesconfortoUltimosDias}
                                        />
                                    </Form.Field>

                                </Form.Group>


                                <Form.Group widths="equal">
                                    <Form.Field>
                                        <label>Desconforto ou dores na coluna</label>
                                        <Form.Select
                                            options={EPerguntaDesconfortoUltimosDiasPesquisaSaude}
                                            placeholder='Selecione uma opção'
                                            value={pesquisa?.colunaPerguntaDesconfortoUltimosDias}
                                        />
                                    </Form.Field>

                                    <Form.Field>
                                        <label>Desconforto ou dores no quadril</label>
                                        <Form.Select
                                            options={EPerguntaDesconfortoUltimosDiasPesquisaSaude}
                                            placeholder='Selecione uma opção'
                                            value={pesquisa?.quadrilPerguntaDesconfortoUltimosDias}
                                        />
                                    </Form.Field>

                                </Form.Group>

                                <Form.Group widths="equal">
                                    <Form.Field>
                                        <label>Desconforto ou dores nas coxas</label>
                                        <Form.Select
                                            options={EPerguntaDesconfortoUltimosDiasPesquisaSaude}
                                            placeholder='Selecione uma opção'
                                            value={pesquisa?.coxasPerguntaDesconfortoUltimosDias}
                                        />
                                    </Form.Field>

                                    <Form.Field>
                                        <label>Desconforto ou dores nos joelhos</label>
                                        <Form.Select
                                            options={EPerguntaDesconfortoUltimosDiasPesquisaSaude}
                                            placeholder='Selecione uma opção'
                                            value={pesquisa?.joelhosPerguntaDesconfortoUltimosDias}
                                        />
                                    </Form.Field>

                                </Form.Group>

                                <Form.Group widths="equal">
                                    <Form.Field>
                                        <label>Desconforto ou dores nas pernas</label>
                                        <Form.Select
                                            options={EPerguntaDesconfortoUltimosDiasPesquisaSaude}
                                            placeholder='Selecione uma opção'
                                            value={pesquisa?.pernasPerguntaDesconfortoUltimosDias}
                                        />
                                    </Form.Field>

                                    <Form.Field>
                                        <label>Desconforto ou dores nos tornozelos</label>
                                        <Form.Select
                                            options={EPerguntaDesconfortoUltimosDiasPesquisaSaude}
                                            placeholder='Selecione uma opção'
                                            value={pesquisa?.tornozelosPerguntaDesconfortoUltimosDias}
                                        />
                                    </Form.Field>

                                </Form.Group>

                                <Form.Group widths="equal">
                                    <Form.Field>
                                        <label>Desconforto ou dores nos pés</label>
                                        <Form.Select
                                            options={EPerguntaDesconfortoUltimosDiasPesquisaSaude}
                                            placeholder='Selecione uma opção'
                                            value={pesquisa?.pesPerguntaDesconfortoUltimosDias}
                                        />
                                    </Form.Field>

                                    <Form.Field>
                                    </Form.Field>

                                </Form.Group>

                            </Form.Field>
                            <br/>
                            <Form.Field>
                                <Header as="h5" textAlign="left" color={'black'}>
                                    Leia cuidadosamente e marque o que se aplicou para você durante a sua última semana:
                                </Header>

                                <Form.Select
                                    label={'Achei difícil me acalmar'}
                                    options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                                    placeholder='Selecione uma opção'
                                    value={pesquisa?.acheiDificilMeAcalmarPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                                    readOnly
                                />
                                <Form.Select
                                    label={'Senti minha boca seca'}
                                    options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                                    placeholder='Selecione uma opção'
                                    value={pesquisa?.sentiMinhaBocaSecaPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                                    readOnly
                                />
                                <Form.Select
                                    label={'Não consegui vivenciar nenhum sentimento positivo'}
                                    options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                                    placeholder='Selecione uma opção'
                                    value={pesquisa?.naoConseguiVivenciarNenhumSentimentoPositivoPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                                    readOnly
                                />
                                <Form.Select
                                    label={'Tive dificuldade em respirar em alguns momentos (ex. respiração ofegante)'}
                                    options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                                    placeholder='Selecione uma opção'
                                    value={pesquisa?.tiveDificuldadeEmRespirarEmAlgunsMomentosPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                                    readOnly
                                />
                                <Form.Select
                                    label={'ofegante, falta de ar, sem ter feito nenhum esforço físico)'}
                                    options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                                    placeholder='Selecione uma opção'
                                    value={pesquisa?.ofeganteFaltadeArSemEsforcoFisicoPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                                    readOnly
                                />

                                <Form.Select
                                    label={'Achei difícil ter iniciativa para fazer as coisas'}
                                    options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                                    placeholder='Selecione uma opção'
                                    value={pesquisa?.acheiDificilTerIniciativaParaFazerAsCoisasPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                                    readOnly
                                />
                                <Form.Select
                                    label={'Tive a tendência de reagir de forma exagerada às situações'}
                                    options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                                    placeholder='Selecione uma opção'
                                    value={pesquisa?.tendenciaAReagirDeFormaExageradaAsSituacoesPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                                    readOnly
                                />
                                <Form.Select
                                    label={'Senti tremores (ex. nas mãos)'}
                                    options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                                    placeholder='Selecione uma opção'
                                    value={pesquisa?.sentiTremoresNoCorpoPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                                    readOnly
                                />
                                <Form.Select
                                    label={'Senti que estava sempre nervoso'}
                                    options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                                    placeholder='Selecione uma opção'
                                    value={pesquisa?.sentiQueEstavaSempreNervosoPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                                    readOnly
                                />
                                <Form.Select
                                    label={'Preocupei-me com situações em que eu pudesse entrar em pânico'}
                                    options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                                    placeholder='Selecione uma opção'
                                    value={pesquisa?.preocupeiComSituacoesQuePudesseEntrarEmPanicoPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                                    readOnly
                                />
                                <Form.Select
                                    label={'Senti que não tinha nada a desejar'}
                                    options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                                    placeholder='Selecione uma opção'
                                    value={pesquisa?.sentiQueNaoTinhaNadaADesejarPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                                    readOnly
                                />
                                <Form.Select
                                    label={'Senti-me agitado'}
                                    options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                                    placeholder='Selecione uma opção'
                                    value={pesquisa?.sentimeAgitadoPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                                    readOnly
                                />


                                <Form.Select
                                    label={'Achei difícil relaxar'}
                                    options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                                    placeholder='Selecione uma opção'
                                    value={pesquisa?.acheiDificilRelaxarPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                                    readOnly
                                />

                                <Form.Select
                                    label={'Senti-me depressivo (a) e sem ânimo'}
                                    options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                                    placeholder='Selecione uma opção'
                                    value={pesquisa?.sentimeDepressivoESemAnimoPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                                    readOnly
                                />

                                <Form.Select
                                    label={'Fui intolerante com as coisas que impediam minhas atividades'}
                                    options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                                    placeholder='Selecione uma opção'
                                    value={pesquisa?.fuiIntoleranteComCoisasQueImpediamMinhasAtividadesPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                                    readOnly
                                />

                                <Form.Select
                                    label={'Senti que ia entrar em pânico'}
                                    options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                                    placeholder='Selecione uma opção'
                                    value={pesquisa?.sentiQueIaEntrarEmPanicoPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                                    readOnly
                                />

                                <Form.Select
                                    label={'Não consegui me entusiasmar com nada'}
                                    options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                                    placeholder='Selecione uma opção'
                                    value={pesquisa?.naoConseguiMeEntusiasmarComNadaPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                                    readOnly
                                />

                                <Form.Select
                                    label={'Senti que não tinha valor como pessoa'}
                                    options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                                    placeholder='Selecione uma opção'
                                    value={pesquisa?.sentiQueNaoTinhaValorComoPessoaPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                                    readOnly                                />

                                <Form.Select
                                    label={'Senti que estava um pouco emotivo/sensível demais'}
                                    options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                                    placeholder='Selecione uma opção'
                                    value={pesquisa?.sentiQueEstavaUmPoucoEmotivoSensivelDemaisPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                                    readOnly
                                />

                                <Form.Select
                                    label={'Sabia que meu coração estava alterado mesmo não tendo feito nenhum esforço'}
                                    options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                                    placeholder='Selecione uma opção'
                                    value={pesquisa?.sabiaQueMeuCoracaoEstavaAlteradoMesmoSemEsforcoPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                                    readOnly
                                />

                                <Form.Select
                                    label={'Senti medo sem motivo'}
                                    options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                                    placeholder='Selecione uma opção'
                                    value={pesquisa?.sentiMedoSemMotivoPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                                    readOnly
                                />

                                <Form.Select
                                    label={'Senti que a vida não tinha sentido'}
                                    options={EPerguntaOqueSeAplicouParaVoceNaUltimaSemana}
                                    placeholder='Selecione uma opção'
                                    value={pesquisa?.sentiQueAVidaNaoTinhaSentidoPerguntaMarqueOqueSeAplicouDuranteAUltimaSemana}
                                    readOnly
                                />
                            </Form.Field>
                            <br/>
                            <Form.Field>
                                <label>
                                    Se você pudesse mudar algum aspecto da sua vida, do seu trabalho, de maneira geral, o que mudaria?
                                </label>
                                <Form.TextArea
                                    name="seVocePudesseMudaAlgumAspectoDaSuaVidaTrabalhoOuManeiraGeralOqueSeria"
                                    placeholder="Resposta"
                                    value={pesquisa?.seVocePudesseMudaAlgumAspectoDaSuaVidaTrabalhoOuManeiraGeralOqueSeria}
                                    rows={8}
                                />
                            </Form.Field>
                        </Form>
                    </Segment>
                </Grid.Column>
            </Grid>
        </div>
    );
}

export default DetailsPesquisaSaude;