import instance from "../../index";

export default GetAvaliacaoErgoById

async function GetAvaliacaoErgoById(id: string): Promise<GetAvaliacaoErgoByIdResponse> {
    const response = await instance.get(`/v1/backoffice/avaliacoes-ergo-preliminares/${id}`).catch();
    return response?.data?.data;
}

export interface GetAvaliacaoErgoByIdResponseData {
    id: string,
    createdAt: Date,
    coletaErgoId: string,
    solucoesErgonomicasIds: string[],
    identificacaoVisual: string,
    fontesGeradoras: string,
    parteDoCorpoAtingida: string,
    gravidade: number,
    probabilidade: number,
    classificação: number,
    eliminarSolucaoErgonomica: string,
    prevencaoProtecaoColetiva: string,
    // tipoDeAcao: number,
    outroTipoDeAcao: string,
    exigeInvestimento: number,
    responsavel: string,
    prazoSugerido: number,
    observacoes: string,
    responsavelPelaColetaDeDados: string,
}

export interface GetAvaliacaoErgoByIdResponse {
    success: boolean;
    data: GetAvaliacaoErgoByIdResponseData;
}