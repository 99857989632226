import axios, {AxiosResponse} from 'axios'
import {ApiConfig, ApiErrors} from "config/ApiConfig";
import GetRefreshToken from "services/auth/get-refresh-token";
import SessionStorage from "store/session";
import 'react-toastify/dist/ReactToastify.css';
import {toast} from "react-toastify";

const instance = axios.create({
    baseURL: ApiConfig.API_URL,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    timeout: 120000
})

instance.interceptors.request.use(
    async function (config) {
        let loggedSession = await SessionStorage.getSession();
        let token = loggedSession?.accessToken
        if (
            loggedSession?.isLogged &&
            !(
                config?.url?.endsWith(ApiConfig.REFRESH_TOKEN_ENDPOINT) ||
                config?.url?.endsWith(ApiConfig.CONNECT_ENDPOINT)
            )
        ) {
            const now = new Date(new Date().toISOString().replace(/z/gi, ''))
            const expiration = loggedSession.expiresIn
                ? new Date(loggedSession.expiresIn.replace(/z/gi, ''))
                : undefined
            //Se o token estiver expirado, ele solicita um novo.
            if (expiration && now > expiration) {
                await GetRefreshToken({
                    refreshToken: loggedSession.refreshToken
                })
                    .then(r => {
                        SessionStorage.saveSession(r.data)
                        token = r.data?.accessToken
                        loggedSession.expiresIn = r.data?.expiresIn
                    })
                    .catch(() => {
                        console.warn('[REFRESH_TOKEN] EXPIRADO')
                        SessionStorage.deleteSession()
                        toast.warn("Sua sessão expirou, faça login para continuar")
                        setTimeout(() => {
                            window.location.reload()
                        }, 1200)

                    })
            }
        }
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }
        return config
    },
    function (error: any) {
        return Promise.reject(error)
    },
)

instance.interceptors.response.use(
    async function (response: AxiosResponse) {
        return response
    },

    async function (error) {
        if (error?.code === "ERR_NETWORK") {
            toast.error("Servidor não encontrado. Verifique sua conexão com a internet.");
            return
        }
        if (error?.response?.status === 400) {
            toast.warn(error.response.data.errors[0] || ApiErrors.BAD_REQUEST)
            return Promise.reject(error.response.data.errors[0] || ApiErrors.BAD_REQUEST)
        } else if (error?.response?.status === 404) {
            toast.warn(ApiErrors.NOT_FOUND);
            console.warn(ApiErrors.NOT_FOUND)
        } else if (error?.response?.status === 415) {
            toast.warn(ApiErrors.UNSUPPORTED_MEDIA_TYPE)
        } else if (error?.response?.status === 422) {
            toast.warn(ApiErrors.UNPROCESSABLE_ENTITY)
        } else if (error?.response?.status === 500) {
            toast.warn(ApiErrors.INTERNAL_SERVER_ERROR)
            return Promise.reject(ApiErrors.INTERNAL_SERVER_ERROR)
        } else if (error?.response?.status === 403 || error?.response?.status === 401) {
            await SessionStorage.deleteSession()
            toast.warn("Sessão expirada, favor fazer login novamente.");
            setTimeout(() => {
                window.location.reload()
            }, 1200)
        } else {
            console.error("API_ERR", ApiErrors.DEFAULT_ERROR)
        }
        return Promise.reject(error)
    },
)

export default instance
