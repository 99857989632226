import {useLoaderData, useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Button, Form, Header, Icon, Input, Label, Message, Segment, Select, Table} from "semantic-ui-react";
import GetTurmaById from "../../../../services/sistema-aulas/turmas/get-turma";
import {Turma} from "../../../../models/turma";
import {
    GetTurmaRelatorio, GetTurmaRelatorioPdf,
    GetTurmaRelatorioPresencaPdf
} from "../../../../services/sistema-aulas/turmas/get-turma-relatorio";
import {TurmaRelatorio} from "../../../../models/turmaRelatorio";
import {TurmaRelatorioFilter} from "../../../../models/turmaRelatorioFilter";
import {EMes} from "../../../../enums/eMes";
import {useBlockUI} from "../../../../shared/services/block-ui-service";
import SessionStorage from "../../../../store/session";
import {SessionUser} from "../../../../models/session-user";

export async function viewTurmaRelatorioLoader({params}: any) {
    return await GetTurmaById(params.id);
}

const TurmaRelatorioPage = () => {
    const [relatorio, setRelatorio] = useState<TurmaRelatorio>();
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const [turma, setTurma] = useState<Turma>();
    const loader: any = useLoaderData();
    const [showFilters, setShowFilters] = useState<boolean>(true);
    const [sessionUser, setSessionUser] = useState<SessionUser>();
    const blockUI = useBlockUI();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const mesParam = queryParams.get("mes") ?? (new Date().getMonth() + 1).toString();
    const anoParam = queryParams.get("ano") ?? new Date().getFullYear().toString();
    const [filter, setFilter] = useState<TurmaRelatorioFilter>({
        mes: parseInt(mesParam),
        ano: parseInt(anoParam)
    });


    useEffect(() => {
        async function updateSessionUser() {
            const user = await SessionStorage.getSession();
            setSessionUser(user)
        }
        updateSessionUser()
    }, [])
    useEffect(() => setTurma(loader), [loader])
    useEffect(() => {
        blockUI?.setActive(loading)
    }, [loading, blockUI]);


    useEffect(() => {
        const getRelatorio = async () => {
            setLoading(true);

            try {
                if (turma?.id) {
                    const response = await GetTurmaRelatorio(turma.id, filter);
                    setRelatorio(response);
                }
            } catch (error) {
                setRelatorio(undefined);
                console.error(error)
            } finally {
                setLoading(false);
            }
        };

        if (!!filter?.mes
            && filter.ano
            && filter?.mes >= 1 && filter?.mes <= 12
            && filter?.ano > 2000 && filter?.ano < 3000
        )

            getRelatorio();
    }, [filter, turma]);

    useEffect(() => {
        const relatorioInfoString = sessionStorage.getItem('relatorioInfo');
        if (relatorioInfoString) {
            const relatorioInfo = JSON.parse(relatorioInfoString);
            if (relatorioInfo) {
                const { mes, ano } = relatorioInfo;
                setFilter({ ...filter, mes, ano });
            }
        }
    }, []);


    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };


    return (
        <div>
            {!loading && <Button onClick={() => {
                if (!!turma?.id)
                    GetTurmaRelatorioPdf(turma?.id, filter)
            }}>
                <Icon name={'print'}></Icon>
                Imprimir</Button>}
            <Header as="h2" textAlign="center" color={'black'}>
                Relatório de participação da turma {turma?.nome}
            </Header>
            <Header as="h3" textAlign="center" color={'black'}>
                Treinamento {turma?.treinamentoNome}
            </Header>


            <div>
                {!!relatorio && (
                    <Table.Row>
                        <div>
                            <span><b>Cliente:</b> {relatorio?.clienteNome}</span>
                            <p></p>
                            <span><b>Treinamento:</b> {relatorio?.treinamentoNome}</span>
                            <p></p>
                            <span><b>Turma:</b> {relatorio?.turmaNome}</span>
                            <p></p>
                            <span><b>Professor:</b> {relatorio?.professorNome}</span>
                            <p></p>
                            <span><b>Colaboradores:</b> {relatorio?.quantidadeParticipantes} participantes</span>
                            <p></p>
                            <span><b>Participação média (%):</b>
                                {relatorio?.participacaoPorcentagem && (
                                    <Label color={
                                        relatorio?.participacaoPorcentagem < 49
                                            ? 'red'
                                            : relatorio?.participacaoPorcentagem > 60
                                                ? 'green'
                                                : 'orange'
                                    }>
                                        {relatorio?.participacaoPorcentagem?.toFixed(2)}%
                                    </Label>
                                )}
            </span>
                            <p></p>
                            <span><b>Início da turma:</b> {relatorio?.inicioTurma}</span>
                        </div>
                    </Table.Row>
                )}


                <br/>
                <Table.HeaderCell>
                    <Button content="Voltar para lista de turmas"
                            icon='arrow left'
                            onClick={() =>
                                navigate('/treinamentos/turmas')} grey/>
                </Table.HeaderCell>
                <Table.HeaderCell>
                    <Button onClick={toggleFilters} primary>
                        {showFilters ? 'Ocultar filtros' : 'Exibir filtros'}
                    </Button>
                </Table.HeaderCell>

                {showFilters && (
                    <Segment>
                        <div style={{padding: 10}}>
                            <Form>
                                <Form.Group widths='equal'>
                                    <Form.Field
                                        width={'4'}
                                        control={Select}
                                        fluid
                                        clearable
                                        label="Mês"
                                        placeholder="Mês"
                                        selection
                                        options={EMes}
                                        value={filter?.mes}
                                        search
                                        selectOnBlur={false}
                                        onChange={(_: React.ChangeEvent<HTMLInputElement>, data: {
                                            value: string;
                                        }) => {
                                            setFilter({...filter, mes: Number(data.value as string)});
                                        }}
                                    />
                                    <Form.Field
                                        width={'4'}
                                        control={Input}
                                        fluid
                                        label="Ano"
                                        placeholer="2023"
                                        type="number"
                                        value={filter?.ano}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            setFilter({
                                                ...filter,
                                                ano: Number(e.target.value),
                                            })
                                        }
                                    />
                                </Form.Group>
                            </Form>

                            {
                                ((sessionUser?.userData?.profile)
                                    || !filter?.mes
                                    || !filter?.ano)
                                && (<Message
                                    icon={'info'}
                                    size={'small'}
                                    info
                                    content={"Preencha os filtros para continuar."}></Message>)
                            }

                        </div>
                    </Segment>
                )}

                <Header as="h4" textAlign="center">
                    Lista de colaboradores/participantes
                </Header>
                {!!relatorio?.participantes && <Table color={"orange"}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell colSpan={12}>
                                <Header textAlign={'center'} as={'h4'}>{relatorio?.periodoDescricao}</Header>
                            </Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell>#</Table.HeaderCell>
                            <Table.HeaderCell>Nome</Table.HeaderCell>
                            <Table.HeaderCell>% Aproveitamento</Table.HeaderCell>
                            <Table.HeaderCell>Presença</Table.HeaderCell>
                            <Table.HeaderCell>Conceito</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {relatorio.participantes?.map((participante, index) => (
                            <Table.Row key={participante.participanteNome}>
                                <Table.Cell>{index + 1}</Table.Cell> {/* Célula de numeração */}
                                <Table.Cell>{`${participante?.participanteNome}`}</Table.Cell>
                                <Table.Cell>{`${participante?.participanteAproveitamento?.toFixed(2)} %`}</Table.Cell>
                                <Table.Cell>{`${participante?.participantePresencas}/${relatorio.aulasTotal}`}</Table.Cell>
                                <Table.Cell>
                                    <Label color={
                                        participante?.participanteAproveitamento < 49
                                            ? 'red'
                                            : participante?.participanteAproveitamento > 60
                                                ? 'green'
                                                : 'orange'
                                    }>
                                        {participante?.aproveitamentoDescricao}
                                    </Label>
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>}

            </div>
        </div>
    );
};

export default TurmaRelatorioPage

