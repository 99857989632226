import instance from "../../../index";
import {RelatorioProfessoresFilter} from "../../../../filters/relatorio-professores-filter";
import {RelatorioProfessores} from "../../../../models/relatorioProfessores";
import {ApiConfig} from "../../../../config/ApiConfig";
import StringUtils from "../../../../shared/utils/string-utils";

export async function GetRelatoriosProfessores(filter?: RelatorioProfessoresFilter): Promise<RelatorioProfessores> {
    const response = await instance.get<RelatorioProfessores>(`/v1/backoffice/relatorios/professores`, { params: filter });
    return response?.data;
}
export async function GetRelatoriosProfessoresPdf(filter?: RelatorioProfessoresFilter) {
    window.open(ApiConfig.API_URL + `/v1/backoffice/relatorios/professores/pdf`+ StringUtils.jsonToQueryString(filter), '_blank')
}