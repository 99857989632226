import instance from "services";
import {ClientFilter} from "../../../filters/client-filter";

export default async function GetClients(filter?: ClientFilter) : Promise<any> {
    const response = await instance.get('/v1/backoffice/clientes', {
        params: filter
    })
    return response.data.data
}
export async function GetAllClientsToDropdown() : Promise<any> {
    const response = await instance.get('/v1/backoffice/clientes/all')
    return response?.data?.data
}

export async function GetAllClientsToDropdownAnon() : Promise<any> {
    const response = await instance.get('/v1/backoffice/clientes/all-anon')
    return response?.data?.data
}
export interface GetClientsResponseData {
    id: string
    createdAt: string
    updatedAt: string
    fantasyName: string
    businessName: string
    cnpj: string
    cnae: string
    address: string
    addressNumber: number
    addressComplement: string
    addressNeighborhood: string
    zipCode: number
    city: string
    state: string
    isActive: boolean
}

export interface GetClientsResponse {
    success: boolean;
    data: GetClientsResponseData;
}
