import instance from "services";

export default DeleteUser

async function DeleteUser( id:string ) {
    const response = await instance.delete(`/v1/backoffice/backoffice-users/${id}`);
    return response.data;
}
export interface DeleteUserRequest {
    id: string;
}
export interface DeleteUserResponseData {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    createdAt: Date;
    updatedAt: Date;
    isActive: boolean;
}

export interface DeleteUserResponse {
    success: boolean;
    data: DeleteUserResponseData;
}
