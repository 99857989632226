import React, {useCallback, useEffect, useState} from 'react';
import {DashboardFilter} from "../../../../filters/dashboard-filter";
import {
    AnaliseSaude,
    DashboardInfoCliente,
    DashboardTurma,
    TotaisDesconforto
} from "../../../../models/dashboardInfoCliente";
import {Client} from "../../../../models/client";
import {Container, Form, Grid, Header, Message, Segment} from "semantic-ui-react";
import {GetAllClientsToDropdown} from "../../../../services/backoffice/clients/get-clients";
import {useBlockUI} from "../../../../shared/services/block-ui-service";
import GetDashboardByClient from "../../../../services/backoffice/dashboard-cliente/get-client-dashboard";
import GraficoParticipacaoGeral from '../components/porcentagem-participacao-geral';
import StringUtils from "../../../../shared/utils/string-utils";
import GraficoNaoParticipacaoGeral from "../components/porcentagem-nao-participacao-geral";
import GraficoPraticaExercicios from "../components/porcentagem-praticantes-exercicios";
import GraficoHabitosNaoSaudaveis from "../components/porcentagem-habitos-nao-saudaveis";
import GraficoIncomodoSaude from "../components/porcentagem-incomodo-ambiente-trabalho";
import GraficoDesconfortoDoresCorpo from "../components/quantidade-dores-desconforto-saude";
import SaudeGraph from "../components/ansiedade-grafico";

interface DashboardChartProps {
    data: {
        turmas: DashboardTurma[];
    };
}

const DashboardSaudeChart = () => {

    const [dashboardInfo, setDashboardInfo] = useState<DashboardInfoCliente>();
    const [loading, setLoading] = React.useState(false);
    const [client, setClient] = useState<Client>();
    const [clientId, setClientId] = useState<string>();
    const [clients, setClients] = useState<any[]>([]);

    const mesParam = new Date().getMonth() + 1;
    const anoParam = new Date().getFullYear();
    const [filter, setFilter] = useState<DashboardFilter>();
    const blockUI = useBlockUI();

    useEffect(() => {
        blockUI?.setActive(loading)
    }, [blockUI, loading]);

    useEffect(() => {
        function ListClients() {
            GetAllClientsToDropdown().then((response) => {
                setClients(response);
            })
                .catch(error => {
                    console.warn(error);
                })
        }

        ListClients()
    }, [])

    const handleDropdownChange = useCallback((name: string, data: any) => {
        setDashboardInfo((prevColeta: any) => ({
            ...prevColeta,
            [name]: data.value,
        }));
    }, []);

    const getDashboardData = useCallback(async () => {
        try {
            setLoading(true);
            if (clientId && filter?.PeriodoInicio && filter?.PeriodoFim) {
                const response = await GetDashboardByClient(clientId, {
                    PeriodoInicio: filter.PeriodoInicio,
                    PeriodoFim: filter.PeriodoFim,
                });
                setDashboardInfo(response);
            }
        } catch (error: any) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }, [clientId, filter]);

    const handleClientChange = useCallback(
        async (e: any, data: any) => {
            const selectedClient = clients.find((c) => c.id === data.value);
            setClient(selectedClient);
            setClientId(data.value);


            if (selectedClient) {
                await getDashboardData();
            }
        },
        [clients, handleDropdownChange, getDashboardData]
    );

    useEffect(() => {
        if (filter?.PeriodoFim) {
            getDashboardData();
        }
    }, [filter?.PeriodoFim, getDashboardData]);

    const formatDate = (date:any) => {
        if (!date) return '';

        const d = new Date(date);
        let month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    };

    // const analiseSaude: DashboardInfoCliente | undefined = dashboardInfo?.analiseSaude;
    // const desconfortosDoresCorpo: { [parteCorpo: string]: TotaisDesconforto | undefined } = analiseSaude?.desconfortoCabeca || {};

    const shouldRenderCharts = clientId && filter?.PeriodoInicio && filter?.PeriodoFim;



    return (
        <Container>
            <Header as="h2" textAlign="center" color={'black'}>
                Dashboard Métricas de Pesquisa de Saúde
            </Header>
            <Segment>
                <div style={{padding: 10}}>
                    <Form>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <Form.Select
                                    label={'Qual o Cliente?'}
                                    options={clients}
                                    placeholder='Selecione uma opção'
                                    value={clientId || ''}
                                    selectOnBlur={false}
                                    onChange={(e, data) => handleClientChange(e, data)}
                                    required
                                />

                            </Form.Field>
                            <Form.Input
                                width={'4'}
                                type="datetime-local"
                                fluid
                                label="Período Inicial de pesquisa"
                                placeholder="Selecione a data"
                                value={filter?.PeriodoInicio}
                                onChange={(e: React.SyntheticEvent<HTMLElement>, data: any) =>
                                    setFilter({
                                        ...filter,
                                        PeriodoInicio: data.value,
                                    })
                                }
                            />

                            <Form.Input
                                width={'4'}
                                type="datetime-local"
                                fluid
                                label="Período Final de pesquisa"
                                placeholder="Selecione a data"
                                value={filter?.PeriodoFim}
                                onChange={(e: React.SyntheticEvent<HTMLElement>, data: any) =>
                                    setFilter({
                                        ...filter,
                                        PeriodoFim: data.value,
                                    })
                                }
                            />
                        </Form.Group>
                    </Form>
                    {(!filter?.ClientId || !filter?.PeriodoInicio || !filter?.PeriodoFim) && (
                        <Message
                            icon={'info'}
                            size={'small'}
                            info
                            content={"Preencha os filtros para continuar."}
                        />
                    )}
                </div>
            </Segment>
            {!!dashboardInfo?.clienteNome && (
                <Segment>
                    <div>
                        <Header as="h2" textAlign="left" color={'black'}>
                            Cliente: {dashboardInfo?.clienteNome}
                        </Header>
                        <Header as="h4" textAlign="left" color={'black'}>
                            Período Inicial filtrado: {StringUtils.formatToBrazilianDate(dashboardInfo?.inicioPeriodo?.toString())}
                        </Header>
                        <Header as="h4" textAlign="left" color={'black'}>
                            Período Final: {StringUtils.formatToBrazilianDate(dashboardInfo?.fimPeriodo?.toString())}
                        </Header>
                    </div>
                </Segment>
            )}

            {shouldRenderCharts && (
                <Segment style={{ backgroundColor: '#f5f5f5', padding: '20px' }}>
                <Grid>
                    <Grid.Column width={4}
                                 style={{
                                     backgroundColor: '#13496a',
                                     display: 'flex',
                                     flexDirection: 'column',
                                     justifyContent: 'center',
                                     alignItems: 'center',
                                     height: '500px'
                                 }}>
                        {/* Painel lateral azul marinho */}
                        <p style={{
                            color: 'white',
                            fontSize: '1em', // Aumenta o tamanho do texto
                            fontWeight: 'bold', // Torna o texto em negrito
                        }}>
                            PRATICANTES DE EXERCÍCIOS FÍSICOS
                        </p>
                    </Grid.Column>
                    <Grid.Column width={12}
                                 style={{
                                     display: 'flex',
                                     alignItems: 'center',
                                     minHeight: '300px'
                                 }}>
                        {/* Container do gráfico */}
                        <div style={{ overflowX: 'auto' }}>
                            <div style={{ minWidth: '700px' }}> {/* Ajuste a largura mínima conforme necessário */}
                                <GraficoPraticaExercicios dashboardInfo={dashboardInfo}
                                                          totaisExercicio={dashboardInfo?.totaisExercicio}
                                ></GraficoPraticaExercicios>
                            </div>
                        </div>
                    </Grid.Column>
                </Grid>
            </Segment>
            )}

            {shouldRenderCharts && (
                <Segment style={{ backgroundColor: '#f5f5f5', padding: '20px' }}>
                <Grid>
                    <Grid.Column width={4}
                                 style={{
                                     backgroundColor: '#13496a',
                                     display: 'flex',
                                     flexDirection: 'column',
                                     justifyContent: 'center',
                                     alignItems: 'center',
                                     height: '500px'
                                 }}>
                        {/* Painel lateral azul marinho */}
                        <p style={{
                            color: 'white',
                            fontSize: '1em', // Aumenta o tamanho do texto
                            fontWeight: 'bold', // Torna o texto em negrito
                        }}>
                            % HÁBITOS QUE NÃO GERAM SAÚDE
                        </p>
                    </Grid.Column>
                    <Grid.Column width={12}
                                 style={{
                                     display: 'flex',
                                     alignItems: 'center',
                                     minHeight: '300px'
                                 }}>
                        {/* Container do gráfico */}
                        <div style={{ overflowX: 'auto' }}>
                            <div style={{ minWidth: '700px' }}> {/* Ajuste a largura mínima conforme necessário */}
                                <GraficoHabitosNaoSaudaveis dashboardInfo={dashboardInfo}
                                                                habitosNaoSaudaveis={dashboardInfo?.habitosQueNaoGeramSaude}
                                ></GraficoHabitosNaoSaudaveis>
                            </div>
                        </div>
                    </Grid.Column>
                </Grid>
            </Segment>
            )}

            {shouldRenderCharts && (
            <Segment style={{ backgroundColor: '#f5f5f5', padding: '20px' }}>
                <Grid>
                    <Grid.Column width={4}
                                 style={{
                                     backgroundColor: '#13496a',
                                     display: 'flex',
                                     flexDirection: 'column',
                                     justifyContent: 'center',
                                     alignItems: 'center',
                                     height: '500px'
                                 }}>
                        <p style={{
                            color: 'white',
                            fontSize: '1em', // Aumenta o tamanho do texto
                            fontWeight: 'bold', // Torna o texto em negrito
                        }}>
                            % INCOMODOS NO AMBIENTE DE TRABALHO
                        </p>
                    </Grid.Column>
                    <Grid.Column width={12}
                                 style={{
                                     display: 'flex',
                                     alignItems: 'center',
                                     minHeight: '300px'
                                 }}>
                        {/* Container do gráfico */}
                        <div style={{ overflowX: 'auto' }}>
                            <div style={{ minWidth: '700px' }}> {/* Ajuste a largura mínima conforme necessário */}
                                <GraficoIncomodoSaude dashboardInfo={dashboardInfo}
                                                            analiseSaude={dashboardInfo?.analiseSaude}

                                ></GraficoIncomodoSaude>
                            </div>
                        </div>
                    </Grid.Column>
                </Grid>
            </Segment>
            )}


            {shouldRenderCharts && (
            <Segment style={{ backgroundColor: '#f5f5f5', padding: '20px' }}>
                <Grid>
                    <Grid.Column width={4}
                                 style={{
                                     backgroundColor: '#13496a',
                                     display: 'flex',
                                     flexDirection: 'column',
                                     justifyContent: 'center',
                                     alignItems: 'center',
                                     height: '500px'
                                 }}>
                        {/* Painel lateral azul marinho */}
                        <p style={{
                            color: 'white',
                            fontSize: '1em', // Aumenta o tamanho do texto
                            fontWeight: 'bold', // Torna o texto em negrito
                        }}>
                            % DORES E DESCONFORTOS NO CORPO
                        </p>
                    </Grid.Column>
                    <Grid.Column width={12}
                                 style={{
                                     display: 'flex',
                                     alignItems: 'center',
                                     minHeight: '300px'
                                 }}>
                        {/* Container do gráfico */}
                        <div style={{ overflowX: 'auto' }}>
                            <div style={{ minWidth: '800px' }}>
                                <GraficoDesconfortoDoresCorpo
                                    desconfortosDoresCorpo={{
                                        'Cabeca': dashboardInfo?.desconfortoCabeca || {
                                            leve: 0,
                                            moderado: 0,
                                            intenso: 0,
                                            naoSintoDores: 0,
                                        },
                                        'Pescoço': dashboardInfo?.desconfortoPescoco || {
                                            leve: 0,
                                            moderado: 0,
                                            intenso: 0,
                                            naoSintoDores: 0,
                                        },
                                        'Ombros': dashboardInfo?.desconfortoOmbros || {
                                            leve: 0,
                                            moderado: 0,
                                            intenso: 0,
                                            naoSintoDores: 0,
                                        },
                                        'Bracos': dashboardInfo?.desconfortoBracos || {
                                            leve: 0,
                                            moderado: 0,
                                            intenso: 0,
                                            naoSintoDores: 0,
                                        },
                                        'Punhos': dashboardInfo?.desconfortoPunhos || {
                                            leve: 0,
                                            moderado: 0,
                                            intenso: 0,
                                            naoSintoDores: 0,
                                        },
                                        'Maos': dashboardInfo?.desconfortoMaos || {
                                            leve: 0,
                                            moderado: 0,
                                            intenso: 0,
                                            naoSintoDores: 0,
                                        },
                                        'Coluna': dashboardInfo?.desconfortoColuna || {
                                            leve: 0,
                                            moderado: 0,
                                            intenso: 0,
                                            naoSintoDores: 0,
                                        },
                                        'Quadril': dashboardInfo?.desconfortoQuadril || {
                                            leve: 0,
                                            moderado: 0,
                                            intenso: 0,
                                            naoSintoDores: 0,
                                        },
                                        'Joelhos': dashboardInfo?.desconfortoJoelhos || {
                                            leve: 0,
                                            moderado: 0,
                                            intenso: 0,
                                            naoSintoDores: 0,
                                        },
                                        'Coxas': dashboardInfo?.desconfortoCoxas || {
                                            leve: 0,
                                            moderado: 0,
                                            intenso: 0,
                                            naoSintoDores: 0,
                                        },
                                        'Pernas': dashboardInfo?.desconfortoPernas || {
                                            leve: 0,
                                            moderado: 0,
                                            intenso: 0,
                                            naoSintoDores: 0
                                        },
                                        'Tornozelos': dashboardInfo?.desconfortoTornozelos || {
                                            leve: 0,
                                            moderado: 0,
                                            intenso: 0,
                                            naoSintoDores: 0
                                        },
                                        'Pes': dashboardInfo?.desconfortoPes || {
                                            leve: 0,
                                            moderado: 0,
                                            intenso: 0,
                                            naoSintoDores: 0
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </Grid.Column>
                </Grid>
            </Segment>
            )}

            {shouldRenderCharts && (
            <Segment style={{ backgroundColor: '#f5f5f5', padding: '20px' }}>
                <Grid>
                    <Grid.Column width={4}
                                 style={{
                                     backgroundColor: '#13496a',
                                     display: 'flex',
                                     flexDirection: 'column',
                                     justifyContent: 'center',
                                     alignItems: 'center',
                                     height: '500px'
                                 }}>
                        <p style={{
                            color: 'white',
                            fontSize: '1em',
                            fontWeight: 'bold',
                        }}>
                            % TESTE DAS21 - ANSIEDADE, ESTRESSE E DEPRESSÃO
                        </p>
                    </Grid.Column>
                    <Grid.Column width={12}
                                 style={{
                                     display: 'flex',
                                     alignItems: 'center',
                                     minHeight: '300px'
                                 }}>
                        {/* Container do gráfico */}
                        <div style={{ overflowX: 'auto' }}>
                            <div style={{ minWidth: '700px' }}>
                                <SaudeGraph dashboardInfo={dashboardInfo}
                                                      analiseSaude={dashboardInfo?.analiseSaude}

                                ></SaudeGraph>
                            </div>
                        </div>
                    </Grid.Column>
                </Grid>
            </Segment>
            )}

        </Container>
    );
};

export default DashboardSaudeChart;
